/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { ChangeEvent, FC, useEffect, useReducer, useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { validate, validatorType } from '../../../utils/validators';
import * as Styled from './styles';

type InputProps = {
  id: string;
  type: string;
  value?: string;
  maxLength?: number;
  label?: string;
  placeholder?: string;
  errorText?: string;
  errorText2?: string;
  error2?: boolean;
  validators?: validatorType[];
  initialValue?: string;
  initialValid?: boolean;
  isLogin?: boolean;
  onInput?: (id: string, value: string, isValid: boolean) => void;
  disabled?: boolean;
  onChange?: (value: any) => any;
  onBlur?: (event: any) => any;
  min?: number;
  max?: number;
  deleteValidator?: boolean;
  paddingInfo?: boolean;
  onChangeInputSave?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type StateType = {
  value: string;
  isValid: boolean;
  isTouched: boolean;
};

type ChangeActionType = {
  type: 'CHANGE';
  payload: {
    value: string;
    validators: validatorType[] | undefined;
  };
};

type TouchActionType = {
  type: 'TOUCH';
};
const inputReducer = (
  state: StateType,
  action: ChangeActionType | TouchActionType
) => {
  switch (action.type) {
    case 'CHANGE':
      return {
        ...state,
        value: action.payload.value,
        isValid: action.payload.validators
          ? validate(action.payload.value, action.payload.validators)
          : true,
      };
    case 'TOUCH':
      return {
        ...state,
        isTouched: true,
      };

    default:
      return state;
  }
};
const InputForm: FC<InputProps> = (props) => {
  const [state, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || '',
    isValid: props.initialValid || false,
    isTouched: false,
  });
  const [showPassword, setShowPassword] = useState(false);

  const { id, onInput } = props;
  const { value, isValid } = state;

  useEffect(() => {
    { onInput ? onInput(id, value, isValid) : '' }
  }, [id, value, isValid, onInput]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'CHANGE',
      payload: {
        value: props.onChange ? props.onChange(event.target.value) : event.target.value,
        validators: props.validators,
      },
    });
  };

  const touchHandler = () => {
    dispatch({ type: 'TOUCH' });
  };



  const InputPassword = (
    <Styled.InputPassword className={props.isLogin ? 'white-theme' : ''}>
      <input
        type={showPassword ? 'text' : 'password'}
        id={props.id}
        placeholder={props.placeholder}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={state.value}
        autoComplete="off"
        maxLength={props.maxLength}
      />
      {!showPassword && <VisibilityOff cursor="pointer" color="disabled" onClick={() => setShowPassword(true)} />}
      {showPassword && (
        <Visibility cursor="pointer" color="disabled" onClick={() => setShowPassword(false)} />
      )}
    </Styled.InputPassword>
  );

  const Input = (
    <Styled.Input
      className={`${((!state.isValid && state.isTouched) || props.error2) && !props.deleteValidator ? 'invalid' : ''} ${props.isLogin ? 'white-theme' : ''}`}
      id={props.id}
      type={props.type}
      placeholder={props.placeholder}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        changeHandler(event);
        props.onChangeInputSave && props.onChangeInputSave(event);
      }}
      onBlur={touchHandler}
      value={props.value ? props.value : state.value}
      autoComplete="off"
      maxLength={props.maxLength}
      disabled={props.disabled}
      min={props.min}
      max={props.max}
    />
  );

  const InputArea = (
    <Styled.InputArea
      className={`${(!state.isValid && state.isTouched) || props.error2 ? 'invalid' : ''} ${props.isLogin ? 'white-theme' : ''}`}
      id={props.id}
      // name='test'
      value={state.value}
      placeholder={props.placeholder}
      onChange={(e: any) => (changeHandler(e), onInput!(props.id, e.target.value, true))}
      paddingInfo={props.paddingInfo}
    />
  );

  return (
    <Styled.Div>
      {props.label
        ?
        <Styled.Label
          htmlFor={props.id}
          className={props.isLogin ? 'white-theme' : ''}
        >
          {props.label}
        </Styled.Label>
        :
        ''
      }
      {props.type === "password" && InputPassword}
      {props.type === "textArea" && InputArea}
      {props.type !== "password" && props.type !== "textArea" && Input}

      {!state.isValid && state.isTouched && !props.disabled && (
        <Styled.Small className={props.isLogin ? 'white-theme' : ''}>
          {props.errorText}
        </Styled.Small>
      )}
      {props.error2 && !state.isTouched && !props.disabled && (
        <Styled.Small className={props.isLogin ? 'white-theme' : ''}>
          {props.errorText2}
        </Styled.Small>
      )}
    </Styled.Div>
  );
};

export default InputForm;
