import Dexie, { Table } from 'dexie'
import { StepCombiOvenCMAXModel } from '../models/stepCombiOvenCMAX'
import { StepCombiOvenTSIModel } from '../models/stepCombiOvenTSI'
import { StepSpeedOvenModel } from '../models/stepSpeedOven'
import Tutorials from '../models/Tutorials'
import PermissionTypesModel from '../models/permissionTypes'
import { StepLastroToroModel } from '../models/stepLastroToro'
import { StepConvectionOvenModel } from '../models/stepConvectionOven'

export interface LoggedUser {
  id?: number
  userName: string
  email: string
  emailVerified: boolean
  companyId: number
  phone: string
  userTypeId?: string
  accessToken: string
  userType?: string
  developerToken?: null
  country?: string
}

export interface UserConfigs {
  id?: number | null
  userId?: number | null
  language?: string | null
  tempUnit?: string | null
  weightUnit?: string | null
  theme?: string | null
  showAgain?: boolean | null
  showGroupModal?: boolean | null
  recover?: boolean | null
  companyTypeId?: string | null
  tutorialsUnmade?: Tutorials[] | null
  permission?: PermissionTypesModel[] | null
  timeOrVolume?: string
  showModalHome?: boolean | null
  showBanner?: boolean | null
  showModalSatisfactionSurvey?: boolean | null
  showModalSatisfactionDate?: string | null
}

export interface Company {
  id: number
  corporateName: string
  companyType: string
}

export interface Store {
  id: number
  storeName: string
  cnpj: string
  companyId: number
  street: string
  state: string
  neighborhood: string
  zipCode: number
  streetNumber: number
  city: string
  latitude: number
  longitude: number
  equipmentCount: number
}

export interface LocationDB {
  id: number
  locationName: string
  companyId: number
  street: string
  state: string
  neighborhood: string
  zipCode: number
  number: number
  city: string
  country: string
  latitude: number
  longitude: number
  equipmentCount: number
  showLocation: boolean
}

export interface ConfigModel {
  id: number,
  menuId: number,
  preHeat1: number,
  preHeat2: number,
  preHeat2Enabled: boolean,
  stabilizationTime: number,
  dateFormat: string,
  timeFormat: string,
  manualModeEnabled: boolean,
  favoritesEnabled: boolean,
  repeatRecipeEnabled: boolean,
  heatBrownMoreEnabled: boolean,
  temperatureUnit: string,
  weightUnit: string,
  theme: string,
  introduction: boolean,
  combiOvenEnabled: boolean,
  multipleCookingEnabled: boolean,
  technicookRecipesEnabled: boolean,
  editGroupsEnabled: boolean,
  operatorModeEnabled: boolean,
  turboGrillEnabled: boolean,
  enableRecipePreRunMessage: boolean,
  enableAutoImport: boolean
}

export interface Recipe {
  id: number,
  equipTypeId: number,
  groupId: number,
  menuId: number,
  recipeName: string,
  recipePosition: number,
  recipeImage: string,
  creationDate: string,
  createdBy: number,
  lastUpdate: string,
  updatedBy: number,
  isFavorite: boolean,
  heatMore: number,
  brownMore: number,
  heatBrownMore: number,
  ingredientType: number,
  dishType: number,
  ingredients: string,
  instructions: string,
  weight: number,
  entryTemp: number,
  preheatTemp: number,
  origin: string,
  preheatMode: string,
  preheatSteam: number,
  preheatType: string,
  steps: StepSpeedOvenModel[] | StepCombiOvenTSIModel[]
}

export interface Group {
  id: number,
  menuId: number,
  groupName: string,
  groupPosition: number,
  groupImage: string,
  preHeat: number,
  creationDate: string,
  lastUpdate: string,
  recipes: Recipe[]
}
export interface Menu {
  id: number,
  equipTypeId: number,
  companyId: number,
  menuName: string,
  creationDate: string,
  lastUpdate: string,
  menuVersion: number,
  deletionDate: string,
  userId: number,
  deletedBy: string,
  language: string,
  configs?: ConfigModel
}

export interface Cookbook {
  id?: number,
  equipTypeId: number,
  recipeName: string,
  recipeImage: string,
  creationDate: string,
  createdBy: number,
  lastUpdate: string,
  updatedBy: number,
  ingredientType: number,
  dishType: number,
  ingredients: string,
  instructions: string,
  weight: number,
  entryTemp: number,
  companyId: number | string,
  menuId: number,
  language: number,
  preheatTemp: number,
  origin: string,
  page?: string,
  preheatMode: string,
  preheatSteam: number,
  preheatType: string
}

export interface EquipmentModel {
  id?: number;
  name: string;
  categoryId: number;
  typeEquipment: String;
  storeId: number;
  serialNumber: String;
  creationDate: String;
  softwareVersion: String;
  sentMenu: number;
  companyId: number;
  iokPin: String;
  dataUpdate: boolean;
  appUpdate: boolean;
  statusData: String;
  statusApp: String;
  hashSw: String;
  menuId: number;
  lastUpdate: String;
  idUser: number;
  companyIdRemoteAccess?: string;
  powerVersion: string;
  forcedUpdate: boolean;
  status?: string
  executedCleanings?: number,
  executedRecipes?: number,
  notCompletedCleanings?: number,
  connectionDate?: string
  locationId?: number,
  ip?: string
};
export interface EquipmentSupportModel {
  id: number;
  name: string;
  categoryId: number;
  typeEquipment: String;
  storeId: number;
  serialNumber: String;
  creationDate: String;
  softwareVersion: String;
  sentMenu: number;
  companyId: number;
  iokPin: String;
  dataUpdate: boolean;
  appUpdate: boolean;
  statusData: String;
  statusApp: String;
  hashSw: String;
  menuId: number;
  lastUpdate: String;
  idUser: number;
  companyIdRemoteAccess?: string;
  powerVersion: string;
  forcedUpdate: boolean;
  userName: string;
  status: string
};

export class MySubClassedDixie extends Dexie {
  user!: Table<LoggedUser>
  company!: Table<Company>
  store!: Table<Store>
  userConfigs!: Table<UserConfigs>
  menu!: Table<Menu>
  group!: Table<Group>
  cookbook!: Table<Cookbook>
  equipment!: Table<EquipmentModel>
  equipmentSupport!: Table<EquipmentSupportModel>
  location!: Table<LocationDB>

  constructor() {
    super('iokPlat')
    this.version(1).stores({
      user: '++id, userName, email, emailVerified, companyId, phone, userTypeId, accessToken, developerToken',
      userConfigs: '++id, userId, language, tempUnit, weightUnit, theme, showAgain, showGroupModal, companyType, recover, tutorialsUnmade, showModalHome, showBanner, showModalSatisfactionSurvey, showModalSatisfactionDate',
      company: '++id, corporateName',
      store: '++id, storeName, cnpj, companyId, street, state, neighborhood, zipCode, streetNumber, city, latitude, longitude, equipmentCount',
      menu: '++id, equipTypeId, companyId, menuName, creationDate, lastUpdate, menuVersion, deletionDate, userId, deletedBy, language, configs',
      group: '++id, menuId, groupName, groupPosition, groupImage, preHeat, creationDate, lastUpdate',
      cookbook: '++id, equipTypeId, recipeName, recipeImage, creationDate, createdBy, lastUpdate, updatedBy, ingredientType, dishType, ingredients, instructions, weight, entryTemp, companyId, menuId, language, origin, preheatTemp, page, preheatMode, preheatSteam, preheatType',
      equipment: '++id, name, categoryId, typeEquipment, storeId, serialNumber, creationDate, softwareVersion, sentMenu, companyId, iokPin, dataUpdate, appUpdate, statusData, statusApp, hashSw, menuId, lastUpdate, idUser, companyIdRemoteAccess, powerVersion, forcedUpdate, status, connectionDate',
      equipmentSupport: '++id, name, categoryId, typeEquipment, storeId, serialNumber, creationDate, softwareVersion, sentMenu, companyId, iokPin, dataUpdate, appUpdate, statusData, statusApp, hashSw, menuId, lastUpdate, idUser, companyIdRemoteAccess, powerVersion, forcedUpdate, status',
      location: '++id, locationName, companyId, street, state, neighborhood, zipCode, number, city, country, latitude, longitude, equipmentCount, showLocation',
    })
  }
}

export const db = new MySubClassedDixie()
