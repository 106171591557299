import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

import equipmentsReducer from './equipments/equipmentSlice';
import equipmentsMasterReducer from './equipments/equipmentsMasterSlice';

import scrollReducer from './configs/scrollSlice';

import tutorialsReducer from './configs/tutorialsSlice';

import menusReducer from './menus/menusSlice';
import menuReducer from './menus/menuSlice';
import menuSimpleReducer from './menus/menuSimpleSlice';

import stepSpeedOvenReducer from './steps/stepSpeedOvenSlice';
import stepCombiOvenTSIReducer from './steps/stepCombiOvenTSISlice';
import stepCombiOvenCMAXReducer from './steps/stepCombiOvenCMAXSlice';
import stepLastroToroReducer from './steps/stepLastroToroSlice';
import stepConvectionOvenReducer from './steps/stepConvectionOvenSlice';

import userReducer from './user/userSlice';

const persistConfig = {
  key: 'user',
  storage,
};

const menuPersistConfig = {
  key: 'menu',
  storage: sessionStorage,
};

const stepsPersistConfig = {
  key: 'steps',
  storage: sessionStorage,
};

const persistedUserReducer = persistReducer(persistConfig, userReducer);
const persistedMenuReducer = persistReducer(menuPersistConfig, menuReducer);
const persistedMenuSimpleReducer = persistReducer(menuPersistConfig, menuSimpleReducer);
const persistedStepSpeedOvenReducer = persistReducer(stepsPersistConfig, stepSpeedOvenReducer);
const persistedStepCombiOvenTSIReducer = persistReducer(stepsPersistConfig, stepCombiOvenTSIReducer);
const persistedStepCombiOvenCMAXReducer = persistReducer(stepsPersistConfig, stepCombiOvenCMAXReducer);
const persistedStepLastroToroReducer = persistReducer(stepsPersistConfig, stepLastroToroReducer);
const persistedStepConvectionOvenReducer = persistReducer(stepsPersistConfig, stepConvectionOvenReducer);

const store = configureStore({
  reducer: {
    equipments: equipmentsReducer,
    equipmentsMaster: equipmentsMasterReducer,
    menus: menusReducer,
    scroll: scrollReducer,
    tutorials: tutorialsReducer,
    menu: persistedMenuReducer,
    menuSimple: persistedMenuSimpleReducer,
    user: persistedUserReducer,
    stepSpeedOven: persistedStepSpeedOvenReducer,
    stepCombiOvenTSI: persistedStepCombiOvenTSIReducer,
    stepCombiOvenCMAX: persistedStepCombiOvenCMAXReducer,
    stepLastroToro: persistedStepLastroToroReducer,
    stepConvectionOven: persistedStepConvectionOvenReducer
  }
})

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;