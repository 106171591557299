import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadMenuWithCount } from '../../models/Menu';

interface MenusState {
  list: LoadMenuWithCount[];
}

const initialState: MenusState = {
  list: []
};

const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    setMenus: (state, action: PayloadAction<LoadMenuWithCount[]>) => {
      state.list = action.payload;
    },
    addMenu: (state, action: PayloadAction<LoadMenuWithCount>) => {
      state.list.push(action.payload);
    },
    removeMenu: (state, action: PayloadAction<number>) => {
      state.list = state.list.filter(menu => menu.id !== action.payload);
    },
    updateMenu: (state, action: PayloadAction<LoadMenuWithCount>) => {
      const index = state.list.findIndex(menu => menu.id === action.payload.id);
      if (index !== -1) {
        state.list[index] = action.payload;
      }
    }
  }
});

export const { setMenus, addMenu, removeMenu, updateMenu } = menusSlice.actions;

export default menusSlice.reducer;
