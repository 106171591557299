import en from './en-US';
import fr from './fr-CA';
import de from './de-DE';
import pt from './pt-BR';
import es from './es-ES';
import ru from './ru-RU';
import pl from './pl-PL';
import el from './el-GR';
import zh from './zh-ZH';

const messages = {
  ...en,
  ...fr,
  ...de,
  ...pt,
  ...es,
  ...ru,
  ...pl,
  ...el,
  ...zh,
};

export default messages;
