import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StepCombiOvenCMAXModel } from '../../models/stepCombiOvenCMAX';

interface StepsCombiOvenCMAXState {
  steps: StepCombiOvenCMAXModel[];
}

const initialState: StepsCombiOvenCMAXState = {
  steps: [],
};

const stepsCombiOvenCMAXSlice = createSlice({
  name: 'stepsCombiOvenCMAX',
  initialState,
  reducers: {
    setStepsCombiOvenCMAX(state, action: PayloadAction<StepCombiOvenCMAXModel[]>) {
      state.steps = action.payload;
    },
    addStepCombiOvenCMAX(state, action: PayloadAction<StepCombiOvenCMAXModel>) {
      state.steps.push(action.payload);
    },
    removeStepCombiOvenCMAX(state, action: PayloadAction<number>) {
      state.steps = state.steps.filter(step => step.id !== action.payload);
    },
    updateStepCombiOvenCMAX(state, action: PayloadAction<{ index: number; changes: Partial<StepCombiOvenCMAXModel> }>) {
      const { index, changes } = action.payload;
      const step = state.steps[index];
      if (step) {
        state.steps[index] = { ...step, ...changes };
      }
    },
    clearStepsCombiOvenCMAX(state) {
      state.steps = [];
    }
  }
});

export const { setStepsCombiOvenCMAX, addStepCombiOvenCMAX, removeStepCombiOvenCMAX, updateStepCombiOvenCMAX, clearStepsCombiOvenCMAX } = stepsCombiOvenCMAXSlice.actions;

export default stepsCombiOvenCMAXSlice.reducer;