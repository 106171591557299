import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import * as Styled from './style';
type ButtonProps = {
  type: 'button' | 'submit' | 'reset';
  size?: 'large' | 'medium' | 'small' | 'largeNotUppercase' | 'extra-large' | 'buttonModal' | 'header';
  model:
  | 'red-solid'
  | 'white-solid'
  | 'white-outline'
  | 'confirmation-gradient'
  | 'confirmation-gradient-modal'
  | 'cancellation-gradient'
  | 'confirmation-solid'
  | 'outline-active'
  | 'outline-inactive'
  | 'outline-dark'
  | 'dark'
  | 'white-solid-with-black-text'
  | 'white-dark'
  | 'cancellation'
  | 'cancellationCloseButton';
  href?: string;
  to?: string;
  width?: string;
  mobileWidth?: string;
  onClick?: () => void;
  id?: string
};
const PrimaryButton: FC<ButtonProps> = (props) => {
  const classes = `${props.size} ${props.model}`;
  if (props.href) {
    return (
      <Styled.Button
        id={props.id}
        as='a'
        href={props.href}
        className={classes}
        width={props.width ? props.width : 'auto'}
        mobileWidth={props.mobileWidth ? props.mobileWidth : 'auto'}
      >
        {props.children}
      </Styled.Button>
    );
  }
  if (props.to) {
    return (
      <Link to={props.to}>
        <Styled.Button
          id={props.id}
          className={classes}
          width={props.width ? props.width : 'auto'}
          mobileWidth={props.mobileWidth ? props.mobileWidth : 'auto'}
        >
          {props.children}
        </Styled.Button>
      </Link>
    );
  }
  return (
    <Styled.Button
      id={props.id}
      type={props.type}
      onClick={props.onClick}
      className={classes}
      width={props.width ? props.width : 'auto'}
      mobileWidth={props.mobileWidth ? props.mobileWidth : 'auto'}
    >
      {props.children}
    </Styled.Button>
  );
};

export default PrimaryButton;
