import React, { ChangeEvent, FC, useEffect } from 'react';
import { InputWrapper, Input, Slider } from './styles'

type SwitchProps = {
  id: string
  valueTrue: string
  valueFalse: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onToggleChange?: (checked: boolean) => void;
  onInput?: (id: string, value: string, isValid: boolean) => void
  checked: boolean
}

const Switch: FC<SwitchProps> = (props) => {
  useEffect(() => {
    { props.onInput && props.onInput(props.id, (props.checked ? props.valueTrue : props.valueFalse), true) }
  }, [props.checked]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.onChange(event);
    if (props.onToggleChange) {
      props.onToggleChange(event.target.checked);
    }
  };

  return (
    <InputWrapper>
      <Input type='checkbox' checked={props.checked} onChange={handleChange} />
      <Slider />
    </InputWrapper>
  );
}

export default Switch;
