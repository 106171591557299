import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import MenuHamburgerIcon from '../../../assets/image/hamburger.svg';
import CookbookIcon from '../../../assets/image/cookbook.svg';
import HomeIcon from '../../../assets/image/home.svg';
import MenusIcon from '../../../assets/image/meus-menus.svg';
import NotificationIcon from '../../../assets/image/notification-full.svg';

import * as Styled from './style';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../../db/db';

enum ButtonActive {
  MENU,
  COOKBOOK,
  HOME,
  MENUS,
  NOTIFICATION,
  NOTHING,
}

const getCurrentLocation = (pathName: string) => {
  switch (pathName) {
    case '/home':
      return ButtonActive.HOME;
    case '/cookbook':
      return ButtonActive.COOKBOOK;
    case '/menu':
      return ButtonActive.MENUS;
    case '/notifications':
      return ButtonActive.NOTIFICATION;
    default:
      return ButtonActive.NOTHING;
  }
};

type FooterBarProps = {
  handleToggleNavbar: () => void;
  handleHideNavbar: () => void;
  navbarIsVisible: boolean;
};
const FooterBar = (props: FooterBarProps) => {
  const intl = useIntl();
  const userConfigDb = useLiveQuery(() => db.userConfigs.toArray());
  const companyDb = useLiveQuery(() => db.company.toArray());
  const { pathname } = useLocation();
  const [isActive, setIsActive] = useState<ButtonActive>(() =>
    getCurrentLocation(pathname)
  );

  useEffect(() => {
    setIsActive(getCurrentLocation(pathname));
  }, [pathname]);

  return (
    <Styled.FooterBar>
      <Styled.Container>
        <Styled.Button
          className={props.navbarIsVisible ? 'active' : ''}
          onClick={() => {
            props.handleToggleNavbar();
            setIsActive(ButtonActive.MENU);
          }}
        >
          <Styled.ImageButton src={MenuHamburgerIcon} alt='Menu hamburger' />
          <Styled.LabelButton>
            {intl.formatMessage({ id: 'footerBar.menu' })}
          </Styled.LabelButton>
        </Styled.Button>
        {(userConfigDb && userConfigDb[0].permission && userConfigDb[0].permission.find((permission) => permission.permission === "cookbook")) &&
          <Link
            to='/cookbook'
            className={isActive === ButtonActive.COOKBOOK ? 'active' : ''}
            onClick={() => {
              props.handleHideNavbar();
              setIsActive(ButtonActive.COOKBOOK);
            }}
          >
            <Styled.ImageButton src={CookbookIcon} alt='Cookbook' />
            <Styled.LabelButton>
              {intl.formatMessage({ id: 'footerBar.cookbook' })}
            </Styled.LabelButton>
          </Link>
        }

        {companyDb && companyDb[0].companyType !== 'Suporte' &&
          <Link
            to='/home'
            className={isActive === ButtonActive.HOME ? 'active' : ''}
            onClick={() => {
              props.handleHideNavbar();
              setIsActive(ButtonActive.HOME);
            }}
          >
            <Styled.ImageButton src={HomeIcon} alt='Home' />
            <Styled.LabelButton>
              {intl.formatMessage({ id: 'footerBar.home' })}
            </Styled.LabelButton>
          </Link>
        }

        {(userConfigDb && userConfigDb[0].permission && userConfigDb[0].permission.find((permission) => permission.permission === "menus")) &&
          <Link
            to='/menu'
            className={isActive === ButtonActive.MENUS ? 'active' : ''}
            onClick={() => {
              props.handleHideNavbar();
              setIsActive(ButtonActive.MENUS);
            }}
          >
            <Styled.ImageButton src={MenusIcon} alt='Menus' />
            <Styled.LabelButton>
              {intl.formatMessage({ id: 'footerBar.menus' })}
            </Styled.LabelButton>
          </Link>
        }




        {
          /*
           <Link
                to='/notifications'
                className={isActive === ButtonActive.NOTIFICATION ? 'active' : ''}
                onClick={() => {
                  props.handleHideNavbar();
                  setIsActive(ButtonActive.NOTIFICATION);
                }}
              >
          
  
        <Styled.ImageButton src={NotificationIcon} alt='Notification' />
        <Styled.LabelButton>
          {intl.formatMessage({ id: 'footerBar.notifications' })}
        </Styled.LabelButton>
      </Link>
      */
        }

      </Styled.Container >
    </Styled.FooterBar>
  );
};

export default FooterBar;
