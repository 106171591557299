import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecipeModel } from '../../models/Recipe';

interface MenuState {
  recipes: RecipeModel[];
}

const initialState: MenuState = {
  recipes: [],
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenuRecipes(state, action: PayloadAction<RecipeModel[]>) {
      state.recipes = action.payload;
    },
    updateRecipe(state, action: PayloadAction<RecipeModel>) {
      const index = state.recipes.findIndex(recipe => recipe.id === action.payload.id);
      if (index !== -1) {
        state.recipes[index] = { ...action.payload };
      }
    },
    clearMenuRecipes(state) {
      state.recipes = [];
    }
  }
});

export const { setMenuRecipes, updateRecipe, clearMenuRecipes } = menuSlice.actions;

export default menuSlice.reducer;
