import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StepSpeedOvenModel } from '../../models/stepSpeedOven';

interface StepsSpeedOvenState {
  steps: StepSpeedOvenModel[];
}

const initialState: StepsSpeedOvenState = {
  steps: [],
};

const stepsSpeedOvenSlice = createSlice({
  name: 'stepsSpeedOven',
  initialState,
  reducers: {
    setStepsSpeedOven(state, action: PayloadAction<StepSpeedOvenModel[]>) {
      state.steps = action.payload;
    },
    addStepSpeedOven(state, action: PayloadAction<StepSpeedOvenModel>) {
      state.steps.push(action.payload);
    },
    removeStepSpeedOven(state, action: PayloadAction<number>) {
      state.steps = state.steps.filter(step => step.id !== action.payload);
    },
    updateStepSpeedOven(state, action: PayloadAction<{ index: number; changes: Partial<StepSpeedOvenModel> }>) {
      const { index, changes } = action.payload;
      const step = state.steps[index];
      if (step) {
         state.steps[index] = { ...step, ...changes };
      }
    },
    clearStepsSpeedOven(state) {
      state.steps = [];
    }
  }
});

export const { setStepsSpeedOven, addStepSpeedOven, removeStepSpeedOven, updateStepSpeedOven, clearStepsSpeedOven } = stepsSpeedOvenSlice.actions;

export default stepsSpeedOvenSlice.reducer;