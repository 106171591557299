import styled, { css } from 'styled-components';
import * as DesignSystem from '../../../assets/styles/StyleTypes';

type ContainerProps = {
  width: string;
  moveUp?: boolean;
};

export const Container = styled.div<ContainerProps>`
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 79vh;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 5px;
  color: ${(props) => props.theme.colors.textSecondary};
  background: ${(props) => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};

  @media (max-width: 768px) {
    top: 0;
    left: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    justify-content: space-around;
    transform: none; 
  }
  @media (max-width: 650px) {
    max-width: 100%;
  }
  @media (max-width: 500px) {
    max-width: 100%;
  }

  ${(props) =>
    props.moveUp &&
    css`
      z-index: 11;
    `}
`;

export const Header = styled.div`
  position: relative;
  flex-basis: 50px;
  min-height: 50px;
  background: ${(props) => props.theme.colors.backgroundHeaderModal};
  color: ${(props) => props.theme.colors.textSecondary};
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0px;

  @media (max-width: 768px) {
    background: none;
    color: ${(props) => props.theme.colors.modalHeaders};
  }
`;

export const Title = styled.h2`
  ${DesignSystem.Typography.SUBTITLE_LG}
`;

export const CloseButton = styled.button`
  cursor: pointer;
  background-color: ${DesignSystem.Colors.HIGH_PURE};
  color: ${(props) => props.theme.colors.header};
  height: 20px;
  width: 20px;
  font-weight: 600;
  border-color: transparent;
  outline: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;

  @media (max-width: 960px) {
    left: 92%;
  }
`;

interface FooterFixedProps {
  footerFixed?: boolean
}

export const Content = styled.div<FooterFixedProps>`
  padding: ${DesignSystem.Padding.SM};
  //max-height: calc(80vh - 130px);
  overflow-y: none;
  color: ${(props) => props.theme.colors.textPrimary};

  ${props => props.footerFixed &&
    css`

      margin-bottom: 55px;
    `
  }

  @media screen and (max-width: 700px) {
    padding: 10px
  }
  @media screen and (max-width: 500px) {
    padding: 6px
  }
`;

export const Footer = styled.div<FooterFixedProps>`
  padding: ${DesignSystem.Padding.SM};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px;

  ${props => props.footerFixed &&
    css`
      padding-top: 40px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: ${(props) => props.theme.colors.background};
    `
  }

  @media screen and (max-width: 768px) {
    
  }
`;

export const Form = styled.form`
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90vh;
  }
`;
