import { LOCALES } from '../constants';
const ru = {
  [LOCALES.RU]: {
    //LOGIN
    "login.Login": "Вход",
    "login.Informe seu e-mail": "Введите свой адрес электронной почты",
    "login.E-mail inválido. Por favor, verifique": "Неверный адрес электронной почты. Пожалуйста, проверьте",
    "login.Senha": "Пароль",
    "login.Sua senha deve possuir no mínimo 6 caracteres": "Ваш пароль должен содержать не менее 6 символов",
    "login.Permanecer conectado": "Оставайтесь на связи",
    "login.Esqueci minha senha": "Забыл пароль",
    "login.ENTRAR": "ВОЙТИ",
    "login.CRIAR CONTA": "СОЗДАТЬ АККАУНТ",
    "login.Não foi possÍvel fazer o login. Verifique seus dados e tente novamente": "Не удалось войти. Проверьте свои данные и попробуйте снова",
    "login.Solicitar Acesso": "Запросить доступ",
    "login.Utilize esta opção caso possua apenas fornos da versão antiga, que não geram PIN.": "Используйте эту опцию, если у вас есть только старые версии печей, которые не генерируют ПИН-код.",
    "login.A PLATAFORMA IOK MUDOU!": "ПЛАТФОРМА IOK ИЗМЕНИЛАСЬ!",
    "login.Não exibir este aviso novamente": "Не показывать это уведомление снова",
    "login.Se você já possui um cadastro na antiga plataforma,": "Если у вас уже есть регистрация на предыдущей платформе,",
    "login.clique aqui": "нажмите здесь",
    "login. para migrar seus dados e aproveitar toda a experiência IOK.": "чтобы перенести свои данные и наслаждаться полным опытом IOK.",
    "login.Sua conta não foi ativada. Por gentileza, verifique seu e-mail para realizar a ativação": "Ваш аккаунт не активирован. Пожалуйста, проверьте свой адрес электронной почты, чтобы активировать его",
    "login.Para solicitar acesso a uma organização, o nome informado por você deve ser exatamente o mesmo cadastrado pelo administrador da conta. Em caso de dúvida, procure o responsável pela rede.": "Для запроса доступа к организации, предоставленное вами имя должно точно совпадать с зарегистрированным именем администратора аккаунта. В случае сомнений, ищите ответственную цепочку.",
    "login.Se você já possui um cadastro na antiga plataforma, ": "Если у вас уже есть регистрация на старой платформе, ",
    "login. para": " для ",
    "login.migrar": "мигрировать ",
    "login.seus dados e aproveitar toda a experiência IOK.": "ваши данные и наслаждайтесь всеми возможностями IOK.",
    "login.PLATAFORMA EM MANUTENÇÃO": "ПЛАТФОРМА НА ОБСЛУЖИВАНИИ",
    "login.ESTAMOS PREPARANDO A NOVA PLATAFORMA IOK E EM BREVE ELA ESTARÁ PRONTA!": "МЫ ГОТОВИМ НОВУЮ ПЛАТФОРМУ IOK, И СКОРО БУДЕТ ГОТОВА!",
    "login.Migrar Conta": "Перенести аккаунт",
    "login.Se você já possui um cadastro na antiga plataforma, clique aqui em Migrar Conta para migrar seus dados e aproveitar toda a experiência IOK": "Если у вас уже есть регистрация на старой платформе, нажмите здесь «Перенести учетную запись», чтобы перенести свои данные и насладиться всеми возможностями IOK.",
    "login.Política de privacidade": "политика конфиденциальности",
    "login.ENTENDI": "ХОРОШО",
    "login.Para melhorar a sua experiência na plataforma e oferecer serviços personalizados, utilizamos cookies.": "Мы используем файлы cookie, чтобы улучшить ваше взаимодействие с платформой и предложить персонализированные услуги.",
    "login.Ao utilizar a Plataforma IOK, você automaticamente concorda com seu uso.": "Используя Платформу IOK, вы автоматически соглашаетесь на ее использование.",

    //REGISTER
    "DISTRIBUIDOR": "ДИСТРИБЬЮТОР: Я являюсь дистрибьютором оборудования для практики.",
    "REDE": "СЕТЬ: У меня есть сеть.",
    "DONO": "КОМПАНИЯ: У меня собственная компания/я являюсь частью сети.",
    "register.Informe seu e-mail cadastrado na plataforma IOK anterior": "Введите свой адрес электронной почты, зарегистрированный на предыдущей платформе IOK",
    "register.E-mail inválido. Por favor, verifique": "Неверный адрес электронной почты. Пожалуйста, проверьте",
    "register.BUSCAR DADOS": "ПОИСК ДАННЫХ",
    "register.Nome da organização": "Название организации",
    "register.Informe o nome de sua organização": "Введите название вашей организации",
    "register.O campo nome da organização é obrigatório": "Поле с названием организации обязательно для заполнения",
    "register.Este nome já existe. Por favor, escolha outro": "Это имя уже существует. Пожалуйста, выберите другое",
    "register.RECUPERAR CONTA": "ВОССТАНОВИТЬ АККАУНТ",
    "register.E-mail não encontrado": "Электронная почта не найдена",
    "register.Digite seu e-mail da plataforma IOK antiga": "Введите свой адрес электронной почты с предыдущей платформы IOK",
    "register.Não foi possível recuperar sua conta. Verifique seus dados e tente novamente": "Не удалось восстановить ваш аккаунт. Проверьте ваши данные и попробуйте снова",
    "register.Não foi possível recuperar seus dados. Verifique e tente novamente": "Не удалось восстановить ваши данные. Проверьте и попробуйте снова",
    "register.PROSSEGUIR": "ПРОДОЛЖИТЬ",
    "register.VOCÊ ESTÁ SOLICITANDO ACESSO PARA A ORGANIZAÇÃO": "ВЫ ЗАПРАШИВАЕТЕ ДОСТУП К ОРГАНИЗАЦИИ:",
    "register.COM O ADMINISTRADOR": "С АДМИНИСТРАТОРОМ:",
    "register.E-mail já cadastrado na plataforma. Informe um novo e-mail ou acesse a plataforma com o e-mail já cadastrado": "Электронная почта уже зарегистрирована на платформе. Введите новый адрес электронной почты или войдите в платформу с уже зарегистрированным адресом",
    "register.Empresa já cadastrada na plataforma. Por favor, informe o nome de uma nova empresa": "Компания уже зарегистрирована на платформе. Пожалуйста, введите новое название компании",
    "register.VOCÊ NÃO RECONHECEU O USUÁRIO {userName} COMO PARTE DA SUA ORGANIZAÇÃO E POR ISSO A CONTA DELE FOI DELETADA.": "ВЫ НЕ УЗНАЛИ ПОЛЬЗОВАТЕЛЯ {userName} ЧАСТЬЮ ВАШЕЙ ОРГАНИЗАЦИИ И ПО ЭТОЙ ПРИЧИНЕ ИХ АККАУНТ БЫЛ УДАЛЕН.",
    "register.NÂO FOI POSSÍVEL DELETAR O USÁRIO {userName}. POR GENTILEZA, TENTE NOVAMENTE MAIS TARDE.": "НЕЛЬЗЯ УДАЛИТЬ ПОЛЬЗОВАТЕЛЯ {userName}. ПОЖАЛУЙСТА, ПОПРОБУЙТЕ ПОЗЖ",
    "register.CONFIRMAR": "ПОДТВЕРДИТЬ",
    "register.Informe o ID da organização": "Укажите идентификатор вашей организации",
    "register.O campo com ID é obrigatório": "Поле идентификатора обязательно для заполнения",
    "register.Ainda não tenho uma organização. Criar": "У меня еще нет организации. Создать",
    "register.ID informado não corresponde a nenhuma companhia. Verifique e tente novamente": "Указанный идентификатор не соответствует ни одной компании. Проверьте и повторите попытку",
    "register.O ADMINISTRADOR DA ORGANIZAÇÃO RECEBERÁ UM E-MAIL PARA ATIVAR SUA CONTA. POR GENTILIZA, AGUARDE": "АДМИНИСТРАТОР ОРГАНИЗАЦИИ ПОЛУЧИТ ЭЛЕКТРОННОЕ ПИСЬМО ДЛЯ АКТИВАЦИИ ВАШЕГО УЧЕТНОГО ЗАПИСИ ПОЛЬЗОВАТЕЛЯ. ПОЖАЛУЙСТА, ПОДОЖДИТЕ",
    "register.Este nome de franquia já possui uma conta ativa na plataforma": "Это название франшизы уже имеет активный аккаунт на платформе",
    "register.Não foi possível fazer o cadastro. Verifique seus dados e tente novamente": "Не удалось зарегистрироваться. Проверьте ваши данные и повторите попытку",
    "register.VOCÊ RECEBERÁ UM E-MAIL PARA CONCLUIR SEU CADASTRO E ATIVAR SUA CONTA DE ADMINISTRADOR": "ВЫ ПОЛУЧИТЕ ЭЛЕКТРОННОЕ ПИСЬМО ДЛЯ ЗАВЕРШЕНИЯ РЕГИСТРАЦИИ И АКТИВАЦИИ ВАШЕГО АККАУНТА АДМИНИСТРАТОРА",
    "register.Nome": "Имя",
    "register.Informe seu nome": "Введите ваше имя",
    "register.O campo nome é obrigatório": "Поле имени обязательно для заполнения",
    "register.E-mail": "Электронная почта",
    "register.Informe seu e-mail": "Введите свою электронную почту",
    "register.E-mail inválido. Por favor, verifique novamente": "Неверный адрес электронной почты. Пожалуйста, проверьте",
    "register.Telefone": "Номер телефона",
    "register.Informe seu telefone": "Введите свой номер телефона",
    "register.Crie sua senha": "Создать пароль",
    "register.Informe sua senha": "Укажите ваш пароль",
    "register.O campo senha deve conter no mínimo 6 caracteres": "Поле пароля должно содержать не менее 6 символов",
    "register.Confirme sua senha": "Подтвердите пароль",
    "register.As senhas devem ser iguais": "Пароли должны совпадать",
    "register.CRIAR CONTA": "СОЗДАТЬ АККАУНТ",
    "register.CONTA ATIVADA COM SUCESSO": "АККАУНТ УСПЕШНО АКТИВИРОВАН",
    "register.ACESSAR": "ДОСТУП",
    "register.NÃO FOI POSSÍVEL ATIVAR SUA CONTA": "НЕ УДАЛОСЬ АКТИВИРОВАТЬ ВАШ АККАУНТ",
    "register.Digite seu telefone": "Укажите свой номер телефона",
    "register.AGUARDE ENQUANTO TRANSFERIMOS SEUS DADOS. ESTE PROCESSO DEVE LEVAR ALGUNS MINUTOS. CASO VOCÊ POSSUA UMA GRANDE QUANTIDADE DE DADOS, A TRANSFERÊNCIA PODE LEVAR ATÉ UMA HORA.": "ПОЖАЛУЙСТА, ПОДОЖДИТЕ, ПОКА МЫ ПЕРЕДАЕМ ВАШИ ДАННЫЕ. ЭТОТ ПРОЦЕСС ДОЛЖЕН ЗАНЯТЬ НЕСКОЛЬКО МИНУТ. ЕСЛИ У ВАС БОЛЬШОЙ ОБЪЕМ ДАННЫХ, ПЕРЕДАЧА МОЖЕТ ЗАНЯТЬ ДО ОДНОГО ЧАСА.",
    "register.DADOS TRANSFERIDOS COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "ДАННЫЕ ПЕРЕНЕСЕНЫ УСПЕШНО! НАЖМИТЕ КНОПКУ НИЖЕ, ЧТОБЫ ПЕРЕЙТИ НА ПЛАТФОРМУ.",
    "register.EMAIL INVÁLIDO. POR GENTILEZA, VERIFIQUE SE SUA CONTA JÁ FOI ATIVADA.": "Неверный адрес электронной почты. Пожалуйста, проверьте, был ли ваш аккаунт уже активирован.",
    "register.A nova plataforma IOK agora conta com novas formas de acesso - que são referentes ao tipo de organização em que você atua. O responsável pela sua organização deve migrar a conta principal da empresa, se tornando o administrador, enquanto o restante dos funcionários devem se vincular a ela pelo ID fornecido.": "На новой платформе IOK теперь есть новые формы доступа, которые связаны с типом организации, в которой вы работаете. Ответственное лицо за вашу организацию должно перенести основной аккаунт компании, став администратором, в то время как остальные сотрудники должны присоединиться к нему, используя предоставленный идентификатор.",
    "register.O administrador da minha organização já realizou a migração da conta e temos um ID. Desejo migrar meus dados de usuário.": "Администратор моей организации уже перенес аккаунт, и у нас есть идентификатор. Я хочу перенести данные моего пользователя.",
    "register.Desejo migrar a conta da minha organização e me tornar o administrador.": "Я хочу перенести аккаунт моей организации и стать администратором.",
    "register.Conta da organização já migrada": "Аккаунт организации уже перенесен",
    "register.Migrar conta da organização": "Перенести аккаунт организации",
    "register.VOCÊ POSSUI MUITOS MENUS, NÃO CONSEGUIMOS REALIZAR A IMPORTAÇÃO AUTOMÁTICA. SUA CONTA SERÁ ATIVADA, PORÉM OS MENUS PRECISARÃO SER MIGRADOS MANUALMENTE.": "У ВАС МНОГО МЕНЮ, МЫ НЕ МОЖЕМ ВЫПОЛНИТЬ АВТОМАТИЧЕСКИЙ ИМПОРТ. ВАШ АККАУНТ БУДЕТ АКТИВИРОВАН, НО МЕНЮ НЕОБХОДИМО ПЕРЕНОСИТЬ ВРУЧНУЮ.",
    "register.PARA MIGRAR SEUS MENUS, APÓS O LOGIN, CLIQUE NO SEU NOME NO CANTO SUPERIOR DIREITO, CLIQUE EM EDITAR CONTA E DEPOIS EM IMPORTAR MENUS.": "ЧТОБЫ ПЕРЕНОСИТЬ ВАШЕ МЕНЮ, ПОСЛЕ ВХОДА В ВХОД, НАЖМИТЕ НА СВОЕ ИМЯ В ПРАВОМ ВЕРХНЕМ УГЛУ, НАЖМИТЕ РЕДАКТИРОВАТЬ АККАУНТ, А ЗАТЕМ ИМПОРТ МЕНЮ.",
    "register.Atenção:": "Внимание:",
    "register.apenas uma pessoa da organização pode criar a conta e, depois de ativá-la, convidar os demais usuários da empresa para acessá-la.": "только одно лицо из организации может создать учетную запись и после активации пригласить других пользователей компании для доступа к ней.",
    "register.TOKEN DESENVOLVEDOR CANCELADO": "ТОКЕН РАЗРАБОТЧИКА ОТМЕНЁН",

    //HOME
    "home.Menus disponíveis na plataforma": "Меню, доступные на платформе",
    "home.Criados na plataforma": "Создано на платформе",
    "home.Recebidos dos fornos": "Получено от печей",
    "home.Equipamentos vinculados": "Связанные устройства",
    "home.Total de receitas cadastradas": "Общее количество зарегистрированных рецептов",
    "home.Receitas": "Рецепты",
    "home.Receitas em menus": "Рецепты в меню",
    "home.Em menus": "В меню",
    "home.Receitas salvas no Cookbook": "Рецепты, сохраненные в кулинарной книге",
    "home.No Cookbook": "кулинарной книге",
    "home.Atualizações": "Обновления",
    "home.Copa": "Copa",
    "home.Rocket": "Rocket",
    "home.Single Mag": "Single Mag",
    "home.Versão instalada": "Установленная версия",
    "home.Versão disponível": "Доступная версия",
    "home.Usuários cadastrados": "Зарегистрированные пользователи",
    "home.Aceitação pendente": "Ожидает принятия",
    "home.Atualizado": "Обновлено",
    "home.OLÁ": "ПРИВЕТ",
    "home.É muito bom ter você aqui!": "Как здорово, что вы здесь!",
    "home.Com a Plataforma IOK é possível administrar seus fornos de onde estiver, criar e gerenciar receitas e menus, fazer backups, analisar os dados de seus equipamentos e muito mais!": "С помощью платформы IOK вы можете управлять своими печами, где бы вы ни находились, создавать рецепты и меню и управлять ими, делать резервные копии, анализировать данные с вашего оборудования и многое другое!",
    "home.Conheça a plataforma": "Откройте для себя платформу",
    "home.Taxa de erro por modelo": "Коэффициент ошибок на модель",
    "home.Erro": "Ошибка",
    "home.Modelos em evidência": "Модели в центре внимания",
    "home.Modelos": "Модели",
    "home.Total de equipamentos vinculados": "Общее количество подключенных устройств",
    "home.Total de equipamentos online": "Общее количество онлайн-устройств",
    "home.Equipamentos online": "Онлайн-устройства",
    "home.Países com Prática": "Страны с Prática",
    "home.Países": "Страны",
    "home.Aguarde enquanto carregamos os dados": "Пожалуйста, подождите, пока мы загружаем данные",
    "home.Quase lá! Aguarde um pouco mais": "Почти готово! Пожалуйста, подождите немного дольше",
    "home.QUEREMOS SABER SUA OPINIÃO SOBRE": "МЫ ХОТИМ УЗНАТЬ ВАШЕ МНЕНИЕ О",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a Plataforma {model} foi criado!": "Мы очень ценим вашу точку зрения, поэтому была создана эта форма опроса удовлетворенности на платформе {model}!",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a linha {model} foi criado!": "Мы очень ценим вашу точку зрения, поэтому была создана эта форма опроса удовлетворенности линейкой {model}!",
    "home.Suas respostas nos ajudarão a aprimorar nossos serviços, garantindo que continuemos a oferecer a você a melhor experiência possível.": "Ваши ответы помогут нам улучшить наши услуги, гарантируя, что мы продолжим предоставлять вам наилучшие впечатления",
    "home.Reserve uns minutinhos de seu dia e participe!": "Выделите несколько минут своего дня и примите участие!",
    "home.Responder a pesquisa": "Ответить на опрос",
    "home.Vendas por Equipamento": "Продажи по оборудованию",
    "home.Total de equipamentos vendidos": "Всего продано оборудования",
    "home.Equipamentos vendidos": "Проданное оборудование",

    //MAIN
    "main.HOME": "ДОМОЙ",
    "main.COOKBOOK": "КУЛИНАРНАЯ КНИГА",
    "main.MEUS MENUS": "МОИ МЕНЮ",
    "main.EQUIPAMENTOS": "ОБОРУДОВАНИЕ",
    "main.USUÁRIOS": "ПОЛЬЗОВАТЕЛИ",
    "main.ATENDIMENTOS": "УСЛУГИ",
    "main.Idioma": "Язык",
    "main.Editar conta": "Редактировать аккаунт",
    "main.Configurações": "Настройки",
    "main.Sair": "Выйти",
    "main.Configurações salvas com sucesso!": "Настройки успешно сохранены!",
    "main.SALVAR": "СОХРАНИТЬ",
    "main.CONFIGURAÇÕES": "НАСТРОЙКИ",
    "main.Tema dark": "Тёмная тема",
    "main.Notificações": "Уведомления",
    "main.Informações para as receitas": "Информация о рецепте",
    "main.Peso:": "Вес",
    "main.Gramas": "Граммы",
    "main.Oz": "Унции",
    "main.Temperatura:": "Температуры:",
    "main.Celsius (°C)": "Цельсий (°C)",
    "main.Fahrenheit (°F)": "Фаренгейт (°F)",
    "main.Conta editada com sucesso!": "Аккаунт успешно отредактирован!",
    "main.EDITAR CONTA": "РЕДАКТИРОВАТЬ АККАУНТ",
    "main.Nome": "Имя",
    "main.Preencha com o seu nome": "Заполните ваше имя",
    "main.Telefone": "Телефон",
    "main.Preencha com o seu telefone": "Заполните ваш номер телефона",
    "main.E-mail": "Электронная почта",
    "main.Preencha com o seu e-mail": "Заполните ваш адрес электронной почты",
    "main.Alterar Senha": "Изменить пароль",
    "main.ALTERAR SENHA": "ИЗМЕНИТЬ ПАРОЛЬ",
    "main.Senha atual": "Текущий пароль",
    "main.Nova senha": "Новый пароль",
    "main.Confirmar nova senha": "Подтвердите новый пароль",
    "main.Informe sua senha atual": "Введите текущий пароль",
    "main.O campo senha deve conter no mínimo 6 caracteres": "Пароль должен содержать не менее 6 символов",
    "main.Informe sua nova senha": "Введите новый пароль",
    "main.Confirme sua nova senha": "Подтвердите новый пароль",
    "main.Sua nova senha deve conter no mínimo 6 caracteres": "Новый пароль должен содержать не менее 6 символов",
    "main.INFORME SUA SENHA": "ВВЕДИТЕ ВАШ ПАРОЛЬ",
    "main.Informe sua senha para realizar a alteração de e-mail da plataforma:": "Введите свой пароль для изменения адреса электронной почты на платформе:",
    "main.CONFIRMAR": "ПОДТВЕРДИТЬ",
    "main.Existem campos que não foram preenchidos corretamente": "Есть поля, которые не были правильно заполнены",
    "main.Informe sua senha para prosseguir com a alteração de e-mail": "Введите пароль, чтобы продолжить изменение адреса электронной почты",
    "main.Senha alterada com sucesso": "Пароль успешно изменен",
    "main.Informe sua senha atual corretamente": "Введите текущий пароль правильно",
    "main.As senhas devem ser iguais": "Пароли должны совпадать",
    "main.Nome do novo tipo de usuário": "Название нового типа пользователя",
    "main.Dê um nome para o novo tipo de usuário": "Дайте название новому типу пользователя",
    "main.Token Desenvolvedor": "Токен разработчика",
    "main.O Token Desenvolvedor é um código único e intransferível que permite a realização de alterações": "Токен разработчика - это уникальный и непередаваемый код, который позволяет вносить изменения на уровне разработки непосредственно в код платформы через внешние API. Они позволяют настраивать платформу для более эффективного удовлетворения потребностей вашей компании.",
    "main.É recomendado que estas alterações sejam realizadas por um profissional da área de tecnologia": "Рекомендуется, чтобы эти изменения осуществлялись специалистом в области технологий.",
    "main.Para acessar o token, informe sua senha": "Для доступа к токену введите свой пароль.",
    "main.Copie e cole o token na autenticação de todas as requisições.": "Скопируйте и вставьте токен в аутентификацию всех запросов.",
    "main.Seu Token": "Ваш токен",
    "main.Token copiado com sucesso!": "Токен успешно скопирован!",
    "main.Você deseja cancelar o preenchimento das informações?": "Вы хотите отменить заполнение информации?",
    "main.Rever tutoriais": "Пересмотреть обучающие материалы",
    "main.Tutoriais resetados com sucesso": "Учебные материалы успешно сброшены",
    "main.review.tutorials": "Хотите пересмотреть учебные материалы на платформе IOK?",
    "main.Procurar": "Искать",

    //MENUS
    "menus.Procurar menu": "Меню поиска или пользователя",
    "menus.Procurar menu excluído": "Поиск удаленного меню",
    "menus.Menus": "Меню",
    "menus.Lixeira": "Корзина",
    "menus.CRIAR MENU": "СОЗДАТЬ МЕНЮ",
    "menus.IMPORTAR MENU": "ИМПОРТИРОВАТЬ МЕНЮ",
    "menus.Os arquivos serão mantidos na lixeira por 30 dias a contar de sua data de exclusão. Após este período, eles serão excluídos definitivamente.": "Файлы будут храниться в корзине в течение 30 дней с даты удаления. После этого периода они будут окончательно удалены.",
    "menus.VOLTAR": "ВЕРНУТЬ",
    "menus.Compatível com: ": "Совместимо с: ",
    "menus.Última atualização: ": "Последнее обновление: ",
    "menus.Última atualização": "Последнее обновление",
    "menus.EXPORTAR MENU": "ЭКСПОРТИРОВАТЬ МЕНЮ",
    "menus.EDITAR MENU": "РЕДАКТИРОВАТЬ МЕНЮ",
    "menus.Equipamentos associados": "Связанное оборудование",
    "menus.Grupos": "Группы",
    "menus.Procurar grupo": "Поиск группы",
    "menus.CRIAR GRUPO": "СОЗДАТЬ ГРУППУ",
    "menus.receitas": "Рецепты",
    "menus.Nenhum grupo cadastrado": "Группа не зарегистрирована",
    "menus.Nenhum menu cadastrado": "Меню не зарегистрировано",
    "menus.Nome": "Название",
    "menus.Modelo": "Модель",
    "menus.Atividade": "Активность",
    "menus.Associação": "Ассоциация",
    "menus.Exportar": "Экспорт",
    "menus.Editar": "Редактирование",
    "menus.Excluir": "Удаление",
    "menus.Equips": "Оборудование",
    "menus.Receitas": "Рецепты",
    "menus.Compatibilidade": "Совместимость",
    "menus.Versão": "Версия",
    "menus.Origem": "Происхождение",
    "menus.Excluído por: ": "Удалено пользователем: ",
    "menus.Restaurar": "Восстановить",
    "menus.Existem campos que não foram preenchidos": "Есть не заполненные поля",
    "menus.Menu criado com sucesso!": "Меню успешно создано!",
    "menus.Existem campos com informações inválidas": "Есть поля с недопустимой информацией",
    "menus.SALVAR": "СОХРАНИТЬ",
    "menus.CANCELAR": "ОТМЕНА",
    "menus.Nome do menu:": "Название меню: ",
    "menus.Informe o nome do menu": "Укажите название меню",
    "menus.Preencha o nome do menu sem caracteres especiais": "Укажите название меню без специальных символов",
    "menus.Selecionar": "Выбрать",
    "menus.Idioma": "Язык",
    "menus.Temperatura de pré-aquec 1:": "Температура предварительного нагрева 1:",
    "menus.Informe a temperatura de pré-aquec 1": "Укажите температуру предварительного нагрева 1",
    "menus.Temperatura de pré-aquec 2:": "Температура предварительного нагрева 2:",
    "menus.Informe a temperatura de pré-aquec 2": "Укажите температуру предварительного нагрева 2",
    "menus.Tempo para estabilização:": "Время стабилизации:",
    "menus.Informe o tempo para estabilização": "Укажите время стабилизации",
    "menus.Desativar pré-aquec 2": "Отключить предварительный нагрев 2",
    "menus.Configurações de data e hora": "Настройки даты и времени",
    "menus.Data:": "Дата:",
    "menus.DD/MM/AAAA": "ДД/ММ/ГГГГ",
    "menus.Hora:": "Времена:",
    "menus.24h": "24 часа",
    "menus.AM/PM": "AM/PM",
    "menus.EUR: DD/MM/AAAA": "EUR: ДД/ММ/ГГГГ",
    "menus.USA: MM/DD/AAAA": "USA: ММ/ДД/ГГГГ",
    "menus.ISO: AAAA/MM/DD": "ISO: ГГГГ/ММ/ДД",
    "menus.Seleção de acessos para habilitar": "Выбор доступа для включения",
    "menus.Modo manual": "Ручной режим",
    "menus.Habilitado": "Включено",
    "menus.Desabilitado": "Отключено",
    "menus.Favoritos": "Избранное",
    "menus.Aquecer/Dourar": "Нагрев/Запекание",
    "menus.Repetir receita": "Повторить рецепт",
    "menus.Configurações gerais do menu": "Общие настройки меню",
    "menus.Temperatura": "Температура",
    "menus.Temperatura:": "Температура:",
    "menus.Celsius (°C)": "Цельсий (°C)",
    "menus.Fahrenheit (°F)": "Фаренгейт (°F)",
    "menus.Peso": "Вес",
    "menus.Gramas": "Граммы",
    "menus.Oz": "Унции",
    "menus.Tema:": "Тема:",
    "menus.Dark": "Темная",
    "menus.Light": "Светлая",
    "menus.Introdução:": "Введение:",
    "menus.Ativada": "Активировано",
    "menus.Desativada": "Деактивировано",
    "menus.Menu deletado com sucesso!": "Меню успешно удалено!",
    "menus.Não foi possivel deletar o menu": "Не удается удалить меню",
    "menus.Sim": "Да",
    "menus.Não": "Нет",
    "menus.Excluir definitivamente": "Полностью удалить",
    "menus.SALVAR EM PEN DRIVE": "СОХРАНИТЬ НА USB-ФЛЭШ-НАКОПИТЕЛЬ",
    "menus.EXPORTAR": "ЭКСПОРТ",
    "menus.Selecione os modelos e versões dos equipamentos que receberão o menu.": "Выберите модели и версии оборудования для получения меню",
    "menus.Forno": "Духовка",
    "menus.Fundo verde": "Зеленый фон",
    "menus.Fundo preto": "Черный фон",
    "menus.Linux": "Linux",
    "menus.Atenção: antes de transferir o menu de seu pen drive para o equipamento, é necessário descompactar o arquivo, exceto para menus de equipamentos Linux.": "Внимание: перед передачей меню с флеш-накопителя на оборудование необходимо разархивировать файл, за исключением меню оборудования с Linux.",
    "menus.Selecione os equipamentos para os quais deseja exportar o menu.": "Выберите оборудование, для которого хотите экспортировать меню.",
    "menus.Nome do equipamento": "Название оборудования",
    "menus.Selecionar todos": "Выбрать все",
    "menus.Selecionar todas": "Выбрать все",
    "menus.Categoria: ": "Категория: ",
    "menus.Você deseja excluir o menu?": "Вы хотите удалить меню?",
    "menus.O menu será deletado da plataforma, mas permanecerá no equipamento. Uma cópia de segurança será criada automaticamente e ficará disponível por 30 dias em Meus Menus - Lixeira.": "Меню будет удалено с платформы, но сохранится на оборудовании. Автоматически будет создана копия безопасности, доступная в течение 30 дней в Мои Меню - Корзина.",
    "menus.Você deseja excluir o arquivo definitivamente?": "Вы хотите удалить файл окончательно?",
    "menus.Esta ação não poderá ser desfeita.": "Это действие нельзя отменить.",
    "menus.Menu editado com sucesso!": "Меню успешно отредактировано!",
    "menus.IMPORTAR": "ИМПОРТ",
    "menus.VERIFICAÇÃO DE VERSÃO": "ПРОВЕРКА ВЕРСИИ",
    "menus.Para conferir a versão da placa de seu equipamento, siga os seguintes passos:": "Чтобы проверить версию платы оборудования, выполните следующие шаги:",
    "menus.No forno, acesse Configurações;": "В духовке зайдите в Настройки;",
    "menus.Insira a senha 456789;": "Введите пароль 456789;",
    "menus.Selecione a opção Sistema;": "Выберите опцию Система;",
    "menus.Selecione Informações do Fabricante;": "Выберите Информация о производителе;",
    "menus.O modelo de sua placa estará na linha Firmware IHM;": "Модель вашей платы будет указана в строке Прошивка IHM;",
    "menus.1ª geração A": "1-е поколение A",
    "menus.Firmware IHM: 12.3.4": "Прошивка IHM: 12.3.4",
    "menus.1ª geração B": "1-е поколение B",
    "menus.2ª geração/Linux:": "2-е поколение/Linux",
    "menus.Firmware IHM: L1.2.3": "Прошивка IHM: L1.2.3",
    "menus.Selecione o modelo/versão do equipamento do qual deseja importar o menu. Saiba como conferir a versão de seu equipamento": "Выберите модель/версию оборудования, с которого вы хотите импортировать меню. Узнайте, как проверить версию вашего оборудования ",
    "menus.Selecione o modelo/versão do equipamento": "Выберите модель/версию оборудования",
    "menus.Como verificar a versão da placa": "Как проверить версию платы",
    "menus.1° Geração": "1-е поколение",
    "menus.Menu restaurado com sucesso!": "Меню успешно восстановлено!",
    "menus.Não foi possivel restaurar o menu": "Невозможно восстановить меню",
    "menus.RESTAURAR ARQUIVO": "ВОССТАНОВИТЬ ФАЙЛ",
    "menus.Você deseja restaurar o arquivo?": "Вы хотите восстановить файл?",
    "menus.O arquivo será restaurado como uma nova versão na plataforma, na guia Todos. Para salvá-lo no equipamento, exporte-o.": "Файл будет восстановлен как новая версия на платформе на вкладке «Все». Чтобы сохранить его на своем устройстве, экспортируйте его.",
    "menus.EXCLUIR MENU": "УДАЛИТЬ МЕНЮ",
    "menus.Nenhum equipamento associado": "Нет связанного оборудования",
    "menus.Nomeie o novo menu": "Дайте новое имя меню",
    "menus.Nomeie o novo grupo": "Дайте новое имя группе",
    "menus.Nome do grupo:": "Название группы: ",
    "menus.Preencha o nome do grupo sem caracteres especiais": "Заполните название группы без специальных символов",
    "menus.Pré-aquecimento:": "Предварительный нагрев:",
    "menus.Pré-aquecimento": "Предварительный нагрев",
    "menus.Grupo criado com sucesso!": "Группа успешно создана!",
    "menus.SELECIONE UMA IMAGEM": "ВЫБЕРИТЕ ИЗОБРАЖЕНИЕ",
    "menus.SELECIONAR": "ВЫБРАТЬ",
    "menus.Selecionar do computador": "Выбрать с компьютера",
    "menus.Selecionar do banco de imagens": "Выбрать из банка изображений",
    "menus.Formato .JPG ou .PNG": "Формат .JPG или .PNG",
    "menus.Procurar imagem": "Поиск изображения",
    "menus.Pertence ao menu": "Принадлежит к меню",
    "menus.Alterar imagem": "Изменить изображение",
    "menus.Remover imagem": "Удалить изображение",
    "menus.EDITAR GRUPO": "РЕДАКТИРОВАТЬ ГРУППУ",
    "menus.CRIAR RECEITA": "СОЗДАТЬ РЕЦЕПТ",
    "menus.SALVAR DO COOKBOOK": "СОХРАНИТЬ ИЗ КУЛИНАРНОЙ КНИГИ",
    "menus.Procurar receita do grupo": "Поиск рецепта группы",
    "menus.Ingrediente principal": "Основной ингредиент",
    "menus.Mover receitas": "Переместить рецепты",
    "menus.Excluir receitas": "Удалить рецепты",
    "menus.Editar grupo": "Редактировать группу",
    "menus.Excluir grupo": "Удалить группу",
    "menus.aqui": "здесь",
    "menus.Se o código possuir apenas números e o fundo da tela for verde, pertence à 1ª A, se possuir apenas números e o fundo da tela for preta, pertence à 1ª B e, se começar com as letras L, F ou S, sua placa pertence à 2ª geração/Linux.": "Если код содержит только цифры и фон зеленый, он относится к 1-му А, если он состоит только из цифр и фон черный, он относится к 1-му Б, и если он начинается с букв L, F или S, его плата относится ко 2-му поколению/Linux.",
    "menus.1ª A - Fundo verde": "1-е А - Зеленый фон",
    "menus.1ª B - Fundo preto": "1-е Б - Черный фон",
    "menus.2ª - Linux": "2-е - Linux",
    "menus.Tivemos um problema. Tente novamente mais tarde.": "Мы столкнулись с проблемой. Повторите попытку позже.",
    "menus.Grupo editado com sucesso!": "Группа успешно отредактирована!",
    "menus.AINDA NÃO HÁ GRUPOS AQUI :(": "ТУТ ПО-ПРЕЖНЕМУ НЕТ ГРУПП :(",
    "menus.NENHUM MENU NA LIXEIRA": "В КОРЗИНЕ НЕТ МЕНЮ",
    "menus.AINDA NÃO HÁ MENUS AQUI :(": "ТУТ ПО-ПРЕЖНЕМУ НЕТ МЕНЮ :(",
    "menus.Adicionar imagem": "Добавить изображение",
    "menus.Nome da receita": "Название рецепта",
    "menus.Origem da receita": "Происхождение рецепта",
    "menus.Detalhes da receita": "Подробности рецепта",
    "menus.Programação da receita": "Программирование рецепта",
    "menus.Nomeie sua receita": "Назовите свой рецепт",
    "menus.Preencha o nome da receita sem caracteres especiais": "Заполните название рецепта без специальных символов",
    "menus.Tipo de prato": "Тип приема пищи",
    "menus.SALVAR RECEITAS DO COOKBOOK": "СОХРАНИТЬ РЕЦЕПТЫ ИЗ КУЛИНАРНОЙ КНИГИ",
    "menus.Selecione as receitas que deseja salvar no grupo de seu menu:": "Выберите рецепты, которые вы хотите сохранить в своей группе меню:",
    "menus.Procurar receita": "Поиск рецепта",
    "menus.Grupo deletado com sucesso!": "Группа успешно удалена!",
    "menus.Não foi possivel deletar o grupo": "Невозможно удалить группу",
    "menus.EXCLUIR GRUPO": "УДАЛИТЬ ГРУППУ",
    "menus.Você deseja excluir o grupo definitivamente?": "Вы хотите удалить группу окончательно?",
    "menus.AINDA NÃO HÁ RECEITAS AQUI :(": "ТУТ ПО-ПРЕЖНЕМУ НЕТ РЕЦЕПТОВ :(",
    "menus.Temp. de entrada": "Температура начальная",
    "menus.Temp. de pré-aquec": "Температура предварительного нагрева",
    "menus.Ingredientes para montagem": "Ингредиенты для сборки",
    "menus.Instruções operacionais": "Инструкции по эксплуатации",
    "menus.Passo": "Шаг",
    "menus.Temp": "Температура ",
    "menus.Temp.": "Темпер. ",
    "menus.Tempo": "Время",
    "menus.Velocidade do ar": "Скорость",
    "menus.Micro-ondas": "Микроволновая печь",
    "menus.Resistência inferior": "Нижнее сопротивление",
    "menus.Informações": "Информация",
    "menus.Digite as informações do passo": "Введите информацию о шаге",
    "menus.desativar": "деактивировать",
    "menus.Ingredientes": "Ингредиенты",
    "menus.Temperatura de entrada": "Начальная температура",
    "menus.Equipamentos compatíveis": "Совместимое оборудование",
    "menus.SALVAR NO COOKBOOK": "СОХРАНИТЬ В КНИГУ",
    "menus.ver localização": "см. расположение",
    "menus.SELECIONE RECEITAS PARA EXCLUIR": "ВЫБЕРИТЕ РЕЦЕПТЫ ДЛЯ УДАЛЕНИЯ",
    "menus.Você deseja excluir as receitas selecionadas definitivamente": "Вы действительно хотите удалить выбранные рецепты?",
    "menus.Receitas deletadas com sucesso!": "Рецепты успешно удалены!",
    "menus.Informe aqui os ingredientes para montagem": "Укажите здесь ингредиенты для сборки",
    "menus.Informe aqui as instruções operacionais": "Укажите здесь инструкции по эксплуатации",
    "menus.Adicionar passo": "Добавить шаг",
    "menus.Receita criada com sucesso!": "Рецепт успешно создан!",
    "menus.Ideal para:": "Идеально для: ",
    "menus.editar detalhes": "pедактировать детали",
    "menus.excluir": "Удалить",
    "menus.editar": "редактировать",
    "menus.Receita editada com sucesso!": "Рецепт успешно отредактирован!",
    "menus.Passo deletado com sucesso!": "Шаг успешно удален!",
    "menus.Não foi possivel deletar o passo": "Невозможно удалить шаг",
    "menus.EXCLUIR PASSO": "УДАЛИТЬ ШАГ",
    "menus.Você deseja excluir este passo": "Вы действительно хотите удалить этот шаг?",
    "menus.Esta ação não poderá ser desfeita. Caso prefira, é possível apenas desativá-lo, mantendo assim sua configuração.": "Это действие нельзя отменить. Если хотите, можно только деактивировать его, сохраняя его настройки.",
    "menus.Plataforma": "Платформа",
    "menus.Receita salva com sucesso!": "Рецепт успешно сохранен!",
    "menus.Exportar receitas": "Экспорт рецептов",
    "menus.expandir": "Раскрыть",
    "menus.Vapor combinado": "Комбинированный пар",
    "menus.Ar quente": "Горячий воздух",
    "menus.Sonda": "Сенсор",
    "menus.Tempo (min)": "Время (мин)",
    "menus.Umidade (%)": "Влажность (%)",
    "menus.Injeção de vapor": "Инъекция пара",
    "menus.Dumper aberto": "Открытый сброс",
    "menus.Informe o peso": "Укажите вес",
    "menus.Informe a temperatura de entrada": "Укажите начальную температуру",
    "menus.Informe os ingredientes para montagem": "Введите ингредиенты для сборки",
    "menus.Informe as instruções operacionais": "Введите инструкции по эксплуатации",
    "menus.Injeção de vapor (seg)": "Инъекция пара (сек)",
    "menus.Aquecer mais": "Подогрев",
    "menus.Dourar mais": "Обжарка",
    "menus.Aquecer e dourar mais": "Подогрев и обжаривание",
    "menus.Favoritar receita": "Избранный рецепт",
    "menus.Informe o tempo para aquecer mais de 0 a 59 segundos": "Введите время подогрева от 0 до 59 секунд",
    "menus.Informe o tempo para dourar mais de 0 a 59 segundos": "Введите время обжарки от 0 до 59 секунд",
    "menus.Informe o tempo para aquecer e dourar mais de 0 a 59 segundos": "Введите время подогрева и обжаривания от 0 до 59 секунд",
    "menus.Apenas favoritos": "Только избранные",
    "menus.Forno combinado": "Комбинированная печь",
    "menus.Cocção múltipla": "Множественное приготовление",
    "menus.Receitas Technicook": "Рецепты Technicook",
    "menus.Editar grupos": "Редактировать группы",
    "menus.Informe a data": "Введите дату",
    "menus.Informe a hora": "Введите время",
    "menus.até 300": "до 300",
    "menus.Vapor": "Пар",
    "menus.Turbina": "Турбина",
    "menus.Criar grupo": "Создать группу",
    "menus.MOVER": "ПЕРЕМЕСТИТЬ",
    "menus.Configurações de acessos": "Настройки доступа:",
    "menus.SELECIONE RECEITAS PARA MOVER": "ВЫБЕРИТЕ РЕЦЕПТЫ ДЛЯ ПЕРЕМЕЩЕНИЯ",
    "menus.AVANÇAR": "ПРОДВИГАТЬСЯ",
    "menus.Selecione ou crie um grupo no mesmo menu para mover as receitas selecionadas ou as mova para outro menu. Note que apenas os menus/grupos compatíveis com as predefinições das receitas serão exibidos.": "Выберите или создайте группу в том же меню, чтобы переместить выбранные рецепты или переместить их в другое меню. Обратите внимание, что будут отображаться только те меню/группы, которые совместимы с предустановками рецепта.",
    "menus.Mover receitas para o mesmo menu": "Переместить рецепты в то же меню",
    "menus.Mover receitas para outro menu": "Переместить рецепты в другое меню",
    "menus.Selecionar grupo": "Выберите группу",
    "menus.Nenhuma receita foi selecionada": "Ни один рецепт не был выбран",
    "menus.Passo editado com sucesso!": "Шаг успешно отредактирован!",
    "menus.Menu atualizado": "Меню обновлено",
    "menus.até 260": "до 260",
    "menus.Umidade na câmara": "Влажность камеры",
    "menus.Passos": "Шаги",
    "menus.ativar": "активировать",
    "menus.Informe a temperatura de pré-aquec": "Введите температуру предварительного нагрева",
    "menus.Movas as receitas selecionadas para outro menu. Note que apenas os menus compatíveis com as predefinições das receitas serão exibidos.": "Переместите выбранные рецепты в другое меню. Обратите внимание, что будут отображаться только те меню, которые совместимы с предустановками рецепта.",
    "menus.Sonda (°C)": "Датчик (ºC)",
    "menus.Turbo Grill": "Турбо-гриль",
    "menus.Pré-aquecimento padrão, conforme passo 1": "Стандартный предварительный нагрев, согласно шагу 1",
    "menus.salvar": "сохранить",
    "menus.até": "до",
    "menus.Verificação de receita": "Проверка рецепта",
    "menus.Auto import": "Автоматический импорт",
    "menus.Atualização forçada": "Принудительное обновление",
    "menus.Lista de receitas exportada com sucesso!": "Список рецептов успешно экспортирован!",
    "menus.Receita movida com sucesso!": "Рецепт успешно перемещен!",
    "menus.Receitas movidas com sucesso!": "Рецепты успешно перемещены!",
    "menus.+ Receita": "Рецепт",
    "menus.Escolha um idioma compatível com o equipamento": "Выберите язык, совместимый с оборудованием",
    "menus.MENU IMPORTADO COM SUCESSO!": "Меню успешно импортировано!",
    "menus.Dourar": "Коричневый",
    "menus.Não atribuir a menu": "Не назначать в меню",
    "menus.Todos": "Все",
    "menus.Meus menus": "Мои меню",
    "menus.Para salvar uma receita, preencha todos os campos": "Чтобы сохранить рецепт, заполните все поля",
    "menus.Selecione os grupos de equipamentos para os quais deseja exportar o menu.": "Выберите группы оборудования, для которых вы хотите экспортировать меню.",
    "menus.Você deseja excluir este passo?": "Хотите удалить этот шаг?",
    "menus.Formato inválido. São permitidos apenas os formatos .jpeg e .png": "Недопустимый формат. Разрешены только форматы .jpeg и .png.",
    "menus.Reordenar grupos": "Переупорядочить группы",
    "menus.Reordenar receitas": "Переупорядочить рецепты",
    "menus.ITENS REORDENADOS COM SUCESSO! PARA APLICAR AS ALTERAÇÕES NO FORNO, EXPORTE O MENU.": "ЭЛЕМЕНТЫ УСПЕШНО ПЕРЕУПОРЯДОЧЕНЫ! ЧТОБЫ ПРИМЕНИТЬ ИЗМЕНЕНИЯ В ПЕЧИ, ЭКСПОРТИРУЙТЕ МЕНЮ.",
    "menus.ARRASTE OS ITENS PARA O LOCAL DESEJADO": "Перетащите элементы в нужное место.",
    "menus.Dumper": "Самосвал",
    "menus.Aberto": "Открыть",
    "menus.Fechado": "Закрыто",
    "menus.Intermitente": "прерывистый",
    "menus.Se o seu menu incluir itens sensíveis à uniformidade, recomendamos definir o Tempo de Estabilização entre 3:00 e 5:00 minutos. Caso contrário, você pode deixá-lo em 0:00 minutos.": "Если в вашем меню есть блюда, которые чувствительны к однородности, мы рекомендуем установить время стабилизации от 3:00 до 5:00 минут. В противном случае вы можете оставить его на 0:00 минут.",
    "menus.Selecione o menu que deseja exportar:": "Выберите меню, которое хотите экспортировать:",
    "menus.Temperatura de pré-aquecimento:": "Температура предварительного нагрева:",
    "menus.Temperatura de lastro": "Температура балласта",
    "menus.Temperatura de teto": "Температура потолка",
    "menus.Volume de injeção de vapor (ml)": "Объем впрыска пара (мл)",
    "menus.Parada de turbina": "Остановка турбины",
    "menus.Alta": "Высокая",
    "menus.Média": "Средняя",
    "menus.Baixa": "Низкая",
    "menus.a": "до",
    "menus.Valor da injeção": "Значение впрыска",
    "menus.Volume: até 999ml": "Объем: до 999 мл",
    "menus.Tempo: até 15 segundos": "Время: до 15 секунд.",
    "menus.Tempo de injeção de vapor (seg)": "Время впрыска пара (сек)",
    "menus.Atenção!": "Внимание!",
    "menus.Verifique as receitas do menu e ajuste os valores da Injeção de Vapor após alterar sua unidade de medida.": "Проверьте рецепты меню и отрегулируйте значения впрыска пара после изменения единицы измерения.",
    "menus.Certifique-se de que a unidade de temperatura (°C ou °F) definida no equipamento seja a mesma que foi selecionada na criação do menu:": "Убедитесь, что единица измерения температуры (°C или °F), определенная на оборудовании, совпадает с выбранной при создании меню:",
    "menus.Injeção desativada": "Впрыск отключен",
    "menus.Selecione a unidade de temperatura que está definida no equipamento:": "Выберите единицу измерения температуры, определенную на оборудовании:",
    "menus.Clique para atualizar": "Нажмите, чтобы обновить",
    "menus.A função Atualização Forçada envia automaticamente o menu para os equipamentos selecionados na próxima vez em que forem ligados.": "Функция принудительного обновления автоматически отправляет меню на выбранное оборудование при следующем его включении.",
    "menus.As opções selecionadas estarão disponíveis nos equipamentos que receberem este menu, podendo ser utilizadas pelo operador. É possível alterar estas configurações posteriormente no próprio forno ou pela plataforma.": "Выбранные опции будут доступны на оборудовании, которое получает это меню, и могут использоваться оператором. Эти настройки можно изменить позже в самой духовке или через платформу.",
    "menus.+ Menu": "+ Меню",
    "menus.Não foi possivel deletar as receitas": "Не могу удалить рецепты.",
    "menus.PRÉ": "ПРЕ",
    "menus.Speed Ovens": "Speed Ovens",
    "menus.Fornos Combinados": "Комбинированные печи",
    "menus.Fornos de Panificação": "Пекарные печи",
    "menus.Após a criação, o tipo do menu não pode ser alterado. Ele só pode ser definido no momento da criação.": "После создания тип меню не может быть изменен. Он может быть установлен только при создании.",
    "menus.Pré-aquecimento do grupo": "Предварительный нагрев группы",

    //COOKBOOK
    "cookbook.RECEITAS DISPONÍVEIS": "ДОСТУПНЫЕ РЕЦЕПТЫ",
    "cookbook.RECEITAS SALVAS": "СОХРАНЕННЫЕ РЕЦЕПТЫ",
    "cookbook.ENVIAR PARA MENU": "ОТПРАВИТЬ В МЕНЮ",
    "cookbook.SALVAR": "СОХРАНИТЬ",
    "cookbook.salvar": "Сохранить",
    "cookbook.Filtrar por": "Фильтровать по",
    "cookbook.Procurar receita do cookbook": "Поиск рецепта в кулинарной книге",
    "cookbook.Equipamento": "Оборудование",
    "cookbook.Ingrediente principal": "Основной ингредиент",
    "cookbook.Tipo de prato": "Тип блюда",
    "cookbook.Região": "Регион",
    "cookbook.Pré-aquecimento": "Предварительный нагрев",
    "cookbook.Procurar receita": "Поиск рецепта",
    "cookbook.Menu": "Меню",
    "cookbook.ENVIAR": "ОТПРАВИТЬ",
    "cookbook.Procurar nome do menu": "Поиск по имени меню",
    "cookbook.Escolha um grupo deste menu": "Выберите группу из этого меню",
    "cookbook.Selecionar grupo": "Выбрать группу",
    "cookbook.Tivemos um problema. Tente novamente mais tarde": "У нас возникла проблема. Повторите попытку позже",
    "cookbook.Receita adicionada com sucesso!": "Рецепт успешно добавлен!",
    "cookbook.Não foi possível adicionar a receita. Tente novamente mais tarde": "Не удалось добавить рецепт. Повторите попытку позже",
    "cookbook.Selecione um menu e um grupo para enviar esta receita": "Выберите меню и группу для отправки этого рецепта",
    "cookbook.Selecione o modelo do equipamento primeiro": "Сначала выберите модель оборудования",
    "cookbook.Grupo": "Группа",
    "cookbook.Não foi possível salvar os passos da receita": "Не удалось сохранить шаги рецепта",
    "cookbook.Bovina": "Говядина",
    "cookbook.Frango": "Курица",
    "cookbook.Ovos": "Яйца",
    "cookbook.Pães": "Хлеб",
    "cookbook.Peixes": "Рыба",
    "cookbook.Queijo": "Сыр",
    "cookbook.Suína": "Свинина",
    "cookbook.Vegetais": "Овощи",
    "cookbook.Outros": "Другие",
    "cookbook.Entradas/aperitivos": "Закуски/аппетиты",
    "cookbook.Principais": "Основное",
    "cookbook.Snacks": "Закуски",
    "cookbook.Pizzas": "Пицца",
    "cookbook.Massas": "Паста",
    "cookbook.Acompanhamentos": "Гарниры",
    "cookbook.Sobremesas": "Десерты",
    "cookbook.Manhã": "Завтрак",
    "cookbook.Padaria": "Пекарня",
    "cookbook.Todos": "Все",
    "cookbook.Não atribuir origem": "Не указывать происхождение",
    "cookbook.Brasil": "Бразилия",
    "cookbook.Alemanha": "Германия",
    "cookbook.França": "Франция",
    "cookbook.Rússia": "Россия",
    "cookbook.China": "Китай",
    "cookbook.Japão": "Япония",
    "cookbook.Itália": "Италия",
    "cookbook.Egito": "Египет",
    "cookbook.Grécia": "Греция",
    "cookbook.Argentina": "Аргентина",
    "cookbook.África": "Африка",
    "cookbook.Estados Unidos": "Соединенные Штаты",
    "cookbook.Coreia": "Корея",
    "cookbook.Turquia": "Турция",
    "cookbook.Ideal para": "Идеально для",
    "cookbook.Polônia": "Польша",
    "cookbook.Sanduíches": "Сэндвичи",

    //EQUIPAMENTOS
    "equipment.equipamentos": "Оборудование",
    "equipment.procurar equipamento": "Поиск оборудования или пользователя",
    "equipment.adicionar equipamento": "Добавить оборудование",
    "equipment.atualizar equipamento": "Обновить оборудование",
    "equipment.+ equipamentos": "Оборудование",
    "equipment.buscar equipamento": "Поиск оборудования",
    "equipment.Desvincular": "Отсоединить",
    "equipment.Editar": "Редактировать",
    "equipment.sem conteúdo": "Здесь пока нет оборудования :(",
    "equipment.editar equipamento": "Редактировать оборудование",
    "equipment.equipamento editado com sucesso!": "Оборудование успешно отредактировано!",
    "equipment.não foi possível editar o equipamento. Tente novamente mais tarde": "Не удалось отредактировать оборудование. Повторите попытку позже",
    "equipment.Menu exportado com sucesso!": "Меню успешно экспортировано!",
    "equipment.Não foi possível exportar o menu. Tente novamente mais tarde": "Не удалось экспортировать меню. Повторите попытку позже",
    "equipment.Selecione pelo menos um equipamento para enviar o menu": "Выберите хотя бы одно оборудование для отправки меню",
    "equipment.Informações do menu": "Информация о меню",
    "equipment.Procurar receita ou grupo": "Поиск рецепта или группы",
    "equipment.Grupo": "Группа",
    "equipment.Receita": "Рецепт",
    "equipment.Quantidade": "Количество",
    "equipment.Receitas mais preparadas no equipamento durante o período": "Самые часто приготовленные рецепты на оборудовании за период",
    "equipment.Informações de limpeza": "Информация о чистке",
    "equipment.Data da limpeza": "Дата чистки",
    "equipment.Hora da limpeza": "Час чистки",
    "equipment.nome": "Имя",
    "equipment.modelo": "Модель",
    "equipment.categoria": "Категория",
    "equipment.loja": "Магазин",
    "equipment.desvincular equipamento": "Отсоединить оборудование",
    "equipment.unlink.paragrafo 1": "Хотите отсоединить оборудование от платформы IOK?",
    "equipment.unlink.paragrafo 2": "Таким образом, не будет возможности управлять настройками и меню через платформу, пока вы снова не подключите оборудование, следуя инструкциям по подключению.",
    "equipamento desvinculado com sucesso!": "Оборудование успешно отсоединено!",
    "equipment.ocorreu um erro. Tente novamente mais tarde": "Произошла ошибка. Повторите попытку позже",
    "equipment.sim": "Да",
    "equipment.não": "Нет",
    "equipment.Encerrar acesso remoto": "Завершить удаленный доступ",
    "equipment.Horas sem limpeza": "Часы без чистки",
    "equipment.Histórico de eventos": "История событий",
    "equipment.Evento": "Событие",
    "equipment.Data": "Дата",
    "equipment.ERRO SENSOR RI": "Ошибка с датчиком нижнего сопротивления",
    "equipment.ERRO COM INV. DE FREQ.": "Ошибка с частотным преобразователем",
    "equipment.ERRO MAGNETRON": "Ошибка с магнетроном",
    "equipment.ERRO SENSOR CAMARA": "Ошибка с датчиком камеры",
    "equipment.ERRO CAN": "CAN-ошибка",
    "equipment.Erro INVERSOR DE FREQUENCIA": "Ошибка частотного преобразователя",
    "equipment.ERRO DO MODELO DO EQUIPAMENTO": "Ошибка модели оборудования",
    "equipment.ERRO PAINEL": "Ошибка панели",
    "equipment.ERRO INV.FREQ.STATUS": "Статус ошибки частотного преобразователя",
    "equipment.ERRO SENSOR BOARD": "Ошибка датчика платы",
    "equipment.ERRO ENERGY": "Ошибка энергии",
    "equipment.ERRO I2C": "Ошибка I2C",
    "equipment.EQUIPAMENTO LIGADO": "Оборудование включено",
    "equipment.EQUIPAMENTO DESLIGADO": "оборудовано выключено",
    "equipment.Plataforma": "Платформа",
    "equipment.Forno": "Духовка",
    "equipment.Origem": "Происхождение",
    "equipment.Locais": "Местоположения",
    "equipment.não foi possível carregar as entidades": "Не удалось загрузить сущности",
    "equipment.conceder acesso remoto a": "Предоставить удаленный доступ",
    "equipment.atualizar equipamentos": "Обновить оборудование",
    "equipment.suporte": "Поддержка",
    "equipment.PIN": "PIN",
    "equipment.nome do equipamento": "Название оборудования",
    "equipment.vinculado à loja": "Связано с магазином",
    "equipment.cadastrar loja": "Зарегистрировать магазин",
    "equipment.informe o PIN do forno": "Введите PIN духовки",
    "equipment.preencha o PIN do forno": "Заполните PIN духовки",
    "equipment.adicione um nome para facilitar seu reconhecimento": "Добавьте имя для удобства распознавания",
    "equipment.preencha o nome do equipamento": "Заполните имя оборудования",
    "equipment.selecionar": "Выберите",
    "equipment.nome fantasia": "Фантазийное имя",
    "equipment.informe o nome fantasia": "Введите фантазийное имя",
    "equipment.preencha o nome fantasia": "Заполните фантазийное имя",
    "equipment.CNPJ": "CNPJ",
    "equipment.XX.XXX.XXX/0001.XX": "XX.XXX.XXX/0001.XX",
    "equipment.preencha o CNPJ": "Заполните CNPJ",
    "equipment.CEP": "Почтовый индекс",
    "equipment.00000-000": "00000-000",
    "equipment.preencha o CEP": "Заполните почтовый индекс",
    "equipment.cidade": "Город",
    "equipment.informe a cidade": "Введите город",
    "equipment.preencha a cidade": "Заполните город",
    "equipment.UF": "Область",
    "equipment.bairro": "Район",
    "equipment.informe o bairro": "Введите район",
    "equipment.endereço": "Адрес",
    "equipment.informe o seu endereço": "Введите свой адрес",
    "equipment.preencha o seu endereço": "Заполните ваш адрес",
    "equipment.Nº": "№",
    "equipment.equipamento cadastrado com sucesso!": "Оборудование успешно зарегистрировано!",
    "equipment.ocorreu um erro": "Произошла ошибка",
    "equipment.preencha o modelo": "Заполните модель",
    "equipment.selecione uma categoria": "Выберите категорию",
    "equipment.selecione uma loja": "Выберите магазин",
    "equipment.preencha o bairro": "Заполните район",
    "equipment.não foi possível cadastrar o equipamento. Tente novamente mais tarde": "Не удалось зарегистрировать оборудование. Попробуйте позже",
    "equipment.loja cadastrada com sucesso": "Магазин успешно зарегистрирован",
    "equipment.não foi possível cadastrar a loja. Tente novamente mais tarde": "Не удалось зарегистрировать магазин. Попробуйте позже",
    "equipment.equipment": "оборудование",
    "equipment.stores": "магазины",
    "equipment.search equipment": "поиск оборудования",
    "equipment.add equipment": "добавить оборудование",
    "equipment.update equipment": "обновить оборудование",
    "equipment.plus equipment": "оборудование",
    "equipment.Procurar equipamento": "Поиск оборудования",
    "equipment.EQUIPAMENTOS": "ОБОРУДОВАНИЕ",
    "equipment.Atualizar equipamento": "Обновить оборудование",
    "equipment.Atualizar equipamentos": "Обновить оборудование",
    "equipment.confirmar": "подтвердить",
    "equipment.Selecione os modelos que deseja atualizar:": "Выберите модели, которые вы хотите обновить",
    "equipment.Como deseja realizar a atualização?": "Как вы хотите продолжить обновление?",
    "equipment.Enviar atualização pela plataforma (wi-fi)": "Отправить обновление через платформу (wi-fi)",
    "equipment.Salvar arquivo de atualização em pen drive": "Сохранить файл обновления на USB-флеш-накопителе",
    "equipment.Linha Express": "Экспресс-линия",
    "equipment.Escolha como deseja realizar a atualização": "Выберите, как вы хотите продолжить обновление",
    "equipment.Selecione ao menos um modelo de equipamento para atualizar": "Выберите хотя бы одну модель оборудования для обновления",
    "equipment.Status:": "Статус: ",
    "equipment.Número de série:": "Серийный номер",
    "equipment.Menu:": "Меню",
    "equipment.Loja:": "Магазин",
    "equipment.End:": "Конец: ",
    "equipment.Cidade:": "Город: ",
    "equipment.CEP:": "Почтовый индекс: ",
    "equipment.Atualização de software enviada com sucesso!": "Обновление программного обеспечения успешно отправлено!",
    "equipment.Não foi possível enviar a atualização de sodtware. Tente novamente mais tarde": "Не удалось отправить обновление программного обеспечения. Попробуйте еще раз позже",
    "equipment.Download realizado com sucesso": "Загрузка завершена успешно",
    "equipment.Não foi possível realizar o download. Tente novamente mais tarde": "Не удалось завершить загрузку. Попробуйте еще раз позже",
    "equipment.name": "Имя",
    "equipment.model": "Модель",
    "equipment.category": "Категория",
    "equipment.store": "магазин",
    "FIT Express/FIT ST": "FIT Express/FIT ST",
    "Copa/Single Mag/Rocket": "Copa/Single Mag/Rocket",
    "Forza STi": "Forza STi",
    "TSi": "TSi",
    "FIT Express": "FIT Express",
    "FIT ST": "FIT ST",
    "equipment.Preencha o nome da versão": "Заполните имя версии",
    "equipment.Informe aqui todas as alterações desta nova versão": "Введите все изменения в этой новой версии здесь",
    "equipment.Nova versão de software atualizada com sucesso!": "Новая версия программного обеспечения успешно обновлена!",
    "equipment.Informe pelo menos uma mudança nesta versão": "Введите хотя бы одно изменение в этой версии",
    "equipment.Falha ao ler o arquivo": "Не удалось прочитать файл",
    "equipment.selecionar tipo": "выберите тип",
    "equipment.selecione o tipo da versão": "выберите тип версии",
    "equipment.atualização": "ОБНОВИТЬ",
    "equipment.Selecione abaixo o que deseja fazer:": "Выберите ниже, что вы хотите сделать:",
    "equipment.Atualizar meus equipamentos": "Обновить мое оборудование",
    "equipment.Enviar atualização para usuários selecionados": "Отправить обновление выбранным пользователям",
    "equipment.ENVIAR": "ОТПРАВИТЬ",
    "equipment.ENVIAR ATUALIZAÇÃO": "ОТПРАВИТЬ ОБНОВЛЕНИЕ",
    "equipment.Selecione os e-mails para os quais deseja enviar a atualização:": "Выберите электронные адреса, на которые вы хотите отправить обновление:",
    "equipment.A atualização disponibilizada no dia": "Доступное обновление на сегодня",
    "equipment.referente ao equipamento": "относительно оборудования",
    "equipment.contempla o seguinte:": "учитывает следующее:",
    "equipment.Selecionar arquivo para envio": "Выберите файл для отправки",
    "equipment.Por favor, selecione um arquivo .tar": "Пожалуйста, выберите файл .tar",
    "equipment.Selecione o modelo do equipamento:": "Выберите модель оборудования:",
    "equipment.Selecione uma opção": "Выберите опцию",
    "equipment.Nomeie a versão": "Назовите версию",
    "equipment.Clique sobre o arquivo que deseja baixar:": "Нажмите на файл, который хотите скачать:",
    "equipment.Baixar atualização": "Скачать обновление",
    "equipment.Versões": "Версии",
    "equipment.e superiores": "и выше",
    "FIT Express, FIT ST": "FIT Express, FIT ST",
    "Copa Express, Rocket Express, Copa Single Mag": "Copa Express, Rocket Express, Copa Single Mag",
    "equipment.e": "Это",
    "equipment.Últimas versões de software:": "Последние версии программного обеспечения: ",
    "equipment.Você não possui nenhum equipamento desatualizado": "У вас нет устаревшего оборудования",
    "equipment.grupos": "группы",
    "equipment.não foi possível carregar os grupos de equipamentos da companhia": "Невозможно загрузить группы оборудования компании.",
    "equipment.vinculado ao grupo": "Связан с группой",
    "equipment.selecione um grupo": "Выберите группу",
    "equipment.cadastrar grupo": "Зарегистрировать группу",
    "equipment.Número de Série": "СН",
    "equipment.Versão": "Версия",
    "equipment.não foi possível cadastrar o equipamento. Selecione ou cadastre um grupo": "Не удалось зарегистрировать оборудование. Выберите или зарегистрируйте группу",
    "equipment.CRIE GRUPOS DE EQUIPAMENTOS!": "СОЗДАЙТЕ ГРУППЫ ОБОРУДОВАНИЯ!",
    "equipment.Para que os usuários vinculados à conta possam visualizar os equipamentos você precisa:": "Чтобы пользователи, привязанные к учетной записи, могли видеть оборудование, вам нужно:",
    "equipment.1) Criar grupo(s);": "1) Создать группу(ы);",
    "equipment.2) Atribuir equipamento(s) ao grupo;": "2) Назначить оборудование(я) группе;",
    "equipment.3) Atribuir usuário(s) ao grupo.": "3) Назначить пользователя(ей) группе.",
    "equipment.Status software": "Статус ПО",
    "equipment.Status menu": "Состояние меню",
    "equipment.Pendente": "В ожидании",
    "equipment.Aceito": "Принято",
    "equipment.Recusado": "Отклонено",
    "equipment.Atualizar": "Обновить",
    "equipment.Deseja enviar a atualização para o equipamento selecionado?": "Хотите отправить обновление на выбранное оборудование?",
    "equipment.Companhia": "Компания",
    "equipment.Exportar menu": "Меню экспорта",
    "equipment.companhias": "компании",
    "equipment.Nome da companhia": "Название компании",
    "equipment.Procurar equipamento, usuário ou companhia": "Поиск оборудования, пользователя или компании",
    "equipment.Versão da power": "силовая версия",
    "equipment.AINDA NÃO HÁ DADOS DE RECEITAS AQUI :(": "ДАННЫХ РЕЦЕПТОВ ЗДЕСЬ ПОКА НЕТ :(",
    "equipment.AINDA NÃO HÁ DADOS DE LIMPEZAS AQUI :(": "ДАННЫХ ПО ОЧИСТКЕ ПОКА НЕТ :(",
    "equipment.AINDA NÃO HÁ DADOS DE EVENTOS AQUI :(": "ДАННЫХ О СОБЫТИИ ПОКА НЕТ :(",
    "ID": "ID",
    "equipment.Hora": "Час",
    "equipment.EVENTOS MAIS OCORRIDOS NO EQUIPAMENTO DURANTE O PERÍODO": "САМЫЕ ПРОИЗОШЕДШИЕ СОБЫТИЯ НА ОБОРУДОВАНИИ ЗА ПЕРИОД",
    "equipment.hour": "Ч",
    "equipment.minutes": "Мин",
    "equipment.date format": "ДД/ММ/ГГГГ",
    "equipment.preparada": "приготовлена",
    "equipment.vezes": "pаз",
    "equipment.vez": "pаз",
    "equipment.Ver": "Смотреть",
    "equipment.Os equipamentos enviarão os dados à plataforma ao serem ligados. A cada dois meses, os dados recebidos serão automaticamente excluídos.": "Оборудование отправит данные на платформу при включении. Каждые два месяца полученные данные будут автоматически удаляться.",
    "equipment.Ligado": "Включено",
    "equipment.Desligado": "Выключено",
    "equipment.CAN ERROR": "Ошибка CAN",
    "equipment.CAMERA SENSOR ERROR": "Ошибка датчика камеры",
    "equipment.STEAM/RI SENSOR ERROR": "Ошибка датчика RI",
    "equipment.PROBE 1 ERROR": "Оповещение зонда 1",
    "equipment.PROBE 2 ERROR": "Оповещение зонда 2",
    "equipment.PROBE 3 ERROR": "Оповещение зонда 3",
    "equipment.PROBE 4 ERROR": "Оповещение зонда 4",
    "equipment.PROBE 5 ERROR": "Оповещение зонда 5",
    "equipment.PROBE 6 ERROR": "Оповещение зонда 6",
    "equipment.GENERAL PROBE ERROR": "Общая ошибка датчика",
    "equipment.WATER LACK ERROR": "Оповещение о нехватке воды",
    "equipment.HIGH PANEL TEMPERATURE ERROR": "Оповещение о высокой температуре на приборной панели",
    "equipment.FREQUENCY INVERTER GENERAL ERROR": "Ошибка преобразователя частоты",
    "equipment.FREQUENCY INVERTER COMMUNICATION ERROR": "Предупреждение о связи на преобразователе частоты",
    "equipment.FREQUENCY INVERTER STATUS ERROR": "Оповещение о состоянии преобразователя частоты",
    "equipment.CLEANING NOT COMPLETED ERROR": "Оповещение о незавершенной уборке",
    "equipment.MAGNETRON ERROR": "Магнетронное оповещение",
    "equipment.EQUIPMENT FAMILY/MODEL ERROR": "Ошибка модели оборудования",
    "equipment.BOARD TEMPERATURE SENSOR ERROR": "Оповещение датчика температуры платы",
    "equipment.ENERGY MODULE ERROR": "Ошибка модуля питания",
    "equipment.POWER BOARD I2C BUS ERROR": "Ошибка платы питания",
    "equipment.GAS SYSTEM ERROR": "Ошибка газа",
    "equipment.VERY HIGH PANEL TEMPERATURE ERROR": "Ошибка сверхвысокой температуры на панели",
    "equipment.POWER ON": "Связанный",
    "equipment.POWER OFF": "Выключенный",
    "equipment.DOOR OPENED": "Открытая дверь",
    "equipment.DOOR CLOSED": "Закрытая дверь",
    "equipment.CLEANING CANCELED ERROR": "Санитизация отменена пользователем",
    "equipment.PIN não encontrado. Por favor, verifique e tente novamente": "ПИН-код не найден. Пожалуйста проверьте и попробуйте снова",
    "equipment.Online": "онлайн",
    "equipment.Offline": "оффлайн",
    "equipment.Exibir apenas eventos": "Показать только события",
    "equipment.Endereço": "Адрес",
    "equipment.EQUIPMENT CONNECTED TO THE PLATFORM": "Оборудование, подключенное к платформе",
    "equipment.EQUIPMENT DISCONNECTED FROM THE PLATFORM": "Оборудование отключено от платформы",
    "equipment.MENU RECEIVED FROM THE PLATFORM": "Меню получено с платформы",
    "equipment.MENU REJECTED BY THE USER": "Меню отклонено пользователем",
    "equipment.CONNECTED TO THE INTERNET": "Подключено к Интернету",
    "equipment.DISCONNECTED FROM THE INTERNET": "Отключено от Интернета",
    "equipment.Infelizmente não há versões que realizem o envio de dados compatíveis com este equipamento.": "К сожалению, нет версий, которые выполняют передачу данных, совместимых с этим оборудованием.",
    "equipment.O equipamento": "Оборудование",
    "equipment.deve ser atualizado para a versão mais recente de software para que passe a enviar os dados para a plataforma": "должно быть обновлено до последней версии программного обеспечения, чтобы начать отправку данных на платформу",
    "equipment.Versão do seu equipamento": "Версия вашего оборудования",
    "equipment.Versão disponível": "Доступная версия",
    "equipment.Selecione abaixo como deseja enviar a atualização para seu equipamento": "Выберите ниже, как вы хотите отправить обновление на ваше оборудование",
    "equipment.Salvar em pen drive": "Сохранить на флеш-накопитель",
    "equipment.Enviar por wi-fi": "Отправить по Wi-Fi",
    "equipment.Cliente": "Клиент",
    "equipment.NÚMERO DE SÉRIE NÃO ENCONTRADO": "СЕРИЙНЫЙ НОМЕР НЕ НАЙДЕН",
    "equipment.BUSCAR": "ПОИСК",
    "equipment.ACESSADOS RECENTEMENTE": "НЕДАВНО ДОСТУП",
    "equipment.SEM BUSCAS RECENTES": "НЕТ ПОСЛЕДНИХ ПОИСКОВ",
    "equipment.Procurar equipamento por NS": "Поиск оборудования по НС",
    "equipment.AIR FILTER CLEANING COMPLETED": "Очистка воздушного фильтра завершена",
    "equipment.AIR FILTER CLEANING REJECTED": "Очистка воздушного фильтра отклонена",
    "equipment.CATALYTIC FILTER CLEANING COMPLETED": "Очистка каталитического фильтра завершена",
    "equipment.CATALYTIC FILTER CLEANING REJECTED": "Очистка каталитического фильтра отклонена",
    "equipment.ID do equipamento": "ID оборудования",
    "equipment.Todo equipamento deve ser vinculado a um grupo. Selecione um grupo da lista ou cadastre um novo clicando em + Cadastrar grupo.": "Все оборудование должно быть связано с группой. Выберите группу из списка или зарегистрируйте новую, нажав + Зарегистрировать группу.",
    "equipment.Nomeie seu forno": "Назовите свою духовку",
    "equipment.Para visualizar o PIN, acesse nas configurações do equipamento a Plataforma Virtual IOK.": "Чтобы просмотреть PIN-код, войдите в виртуальную платформу IOK в настройках оборудования.",
    "equipment.localização": "расположение",
    "equipment.AINDA NÃO HÁ NENHUMA LOCALIZAÇÃO CADASTRADA :(": "МЕСТО ПОКА НЕ ЗАРЕГИСТРИРОВАНО :(",
    "equipment.Procurar empresa ou local": "Поиск компании или местоположения",
    "equipment.Adicionar localização": "Добавить местоположение",
    "equipment.espaço": "Космос",
    "equipment.local": "Местный",
    "equipment.EXCLUIR LOCALIZAÇÃO": "УДАЛЕНИЕ МЕСТОПОЛОЖЕНИЯ",
    "equipment.Você deseja excluir esta localização?": "Вы хотите удалить это местоположение?",
    "equipment.Ao fazer isso, os equipamentos atrelados à ela não exibirão nenhuma localização, sendo necessário informar um novo local para eles.": "При этом связанное с ним оборудование не будет отображать какое-либо местоположение, поэтому необходимо указать для него новое местоположение.",
    "equipment.Localização deletada com sucesso!": "Местоположение успешно удалено!",
    "equipment.Erro ao deletar a localização. Tente novamente mais tarde": "Ошибка удаления местоположения. Попробуйте позже",
    "equipment.Não foi possivel deletar a localização": "Не удалось удалить местоположение",
    "equipment.Localização adicionada com sucesso!": "Местоположение успешно добавлено!",
    "equipment.Não foi possível adicionar a localização. Tente novamente mais tarde": "Не удалось добавить местоположение. Попробуйте позже",
    "equipment.Nome do espaço": "Название пространства",
    "equipment.Informe o nome do espaço": "Введите название пространства",
    "equipment.Preencha o nome do espaço": "Введите название помещения",
    "equipment.país": "Страна",
    "equipment.Endereço (rua, avenida, rodovia)": "Адрес (улица, проспект, шоссе)",
    "equipment.Nº/Km": "№/км",
    "equipment.informe o país": "Введите страну",
    "equipment.preencha o país": "Заполните страну",
    "equipment.Localização editada com sucesso!": "Местоположение успешно изменено!",
    "equipment.Não foi possível editar a localização. Tente novamente mais tarde": "Невозможно изменить местоположение. Попробуйте позже",
    "equipment.Editar localização": "Изменить местоположение",
    "equipment.Pico de preparações": "Пик приготовлений",
    "equipment.informações da semana": "информация недели",
    "equipment.horários de pico": "часы пик",
    "equipment.horário de pico": "время пик",
    "equipment.preparações do dia": "Приготовления дня",
    "equipment.Horário": "Время",
    "equipment.Preparações": "Приготовления",
    "equipment.dia selecionado": "выбранный день",
    "equipment.Vinculação IOK": "IOK-привязка",
    "equipment.Última preparação": "Последнее приготовление",
    "equipment.A limpeza adequada e diária do forno auxilia em seu funcionamento correto e impacta na garantia.": "Правильная и ежедневная очистка духовки помогает ей работать правильно и влияет на гарантию.",
    "equipment.Total de limpezas realizadas:": "Всего проведена чистка:",
    "equipment.Limpezas não concluídas:": "Уборка не завершена:",
    "equipment.Desde a vinculação do forno a IOK": "От подключения духовки к ИОК",
    "equipment.Equipamento ligado há:": "Оборудование включено уже:",
    "equipment.Total de receitas preparadas:": "Общее количество приготовленных рецептов:",
    "equipment.Receitas preparadas": "Приготовленные рецепты",
    "equipment.Aberturas de porta": "Дверные проемы",
    "equipment.Desde a última vez que o forno foi ligado": "С момента последнего включения духовки",
    "equipment.Preparação": "Приготовление",
    "equipment.Nenhuma localização criada": "Местоположение не создано",
    "equipment.Acesso remoto": "Удаленный доступ",
    "equipment.Informe o ID da empresa que poderá acessar remotamente seu forno. Use ; para separar IDs.": "Введите идентификатор компании, которая может получить удаленный доступ к вашей духовке. Использовать ; для разделения идентификаторов.",
    "equipment.O acesso remoto permite que as empresas selecionadas enviem menus e atualizações para seu equipamento.": "Удаленный доступ позволяет выбранным компаниям отправлять меню и обновления для вашего оборудования.",
    "equipment.Concedo acesso remoto à Prática oficial.": "Предоставляю удаленный доступ к официальной Prática.",
    "equipment.Deseja selecionar outros equipamentos vinculados à sua conta para conceder acesso remoto a eles?": "Хотите ли вы выбрать другие устройства, связанные с вашей учетной записью, чтобы предоставить им удаленный доступ?",
    "equipment.Selecione abaixo os equipamentos para os quais deseja conceder acesso remoto à": "Выберите ниже оборудование, для которого вы хотите предоставить удаленный доступ к",
    "equipment.Acesso remoto concedido com sucesso!": "Удаленный доступ успешно предоставлен!",
    "equipment.Selecione pelo menos um equipamento": "Выберите хотя бы одно устройство",
    "equipment.Não foi possível editar os equipamentos. Tente novamente mais tarde": "Невозможно редактировать оборудование. Повторите попытку позже",
    "equipment.Solicitar atendimento": "Запросить услугу",
    "equipment.Relate um problema": "Сообщить о проблеме",
    "equipment.Descreva aqui o problema de seu equipamento": "Опишите здесь проблему с вашим оборудованием",
    "equipment.Não foi possível enviar sua solicitação de atendimento. Tente mais tarde": "Нам не удалось отправить ваш запрос на обслуживание. Попробуйте позже",
    "equipment.Solicitação técnica enviada com sucesso!": "Технический запрос успешно отправлен!",
    "equipment.Enviar": "Отправить",
    "equipment.Solicitação de atendimento": "Запрос на обслуживание",
    "equipment.Informações do magnetron": "Информация о магнетроне",
    "equipment.Vida útil aproximada": "Примерный срок службы",
    "equipment.MAGNETRON USAGE HOURS": "Часы использования магнетрона",
    "equipment.Atendimento": "Услуга",
    "equipment.Eventos mais ocorridos no equipamento durante o período": "События, наиболее часто происходящие на оборудовании за период",
    "equipment.EQUIPAMENTO SEM NÚMERO DE SÉRIE": "ОБОРУДОВАНИЕ БЕЗ СЕРИЙНОГО НОМЕРА",
    "equipment.Filtros": "Фильтры",
    "equipment.Filtrar": "Фильтровать",
    "equipment.Limpar filtros": "Очистить фильтры",
    "equipment.Procurar por modelo, versão ou país": "Поиск по модели, версии или стране",
    "equipment.Procurar equipamento, NS, modelo, versão ou país": "Поиск оборудования, СН, модели, версии или страны",
    "equipment.Os seguintes IDs não pertencem a uma companhia do tipo 'Distribuidor' e foram removidos": "Следующие ID не принадлежат компании типа 'Дистрибьютор' и были удалены",
    "equipment.BAIXAR VERSÕES": "СКАЧАТЬ ВЕРСИИ",
    "equipment.Compatível com todas as versões da IHM.": "Совместим со всеми версиями HMI.",
    "equipment.Compatível apenas com a versão 2.0.0C da Power.": "Совместим только с версией Power 2.0.0C.",
    "equipment.Procurar evento": "Искать событие",
    "equipment.Forno conectado": "Печь подключена",
    "equipment.Limpeza de filtro de ar": "Очистка воздушного фильтра",
    "equipment.Limpeza de filtro catalítico": "Очистка каталитического фильтра",
    "equipment.Forno ligado": "Печь включена",
    "equipment.Inversor de frequência": "Преобразователь частоты",
    "equipment.Temperatura de painel": "Температура панели",
    "equipment.Sensor da câmara": "Датчик камеры",
    "equipment.Magnetron": "Магнетрон",
    "equipment.Power": "Power",
    "equipment.Informações de menu": "Информация о меню",
    "equipment.Limpeza": "Очистка",
    "equipment.O equipamento já está utilizando a versão mais recente do software.": "Оборудование уже использует последнюю версию программного обеспечения.",

    //LOJAS
    "store.Procurar loja": "Поиск магазина",
    "store.Excluir": "Удалить",
    "store.Editar": "Редактировать",
    "store.nome": "Имя",
    "store.equipamentos": "Оборудование",
    "store.cidade": "Город",
    "store.estado": "Область",
    "store.salvar": "Сохранить",
    "store.cadastrar loja": "зарегистрировать магазин",
    "store.Nome fantasia": "Фантазийное название",
    "store.Preencha o nome da loja": "Заполните название магазина",
    "store.CNPJ": "CNPJ",
    "store.Preencha o CNPJ da loja": "Заполните CNPJ магазина",
    "store.CEP": "Почтовый индекс",
    "store.Preencha o CEP da loja": "Заполните почтовый индекс магазина",
    "store.Informe o nome fantasia": "Заполните фантазийное название",
    "store.Cidade": "Город",
    "store.Informe a cidade": "Введите город",
    "store.Preencha a cidade": "Заполните город",
    "store.UF": "Область",
    "store.Preencha o UF da loja": "Заполните область магазина",
    "store.Bairro": "Район",
    "store.Informe o bairro": "Введите район",
    "store.Preencha o bairro da loja": "Заполните район магазина",
    "store.Endereço": "Адрес",
    "store.Informe o endereço": "Введите адрес",
    "store.Preencha o endereço da loja": "Заполните адрес магазина",
    "store.Nº": "№",
    "store.Loja cadastrada com sucesso": "Магазин успешно зарегистрирован",
    "store.Erro ao adicionar loja. Tente novamente": "Ошибка при добавлении магазина. Попробуйте снова",
    "store.Loja deletada com sucesso": "Магазин успешно удален",
    "store.Erro ao deletar loja. Tente novamente mais tarde": "Ошибка при удалении магазина. Попробуйте позже",
    "store.AINDA NÃO HÁ NENHUMA LOJA CADASTRADA :(": "ВСЕ ЕЩЕ НЕТ ЗАРЕГИСТРИРОВАННЫХ МАГАЗИНОВ :(",
    "store.EXCLUIR LOJA": "УДАЛИТЬ МАГАЗИН",
    "store.Você deseja excluir a loja da plataforma IOK?": "Вы хотите удалить магазин с платформы IOK?",
    "store.Ao fazer isso, os equipamentos atrelados à ela não terão vínculo com nenhuma loja, a menos que os vincule novamente.": "Таким образом, привязанное к нему оборудование не будет привязано к какому-либо магазину, пока вы снова не свяжете их.",
    "store.loja editada com sucesso": "Магазин успешно отредактирован",
    "store.não foi possível editar a loja. Tente novamente mais tarde": "Не удалось отредактировать магазин. Попробуйте снова позже",
    "store.editar loja": "редактировать магазин",
    "store.Procurar grupo de equipamentos": "Группа поискового оборудования",
    "store.cadastrar grupo": "зарегистрировать группу",
    "store.AINDA NÃO HÁ NENHUM GRUPO CADASTRADO :(": "ГРУППА ПОКА НЕ ЗАРЕГИСТРИРОВАНА :(",
    "store.cadastrar grupo de equipamentos": "зарегистрировать группу оборудования",
    "store.grupo de equipamentos cadastrado com sucesso!": "Группа оборудования успешно зарегистрирована!",
    "store.não foi possível cadastrar o grupo. Tente novamente mais tarde": "Невозможно зарегистрировать группу. Попробуйте позже",
    "store.Nome do grupo": "Название группы",
    "store.informe o nome do grupo": "Введите название группы",
    "store.preencha o nome do grupo": "Введите название группы",
    "store.Grupo de equipamentos deletado com sucesso!": "Группа оборудования успешно удалена!",
    "store.Erro ao deletar o grupo. Tente novamente mais tarde": "Ошибка удаления группы. Попробуйте позже",
    "store.EXCLUIR GRUPO DE EQUIPAMENTOS": "УДАЛЕНИЕ ГРУППЫ ОБОРУДОВАНИЯ",
    "store.Você deseja excluir este grupo de equipamentos da plataforma IOK?": "Вы хотите удалить эту группу оборудования с платформы IOK?",
    "store.Ao fazer isso, os equipamentos atrelados à ele não terão vínculo com nenhum grupo, a menos que os vincule novamente.": "Когда вы это сделаете, связанное с ним оборудование не будет связано ни с одной группой, если вы не свяжете его снова.",
    "store.grupo de equipamentos editado com sucesso!": "Группа оборудования успешно изменена!",
    "store.não foi possível editar o grupo. Tente novamente mais tarde": "Невозможно редактировать группу. Попробуйте позже",
    "store.editar grupo de equipamentos": "редактировать группу оборудования",
    "store.CEP não encontrado no Brasil": "Почтовый индекс не найден в Бразилии",

    //NAVBAR
    "navbar.home": "дом",
    "navbar.cookbook": "кулинарная книга",
    "navbar.my menus": "мои меню",
    "navbar.equipment": "оборудование",
    "navbar.users": "пользователи",
    "navbar.calls": "услуги",
    "navbar.settings": "настройки",
    "navbar.help": "помощь",
    "navbar.logout": "выйти",
    "navbar.Editar conta": "Редактировать аккаунт",
    "navbar.API Externa": "Внешнее API",

    //FOOTERBAR
    "footerBar.menu": "Меню",
    "footerBar.cookbook": "Кулинарная книга",
    "footerBar.home": "Главная",
    "footerBar.menus": "Меню",
    "footerBar.notifications": "Уведомления",

    //ATENDIMENTOS
    "calls.ATENDIMENTOS EM ABERTO": "ТЕКУЩИЕ СЛУЖБЫ",
    "calls.ATENDIMENTOS ENCERRADOS": "ЗАКРЫТЫЕ СЛУЖБЫ",

    //MAPA
    "map.título": "Местоположение",
    "map.erro": "ошибка",
    "map.mensagem de erro": "невозможно загрузить карту",
    "map.Localização não disponível": "Местоположение недоступно",

    //MODAL
    "modal.CONFIRMAR": "ПОДТВЕРДИТЬ",
    "modal.Salvar alterações": "Сохранить изменения",
    "Modal.Há alterações não salvas nos parâmetros dos passos": "Есть несохраненные изменения параметров шага",
    "Modal.Como deseja proceder?": "Как вы хотите продолжить?",
    "Modal.Avaliar alterações realizadas": "Оценить внесенные изменения",
    "Modal.Descartar alterações": "Отклонить изменения",

    //USUÁRIOS
    "user.Nome": "Имя",
    "user.E-mail": "Электронная почта",
    "user.Permissão": "Разрешение",
    "user.Loja": "Хранить",
    "user.Status": "Статус",
    "user.Excluir": "Удалить",
    "user.Editar": "Редактировать",
    "user.Procurar usuário": "Поиск пользователя",
    "user.ADICIONAR USUÁRIO": "ДОБАВИТЬ ПОЛЬЗОВАТЕЛЯ",
    "user.AINDA NÃO HÁ NENHUM USUÁRIO CADASTRADO :(": "ПОКА НЕТ ЗАРЕГИСТРИРОВАННЫХ ПОЛЬЗОВАТЕЛЕЙ :(",
    "user.+ Usuário": "+ Пользователь",
    "user.Informe o nome do usuário": "Введите имя пользователя",
    "user.Preencha o nome do usuário": "Заполните имя пользователя",
    "user.Informe o e-mail do usuário": "Введите электронную почту пользователя",
    "user.Preencha com um e-mail válido": "Введите действительный адрес электронной почты",
    "user.Administrador": "Администратор",
    "user.Usuário": "Пользователь",
    "user.Preencha o telefone do usuário": "Укажите телефон пользователя",
    "user.Selecione a permissão do usuário": "Выберите разрешение пользователя",
    "user.Ativo": "Активный",
    "user.Inativo": "Неактивный",
    "user.Usuário deletado da plataforma com sucesso!": "Пользователь успешно удален из платформы!",
    "user.Ocorreu um erro. Tente novamente mais tarde": "Произошла ошибка. Повторите попытку позже",
    "user.EXCLUIR USUÁRIO": "УДАЛИТЬ ПОЛЬЗОВАТЕЛЯ",
    "user.delete.paragrafo 1": "Хотите удалить пользователя из платформы IOK?",
    "user.delete.paragrafo 2": "Таким образом, они потеряют доступ ко всем инструментам платформы, пока их не добавят снова, следуя инструкциям по регистрации.",
    "user.EDITAR USUÁRIO": "РЕДАКТИРОВАТЬ ПОЛЬЗОВАТЕЛЯ",
    "user.Usuário editado com sucesso!": "Пользователь успешно отредактирован!",
    "user.Não foi possível editar este usuário. Por favor, tente mais tarde": "Не удалось отредактировать этого пользователя. Пожалуйста, повторите попытку позже",
    "user.INFORMAÇÕES DO USUÁRIO": "ИНФОРМАЦИЯ О ПОЛЬЗОВАТЕЛЕ",
    "user.Telefone": "Телефон",
    "user.Não foi possível cadastrar este usuário. Por favor, tente mais tarde": "Не удалось зарегистрировать этого пользователя. Пожалуйста, повторите попытку позже",
    "user.Usuário adicionado com sucesso!": "Пользователь успешно добавлен!",
    "user.CRIAR TIPO DE USUÁRIO": "СОЗДАТЬ ТИП ПОЛЬЗОВАТЕЛЯ",
    "user.Criar novo tipo de usuário": "Создать новый тип пользователя",
    "user.Adicionar novo usuário": "Добавить нового пользователя",
    "user.Permissões": "Разрешения",
    "user.Preencha o nome do novo tipo de usuário": "Заполните имя нового типа пользователя",
    "user.Selecione pelo menos uma permissão": "Выберите хотя бы одно разрешение",
    "user.Não foi possível carregar as permissões": "Не удалось загрузить разрешения",
    "user.Tipo de usuário criado com sucesso!": "Тип пользователя успешно создан!",
    "user.Não foi possível criar este novo tipo de usuário. Por favor, tente mais tarde": "Не удалось создать этот новый тип пользователя. Пожалуйста, повторите попытку позже",
    "user.Não foi possível carregar os tipos de usuário da companhia": "Не удалось загрузить типы пользователей компании",
    "user.Tipo de usuário": "Тип пользователя",
    "user.E-mail já cadastrado na plataforma. Informe um novo e-mail": "Электронная почта уже зарегистрирована на платформе. Введите новый адрес электронной почты",
    "user.UNIR CONTAS": "ПРИСОЕДИНЯЙТЕСЬ К СЧЕТАМ",
    "user.ID da companhia": "Идентификатор компании",
    "user.Digite o ID da companhia que deseja unir a sua conta": "Введите идентификатор компании, с которой вы хотите объединить свою учетную запись.",
    "user.Preencha o ID da companhia": "Заполните идентификатор компании",
    "user.O administrador da conta com a qual deseja se unir poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.":
      "Администратор учетной записи, к которой вы хотите присоединиться, сможет найти идентификатор, щелкнув ваше имя пользователя, расположенное в верхнем меню в правом углу экрана.",
    "user.O administrador da outra conta receberá um e-mail para aceitar sua solicitação. Assim que for aceita, a união das duas contas será realizada!":
      "Администратор другой учетной записи получит электронное письмо с просьбой принять ваш запрос. После принятия два аккаунта будут объединены!",
    "user.Não foi possível solicitar a união de contas. Por favor, confirme o ID ou tente mais tarde": "Невозможно запросить объединение учетных записей. Пожалуйста, подтвердите идентификатор или попробуйте позже.",
    "user.SOLICITAÇÃO APROVADA COM SUCESSO": "ЗАПРОС УСПЕШНО ОДОБРЕН",
    "user.AGUARDE ENQUANTO REALIZAMOS A UNIÃO DAS CONTAS. ESTE PROCESSO PODE LEVAR ALGUNS MINUTOS...": "ПОЖАЛУЙСТА, ПОДОЖДИТЕ, ПОКА МЫ ПОДКЛЮЧИМ АККАУНТЫ. ЭТОТ ПРОЦЕСС МОЖЕТ ЗАНЯТЬ НЕСКОЛЬКО МИНУТ...",
    "user.UNIÃO DE CONTAS FINALIZADA COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "ОБЪЕДИНЕНИЕ СЧЕТОВ УСПЕШНО ЗАВЕРШЕНО! НАЖМИТЕ КНОПКУ НИЖЕ, ЧТОБЫ ДОСТУП К ПЛАТФОРМЕ.",
    "user.NÃO CONSEGUIMOS REALIZAR A UNIÃO DAS CONTAS. POR FAVOR, TENTE NOVAMENTE. SE O PROBLEMA PERSISTIR, ENTRE EM CONTATO COM NOSSO SUPORTE.":
      "МЫ НЕ МОЖЕМ ОБЪЕДИНИТЬ АККАУНТЫ. ПОЖАЛУЙСТА, ПОПРОБУЙТЕ ЕЩЕ РАЗ. ЕСЛИ ПРОБЛЕМА ПРОДОЛЖАЕТСЯ, ОБРАЩАЙТЕСЬ В НАШУ ПОДДЕРЖКУ.",
    "user.possui todos os acessos": "имеет весь доступ",
    "user.possui o acesso das abas cookbook, meus menus e equipamentos": "имеет доступ к кулинарной книге, моему меню и вкладкам оборудования",
    "user.possui apenas o acesso da aba cookbook": "имеет доступ только к вкладке кулинарной книги",
    "user.possui apenas o acesso da aba equipamentos": "имеет доступ только к вкладке оборудования",
    "user.possui apenas o acesso da aba meus menus": "имеет доступ только к вкладке Мое меню",
    "user.Grupo de equipamentos": "Группа оборудования",
    "user.Informe o telefone do usuário": "Введите номер телефона пользователя",
    "user.Nenhum grupo criado": "Группы не созданы",
    "user.Digite o ID da companhia que terá acesso à sua conta": "Введите идентификатор компании, которая будет иметь доступ к вашей учетной записи",
    "user.Ao informar um ID para unir contas com sua companhia, esteja ciente de que o novo usuário também se tornará Administrador da conta de sua companhia. É importante informar que a partir do momento em que um usuário aceitar unir contas com sua companhia, a companhia pertencente a ele deixará de existir.":
      "Предоставляя идентификатор для объединения учетных записей с вашей компанией, имейте в виду, что новый пользователь также станет администратором учетной записи вашей компании. Важно сообщить, что с того момента, как пользователь согласится объединить аккаунты с вашей компанией, принадлежащая ему компания прекратит свое существование.",
    "user.Exemplo: Sua conta: 001 | Outra conta: 111. Depois de unir contas com você, a outra conta passará a ser 001.": "Пример: Ваш аккаунт: 001 | Другая учетная запись: 111. После объединения учетных записей с вами другая учетная запись станет 001.",
    "user.O administrador da outra conta poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.": "Администратор другой учетной записи сможет найти идентификатор, щелкнув ваше имя пользователя, расположенное в верхнем меню в правом углу экрана.",

    //TUTORIAIS
    "tutorial.Fechar": "Закрыть",
    "tutorial.Fim": "Конец",
    "tutorial.Próximo": "Далее",
    "tutorial.Abrir": "Открыть",
    "tutorial.Pular tutorial": "Пропустить обучение",
    "tutorial.Editar detalhes da receita": "Редактировать детали рецепта",
    "tutorial.Agora você pode adicionar mais detalhes às suas receitas e deixá-las ainda mais completas. Clique em Editar detalhes e preencha as informações necessárias.": "Теперь вы можете добавить больше деталей в свои рецепты и сделать их еще более полными. Нажмите «Редактировать детали» и заполните необходимую информацию.",
    "tutorial.Salvar edição da receita": "Сохранить редактирование рецепта",
    "tutorial.Depois de informar mais detalhes da receita, clique em Salvar.": "После того как вы добавили больше деталей к рецепту, нажмите «Сохранить»",
    "tutorial.Editar registro de um equipamento": "Редактировать регистрацию оборудования",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Categoria e Loja.": "Чтобы завершить регистрацию вашего оборудования, откройте боковое меню, нажмите «Редактировать» и заполните информацию о категории и магазине.",
    "tutorial.Editar usuário": "Редактировать пользователя",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente a Loja a qual ele faz parte.": "Для завершения регистрации пользователя откройте боковое меню, нажмите Редактировать и заполните информацию о магазине, к которому они относятся.",
    "tutorial.Salvar edições de um equipamento": "Сохраните изменения в оборудовании.",
    "tutorial.Depois de completar o registro de seus equipamentos, clique em Salvar.": "После завершения регистрации вашего оборудования нажмите Сохранить.",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em uma loja, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "Нажав на строку зарегистрированного оборудования в магазине, вы можете просмотреть дополнительную статистику и информацию о нем, а также запросить услугу или запустить обновления.",
    "tutorial.Para visualizar mais informações sobre a loja, como localização e receitas mais preparadas, clique sobre a linha da unidade que deseja analisar.": "Чтобы увидеть больше информации о магазине, таких как местоположение и самые подготовленные рецепты, нажмите на строку подразделения, которое хотите проанализировать.",
    "tutorial.Criar receita": "Создать рецепт",
    "tutorial.No cookbook você pode criar suas próprias receitas! Clique em Criar Receita e preencha todas as informações necessárias.": "В кулинарной книге вы можете создавать свои собственные рецепты! Нажмите Создать рецепт и заполните всю необходимую информацию.",
    "tutorial.Editar ou excluir um usuário": "Редактировать или удалить пользователя",
    "tutorial.Para excluir ou editar as informações de um usuário já cadastrado, clique no menu lateral e selecione a opção desejada.": "Чтобы удалить или отредактировать информацию о зарегистрированном пользователе, откройте боковое меню и выберите нужную опцию.",
    "tutorial.Gerenciamento de usuários": "Управление пользователями",
    "tutorial.Aqui você pode gerenciar seus usuários com mais facilidade. Ao clicar em + Usuário, você poderá adicionar um novo usuário ou criar um novo tipo de usuário.": "Здесь вам будет проще управлять своими пользователями. Нажав + Пользователь, вы можете добавить нового пользователя или создать новый тип пользователя.",
    "tutorial.Suas receitas": "Ваши рецепты",
    "tutorial.Pelo cookbook você também encontra suas próprias receitas, além de poder criá-las! Clique em Receitas Salvas para visualizar as receitas que foram salvas do cookbook da Prática e também as criadas por você.": "Через кулинарную книгу вы также можете находить свои собственные рецепты, помимо возможности создавать их! Нажмите Сохраненные рецепты, чтобы просмотреть рецепты, сохраненные в кулинарной книге Prática, а также те, которые вы создали.",
    "tutorial.Filtros do Cookbook": "Фильтры кулинарной книги",
    "tutorial.Para facilitar a navegação pelas receitas, você pode aplicar filtros a elas. Basta clicar no filtro desejado e escolher uma de suas opções.": "Чтобы упростить навигацию по рецептам, вы можете применять к ним фильтры. Просто щелкните по нужному фильтру и выберите один из его вариантов.",
    "tutorial.Cookbook": "Кулинарная книга",
    "tutorial.No cookbook, em Receitas Disponíveis, você encontrará as receitas criadas pelos Chefs da Prática e poderá utilizá-las sempre que quiser.": "В кулинарной книге, в разделе Доступные рецепты, вы найдете рецепты, созданные шеф-поварами Prática, и сможете использовать их по своему усмотрению.",
    "tutorial.Atualizar equipamentos": "Обновить оборудование",
    "tutorial.Para enviar uma atualização a seus equipamentos, clique em Atualizar Equipamentos, selecione os modelos que deseja atualizar e a forma como deseja realizar essa atualização - via wi-fi ou pen drive. Depois disso, clique em Confirmar.": "Чтобы отправить обновление на ваше оборудование, нажмите Обновить оборудование, выберите модели, которые вы хотите обновить, и выберите способ - через Wi-Fi или USB-флеш-накопитель. Затем нажмите Подтвердить.",
    "tutorial.Adicionar equipamento": "Добавить оборудование",
    "tutorial.Para adicionar um equipamento à sua conta, clique em Adicionar Equipamento e preencha as informações do modal. Depois disso, clique em Salvar.": "Чтобы добавить оборудование в ваш аккаунт, нажмите Добавить оборудование и заполните модальную информацию. Затем нажмите Сохранить.",
    "tutorial.Editar equipamento": "Отредактировать оборудование",
    "tutorial.Para desvincular um equipamento ou editar suas informações, clique no menu lateral e selecione a opção desejada.": "Чтобы отсоединить оборудование или отредактировать его информацию, откройте боковое меню и выберите нужную опцию.",
    "tutorial.Seus equipamentos": "Ваше оборудование",
    "tutorial.Aqui você visualizará todos os equipamentos cadastrados em sua plataforma.": "Здесь вы увидите все оборудование, зарегистрированное на вашей платформе.",
    "tutorial.Restaurar arquivos": "Восстановить файлы",
    "tutorial.Para restaurar um arquivo excluído ou deletá-lo permanentemente, clique no menu lateral e selecione a opção desejada.": "Для восстановления удаленного файла или его окончательного удаления нажмите на боковое меню и выберите нужную опцию.",
    "tutorial.Lixeira": "Корзина",
    "tutorial.Os menus excluídos serão mantidos na Lixeira por 30 dias. Depois desse prazo serão excluídos permanentemente.": "Удаленные меню будут храниться в корзине в течение 30 дней. После этого срока они будут окончательно удалены.",
    "tutorial.Salvar edições de usuário": "Сохранить изменения пользователя",
    "tutorial.Depois de completar o cadastro dos usuários, clique em Salvar.": "После завершения регистрации пользователя, нажмите Сохранить.",
    "tutorial.Criar menu": "Создать меню",
    "tutorial.Para criar e personalizar um menu, clique em Criar Menu e preencha as informações necessárias no modal. Você pode editar um menu a qualquer momento.": "Для создания и настройки меню нажмите Создать меню и заполните необходимую информацию в модальном окне. Вы можете редактировать меню в любое время.",
    "tutorial.Importar menu": "Импортировать меню",
    "tutorial.Para importar um menu, clique em Importar Menu e selecione o modelo e versão do equipamento.": "Для импорта меню нажмите Импортировать меню и выберите модель и версию оборудования.",
    "tutorial.Criar grupo": "Создать группу",
    "tutorial.Para organizar suas receitas em grupos, clique em Criar Grupo e preencha as informações necessárias no modal.": "Для управления вашими рецептами группы нажмите Создать группу и заполните необходимую информацию в модальном окне.",
    "tutorial.Tipos de usuário": "Типы пользователей",
    "tutorial.Agora você também pode criar tipos de usuários com permissões específicas para atender melhor a sua organização.": "Теперь вы также можете создавать типы пользователей с определенными разрешениями, чтобы лучше обслуживать вашу организацию.",
    "tutorial.Gerenciamento de contas": "Управление аккаунтом",
    "tutorial.Se caso outra pessoa da sua organização também migrou a conta principal da empresa, aqui você pode unir as duas contas.": "Если кто-то еще в вашей организации также перенес свою основную учетную запись компании, здесь вы можете объединить две учетные записи.",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Grupo.": "Для завершения регистрации вашего оборудования откройте боковое меню, нажмите «Редактировать» и заполните информацию о группе.",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente ao Grupo de equipamentos a qual ele faz parte.": "Для завершения регистрации пользователя откройте боковое меню, нажмите «Редактировать» и заполните информацию о группе оборудования, к которой он принадлежит.",
    "tutorial.Grupos de equipamentos": "Группы оборудования",
    "tutorial.Na aba Grupos você pode visualizar os grupos cadastrados e os equipamentos distribuídos entre eles. Além disso, é possível cadastrar novos grupos e editar os já cadastrados.": "Во вкладке «Группы» вы можете просмотреть зарегистрированные группы и распределенное между ними оборудование. Кроме того, можно регистрировать новые группы и редактировать уже зарегистрированные.",
    "tutorial.Detalhes de grupos": "Детали группы",
    "tutorial.Para visualizar mais informações sobre o grupo, como localização e receitas mais preparadas, clique sobre a linha que deseja analisar.": "Чтобы просмотреть больше информации о группе, например, местоположение и самые часто приготовленные рецепты, нажмите на строку, которую хотите проанализировать.",
    "tutorial.Equipamentos em grupos": "Оборудование в группах",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em um grupo, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "Нажав на строку устройства, зарегистрированного в группе, вы можете просмотреть дополнительную статистику и информацию о нем, а также иметь возможность запрашивать обслуживание или запускать обновления.",
    "tutorial.Grupos": "Группы",
    "tutorial.Atualização e cache": "Обновление и кэш",
    "tutorial.Para atualizar a plataforma e corrigir eventuais problemas de cache, clique aqui.": "Чтобы обновить платформу и исправить проблемы с кэшем, нажмите здесь.",
    "tutorial.Dados dos equipamentos": "Данные оборудования",
    "tutorial.Ao clicar sobre a linha de um equipamento, você pode visualizar mais estatísticas e informações sobre ele, como as receitas mais preparadas, limpezas realizadas e outros dados relevantes.": "Нажав на строку устройства, вы можете просмотреть больше статистики и информации о нем, например, самые часто приготовленные рецепты, выполненные очистки и другие важные данные.",
    "tutorial.Para alterar o idioma de sua plataforma, clique em Idioma e selecione a linguagem desejada.": "Чтобы изменить язык вашей платформы, нажмите «Язык» и выберите нужный язык.",
    "tutorial.Alterar idioma": "Изменить язык",
    "tutorial.Para editar ou configurar sua conta, clique em seu nome e selecione a opção desejada.": "Чтобы отредактировать или настроить свою учетную запись, нажмите на свое имя и выберите нужную опцию.",
    "tutorial.Editar conta": "Редактировать аккаунт",
    "tutorial.Total de Usuários Cadastrados em sua conta.": "Всего пользователей, зарегистрированных в вашей учетной записи.",
    "tutorial.Home": "домашняя страница",
    "tutorial.Localização de seus equipamentos, sendo possível expandir o mapa para melhor visualização;": "Расположение вашего оборудования, дающее возможность расширить карту для лучшего просмотра;",
    "tutorial.Atualizações referentes a versão dos equipamentos vinculados a sua conta;": "Обновления относительно версии оборудования, связанного с вашей учетной записью;",
    "tutorial.Total de Receitas Cadastradas;": "Общий зарегистрированный доход;",
    "tutorial.Menus Disponíveis;": "Доступные меню;",
    "tutorial.Esta é a sua home! Aqui você encontra um resumo geral sobre sua conta, como quantidade de Equipamentos Vinculados;": "Это ваша домашняя страница! Здесь вы найдете общую информацию о вашей учетной записи, например количество подключенного оборудования;",
    "tutorial.Clique nas abas do menu para explorar a plataforma e conhecer tudo o que ela pode oferecer.": "Нажимайте на вкладки меню, чтобы изучить платформу и узнать все, что она может предложить.",
    "tutorial.Exportar menu": "Меню экспорта",
    "tutorial.Depois de personalizar seu menu, exporte-o para seus equipamentos pela internet ou por pen drive.": "После настройки меню экспортируйте его на свои устройства через Интернет или с помощью флэш-накопителя.",
    "tutorial.Na aba Localização você pode visualizar os endereços já cadastrados, além de criar localizações precisas para vincular seus equipamentos a elas.": "На вкладке «Местоположение» вы можете просмотреть уже зарегистрированные адреса, а также создать точные местоположения для привязки к ним вашего оборудования.",
    "tutorial.Localização": "Расположение",

    //RECOVER ACCOUNT
    "recoverAccount.Para prosseguir, selecione a categoria na qual você se encaixa:": "Чтобы продолжить, выберите категорию, в которую вы вписываетесь:",
    "recoverAccount.Deseja acessar a plataforma como DISTRIBUIDOR? Crie sua conta clicando em EMPRESA e entre em contato conosco para liberarmos os demais acessos.": "Хотите получить доступ к платформе как ДИСТРИБЬЮТОР? Создайте свой аккаунт, нажав на КОМПАНИЮ, и свяжитесь с нами, чтобы открыть другие доступы.",
    "recoverAccount.A qual organização você pertence": "К какой организации вы принадлежите?",

    //RECOVER PASSWORD
    "recoverPassword.E-mail para recuperação de senha": "Электронная почта для восстановления пароля",
    "recoverPassword.Informe o e-mail cadastrado na plataforma": "Укажите электронную почту, зарегистрированную на платформе",
    "recoverPassword.E-mail inválido. Por favor, verifique": "Неверный адрес электронной почты. Пожалуйста, проверьте",
    "recoverPassword.RECUPERAR SENHA": "ВОССТАНОВИТЬ ПАРОЛЬ",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL COM O LINK DE RECUPERAÇÃO DE SENHA": "В БЛИЖАЙШЕЕ ВРЕМЯ ВЫ ПОЛУЧИТЕ ЭЛЕКТРОННОЕ ПИСЬМО С ССЫЛКОЙ НА ВОССТАНОВЛЕНИЕ ПАРОЛЯ",
    "recoverPassword.Login": "Логин",
    "recoverPassword.Informe seu e-mail": "Укажите свою электронную почту",
    "recoverPassword.Crie uma nova senha": "Создайте новый пароль",
    "recoverPassword.O campo senha deve conter no mínimo 6 caracteres": "Поле пароля должно содержать не менее 6 символов",
    "recoverPassword.Confirme sua senha": "Подтвердите пароль",
    "recoverPassword.As senhas devem ser iguais": "Пароли должны совпадать",
    "recoverPassword.ENTRAR": "ВОЙТИ",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL PARA CONFIRMAR SUA IDENTIDADE": "В БЛИЖАЙШЕЕ ВРЕМЯ ВЫ ПОЛУЧИТЕ ЭЛЕКТРОННОЕ ПИСЬМО ДЛЯ ПОДТВЕРЖДЕНИЯ ВАШЕЙ ЛИЧНОСТИ",
    "recoverPassword.CONTINUAR": "ПРОДОЛЖИТЬ",
    "recoverPassword.CONFIRMAR": "ПОДТВЕРДИТЬ",

    // EXPORT
    "export.Limpezas": "Очистки",
    "export.Eventos": "События",
    "export.Lista de receitas preparadas": "Список приготовленных рецептов",
    "export.Lista de limpezas realizadas": "Список выполненных очисток",
    "export.Lista de eventos ocorridos": "Список произошедших событий",
    "export.Exportar dados": "Экспорт данных",
    "export.Informações do equipamento": "Информация об оборудовании",
    "export.Dados exportados com sucesso!": "Данные успешно экспортированы!",
    "export.Não foi possível exportar os dados. Por favor, tente novamente mais tarde.": "Не удалось экспортировать данные. Пожалуйста, попробуйте позже.",
    "export.Detalhes do menu": "Подробности меню",
    "export.Detalhes de receitas": "Подробности рецептов",
    "export.Detalhes e Passos": "Подробности и Шаги",
    "export.Exportando dados. Em breve o arquivo estará disponível!": "Экспорт данных. Файл скоро будет доступен!",
    "export.Resumo dos passos": "Резюме шагов",
    "export.salvar imagem": "сохранить изображение"
  },
};

export default ru;
