/**
 * Developed by: Inatel Competence Center
 * Copyright 2019, Prática
 * Author: Lucas Marciano
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import React from 'react';
import { Container, Spin } from './styles';

const Loading: React.FC = () => {
	return (
		<Container>
			<Spin />
		</Container>
	);
};

export default Loading;
