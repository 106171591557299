import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StepCombiOvenTSIModel } from '../../models/stepCombiOvenTSI';

interface StepsCombiOvenTSIState {
  steps: StepCombiOvenTSIModel[];
}

const initialState: StepsCombiOvenTSIState = {
  steps: [],
};

const stepsCombiOvenTSISlice = createSlice({
  name: 'stepsCombiOvenTSI',
  initialState,
  reducers: {
    setStepsCombiOvenTSI(state, action: PayloadAction<StepCombiOvenTSIModel[]>) {
      state.steps = action.payload;
    },
    addStepCombiOvenTSI(state, action: PayloadAction<StepCombiOvenTSIModel>) {
      state.steps.push(action.payload);
    },
    removeStepCombiOvenTSI(state, action: PayloadAction<number>) {
      state.steps = state.steps.filter(step => step.id !== action.payload);
    },
    updateStepCombiOvenTSI(state, action: PayloadAction<{ index: number; changes: Partial<StepCombiOvenTSIModel> }>) {
      const { index, changes } = action.payload;
      const step = state.steps[index];
      if (step) {
         state.steps[index] = { ...step, ...changes };
      }
    },
    clearStepsCombiOvenTSI(state) {
      state.steps = [];
    }
  }
});

export const { setStepsCombiOvenTSI, addStepCombiOvenTSI, removeStepCombiOvenTSI, updateStepCombiOvenTSI, clearStepsCombiOvenTSI } = stepsCombiOvenTSISlice.actions;

export default stepsCombiOvenTSISlice.reducer;