import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SimpleRecipeModel from '../../models/SimpleRecipe';

interface MenuState {
  recipes: SimpleRecipeModel[];
}

const initialState: MenuState = {
  recipes: [],
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenuSimpleRecipes(state, action: PayloadAction<SimpleRecipeModel[]>) {
      state.recipes = action.payload;
    },
    updateStateSimpleRecipe(state, action: PayloadAction<SimpleRecipeModel>) {
      const index = state.recipes.findIndex(recipe => recipe.id === action.payload.id);
      if (index !== -1) {
        state.recipes[index] = action.payload;
      }
    },
    clearMenuSimpleRecipes(state) {
      state.recipes = [];
    }
  }
});

export const { setMenuSimpleRecipes, updateStateSimpleRecipe, clearMenuSimpleRecipes } = menuSlice.actions;

export default menuSlice.reducer;
