import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserStateModel } from '../../models/User';

interface UserState {
  object: UserStateModel;
}

const initialState: UserState = {
  object: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserStateModel>) => {
      state.object = action.payload;
    },
    removeUser: (state) => {
      state.object = null;
    },
    updateUser: (state, action: PayloadAction<UserStateModel>) => {
      state.object = action.payload;
    }
  }
});

export const { setUser, removeUser, updateUser } = userSlice.actions;

export default userSlice.reducer;
