import styled from 'styled-components';
import { Colors } from '../../assets/styles/StyleTypes';
const width = '660px';

export const Section = styled.div`
  grid-area: section;
  text-transform: none;
  height: 100vh;
  width: 100vw;
  height: 100dvh;
  width: 100dvw;
  background: ${(props) => props.theme.colors.background};
  display: flex;

  flex-direction: column;
  @media screen and (max-width: 1060px) {
    width: calc(100vw - 240px);
  }
  @media screen and (max-width: 960px) {
    width: 100dvw;
    height: 90dvh;
  }
`;

export const Header = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 9vh;
  width: 100vw;
  height: 9dvh;
  width: 100dvw;

  background-color: ${(props) => props.theme.colors.header};
  @media screen and (max-width: 960px) {
    display: none;
  }
  @media (min-height: 800px) {
    height: 7dvh;   
  }
`;


export const Container = styled.div`
  display: flex;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  width: 100dvw;
  background: ${(props) => props.theme.colors.background};
  @media screen and (max-width: 960px) {
    //margin-top: -10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

   /*  align-content: space-around;
    grid-template-columns: 100dvw;
    grid-template-rows: calc(100dvh - 93px) 30px;
    grid-template-areas:
      'section'
      'footer-nav'; */
  }
`;

export const SideMenu = styled.div`
  border: none;
  width: auto;
  height: 100vh;
  height: 100dvh;
  z-index: 1;
  background: ${(props) => props.theme.colors.navbar};
  box-shadow: ${(props) => props.theme.colors.navbarShadow};
  border-radius: 0px 35px 35px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: calc(100vh - 60px);
    width: 100dvw;
    height: calc(100dvh - 60px);
    //min-width: 360px;
    position: absolute;
    top: 0px;
    left: -100%;
    opacity: 1;
    box-shadow: none;
    background: ${Colors.PRIMARY_HIGHLIGHT};
    border-radius: 0px;
    justify-content: center;
    align-items: flex-start;
    transition: all 0.5s ease;
    &.active {
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 3;
    }
  }
`;

export const Main = styled.main`
  width: 660px;
  height: 100%;
  position: absolute;
  top: 0px;
  z-index: 1;
  background: ${Colors.PRIMARY_PURE};
  box-shadow: 5px 0px 10px #00000080;
  border-radius: 0px 35px 35px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: overlay;

  @media only screen and (max-width: 360px) {
    border-radius: 0px;       
  }

  @media screen and (max-width: 960px) {
    width: 100vw;
    height: 100vh;
    width: 100dvw;
    height: 100dvh;
    border-radius: 0px;
  }
`;

export const Aside = styled.aside`
  width: calc(100vw - ${width} + 40px);
  //min-height: 750px;
  height: 100%;

  position: absolute;
  top: 0px;
  left: calc(${width} - 40px);
  z-index: 0;

  @media screen and (max-width: 768px){
    display: none;
  }
`;

export const DivLang = styled.div`
  position: fixed;
  top: 20px;
  left: calc(100vw - 175px);
  height: 40px;
  width: 175px;
  background: #f2f2f2;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 5px #00000080;
  border-radius: 20px 0px 0px 20px;
  opacity: 1;
  overflow-x: none;
  @media (max-width: 960px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: none;
    box-shadow: none;
    margin-right: auto;
    margin-left: auto;
  }
`;

export const Settings = styled.img`
  height: 30px;
  padding: 5px;
  border-radius: 30px;
  border: 2px solid #f15b29;
  background-color: #383838;
  margin: 0px 30px;
`;

export const FooterNav = styled.footer`
  width: 100vw;
  grid-area: footer-nav;
  background-color: ${Colors.PRIMARY_PURE};
  z-index: 1;
  display: none;
  @media screen and (max-width: 960px) {
    display: block;
  }
`;


export const Canvas = styled.canvas`
    position: absolute;
    display: none;
    z-index: 100;
`;
