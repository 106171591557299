import React, { FC, FormEvent, useEffect } from 'react';
import ReactDOM from 'react-dom';

import Backdrop from '../Backdrop';

import * as Styled from './styles';

import { useDispatch } from 'react-redux';
import { setShowScroll } from '../../../store/configs/scrollSlice';

type ModalProps = {
  onCancel: () => void;
  onSubmit?: (event: FormEvent) => void;
  children: React.ReactNode;
  footer?: React.ReactElement;
  modalClass?: string;
  headerClass?: string;
  contentClass?: string;
  footerClass?: string;
  title: string;
  width: string;
  showButtonClose?: boolean;
  footerFixed?: boolean;
  moveUp?: boolean;
  style?: React.CSSProperties;
};

const findScrollableDiv = () => {
  return document.querySelector('.scrollable-content');
};

const ModalOverlay: FC<ModalProps> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const scrollableDiv = findScrollableDiv() as HTMLElement | null;
    if (scrollableDiv) scrollableDiv.style.overflow = 'hidden';
    dispatch(setShowScroll(true));
    return () => {
      if (scrollableDiv) scrollableDiv.style.overflow = '';
      dispatch(setShowScroll(false))
    }
  }, [dispatch])

  const content = (
    <Styled.Container className={props.modalClass} width={props.width} moveUp={props.moveUp} style={props.style}>
      <Styled.Header className={props.headerClass}>
        <Styled.Title>{props.title}</Styled.Title>
        {props.showButtonClose ? (
          <Styled.CloseButton onClick={props.onCancel}>X</Styled.CloseButton>
        ) : (
          ''
        )}
      </Styled.Header>
      <Styled.Form
        onSubmit={
          props.onSubmit ? props.onSubmit : (event) => event.preventDefault()
        }
      >
        <Styled.Content className={props.contentClass} footerFixed={props.footerFixed}>
          {props.children}
        </Styled.Content>
        {props.footer ? (
          <Styled.Footer className={props.footerClass} footerFixed={props.footerFixed}>
            {props.footer}
          </Styled.Footer>
        ) : (
          ''
        )}
      </Styled.Form>
    </Styled.Container>
  );

  return ReactDOM.createPortal(content, document.getElementById('modal')!);
};

const Modal: FC<ModalProps> = (props) => {
  return (
    <>
      <Backdrop moveUp={props.moveUp} />
      <ModalOverlay {...props} />
    </>
  );
};

export default Modal;
