export enum Colors {
  // PRIMARY COLORS
  PRIMARY = '#004992',
  PRIMARY_PURE = '#004687',
  PRIMARY_HIGHLIGHT = '#025CB7',
  PRIMARY_MEDIUM = '#4F8FE9',
  PRIMARY_LIGHT = '#5DAEFF',
  PRIMARY_LIGHTEST = '#D0DFF0',
  //NEUTRAL COLORS
  LOW_PURE = '#333333',
  LOW_DARK = '#202020',
  LOW_DARKEST = '#000000',
  LOW_MEDIUM = '#515151',
  LOW_LIGHT = '#767676',
  HIGH_PURE = '#F8F9FA',
  HIGH_LIGHT = '#F2F2F2',
  HIGH_LIGHTEST = '#FFFFFF',
  HIGH_MEDIUM = '#E5E5E5',
  HIGH_DARK = '#A6A6A6',
  PLACEHOLDER = '#6A6A6A',
  CANCELLATION = '#FF4143',
  STEP_LIGHT = '#C8C8C8',
  STEP_DARK = '#232323',
  GRAPHIC_LIGHT = '#E5ECF4',
  // FEEDBACK COLORS
  TOAST_NEUTRAL = '#5DAEFF',
  TOAST_SUCCESS = '#048243',
  TOAST_WARNING = '#C21618',
  ERROR_WARNING = '#FFE5E5',
  //GRADIENTS
  HOMECOMING_GRADIENT = 'linear-gradient(102deg, #4F8FE9 0%, #396BC9 45%, #5E50AE 100%)',
  CONFIRMATION_GRADIENT = 'linear-gradient(180deg, #5DAEFF 0%, #2F72CF 100%)',
  CANCELATION_GRADIENT = 'linear-gradient(180deg, #E54529 0%, #C21618 100%)',
  HIGH_GRADIENT = 'linear-gradient(1deg, #D0DFF0 0%, #F8F9FA 100%)',
  LOW_GRADIENT = 'linear-gradient(180deg, #232323 0%, #0F3451 100%)',
  INFO_GRADIENT = 'linear-gradient(96deg, #4F8FE9 0%, #396BC9 45%, #5E50AE 100%)',
}

export enum BorderRadius {
  LG = '35px',
  MD = '10px',
  SM = '6px',
  XS = '4px',
  NONE = '0px',
}

export enum BorderWeight {
  THIN = '2px',
  HARD_LINE = '1px',
  NONE = '0px,',
}

export enum Opacity {
  MEDIUM = '0.6',
  INTENSE = '0.8',
}

export enum Shadow {
  DARK_MODAL_SHADOW = '0px 0px 5px #000000',
  DARK_BOOK_SHADOW = '0px 5px 6px #333333CC',
  DARK_MENU_SHADOW = '0px 3px 6px #2F72CF',
  DARK_GUIDE_SHADOW = '0px 3px 6px #2F72CF',
  LIGHT_MODAL_SHADOW = '0px 0px 6px #3333334D',
  LIGHT_BOOK_SHADOW = '0px 5px 6px #33333333',
  LIGHT_BOX_SHADOW = '0px 0px 5px #00000080',
  WHITE_BOX_SHADOW = '0px 0px 1px #ffffff80'
}

export enum Padding {
  LG = '30px',
  MD = '30px 20px',
  SM = '20px',
  XS = '10px',
  XY = '20px 20px 10px 20px',
}

export enum Typography {
  HEADING_XXL = `
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    text-transform: uppercase;
    letter-spacing: 0.45px;
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
  `,
  HEADING_XL = `
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-transform: uppercase;
    letter-spacing: 0.36px;
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
  `,
  HEADING_LG = `
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
  `,
  SUBTITLE_LG = `
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 0.32px;
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
  `,
  SUBTITLE_MD = `
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    letter-spacing: 0.24px;
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
  `,
  SUBTITLE_SM = `
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    letter-spacing: 0.21px;
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
  `,
  SUBTITLE_XS = `
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    letter-spacing: 0.21px;
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
  `,
  BODY_SM = `
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-transform: none;
    letter-spacing: 0.26px;
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
  `,
  BODY_XS = `
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-transform: none;
    letter-spacing: 0.26px;
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
  `,
  INPUT_XXS = `
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: none;
    letter-spacing: 0.18px;
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
  `,
  LABEL_XXS = `
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: none;
    letter-spacing: 0.24px;
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
  `,
  HELPER_XXS = `
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-transform: none;
    letter-spacing: 0.18px;
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
  `,
  HELPER_XXXS = `
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    text-transform: none;
    letter-spacing: 0.15px;
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
  `,
}
