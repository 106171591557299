import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadEquipmentByCompanyData } from '../../models/equipment';

interface EquipmentsState {
  list: LoadEquipmentByCompanyData[];
}

const initialState: EquipmentsState = {
  list: [],
};

const equipmentsSlice = createSlice({
  name: 'equipments',
  initialState,
  reducers: {
    setEquipments(state, action: PayloadAction<LoadEquipmentByCompanyData[]>) {
      state.list = action.payload;
    },
    addEquipment(state, action: PayloadAction<LoadEquipmentByCompanyData>) {
      state.list.push(action.payload);
    },
    updateEquipment(state, action: PayloadAction<LoadEquipmentByCompanyData>) {
      const index = state.list.findIndex(e => e.id === action.payload.id);
      if (index !== -1) {
        state.list[index] = action.payload;
      }
    },
  },
});

export const { setEquipments, addEquipment, updateEquipment } = equipmentsSlice.actions;

export default equipmentsSlice.reducer;
