import styled from 'styled-components';
import { Colors } from '../../../../assets/styles/StyleTypes';

export const LinksList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 100vh;
  overflow: auto;
`;

export const ListItem = styled.li`
  position: relative;
  &:hover {
    background-color: ${(props) => props.theme.colors.navbarHoverItem};
  }
  & > a {
    border: 1px solid transparent;
    color: ${(props) => props.theme.colors.textSecondary};
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 15px;
    height: 70px;
    padding-left: 30px;
    text-transform: uppercase;
  }

  & > a:hover {
    background-color: ${(props) => props.theme.colors.navbarHoverItem};
  }

  .active {
    background-color: ${(props) => props.theme.colors.navbarHoverItem};
  }

  &.mobile {
    display: none;
  }

  &#button-logout {
    margin-top: 15px;
  }

  & svg {
    font-size: 35px;

    &#external {
      font-size: 15px;
      position: absolute;
      top: calc(50% - 7.5px);
      right: 20px;
      cursor: pointer;
      color: #767676;
    }
  }

  @media screen and (max-width: 960px) {
    &.desktop {
      display: none;
    }
    &.mobile {
      display: block;
    }

    &:hover {
      background-color: ${Colors.PRIMARY_HIGHLIGHT};
    }
    & > a {
      border: 1px solid transparent;
      color: ${Colors.HIGH_PURE};
      text-decoration: none;
      display: flex;
      align-items: center;
      align-items: flex-start;
      gap: 15px;
      height: 50px;
      text-transform: uppercase;
      padding-left: 20px;
      font-size: 14px;
    }

    & > a:hover {
      background-color: ${Colors.PRIMARY_HIGHLIGHT};
    }

    .active {
      background-color: ${Colors.PRIMARY_HIGHLIGHT};
    }
  }
`;

export const Icon = styled.img`
  width: 30px;
  height: auto;
  @media screen and (max-width: 960px) {
    width: 25px;
  }
`;
