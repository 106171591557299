import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadAllEquipments } from '../../models/equipment';

interface EquipmentsMasterState {
  list: LoadAllEquipments[];
}

const initialState: EquipmentsMasterState = {
  list: [],
};

const equipmentsMasterSlice = createSlice({
  name: 'equipmentsMaster',
  initialState,
  reducers: {
    setEquipments(state, action: PayloadAction<LoadAllEquipments[]>) {
      state.list = action.payload;
    },
    addEquipment(state, action: PayloadAction<LoadAllEquipments>) {
      state.list.push(action.payload);
    },
    updateEquipment(state, action: PayloadAction<LoadAllEquipments>) {
      const index = state.list.findIndex(e => e.id === action.payload.id);
      if (index !== -1) {
        state.list[index] = action.payload;
      }
    },
  },
});

export const { setEquipments, addEquipment, updateEquipment } = equipmentsMasterSlice.actions;

export default equipmentsMasterSlice.reducer;
