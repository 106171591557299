import styled from 'styled-components';
import { Colors, Typography } from '../../../assets/styles/StyleTypes';

export const FooterBar = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.PRIMARY_PURE};
  height: 60px;
  /* justify-content: center; */
  p {
    color: white;
    font-size: 11px;
   // position: absolute;
   // bottom: 5px;
    width: 100%;
    //right: 30px;
    display: flex;
    justify-content: flex-end;
    text-align: flex-end;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 60px;
 // height: 100px;
  background-color: ${Colors.PRIMARY_PURE};
  display: grid;
  align-items: start;
  padding-top: 5px;
  grid-template-columns: repeat(4, 1fr);
  & a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${Colors.HIGH_PURE};
    text-decoration: none;
    height: 50px;
  }

  & a.active {
    background-color: ${Colors.PRIMARY_HIGHLIGHT};
    border-radius: 15px;
    span {
      display: none;
    }
  }

  & a.active:last-child {
    background-color: ${Colors.PRIMARY_HIGHLIGHT};
    border-radius: 15px 15px 15px 15px;
    span {
      display: none;
      transition: all 0.5s ease-out;
    }
  }
`;

export const Button = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Colors.HIGH_PURE};
  text-decoration: none;
  height: 55px;
  &.active {
    background-color: ${Colors.PRIMARY_HIGHLIGHT};
    border-radius: 0px 15px 15px 0px;
    span {
      display: none;
    }
  }
`;

export const ImageButton = styled.img`
  color: ${Colors.HIGH_PURE};
  height: 20px;
`;

export const LabelButton = styled.span`
  ${Typography.HELPER_XXXS}
`;
