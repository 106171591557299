import { LOCALES } from "../constants";
const en = {
  [LOCALES.EN]: {
    //LOGIN
    "login.Login": "Login",
    "login.Informe seu e-mail": "Enter your e-mail",
    "login.E-mail inválido. Por favor, verifique":
      "Invalid e-mail. Please, check",
    "login.Senha": "Password",
    "login.Sua senha deve possuir no mínimo 6 caracteres":
      "Your password must have at least 6 characters",
    "login.Permanecer conectado": "Stay connected",
    "login.Esqueci minha senha": "Forgot my password",
    "login.ENTRAR": "ENTER",
    "login.CRIAR CONTA": "CREATE ACCOUNT",
    "login.Não foi possÍvel fazer o login. Verifique seus dados e tente novamente":
      "It was not possible to login. Check your data and try again",
    "login.Solicitar Acesso": "Request access",
    "login.Utilize esta opção caso possua apenas fornos da versão antiga, que não geram PIN.":
      "Use this option in case you have only old version ovens, which do not generate PIN.",
    "login.A PLATAFORMA IOK MUDOU!": "THE IOK PLATFORM HAS CHANGED!",
    "login.Não exibir este aviso novamente": "Do not show this notice again",
    "login.Se você já possui um cadastro na antiga plataforma, ": "If you already have a registration on the former platform, ",
    "login.clique aqui": "click here",
    "login. para": " to ",
    "login.migrar": "migrate ",
    "login.seus dados e aproveitar toda a experiência IOK.": "your data and enjoy the full IOK experience.",
    "login.Sua conta não foi ativada. Por gentileza, verifique seu e-mail para realizar a ativação":
      "Your account has not been activated. Please, verify your e-mail to activate it",
    "login.Para solicitar acesso a uma organização, o nome informado por você deve ser exatamente o mesmo cadastrado pelo administrador da conta. Em caso de dúvida, procure o responsável pela rede.":
      "To request access to an organization, the name you provide must be exactly the same as registered by the account's administrator. If in doubt, search for the chain responsible.",
    "login.PLATAFORMA EM MANUTENÇÃO": "PLATFORM UNDER MAINTENANCE",
    "login.ESTAMOS PREPARANDO A NOVA PLATAFORMA IOK E EM BREVE ELA ESTARÁ PRONTA!": "WE ARE PREPARING THE NEW IOK PLATFORM AND IT WILL BE READY SOON!",
    "login.Migrar Conta": "Migrate Account",
    "login.Se você já possui um cadastro na antiga plataforma, clique aqui em Migrar Conta para migrar seus dados e aproveitar toda a experiência IOK": "If you already have a registration on the old platform, click here on Migrate Account to migrate your data and enjoy the entire IOK experience",
    "login.Política de privacidade": "Privacy Policy",
    "login.ENTENDI": "OK",
    "login.Para melhorar a sua experiência na plataforma e oferecer serviços personalizados, utilizamos cookies.": "We use cookies to improve your experience on the platform and offer personalized services.",
    "login.Ao utilizar a Plataforma IOK, você automaticamente concorda com seu uso.": "By using the IOK Platform, you automatically agree to its use.",

    // REGISTER
    "DISTRIBUIDOR": "DISTRIBUTOR:I am a distributor of Prática equipment.",
    "REDE": "CHAIN: I own a chain.",
    "DONO": "COMPANY: I own my own company/I am part of a chain.",
    "register.Informe seu e-mail cadastrado na plataforma IOK anterior": "Enter your email registered on the previous IOK platform",
    "register.E-mail inválido. Por favor, verifique": "Invalid e-mail. Please, check",
    "register.BUSCAR DADOS": "SEARCH DATA",
    "register.Nome da organização": "Organization name",
    "register.Informe o nome de sua organização": "Enter your organization's name",
    "register.O campo nome da organização é obrigatório": "The organization's name field is required",
    "register.Este nome já existe. Por favor, escolha outro": "This name already exists. Please, choose another one",
    "register.RECUPERAR CONTA": "RETRIEVE ACCOUNT",
    "register.O ADMINISTRADOR DA ORGANIZAÇÃO RECEBERÁ UM E-MAIL PARA ATIVAR SUA CONTA DE USUÁRIO. POR GENTILIZA, AGUARDE": "THE ORGANIZATION ADMINISTRATOR WILL RECEIVE AN EMAIL TO ACTIVATE YOUR USER ACCOUNT. PLEASE WAIT",
    "register.E-mail não encontrado": "E-mail not found",
    "register.Digite seu e-mail da plataforma IOK antiga": "Type your e-mail from the former IOK platform",
    "register.Não foi possível recuperar sua conta. Verifique seus dados e tente novamente": "It was not possible to retrieve your account. Verify your data and try again",
    "register.Não foi possível recuperar seus dados. Verifique e tente novamente": "It was not possible to retrieve your data. Verify and try again",
    "register.PROSSEGUIR": "CONTINUE",
    "register.VOCÊ ESTÁ SOLICITANDO ACESSO PARA A ORGANIZAÇÃO:": "YOU ARE REQUESTING ACCESS TO THE ORGANIZATION: ",
    "register.COM O ADMINISTRADOR:": " WITH ADMINISTRATOR: ",
    "register.E-mail já cadastrado na plataforma. Informe um novo e-mail ou acesse a plataforma com o e-mail já cadastrado": "E-mail already registered on the platform. Enter a new e-mail or access the platform with the one already registered",
    "register.Empresa já cadastrada na plataforma. Por favor, informe o nome de uma nova empresa": "Company already registered on the platform. Please, enter a new company's name",
    "register.VOCÊ NÃO RECONHECEU O USUÁRIO {userName} COMO PARTE DA SUA ORGANIZAÇÃO E POR ISSO A CONTA DELE FOI DELETADA.": "YOU DID NOT RECOGNIZE THE USER {userName} AS PART OF YOUR ORGANIZATION AND BECAUSE OF THAT THEIR ACCOUNT WERE DELETED.",
    "register.NÂO FOI POSSÍVEL DELETAR O USÁRIO {userName}. POR GENTILEZA, TENTE NOVAMENTE MAIS TARDE.": "IT WAS NOT POSSIBLE TO DELETE USER {userName}. PLEASE, TRY AGAIN LATER.",
    "register.CONFIRMAR": "CONFIRM",
    "register.Não foi possível fazer o cadastro. Verifique seus dados e tente novamente":
      "It was not possible to register. Verify your data and try again",
    "register.VOCÊ RECEBERÁ UM E-MAIL PARA CONCLUIR SEU CADASTRO E ATIVAR SUA CONTA DE ADMINISTRADOR":
      "YOU WILL RECEIVE AN E-MAIL TO FINISH YOUR REGISTRATION AND ACTIVATE YOUR ADMINISTRATOR ACCOUNT",
    "register.Nome": "Name",
    "register.Informe seu nome": "Enter your name",
    "register.O campo nome é obrigatório": "The name field is required",
    "register.E-mail": "E-mail",
    "register.Informe seu e-mail": "Enter your e-mail",
    "register.E-mail inválido. Por favor, verifique novamente":
      "Invalid e-mail. Please, check",
    "register.Telefone": "Phone number",
    "register.Informe seu telefone": "Enter your phone number",
    "register.Crie sua senha": "Create a password",
    "register.Informe sua senha": "Inform your password",
    "register.O campo senha deve conter no mínimo 6 caracteres":
      "The password field must have at least 6 characters",
    "register.Confirme sua senha": "Confirm password",
    "register.As senhas devem ser iguais": "The password must match",
    "register.CRIAR CONTA": "CREATE ACCOUNT",
    "register.CONTA ATIVADA COM SUCESSO": "ACCOUNT ACTIVATED SUCCESSFULLY",
    "register.ACESSAR": "ACCESS",
    "register.NÃO FOI POSSÍVEL ATIVAR SUA CONTA":
      "IT WAS NOT POSSIBLE TO ACTIVATE YOUR ACCOUNT",
    "register.Digite seu telefone": "Inform your phone number",
    "register.AGUARDE ENQUANTO TRANSFERIMOS SEUS DADOS. ESTE PROCESSO DEVE LEVAR ALGUNS MINUTOS. CASO VOCÊ POSSUA UMA GRANDE QUANTIDADE DE DADOS, A TRANSFERÊNCIA PODE LEVAR ATÉ UMA HORA.": "PLEASE WAIT WHILE WE TRANSFER YOUR DATA. THIS PROCESS SHOULD TAKE A FEW MINUTES. IF YOU HAVE A LARGE AMOUNT OF DATA, THE TRANSFER MAY TAKE UP TO ONE HOUR.",
    "register.DADOS TRANSFERIDOS COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "DATA TRANSFERED SUCCESSFULLY! CLICK THE BUTTON BELLOW TO ACCESS THE PLATFORM.",
    "register.EMAIL INVÁLIDO. POR GENTILEZA, VERIFIQUE SE SUA CONTA JÁ FOI ATIVADA.": "INVALID E-MAIL. PLEASE, CHECK IF YOUR ACCOUNT HAS ALREADY BEED ACTIVATED.",
    "register.Ainda não tenho uma organização. Criar": "I do not have an organization yet. Create",
    "register.Informe o ID da organização": "Inform your organization's ID",
    "register.O campo com ID é obrigatório": "The ID field is required",
    "register.O ADMINISTRADOR DA ORGANIZAÇÃO RECEBERÁ UM E-MAIL PARA ATIVAR SUA CONTA. POR GENTILIZA, AGUARDE": "THE ORGANIZATION ADMINISTRATOR WILL RECEIVE AN EMAIL TO ACTIVATE YOUR ACCOUNT. PLEASE WAIT",
    "register.ID informado não corresponde a nenhuma companhia. Verifique e tente novamente": "The informed ID does not match any company. Verify and try again",
    "register.A nova plataforma IOK agora conta com novas formas de acesso - que são referentes ao tipo de organização em que você atua. O responsável pela sua organização deve migrar a conta principal da empresa, se tornando o administrador, enquanto o restante dos funcionários devem se vincular a ela pelo ID fornecido.": "The new IOK platform now has new forms of access - which are related to the type of organization in which you work. The person responsible for your organization must migrate the company's main account, becoming the administrator, while the rest of the employees must link to it using the ID provided.",
    "register.O administrador da minha organização já realizou a migração da conta e temos um ID. Desejo migrar meus dados de usuário.": "My organization's administrator has already migrated the account and we have an ID. I want to migrate my user data.",
    "register.Desejo migrar a conta da minha organização e me tornar o administrador.": "I want to migrate my organization's account and become the administrator.",
    "register.Conta da organização já migrada": "Organization account already migrated",
    "register.Migrar conta da organização": "Migrate organization account",
    "register.VOCÊ POSSUI MUITOS MENUS, NÃO CONSEGUIMOS REALIZAR A IMPORTAÇÃO AUTOMÁTICA. SUA CONTA SERÁ ATIVADA, PORÉM OS MENUS PRECISARÃO SER MIGRADOS MANUALMENTE.": "YOU HAVE MANY MENUS, WE CANNOT PERFORM AUTOMATIC IMPORTATION. YOUR ACCOUNT WILL BE ACTIVATED, BUT MENUS WILL NEED TO BE MIGRATED MANUALLY.",
    "register.PARA MIGRAR SEUS MENUS, APÓS O LOGIN, CLIQUE NO SEU NOME NO CANTO SUPERIOR DIREITO, CLIQUE EM EDITAR CONTA E DEPOIS EM IMPORTAR MENUS.": "TO MIGRATE YOUR MENUS, AFTER LOGIN, CLICK ON YOUR NAME IN THE TOP RIGHT CORNER, CLICK ON EDIT ACCOUNT AND THEN ON IMPORT MENUS.",
    "register.Atenção:": "Attention:",
    "register.apenas uma pessoa da organização pode criar a conta e, depois de ativá-la, convidar os demais usuários da empresa para acessá-la.": "only one person from the organization can create the account and, after activating it, invite other users from the company to access it.",
    "register.TOKEN DESENVOLVEDOR CANCELADO": "DEVELOPER TOKEN CANCELED",

    //HOME
    "home.Menus disponíveis na plataforma": "Menus available on the platform",
    "home.Criados na plataforma": "Created on the platform",
    "home.Recebidos dos fornos": "Received from the ovens",
    "home.Equipamentos vinculados": "Linked equipment",
    "home.Total de receitas cadastradas": "Total registered recipes",
    "home.Receitas": "Recipes",
    "home.Receitas em menus": "Recipes in menus",
    "home.Em menus": "In menus",
    "home.Receitas salvas no Cookbook": "Recipes saved in the Cookbook",
    "home.No Cookbook": "In the Cookbook",
    "home.Atualizações": "Updates",
    "home.Copa": "Copa",
    "home.Rocket": "Rocket",
    "home.Single Mag": "Single Mag",
    "home.Versão instalada": "Installed version",
    "home.Versão disponível": "Available version",
    "home.Usuários cadastrados": "Registered users",
    "home.Aceitação pendente": "Pending acceptance",
    "home.Atualizado": "Updated",
    "home.OLÁ": "HELLO",
    "home.É muito bom ter você aqui!": "It's great to have you here!",
    "home.Com a Plataforma IOK é possível administrar seus fornos de onde estiver, criar e gerenciar receitas e menus, fazer backups, analisar os dados de seus equipamentos e muito mais!": "With the IOK Platform you can manage your ovens from wherever you are, create and manage recipes and menus, make backups, analyze data from your equipment and much more!",
    "home.Conheça a plataforma": "Discover the platform",
    "home.Taxa de erro por modelo": "Error rate per model",
    "home.Erro": "Error",
    "home.Modelos em evidência": "Featured models",
    "home.Modelos": "Models",
    "home.Total de equipamentos vinculados": "Total linked equipment",
    "home.Total de equipamentos online": "Total online equipment",
    "home.Equipamentos online": "Online Equipment",
    "home.Países com Prática": "Countries with Prática",
    "home.Países": "Countries",
    "home.Aguarde enquanto carregamos os dados": "Please wait while we load the data",
    "home.Quase lá! Aguarde um pouco mais": "Almost there! Please wait a little longer",
    "home.QUEREMOS SABER SUA OPINIÃO SOBRE": "WE WANT TO KNOW YOUR OPINION ABOUT",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a Plataforma {model} foi criado!": "We greatly value your point of view and, therefore, this satisfaction survey form on the {model} Platform was created!",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a linha {model} foi criado!": "We greatly value your point of view and, therefore, this satisfaction survey form about the {model} line was created!",
    "home.Suas respostas nos ajudarão a aprimorar nossos serviços, garantindo que continuemos a oferecer a você a melhor experiência possível.": "Your responses will help us improve our services, ensuring we continue to provide you with the best experience possible.",
    "home.Reserve uns minutinhos de seu dia e participe!": "Take a few minutes out of your day and participate!",
    "home.Responder a pesquisa": "Answer the survey",
    "home.Vendas por Equipamento": "Sales by Equipment",
    "home.Total de equipamentos vendidos": "Total Equipment Sold",
    "home.Equipamentos vendidos": "Equipment Sold",

    //MAIN
    "main.HOME": "HOME",
    "main.COOKBOOK": "COOKBOOK",
    "main.MEUS MENUS": "MY MENUS",
    "main.EQUIPAMENTOS": "EQUIPMENT",
    "main.USUÁRIOS": "USERS",
    "main.ATENDIMENTOS": "SERVICES",
    "main.Idioma": "Language",
    "main.Editar conta": "Edit account",
    "main.Configurações": "Settings",
    "main.Configurações salvas com sucesso!": "Settings saved successfully!",
    "main.SALVAR": "SAVE",
    "main.CONFIGURAÇÕES": "SETTINGS",
    "main.Tema dark": "Dark theme",
    "main.Notificações": "Notifications",
    "main.Informações para as receitas": "Recipe information",
    "main.Peso:": "Weight:",
    "main.Gramas": "Grams",
    "main.Oz": "Oz",
    "main.Temperatura:": "Temperature:",
    "main.Fahrenheit (°F)": "Fahrenheit (°F)",
    "main.Conta editada com sucesso!": "Account edited successfully!",
    "main.EDITAR CONTA": "EDIT ACCOUNT",
    "main.Nome": "Name",
    "main.Preencha com o seu nome": "Type your name",
    "main.Telefone": "Phone number",
    "main.Preencha com o seu telefone": "Type your phone number",
    "main.E-mail": "E-mail",
    "main.Preencha com o seu e-mail": "Type your e-mail",
    "main.Alterar Senha": "Change Password",
    "main.ALTERAR SENHA": "CHANGE PASSWORD",
    "main.Senha atual": "Current password",
    "main.Nova senha": "New password",
    "main.Confirmar nova senha": "Confirm new password",
    "main.Informe sua senha atual": "Inform your current password",
    "main.O campo senha deve conter no mínimo 6 caracteres":
      "The password field must have at least 6 characters",
    "main.Informe sua nova senha": "Inform your new password",
    "main.Confirme sua nova senha": "Confirm your new password",
    "main.Sua nova senha deve conter no mínimo 6 caracteres":
      "Your new password must contain at least 6 characters",
    "main.INFORME SUA SENHA": "INFORM YOUR PASSWORD",
    "main.Informe sua senha para realizar a alteração de e-mail da plataforma:":
      "Enter your password to change your platform email:",
    "main.CONFIRMAR": "CONFIRM",
    "main.Existem campos que não foram preenchidos corretamente":
      "There are fields that were not filled out correctly",
    "main.Informe sua senha para prosseguir com a alteração de e-mail":
      "Enter your password to proceed with changing your e-mail",
    "main.Senha alterada com sucesso": "Password changed successfully",
    "main.Informe sua senha atual corretamente": "Enter your current password correctly",
    "main.As senhas devem ser iguais": "The password must match",
    "main.Nome do novo tipo de usuário": "New user type name",
    "main.Dê um nome para o novo tipo de usuário": "Give a name to the new user type",
    "main.Token Desenvolvedor": "Developer Token",
    "main.O Token Desenvolvedor é um código único e intransferível que permite a realização de alterações": "The Developer Token is a unique and non-transferable code that allows changes at the development level directly in the platform's code through external APIs. They enable customization of the platform to better meet your company's needs.",
    "main.É recomendado que estas alterações sejam realizadas por um profissional da área de tecnologia": "It is recommended that these changes be made by a professional in the technology field.",
    "main.Para acessar o token, informe sua senha": "To access the token, please provide your password.",
    "main.Copie e cole o token na autenticação de todas as requisições.": "Copy and paste the token into the authentication of all requests.",
    "main.Seu Token": "Your token",
    "main.Token copiado com sucesso!": "Token copied successfully!",
    "main.Você deseja cancelar o preenchimento das informações?": "Do you want to cancel filling out the information?",
    "main.Rever tutoriais": "Review tutorials",
    "main.Tutoriais resetados com sucesso": "Tutorials successfully reset",
    "main.review.tutorials": "Do you want to review the tutorials on the IOK platform?",
    "main.Procurar": "Search",

    //MENUS
    "menus.Procurar menu": "Search menu or user",
    "menus.Procurar menu excluído": "Search deleted menu",
    "menus.Menus": "Menus",
    "menus.Lixeira": "Trash",
    "menus.CRIAR MENU": "CREATE MENU",
    "menus.IMPORTAR MENU": "IMPORT MENU",
    "menus.Os arquivos serão mantidos na lixeira por 30 dias a contar de sua data de exclusão. Após este período, eles serão excluídos definitivamente.":
      "The files will be kept in trash for 30 days from the date of deletion. After this period, they will be deleted definitely.",
    "menus.VOLTAR": "return",
    "menus.Compatível com: ": "Compatible with: ",
    "menus.Última atualização: ": "Last update: ",
    "menus.Última atualização": "Last update",
    "menus.EXPORTAR MENU": "EXPORT MENU",
    "menus.EDITAR MENU": "EDIT MENU",
    "menus.Equipamentos associados": "Associated equipment",
    "menus.Grupos": "Groups",
    "menus.Procurar grupo": "Search group",
    "menus.CRIAR GRUPO": "CREATE GROUP",
    "menus.receitas": "recipes",
    "menus.Nenhum grupo cadastrado": "No groups registered",
    "menus.Nenhum menu cadastrado": "No menus registered",
    "menus.Nome": "Name",
    "menus.Modelo": "Model",
    "menus.Atividade": "Activity",
    "menus.Associação": "Association",
    "menus.Exportar": "Export",
    "menus.Editar": "Edit",
    "menus.Excluir": "Delete",
    "menus.Equips": "Equip",
    "menus.Receitas": "Recipes",
    "menus.Compatibilidade": "Compatibility",
    "menus.Versão": "Version",
    "menus.Origem": "Origin",
    "menus.Excluído por: ": "Deleted by: ",
    "menus.Restaurar": "Restore",
    "menus.Existem campos que não foram preenchidos":
      "There are fields that have not been filled",
    "menus.Menu criado com sucesso!": "Menu created successfully!",
    "menus.Existem campos com informações inválidas":
      "There are fields with invalid information",
    "menus.SALVAR": "SAVE",
    "menus.CANCELAR": "CANCEL",
    "menus.Nome do menu:": "Menu's name: ",
    "menus.Informe o nome do menu": "Inform menu's name",
    "menus.Preencha o nome do menu sem caracteres especiais": "Fill the menu's name with no special characters",
    "menus.Selecionar": "Select",
    "menus.Idioma": "Language",
    "menus.Temperatura de pré-aquec 1:": "Pre heating temperature 1:",
    "menus.Informe a temperatura de pré-aquec 1":
      "Inform pre heating temperature 1",
    "menus.Temperatura de pré-aquec 2:": "Pre heating temperature 2:",
    "menus.Informe a temperatura de pré-aquec 2":
      "Inform pre heating temperature 2",
    "menus.Tempo para estabilização:": "Stabilization time:",
    "menus.Informe o tempo para estabilização":
      "Inform stabilization time",
    "menus.Desativar pré-aquec 2": "Deactivate pre heating 2",
    "menus.Configurações de data e hora": "Date and time settings",
    "menus.Data:": "Date:",
    "menus.DD/MM/AAAA": "DD/MM/YYYY",
    "menus.Hora:": "Times:",
    "menus.24h": "24h",
    "menus.AM/PM": "AM/PM",
    "menus.EUR: DD/MM/AAAA": "EUR: DD/MM/YYYY",
    "menus.USA: MM/DD/AAAA": "USA: MM/DD/YYYY",
    "menus.ISO: AAAA/MM/DD": "ISO: YYYY/MM/DD",
    "menus.Seleção de acessos para habilitar":
      "Selection of access to enable",
    "menus.Modo manual": "Manual mode",
    "menus.Habilitado": "Able",
    "menus.Desabilitado": "Disabled",
    "menus.Favoritos": "Favorites",
    "menus.Aquecer/Dourar": "Heat/Brown",
    "menus.Repetir receita": "Repeat recipe",
    "menus.Configurações gerais do menu": "Menu's general settings",
    "menus.Temperatura:": "Temperature:",
    "menus.Fahrenheit (°F)": "Fahrenheit (°F)",
    "menus.Gramas": "Grams",
    "menus.Oz": "Oz",
    "menus.Tema:": "Theme:",
    "menus.Dark": "Dark",
    "menus.Light": "Light",
    "menus.Introdução:": "Introduction:",
    "menus.Ativada": "Activated",
    "menus.Desativada": "Deactivated",
    "menus.Menu deletado com sucesso!": "Menu deleted successfully!",
    "menus.Não foi possivel deletar o menu": "Unable to delete menu",
    "menus.Sim": "Yes",
    "menus.Não": "No",
    "menus.Excluir definitivamente": "Permanently delete",
    "menus.SALVAR EM PEN DRIVE": "SAVE TO FLASH DRIVE",
    "menus.EXPORTAR": "EXPORT",
    "menus.Selecione os modelos e versões dos equipamentos que receberão o menu.":
      "Select the equipment models and versions to receive the menu.",
    "menus.Forno": "Oven",
    "menus.Fundo verde": "Green background",
    "menus.Fundo preto": "Black background",
    "menus.Linux": "Linux",
    "menus.Atenção: antes de transferir o menu de seu pen drive para o equipamento, é necessário descompactar o arquivo, exceto para menus de equipamentos Linux.":
      "Attention: before transfering the menu from your  flash drive to the equipment, it is necessary to unzip the file, except for Linux equipment's menus.",
    "menus.Selecione os equipamentos para os quais deseja exportar o menu.":
      "Select the equipment for which you want to export the menu.",
    "menus.Nome do equipamento": "Equipment's name",
    "menus.Selecionar todos": "Select all",
    "menus.Selecionar todas": "Select all",
    "menus.Categoria: ": "Category: ",
    "menus.Você deseja excluir o menu?": "Do you wish to delete the menu?",
    "menus.O menu será deletado da plataforma, mas permanecerá no equipamento. Uma cópia de segurança será criada automaticamente e ficará disponível por 30 dias em Meus Menus - Lixeira.":
      "The menu will be deleted from the platform, but will be kept in the equipment. A safety copy will be created automatically and will be available for 30 days in My Menus - Trash.",
    "menus.Você deseja excluir o arquivo definitivamente?":
      "Do you wish to delete the file permanently?",
    "menus.Esta ação não poderá ser desfeita.":
      "This action cannot be undone.",
    "menus.Menu editado com sucesso!": "Menu edited successfully!",
    "menus.IMPORTAR": "IMPORT",
    "menus.VERIFICAÇÃO DE VERSÃO": "VERSION VERIFICATION",
    "menus.Para conferir a versão da placa de seu equipamento, siga os seguintes passos:":
      "To check the equipment's board version, follow the following steps:",
    "menus.No forno, acesse Configurações;": "In the oven, access Settings;",
    "menus.Insira a senha 456789;": "Enter password 456789;",
    "menus.Selecione a opção Sistema;": "Select option System;",
    "menus.Selecione Informações do Fabricante;":
      "Select Manufacturer Information;",
    "menus.O modelo de sua placa estará na linha Firmware IHM;":
      "Your board's model will be in line Firmware IHM;",
    "menus.1ª geração A": "1st generation A",
    "menus.Firmware IHM: 12.3.4": "Firmware IHM: 12.3.4",
    "menus.1ª geração B": "1st generation B",
    "menus.2ª geração/Linux:": "2nd generation/Linux:",
    "menus.Firmware IHM: L1.2.3": "Firmware IHM: L1.2.3",
    "menus.Selecione o modelo/versão do equipamento do qual deseja importar o menu. Saiba como conferir a versão de seu equipamento":
      "Select the equipment's model/version from which you wish to import the menu. Know how to check your equipment's version ",
    "menus.Selecione o modelo/versão do equipamento": "Select the equipment model/version",
    "menus.Como verificar a versão da placa":
      "How to check the board's version",
    "menus.1° Geração": "1st generation",
    "menus.Menu restaurado com sucesso!": "Menu restored successfully!",
    "menus.Não foi possivel restaurar o menu":
      "Unable to restore menu",
    "menus.RESTAURAR ARQUIVO": "RESTORE FILE",
    "menus.Você deseja restaurar o arquivo?":
      "Do you wish to restore file?",
    "menus.O arquivo será restaurado como uma nova versão na plataforma, na guia Todos. Para salvá-lo no equipamento, exporte-o.":
      "The file will be restored as a new version on the platform under the All tab. To save it on your device, export it.",
    "menus.EXCLUIR MENU": "DELETE MENU",
    "menus.Nenhum equipamento associado": "No associated equipment",
    "menus.Nomeie o novo menu": "Name the new menu",
    "menus.Nomeie o novo grupo": "Name the new group",
    "menus.Nome do grupo:": "Group's name: ",
    "menus.Preencha o nome do grupo sem caracteres especiais":
      "Fill the group's name with no special characters",
    "menus.Pré-aquecimento:": "Pre heating:",
    "menus.Grupo criado com sucesso!": "Group created successfully!",
    "menus.SELECIONE UMA IMAGEM": "SELECT AN IMAGE",
    "menus.SELECIONAR": "SELECT",
    "menus.Selecionar do computador": "Select from computer",
    "menus.Selecionar do banco de imagens": "Select from image bank",
    "menus.Formato .JPG ou .PNG": ".JPG or .PNG format",
    "menus.Procurar imagem": "Search image",
    "menus.Pertence ao menu": "Belongs to menu",
    "menus.Alterar imagem": "Change image",
    "menus.Remover imagem": "Remove image",
    "menus.EDITAR GRUPO": "EDIT GROUP",
    "menus.Nome:": "Name:",
    "menus.CRIAR RECEITA": "CREATE RECIPE",
    "menus.SALVAR DO COOKBOOK": "SAVE FROM COOKBOOK",
    "menus.Procurar receita do grupo": "Search group's recipe",
    "menus.Ingrediente principal": "Main ingredient",
    "menus.Mover receitas": "Move recipes",
    "menus.Excluir receitas": "Delete recipes",
    "menus.Editar grupo": "Edit group",
    "menus.Excluir grupo": "Delete group",
    "menus.aqui": "here",
    "menus.Se o código possuir apenas números e o fundo da tela for verde, pertence à 1ª A, se possuir apenas números e o fundo da tela for preta, pertence à 1ª B e, se começar com as letras L, F ou S, sua placa pertence à 2ª geração/Linux.":
      "If the code has only numbers and the background is green, it belong to 1st A, if it has only numbers and the background is black, it belongs to 1st B and, if it starts with letters L, F or S, its board belongs to 2nd generation/Linux.",
    "menus.1ª A - Fundo verde": "1st A - Green background",
    "menus.1ª B - Fundo preto": "1st B - Black background",
    "menus.2ª - Linux": "2nd - Linux",
    "menus.Tivemos um problema. Tente novamente mais tarde.":
      "We have had a problem. Try again later.",
    "menus.Grupo editado com sucesso!": "Group edited successfully!",
    "menus.AINDA NÃO HÁ GRUPOS AQUI :(": "THERE ARE STILL NO GROUPS HERE :(",
    "menus.NENHUM MENU NA LIXEIRA": "NO MENU IN TRASH",
    "menus.AINDA NÃO HÁ MENUS AQUI :(": "THERE ARE STILL NO MENUS HERE :(",
    "menus.Adicionar imagem": "Add image",
    "menus.Nome da receita": "Recipe's name",
    "menus.Detalhes da receita": "Recipe's details",
    "menus.Programação da receita": "Recipe's programming",
    "menus.Nomeie sua receita": "Name your recipe",
    "menus.Preencha o nome da receita sem caracteres especiais": "Fill the recipe's name with no special characters",
    "menus.Tipo de prato": "Type of meal",
    "menus.SALVAR RECEITAS DO COOKBOOK": "SAVE RECIPES FROM COOKBOOK",
    "menus.Selecione as receitas que deseja salvar no grupo de seu menu:":
      "Select the recipes you wish to save in your menu group:",
    "menus.Procurar receita": "Search recipe",
    "menus.Grupo deletado com sucesso!": "Group deleted successfully!",
    "menus.Não foi possivel deletar o grupo":
      "Unable to delete group",
    "menus.EXCLUIR GRUPO": "DELETE GROUP",
    "menus.Você deseja excluir o grupo definitivamente?":
      "Do you wish to dele the group definitely?",
    "menus.AINDA NÃO HÁ RECEITAS AQUI :(": "THERE ARE STILL NO RECIPES HERE :(",
    "menus.Temp. de entrada": "Starting temp",
    "menus.Temp. de pré-aquec": "Pre heating temperature",
    "menus.Ingredientes para montagem": "Ingredients for assembly",
    "menus.Instruções operacionais": "Operating instructions",
    "menus.Passo": "Step",
    "menus.Temp": "Temp ",
    "menus.Temp.": "Temp. ",
    "menus.Tempo": "Time",
    "menus.Velocidade do ar": "Air speed",
    "menus.Micro-ondas": "Microwave",
    "menus.Resistência inferior": "Lower resistance",
    "menus.Informações": "Information",
    "menus.Digite as informações do passo": "Type the step information",
    "menus.desativar": "deactivate",
    "menus.Ingredientes": "Ingredients",
    "menus.Temperatura de entrada": "Starting temperature",
    "menus.Equipamentos compatíveis": "Compatible equipment",
    "menus.SALVAR NO COOKBOOK": "SAVE TO COOKBOOK",
    "menus.ver localização": "see location",
    "menus.SELECIONE RECEITAS PARA EXCLUIR": "SELECT RECIPES TO DELETE",
    "menus.Você deseja excluir as receitas selecionadas definitivamente?":
      "Do you wish to delete the selected recipes definitely?",
    "menus.Receitas deletadas com sucesso!": "Recipes deleted successfully!",
    "menus.Informe aqui os ingredientes para montagem":
      "Inform here the ingredientes for assembly",
    "menus.Informe aqui as instruções operacionais":
      "Inform here the operating instructions",
    "menus.Adicionar passo": "Add step",
    "menus.Receita criada com sucesso!": "Recipe created successfully!",
    "menus.Ideal para:": "Ideal for: ",
    "menus.editar detalhes": "edit details",
    "menus.excluir": "delete",
    "menus.editar": "edit",
    "menus.Receita editada com sucesso!": "Recipe edited successfully!",
    "menus.Passo deletado com sucesso!": "Step deleted sucessfully!",
    "menus.Não foi possivel deletar o passo":
      "Unable to delete step",
    "menus.EXCLUIR PASSO": "DELETE STEP",
    "menus.Você deseja excluir este passo?": "Do you wish to delete this step?",
    "menus.Esta ação não poderá ser desfeita. Caso prefira, é possível apenas desativá-lo, mantendo assim sua configuração.":
      "This action cannot be undone. If you prefer, it is possible to only deactivate it, keeping its settings. ",
    "menus.Peso": "Weight",
    "menus.Origem da receita": "Recipe's origin",
    "menus.Pré-aquecimento": "Pre heating",
    "menus.Plataforma": "Platform",
    "menus.Receita salva com sucesso!": "Recipe saved successfully!",
    "menus.Exportar receitas": "Export recipes",
    "menus.expandir": "expand",
    "menus.Temperatura": "Temperature",
    "menus.Vapor combinado": "Combined steam",
    "menus.Ar quente": "Hot air",
    "menus.Sonda": "Probe",
    "menus.Tempo (min)": "Time (min)",
    "menus.Umidade (%)": "Humidity (%)",
    "menus.Injeção de vapor": "Steam injection",
    "menus.Dumper aberto": "Open dumper",
    "menus.Informe o peso": "Inform the weight",
    "menus.Informe a temperatura de entrada":
      "Inform the starting temperature",
    "menus.Informe os ingredientes para montagem":
      "Enter the ingredients for assembly",
    "menus.Informe as instruções operacionais":
      "Enter the operating instructions",
    "menus.Injeção de vapor (seg)": "Steam injection (sec)",
    "menus.Aquecer mais": "Heat more",
    "menus.Dourar mais": "Brown more",
    "menus.Aquecer e dourar mais": "Heat and brown more",
    "menus.Favoritar receita": "Favorite recipe",
    "menus.Informe o tempo para aquecer mais de 0 a 59 segundos":
      "Enter the time to heat more from 0 to 59 seconds",
    "menus.Informe o tempo para dourar mais de 0 a 59 segundos":
      "Enter the time to brown more from 0 to 59 seconds",
    "menus.Informe o tempo para aquecer e dourar mais de 0 a 59 segundos":
      "Enter the time to heat and brown more from 0 to 59 seconds",
    "menus.Apenas favoritos": "Only favorites",
    "menus.Forno combinado": "Combined oven",
    "menus.Cocção múltipla": "Multiple cooking",
    "menus.Receitas Technicook": "Technicook Recipes",
    "menus.Editar grupos": "Edit groups",
    "menus.Informe a data": "Enter date",
    "menus.Informe a hora": "Enter time",
    "menus.até 300": "up to 300",
    "menus.Vapor": "Vapor",
    "menus.Turbina": "Turbine",
    "menus.Criar grupo": "Create group",
    "menus.MOVER": "MOVE",
    "menus.Configurações de data e hora:": "Date and time settings:",
    "menus.Configurações de acessos:": "Access settings:",
    "menus.SELECIONE RECEITAS PARA MOVER": "SELECT RECIPES TO MOVE",
    "menus.AVANÇAR": "ADVANCE",
    "menus.Selecione ou crie um grupo no mesmo menu para mover as receitas selecionadas ou as mova para outro menu. Note que apenas os menus/grupos compatíveis com as predefinições das receitas serão exibidos.":
      "Select or create a group in the same menu to move selected recipes or move them to another menu. Note that only the menus/groups that are compatible with the recipe's presets will be displayed.",
    "menus.Mover receitas para o mesmo menu": "Move recipes to the same menu",
    "menus.Mover receitas para outro menu": "Move recipes to another menu",
    "menus.Selecionar grupo": "Select group",
    "menus.Nenhuma receita foi selecionada": "No recipe was selected",
    "menus.Passo editado com sucesso!": "Step edited successfully!",
    "menus.Menu atualizado": "Menu updated",
    "menus.até 260": "up to 260",
    "menus.Umidade na câmara": "Chamber humidity",
    "menus.Passos": "Steps",
    "menus.ativar": "activate",
    "menus.Informe a temperatura de pré-aquec": "Enter the pre heating temperature",
    "menus.Movas as receitas selecionadas para outro menu. Note que apenas os menus compatíveis com as predefinições das receitas serão exibidos.":
      "Move selected recipes to another menu. Note that only the menus that are compatible with the recipe's presets will be displayed.",
    "menus.Sonda (°C)": "Probe (ºC)",
    "menus.Turbo Grill": "Turbo Grill",
    "menus.Pré-aquecimento padrão, conforme passo 1": "Standard pre heating, as per step 1",
    "menus.salvar": "save",
    "menus.até": "up to",
    "menus.Verificação de receita": "Recipe's check",
    "menus.Auto import": "Auto import",
    "menus.Atualização forçada": "Forced update",
    "menus.Lista de receitas exportada com sucesso!": "Recipes list exported successfully!",
    "menus.Receita movida com sucesso!": "Recipe moved successfully!",
    "menus.Receitas movidas com sucesso!": "Recipes moved successfully!",
    "menus.+ Menu": "+ Menu",
    "menus.+ Receita": "+ Recipe",
    "menus.Escolha um idioma compatível com o equipamento": "Select a language compatible with the equipment",
    "menus.Dourar": "Brown",
    "menus.Não atribuir a menu": "Do not assign to menu",
    "menus.Todos": "All",
    "menus.Meus menus": "My menus",
    "menus.Para salvar uma receita, preencha todos os campos": "To save a recipe, fill in all fields",
    "menus.Selecione os grupos de equipamentos para os quais deseja exportar o menu.": "Select the equipment groups for which you want to export the menu.",
    "menus.MENU IMPORTADO COM SUCESSO!": "MENU IMPORTED SUCCESSFULLY!",
    "menus.Formato inválido. São permitidos apenas os formatos .jpeg e .png": "Invalid format. Only formats .jpeg and .png are allowed.",
    "menus.Reordenar grupos": "Reorder groups",
    "menus.Reordenar receitas": "Reorder recipes",
    "menus.ITENS REORDENADOS COM SUCESSO! PARA APLICAR AS ALTERAÇÕES NO FORNO, EXPORTE O MENU.": "ITEMS REORDERED SUCCESSFULLY! TO APPLY CHANGES TO THE OVEN, EXPORT THE MENU.",
    "menus.ARRASTE OS ITENS PARA O LOCAL DESEJADO": "Drag the items to the desired location.",
    "menus.Dumper": "Dumper",
    "menus.Aberto": "Open",
    "menus.Fechado": "Closed",
    "menus.Intermitente": "Intermittent",
    "menus.Se o seu menu incluir itens sensíveis à uniformidade, recomendamos definir o Tempo de Estabilização entre 3:00 e 5:00 minutos. Caso contrário, você pode deixá-lo em 0:00 minutos.": "If your menu includes items that are sensitive to uniformity, we recommend setting the Stabilization Time between 3:00 and 5:00 minutes. Otherwise, you may leave it at 0:00 minutes.",
    "menus.Selecione o menu que deseja exportar:": "Select the menu you want to export:",
    "menus.Temperatura de pré-aquecimento:": "Preheating temperature:",
    "menus.Temperatura de lastro": "Ballast temperature",
    "menus.Temperatura de teto": "Ceiling temperature",
    "menus.Volume de injeção de vapor (ml)": "Steam injection volume (ml)",
    "menus.Parada de turbina": "Turbine stop",
    "menus.Alta": "High",
    "menus.Média": "Medium",
    "menus.Baixa": "Low",
    "menus.a": "the",
    "menus.Valor da injeção": "Injection value",
    "menus.Volume: até 999ml": "Volume: up to 999ml",
    "menus.Tempo: até 15 segundos": "Time: up to 15 seconds",
    "menus.Tempo de injeção de vapor (seg)": "Steam injection time (sec)",
    "menus.Atenção!": "Attention!",
    "menus.Verifique as receitas do menu e ajuste os valores da Injeção de Vapor após alterar sua unidade de medida.": "Check menu recipes and adjust Steam Injection values ​​after changing your unit of measurement.",
    "menus.Certifique-se de que a unidade de temperatura (°C ou °F) definida no equipamento seja a mesma que foi selecionada na criação do menu:": "Make sure that the temperature unit (°C or °F) defined on the equipment is the same as that selected when creating the menu:",
    "menus.Injeção desativada": "Injection disabled",
    "menus.Selecione a unidade de temperatura que está definida no equipamento:": "Select the temperature unit that is defined on the equipment:",
    "menus.Clique para atualizar": "Click to update",
    "menus.A função Atualização Forçada envia automaticamente o menu para os equipamentos selecionados na próxima vez em que forem ligados.": "The Forced Update function automatically sends the menu to the selected equipment the next time it is turned on.",
    "menus.As opções selecionadas estarão disponíveis nos equipamentos que receberem este menu, podendo ser utilizadas pelo operador. É possível alterar estas configurações posteriormente no próprio forno ou pela plataforma.": "The selected options will be available on equipment that receives this menu and can be used by the operator. It is possible to change these settings later in the oven itself or via the platform.",
    "menus.Não foi possivel deletar as receitas": "Unable to delete recipes",
    "menus.PRÉ": "PRE",
    "menus.Speed Ovens": "Speed Ovens",
    "menus.Fornos Combinados": "Combi Ovens",
    "menus.Fornos de Panificação": "Baking Ovens",
    "menus.Após a criação, o tipo do menu não pode ser alterado. Ele só pode ser definido no momento da criação.": "After creation, the menu type cannot be changed. It can only be set at the time of creation.",
    "menus.Pré-aquecimento do grupo": "Group Preheating",

    //COOKBOOK
    "cookbook.RECEITAS DISPONÍVEIS": "AVAILABLE RECIPES",
    "cookbook.RECEITAS SALVAS": "SAVED RECIPES",
    "cookbook.ENVIAR PARA MENU": "SEND TO MENU",
    "cookbook.SALVAR": "SAVE",
    "cookbook.salvar": "save",
    "cookbook.Filtrar por": "Filter by",
    "cookbook.Procurar receita do cookbook": "Search recipe from cookbook",
    "cookbook.Equipamento": "Equipment",
    "cookbook.Ingrediente principal": "Main ingredient",
    "cookbook.Tipo de prato": "Type of meal",
    "cookbook.Região": "Region",
    "cookbook.Pré-aquecimento": "Pre heating",
    "cookbook.Procurar receita": "Search recipe",
    "cookbook.Menu": "Menu",
    "cookbook.ENVIAR": "SEND",
    "cookbook.Procurar nome do menu": "Search for menu's name",
    "cookbook.Escolha um grupo deste menu": "Choose a group from this menu",
    "cookbook.Selecionar grupo": "Select group",
    "cookbook.Tivemos um problema. Tente novamente mais tarde":
      "We had a problem. Try again later",
    "cookbook.Receita adicionada com sucesso!": "Recipe added successfully!",
    "cookbook.Não foi possível adicionar a receita. Tente novamente mais tarde":
      "It was not possible to add recipe. Try again later",
    "cookbook.Selecione um menu e um grupo para enviar esta receita":
      "Select a menu and a group to send this recipe",
    "cookbook.Selecione o modelo do equipamento primeiro": "Select the equipment's model first",
    "cookbook.Grupo": "Group",
    "cookbook.Não foi possível salvar os passos da receita": "It was not possible to save the recipe's steps",
    "cookbook.Bovina": "Beef",
    "cookbook.Frango": "Chicken",
    "cookbook.Ovos": "Eggs",
    "cookbook.Pães": "Breads",
    "cookbook.Peixes": "Fish",
    "cookbook.Queijo": "Cheese",
    "cookbook.Suína": "Pork",
    "cookbook.Vegetais": "Vegetables",
    "cookbook.Outros": "Others",
    "cookbook.Entradas/aperitivos": "Starters/appetizers",
    "cookbook.Principais": "Main",
    "cookbook.Snacks": "Snacks",
    "cookbook.Pizzas": "Pizzas",
    "cookbook.Massas": "Pastas",
    "cookbook.Acompanhamentos": "Side dishes",
    "cookbook.Sobremesas": "Desserts",
    "cookbook.Manhã": "Breakfast",
    "cookbook.Padaria": "Bakery",
    "cookbook.Todos": "All",
    "cookbook.Não atribuir origem": "Do not attribute origin",
    "cookbook.Brasil": "Brazil",
    "cookbook.Alemanha": "Germany",
    "cookbook.França": "France",
    "cookbook.Rússia": "Russia",
    "cookbook.China": "China",
    "cookbook.Japão": "Japan",
    "cookbook.Itália": "Italy",
    "cookbook.Egito": "Egypt",
    "cookbook.Grécia": "Greece",
    "cookbook.Argentina": "Argentina",
    "cookbook.África": "Africa",
    "cookbook.Estados Unidos": "United States",
    "cookbook.Coreia": "Korea",
    "cookbook.Turquia": "Turkey",
    "cookbook.Ideal para": "Ideal for",
    "cookbook.Polônia": "Poland",
    "cookbook.Sanduíches": "Sandwiches",

    //EQUIPAMENTOS
    "equipment.equipamentos": "equipment",
    "equipment.procurar equipamento": "Search for equipment or user",
    "equipment.adicionar equipamento": "add equipment",
    "equipment.atualizar equipamento": "update equipment",
    "equipment.+ equipamentos": "+ equipment",
    "equipment.buscar equipamento": "Search equipment",
    "equipment.Desvincular": "Unlink",
    "equipment.Editar": "Edit",
    "equipment.sem conteúdo": "there are still no equipment here :(",
    "equipment.editar equipamento": "edit equipment",
    "equipment.equipamento editado com sucesso!": "Equipment edited successfully!",
    "equipment.não foi possível editar o equipamento. Tente novamente mais tarde": "It was not possible to edit the equipment. Try again later",
    "equipment.Menu exportado com sucesso!": "Menu exported successfully!",
    "equipment.Não foi possível exportar o menu. Tente novamente mais tarde": "It was not possible to export menu. Try again later",
    "equipment.Selecione pelo menos um equipamento para enviar o menu": "Select at least one equipment to send the menu",
    "equipment.Informações do menu": "Menu information",
    "equipment.Procurar receita ou grupo": "Search recipe or group",
    "equipment.Grupo": "Group",
    "equipment.Receita": "Recipe",
    "equipment.Quantidade": "Quantity",
    "equipment.Receitas mais preparadas no equipamento durante o período": "Most prepared recipes on the equipment during the period",
    "equipment.Informações de limpeza": "Cleaning information",
    "equipment.Data da limpeza": "Cleaning date",
    "equipment.Hora da limpeza": "Cleaning hour",
    "equipment.nome": "Name",
    "equipment.modelo": "Model",
    "equipment.categoria": "Category",
    "equipment.loja": "Store",
    "desvincular equipamento": "Unlink equipment",
    "equipment.unlink.paragrafo 1":
      "Do you wish to unlink the equipment from the IOK platform?",
    "equipment.unlink.paragrafo 2":
      "By doing so, it will not be possible to manage your settings and menu through the platform, unless you link the equipment again, following the connection instructions.",
    "equipamento desvinculado com sucesso!":
      "Equipment unlinked successfully!",
    "equipment.ocorreu um erro. Tente novamente mais tarde":
      "An error has occurred. Try again later",
    "equipment.sim": "Yes",
    "equipment.não": "No",
    "equipment.Encerrar acesso remoto": "End remote access",
    "equipment.Horas sem limpeza": "Hours without cleaning",
    "equipment.Histórico de eventos": "Events history",
    "equipment.Evento": "Event",
    "equipment.Data": "Date",
    "equipment.ERRO SENSOR RI": "Error with lower resistance sensor",
    "equipment.ERRO COM INV. DE FREQ.": "Error with frequency inverter",
    "equipment.ERRO MAGNETRON": "Magnetron error",
    "equipment.ERRO SENSOR CAMARA": "Error with camara sensor",
    "equipment.ERRO CAN": "CAN error",
    "equipment.Erro INVERSOR DE FREQUENCIA": "Frequency inverter error",
    "equipment.ERRO DO MODELO DO EQUIPAMENTO": "Equipment's model error",
    "equipment.ERRO PAINEL": "Panel error",
    "equipment.ERRO INV.FREQ.STATUS": "Frequency inverter error status",
    "equipment.ERRO SENSOR BOARD": "Board's sensor error",
    "equipment.ERRO ENERGY": "Energy error",
    "equipment.ERRO I2C": "I2C error",
    "equipment.EQUIPAMENTO LIGADO": "Equipment on",
    "equipment.EQUIPAMENTO DESLIGADO": "Equipment off",
    "equipment.Plataforma": "Platform",
    "equipment.Forno": "Oven",
    "equipment.Origem": "Origin",
    "equipment.Locais": "Locations",
    "equipment.não foi possível carregar as entidades": "It was not possible to load entities",
    "equipment.conceder acesso remoto a": "Grant remote access to",
    "equipment.atualizar equipamentos": "update equipment",
    "equipment.suporte": "support",
    "equipment.PIN": "PIN",
    "equipment.nome do equipamento": "Equipment's name",
    "equipment.vinculado à loja": "Linked to store",
    "equipment.cadastrar loja": "Register store",
    "equipment.informe o PIN do forno": "Enter the oven PIN",
    "equipment.preencha o PIN do forno": "Fill in the oven PIN",
    "equipment.adicione um nome para facilitar seu reconhecimento":
      "Add a name to make it easier to recognize",
    "equipment.preencha o nome do equipamento":
      "Fill in the equipment's name",
    "equipment.selecionar": "Select",
    "equipment.nome fantasia": "Fantasy name",
    "equipment.informe o nome fantasia": "Enter the fantasy name",
    "equipment.preencha o nome fantasia": "Fill in the fanatsy name",
    "equipment.CNPJ": "CNPJ",
    "equipment.XX.XXX.XXX/0001.XX": "XX.XXX.XXX/0001.XX",
    "equipment.preencha o CNPJ": "Fill in the CNPJ",
    "equipment.CEP": "Postal code",
    "equipment.00000-000": "00000-000",
    "equipment.preencha o CEP": "Fill in the postal code",
    "equipment.cidade": "City",
    "equipment.informe a cidade": "Enter the city",
    "equipment.preencha a cidade": "Fill in the city",
    "equipment.UF": "State",
    "equipment.bairro": "Neighborhood",
    "equipment.informe o bairro": "Enter neighborhood",
    "equipment.endereço": "Address",
    "equipment.informe o seu endereço": "Enter your address",
    "equipment.preencha o seu endereço": "Fill in your address",
    "equipment.Nº": "Nº",
    "equipment.equipamento cadastrado com sucesso!":
      "Equipment registered successfully!",
    "equipment.ocorreu um erro": "An error has occurred",
    "equipment.preencha o modelo": "Fill in the model",
    "equipment.selecione uma categoria": "Select a category",
    "equipment.selecione uma loja": "Select a store",
    "equipment.preencha o bairro": "Fill in the neighborhood",
    "equipment.não foi possível cadastrar o equipamento. Tente novamente mais tarde":
      "It was not possible to register the equipment. Try again later",
    "equipment.loja cadastrada com sucesso": "Store registered successfully",
    "equipment.não foi possível cadastrar a loja. Tente novamente mais tarde":
      "It was not possible to register the store. Try again later",
    "equipment.equipment": "equipment",
    "equipment.stores": "stores",
    "equipment.search equipment": "seach equipment",
    "equipment.add equipment": "add equipment",
    "equipment.update equipment": "update equipment",
    "equipment.plus equipment": "+ equipment",
    "equipment.Procurar equipamento": "Search equipment",
    "equipment.EQUIPAMENTOS": "EQUIPMENT",
    "equipment.Atualizar equipamento": "Update equipment",
    "equipment.Atualizar equipamentos": "Update equipment",
    "equipment.confirmar": "confirm",
    "equipment.Selecione os modelos que deseja atualizar:": "Select the models you wish to update:",
    "equipment.Como deseja realizar a atualização?": "How do you wish to proceed with the update?",
    "equipment.Enviar atualização pela plataforma (wi-fi)": "Send updates through platform (wi-fi)",
    "equipment.Salvar arquivo de atualização em pen drive": "Save update file in flash drive",
    "equipment.Linha Express": "Express line",
    "equipment.Escolha como deseja realizar a atualização": "Choose how you wish to proceed with the update",
    "equipment.Selecione ao menos um modelo de equipamento para atualizar": "Select at least one equipment model to update",
    "equipment.Status: ": "Status: ",
    "equipment.Número de série:": "Serial number",
    "equipment.Menu:": "Menu",
    "equipment.Status:": "Status: ",
    "equipment.Número de série": "Serial number",
    "equipment.Menu": "Menu",
    "equipment.Loja: ": "Store: ",
    "equipment.End:": "Address: ",
    "equipment.Cidade:": "City: ",
    "equipment.CEP:": "Postal code: ",
    "equipment.Atualização de software enviada com sucesso!": "Software update sent successfully!",
    "equipment.Não foi possível enviar a atualização de software. Tente novamente mais tarde": "It was not possible to send the software update. Try again later",
    "equipment.Download realizado com sucesso": "Download completed successfully",
    "equipment.Não foi possível realizar o download. Tente novamente mais tarde": "It was not possible to complete the download. Try again later",
    "equipment.Solicitação processada com erro. Tente novamente mais tarde": "Request processed with error. Try again later",
    "equipment.Preencha o nome da versão": "Fill in the version name",
    "equipment.Informe aqui todas as alterações desta nova versão": "Enter here all the changes in this new version",
    "equipment.Nova versão de software atualizada com sucesso!": "New sotware version updated successfully!",
    "equipment.Informe pelo menos uma mudança nesta versão": "Enter at least one change in this version",
    "equipment.Falha ao ler o arquivo": "Failed to read file",
    "equipment.selecionar tipo": "select type",
    "equipment.selecione o tipo da versão": "select version type",
    "equipment.atualização": "UPDATE",
    "equipment.Selecione abaixo o que deseja fazer:": "Select below what you wish to do:",
    "equipment.Atualizar meus equipamentos": "Update my equipment",
    "equipment.Enviar atualização para usuários selecionados": "Send update to selected users",
    "equipment.ENVIAR": "SEND",
    "equipment.ENVIAR ATUALIZAÇÃO": "SEND UPDATE",
    "equipment.Selecione os e-mails para os quais deseja enviar a atualização:": "Select the e-mails you wish to send the update to:",
    "equipment.A atualização disponibilizada no dia": "The available update on the day ",
    "equipment.referente ao equipamento": "regarding the equipment ",
    "equipment.contempla o seguinte:": "considers the following:",
    "equipment.Selecionar arquivo para envio": "Select file to send",
    "equipment.Por favor, selecione um arquivo .tar": "Please select a file .tar",
    "equipment.Selecione o modelo do equipamento:": "Select the equipment model:",
    "equipment.Selecione uma opção": "Select an option",
    "equipment.Nomeie a versão": "Name the version",
    "equipment.Clique sobre o arquivo que deseja baixar:": "Click on the file you want to download:",
    "equipment.Baixar atualização": "Download update",
    "equipment.Versões": "Versions",
    "equipment.e superiores": "and higher",
    "equipment.name": "name",
    "equipment.model": "model",
    "equipment.category": "category",
    "equipment.store": "store",
    "FIT Express/FIT ST": "FIT Express/FIT ST",
    "Copa/Single Mag/Rocket": "Copa/Single Mag/Rocket",
    "Forza STi": "Forza STi",
    "TSi": "TSi",
    "FIT Express": "FIT Express",
    "FIT ST": "FIT ST",
    "FIT Express, FIT ST": "FIT Express, FIT ST",
    "Copa Express, Rocket Express, Copa Single Mag": "Copa Express, Rocket Express, Copa Single Mag",
    "equipment.e": "and",
    "equipment.Últimas versões de software:": "Latest software versions: ",
    "equipment.Você não possui nenhum equipamento desatualizado": "You don't have any outdated equipment",
    "equipment.grupos": "groups",
    "equipment.não foi possível carregar os grupos de equipamentos da companhia": "Unable to load company equipment groups",
    "equipment.vinculado ao grupo": "Linked to the group",
    "equipment.selecione um grupo": "Select a group",
    "equipment.cadastrar grupo": "Register group",
    "equipment.Número de Série": "SN",
    "equipment.Versão": "Version",
    "equipment.não foi possível cadastrar o equipamento. Selecione ou cadastre um grupo": "Could not register the equipment. Select or register a group",
    "equipment.CRIE GRUPOS DE EQUIPAMENTOS!": "CREATE EQUIPMENT GROUPS!",
    "equipment.Para que os usuários vinculados à conta possam visualizar os equipamentos você precisa:": "So that users linked to the account can view the equipment, you need to:",
    "equipment.1) Criar grupo(s);": "1) Create group(s);",
    "equipment.2) Atribuir equipamento(s) ao grupo;": "2) Assign equipment(s) to the group;",
    "equipment.3) Atribuir usuário(s) ao grupo.": "3) Assign user(s) to the group.",
    "equipment.Status software": "Software Status",
    "equipment.Status menu": "Menu Status",
    "equipment.Pendente": "Pending",
    "equipment.Aceito": "Accepted",
    "equipment.Recusado": "Rejected",
    "equipment.Atualizar": "Update",
    "equipment.Deseja enviar a atualização para o equipamento selecionado?": "Do you want to send the update to the selected equipment?",
    "equipment.Companhia": "Company",
    "equipment.Exportar menu": "Export menu",
    "equipment.companhias": "Companies",
    "equipment.Nome da companhia": "Company name",
    "equipment.Procurar equipamento, usuário ou companhia": "Search for equipment, user or company",
    "equipment.Versão da power": "Power version",
    "equipment.AINDA NÃO HÁ DADOS DE RECEITAS AQUI :(": "THERE IS NO RECIPE DATA HERE YET :(",
    "equipment.AINDA NÃO HÁ DADOS DE LIMPEZAS AQUI :(": "THERE IS NO CLEANING DATA HERE YET :(",
    "equipment.AINDA NÃO HÁ DADOS DE EVENTOS AQUI :(": "THERE IS NO EVENT DATA HERE YET :(",
    "ID": "ID",
    "equipment.Hora": "Hour",
    "equipment.EVENTOS MAIS OCORRIDOS NO EQUIPAMENTO DURANTE O PERÍODO": "MOST OCCURRED EVENTS ON THE EQUIPMENT DURING THE PERIOD",
    "equipment.hour": "h",
    "equipment.minutes": "min",
    "equipment.date format": "DD/MM/YYYY",
    "equipment.preparada": "prepared",
    "equipment.vezes": "times",
    "equipment.vez": "time",
    "equipment.Ver": "View",
    "equipment.Os equipamentos enviarão os dados à plataforma ao serem ligados. A cada dois meses, os dados recebidos serão automaticamente excluídos.": "The equipment will send data to the platform when turned on. Every two months, the data received will be automatically deleted.",
    "equipment.Ligado": "On",
    "equipment.Desligado": "Off",
    "equipment.CAN ERROR": "CAN Error",
    "equipment.CAMERA SENSOR ERROR": "Camera sensor error",
    "equipment.STEAM/RI SENSOR ERROR": "RI sensor error",
    "equipment.PROBE 1 ERROR": "Probe 1 alert",
    "equipment.PROBE 2 ERROR": "Probe 2 alert",
    "equipment.PROBE 3 ERROR": "Probe 3 alert",
    "equipment.PROBE 4 ERROR": "Probe 4 alert",
    "equipment.PROBE 5 ERROR": "Probe 5 alert",
    "equipment.PROBE 6 ERROR": "Probe 6 alert",
    "equipment.GENERAL PROBE ERROR": "General probe error",
    "equipment.WATER LACK ERROR": "Water lack alert",
    "equipment.HIGH PANEL TEMPERATURE ERROR": "High panel temperature alert",
    "equipment.FREQUENCY INVERTER GENERAL ERROR": "Frequency inverter general error",
    "equipment.FREQUENCY INVERTER COMMUNICATION ERROR": "Frequency inverter communication alert",
    "equipment.FREQUENCY INVERTER STATUS ERROR": "Frequency inverter status alert",
    "equipment.CLEANING NOT COMPLETED ERROR": "Incomplete cleaning alert",
    "equipment.MAGNETRON ERROR": "Magnetron alert",
    "equipment.EQUIPMENT FAMILY/MODEL ERROR": "Equipment model error",
    "equipment.BOARD TEMPERATURE SENSOR ERROR": "Board temperature sensor alert",
    "equipment.ENERGY MODULE ERROR": "Energy module error",
    "equipment.POWER BOARD I2C BUS ERROR": "Power board error",
    "equipment.GAS SYSTEM ERROR": "Gas error",
    "equipment.VERY HIGH PANEL TEMPERATURE ERROR": "Super high panel temperature error",
    "equipment.POWER ON": "On",
    "equipment.POWER OFF": "Off",
    "equipment.DOOR OPENED": "Door open",
    "equipment.DOOR CLOSED": "Door closed",
    "equipment.CLEANING CANCELED ERROR": "Cleaning canceled by user",
    "equipment.PIN não encontrado. Por favor, verifique e tente novamente": "PIN not found. Please check and try again",
    "equipment.Online": "Online",
    "equipment.Offline": "Offline",
    "equipment.Exibir apenas eventos": "Show events only",
    "equipment.Endereço": "Address",
    "equipment.EQUIPMENT CONNECTED TO THE PLATFORM": "Equipment connected to the platform",
    "equipment.EQUIPMENT DISCONNECTED FROM THE PLATFORM": "Equipment disconnected from the platform",
    "equipment.MENU RECEIVED FROM THE PLATFORM": "Menu received from the platform",
    "equipment.MENU REJECTED BY THE USER": "Menu rejected by the user",
    "equipment.CONNECTED TO THE INTERNET": "Connected to the internet",
    "equipment.DISCONNECTED FROM THE INTERNET": "Disconnected from the internet",
    "equipment.Infelizmente não há versões que realizem o envio de dados compatíveis com este equipamento.": "Unfortunately, there are no versions that perform data transfers compatible with this equipment.",
    "equipment.O equipamento": "The equipment",
    "equipment.deve ser atualizado para a versão mais recente de software para que passe a enviar os dados para a plataforma": "must be updated to the latest software version to start sending data to the platform",
    "equipment.Versão do seu equipamento": "Version of your equipment",
    "equipment.Versão disponível": "Available version",
    "equipment.Selecione abaixo como deseja enviar a atualização para seu equipamento": "Select below how you want to send the update to your equipment",
    "equipment.Salvar em pen drive": "Save to USB drive",
    "equipment.Enviar por wi-fi": "Send via Wi-Fi",
    "equipment.Cliente": "Client",
    "equipment.NÚMERO DE SÉRIE NÃO ENCONTRADO": "SERIAL NUMBER NOT FOUND",
    "equipment.BUSCAR": "SEARCH",
    "equipment.ACESSADOS RECENTEMENTE": "RECENTLY ACCESSED",
    "equipment.SEM BUSCAS RECENTES": "NO RECENT SEARCHES",
    "equipment.Procurar equipamento por NS": "Search equipment by NS",
    "equipment.AIR FILTER CLEANING COMPLETED": "Air filter cleaning complete",
    "equipment.AIR FILTER CLEANING REJECTED": "Air filter cleaning rejected",
    "equipment.CATALYTIC FILTER CLEANING COMPLETED": "Catalytic filter cleaning complete",
    "equipment.CATALYTIC FILTER CLEANING REJECTED": "Catalytic filter cleaning rejected",
    "equipment.Endereço (rua, avenida, rodovia)": "Address (street, avenue, highway)",
    "equipment.Nº/Km": "Nº/Km",
    "equipment.ID do equipamento": "Equipment ID",
    "equipment.Todo equipamento deve ser vinculado a um grupo. Selecione um grupo da lista ou cadastre um novo clicando em + Cadastrar grupo.": "All equipment must be linked to a group. Select a group from the list or register a new one by clicking + Register group.",
    "equipment.Nomeie seu forno": "Name your oven",
    "equipment.Para visualizar o PIN, acesse nas configurações do equipamento a Plataforma Virtual IOK.": "To view the PIN, access the IOK Virtual Platform in the equipment settings.",
    "equipment.Vinculação IOK": "IOK Binding",
    "equipment.Preparações": "Preparations",
    "equipment.Última preparação": "Last preparation",
    "equipment.Pico de preparações": "Peak of preparations",
    "equipment.informações da semana": "week information",
    "equipment.horários de pico": "peak times",
    "equipment.localização": "location",
    "equipment.AINDA NÃO HÁ NENHUMA LOCALIZAÇÃO CADASTRADA :(": "THERE IS NO LOCATION REGISTERED YET :(",
    "equipment.Procurar empresa ou local": "Search for company or location",
    "equipment.Adicionar localização": "Add location",
    "equipment.espaço": "Room",
    "equipment.local": "Local",
    "equipment.EXCLUIR LOCALIZAÇÃO": "DELETE LOCATION",
    "equipment.Você deseja excluir esta localização?": "Do you want to delete this location?",
    "equipment.Ao fazer isso, os equipamentos atrelados à ela não exibirão nenhuma localização, sendo necessário informar um novo local para eles.": "When doing this, the equipment linked to it will not display any location, making it necessary to provide a new location for them.",
    "equipment.Localização deletada com sucesso!": "Location deleted successfully!",
    "equipment.Erro ao deletar a localização. Tente novamente mais tarde": "Error deleting location. Try again later",
    "equipment.Não foi possivel deletar a localização": "Unable to delete location",
    "equipment.Localização adicionada com sucesso!": "Location added successfully!",
    "equipment.Não foi possível adicionar a localização. Tente novamente mais tarde": "Unable to add location. Try again later",
    "equipment.Nome do espaço": "Room name",
    "equipment.Informe o nome do espaço": "Enter the name of the room",
    "equipment.Preencha o nome do espaço": "Fill in the name of the room",
    "equipment.país": "Country",
    "equipment.informe o país": "Enter the country",
    "equipment.preencha o país": "Fill in the country",
    "equipment.Localização editada com sucesso!": "Location edited successfully!",
    "equipment.Não foi possível editar a localização. Tente novamente mais tarde": "Unable to edit location. Try again later",
    "equipment.Editar localização": "Edit location",
    "equipment.horário de pico": "rush hour",
    "equipment.preparações do dia": "preparations of the day",
    "equipment.Horário": "Time",
    "equipment.dia selecionado": "selected day",
    "equipment.A limpeza adequada e diária do forno auxilia em seu funcionamento correto e impacta na garantia.": "Proper and daily cleaning of the oven helps it function correctly and impacts the warranty.",
    "equipment.Total de limpezas realizadas:": "Total cleaning performed:",
    "equipment.Limpezas não concluídas:": "Cleanings not completed:",
    "equipment.Desde a vinculação do forno a IOK": "From connecting the oven to IOK",
    "equipment.Equipamento ligado há:": "Equipment on for:",
    "equipment.Total de receitas preparadas:": "Total prepared recipes:",
    "equipment.Receitas preparadas": "Prepared recipes",
    "equipment.Aberturas de porta": "Door openings",
    "equipment.Desde a última vez que o forno foi ligado": "Since the last time the oven was turned on",
    "equipment.Preparação": "Preparation",
    "equipment.Nenhuma localização criada": "No locations created",
    "equipment.Acesso remoto": "Remote access",
    "equipment.Informe o ID da empresa que poderá acessar remotamente seu forno. Use ; para separar IDs.": "Enter the ID of the company that can remotely access your oven. Use ; to separate IDs.",
    "equipment.O acesso remoto permite que as empresas selecionadas enviem menus e atualizações para seu equipamento.": "Remote access allows selected companies to send menus and updates to your equipment.",
    "equipment.Concedo acesso remoto à Prática oficial.": "I grant remote access to the official Prática.",
    "equipment.Deseja selecionar outros equipamentos vinculados à sua conta para conceder acesso remoto a eles?": "Do you want to select other devices linked to your account to grant them remote access?",
    "equipment.Selecione abaixo os equipamentos para os quais deseja conceder acesso remoto à": "Select below the equipment for which you want to grant remote access to the",
    "equipment.Acesso remoto concedido com sucesso!": "Remote access granted successfully!",
    "equipment.Selecione pelo menos um equipamento": "Select at least one device",
    "equipment.Não foi possível editar os equipamentos. Tente novamente mais tarde": "Unable to edit equipment. Try again later",
    "equipment.Solicitar atendimento": "Request service",
    "equipment.Relate um problema": "Report a problem",
    "equipment.Descreva aqui o problema de seu equipamento": "Describe your equipment problem here",
    "equipment.Não foi possível enviar sua solicitação de atendimento. Tente mais tarde": "We were unable to send your service request. Try later",
    "equipment.Solicitação técnica enviada com sucesso!": "Technical request sent successfully!",
    "equipment.Enviar": "Send",
    "equipment.Solicitação de atendimento": "Service request",
    "equipment.Informações do magnetron": "Magnetron Information",
    "equipment.Vida útil aproximada": "Approximate Lifespan",
    "equipment.MAGNETRON USAGE HOURS": "Magnetron Usage Hours",
    "equipment.Filtros": "Filters",
    "equipment.Filtrar": "Filter",
    "equipment.Limpar filtros": "Clear filters",
    "equipment.Procurar por modelo, versão ou país": "Search by model, version or country",
    "equipment.Procurar equipamento, NS, modelo, versão ou país": "Search for equipment, SN, model, version, or country",
    "equipment.Atendimento": "Service",
    "equipment.Eventos mais ocorridos no equipamento durante o período": "Events most occurring on the equipment during the period",
    "equipment.EQUIPAMENTO SEM NÚMERO DE SÉRIE": "EQUIPMENT WITHOUT SERIAL NUMBER",
    "equipment.Os seguintes IDs não pertencem a uma companhia do tipo 'Distribuidor' e foram removidos": "The following IDs do not belong to a company of type 'Distributor' and have been removed",
    "equipment.BAIXAR VERSÕES": "DOWNLOAD VERSIONS",
    "equipment.Compatível com todas as versões da IHM.": "Compatible with all HMI versions.",
    "equipment.Compatível apenas com a versão 2.0.0C da Power.": "Only compatible with Power version 2.0.0C.",
    "equipment.Procurar evento": "Search event",
    "equipment.Forno conectado": "Oven connected",
    "equipment.Limpeza de filtro de ar": "Air filter cleaning",
    "equipment.Limpeza de filtro catalítico": "Catalytic filter cleaning",
    "equipment.Forno ligado": "Oven on",
    "equipment.Inversor de frequência": "Frequency inverter",
    "equipment.Temperatura de painel": "Panel temperature",
    "equipment.Sensor da câmara": "Chamber sensor",
    "equipment.Magnetron": "Magnetron",
    "equipment.Power": "Power",
    "equipment.Informações de menu": "Menu information",
    "equipment.Limpeza": "Cleaning",
    "equipment.O equipamento já está utilizando a versão mais recente do software.": "The equipment is already using the latest version of the software.",

    //LOJAS
    "store.Procurar loja": "Search store",
    "store.Excluir": "Delete",
    "store.Editar": "Edit",
    "store.nome": "Name",
    "store.equipamentos": "Equipment",
    "store.cidade": "City",
    "store.estado": "State",
    "store.salvar": "save",
    "store.cadastrar loja": "register store",
    "store.Nome fantasia": "Fantasy name",
    "store.Preencha o nome da loja": "Fill in the store name",
    "store.CNPJ": "CNPJ",
    "store.Preencha o CNPJ da loja": "Fill in the store's CNPJ",
    "store.CEP": "Postal code",
    "store.Preencha o CEP da loja": "Fill in the store's postal code",
    "store.Informe o nome fantasia": "Fill in the fantasy name",
    "store.Cidade": "City",
    "store.Informe a cidade": "Enter the city",
    "store.Preencha a cidade": "Fill in the city",
    "store.UF": "State",
    "store.Preencha o UF da loja": "Fill in the store's state",
    "store.Bairro": "Neighborhood",
    "store.Informe o bairro": "Enter the neighborhood",
    "store.Preencha o bairro da loja": "Fill in the store's neighborhood",
    "store.Endereço": "Address",
    "store.Informe o endereço": "Enter the address",
    "store.Preencha o endereço da loja": "Fill in the store's address",
    "store.Nº": "Nº",
    "store.Preencha o número": "Preencha o número",
    "store.Loja cadastrada com sucesso": "Store registered successfully",
    "store.Erro ao adicionar loja. Tente novamente":
      "Error adding store. Try again",
    "store.Loja deletada com sucesso": "Store deleted successfully",
    "store.Erro ao deletar loja. Tente novamente mais tarde":
      "Error deleting store. Try again later",
    "store.AINDA NÃO HÁ NENHUMA LOJA CADASTRADA :(": "THERE ARE STILL NO REGISTERED STORE :(",
    "store.EXCLUIR LOJA": "DELETE STORE",
    "store.Você deseja excluir a loja da plataforma IOK?": "Do you wish to delete the store from the IOK platform?",
    "store.Ao fazer isso, os equipamentos atrelados à ela não terão vínculo com nenhuma loja, a menos que os vincule novamente.": "By doing so, the equipment linked to it will have no link to any stores, unless you link them again.",
    "store.loja editada com sucesso": "Store edited successfully",
    "store.não foi possível editar a loja. Tente novamente mais tarde": "It was not possible to edit store. Try again later",
    "store.editar loja": "edit store",
    "store.Procurar grupo de equipamentos": "Search equipment group",
    "store.cadastrar grupo": "register group",
    "store.AINDA NÃO HÁ NENHUM GRUPO CADASTRADO :(": "THERE ARE NO GROUP REGISTERED YET :(",
    "store.cadastrar grupo de equipamentos": "register equipment group",
    "store.grupo de equipamentos cadastrado com sucesso!": "Equipment group registered successfully!",
    "store.não foi possível cadastrar o grupo. Tente novamente mais tarde": "Unable to register the group. Try again later",
    "store.Nome do grupo": "Group's name",
    "store.informe o nome do grupo": "Enter the group name",
    "store.preencha o nome do grupo": "Fill in the group name",
    "store.Grupo de equipamentos deletado com sucesso!": "Equipment group deleted successfully!",
    "store.Erro ao deletar o grupo. Tente novamente mais tarde": "Error deleting the group. Try again later",
    "store.EXCLUIR GRUPO DE EQUIPAMENTOS": "DELETE EQUIPMENT GROUP",
    "store.Você deseja excluir este grupo de equipamentos da plataforma IOK?": "Do you want to delete this equipment group from the IOK platform?",
    "store.Ao fazer isso, os equipamentos atrelados à ele não terão vínculo com nenhum grupo, a menos que os vincule novamente.": "When you do this, the equipment linked to it will not be linked to any group, unless you link them again.",
    "store.grupo de equipamentos editado com sucesso!": "Equipment group edited successfully!",
    "store.não foi possível editar o grupo. Tente novamente mais tarde": "Unable to edit the group. Try again later",
    "store.editar grupo de equipamentos": "edit equipment group",
    "store.CEP não encontrado no Brasil": "Postal code not found in Brazil",

    // NAVBAR
    "navbar.home": "home",
    "navbar.cookbook": "cookbook",
    "navbar.my menus": "my menus",
    "navbar.equipment": "equipment",
    "navbar.users": "users",
    "navbar.calls": "services",
    "navbar.settings": "settings",
    "navbar.help": "help",
    "navbar.logout": "Log out",
    "navbar.Editar conta": "Edit account",
    "navbar.API Externa": "External API",

    // FOOTERBAR
    "footerBar.menu": "Menu",
    "footerBar.cookbook": "Cookbook",
    "footerBar.home": "Home",
    "footerBar.menus": "Menus",
    "footerBar.notifications": "Notifications",

    //ATENDIMENTOS
    "calls.ATENDIMENTOS EM ABERTO": "ON GOING SERVICES",
    "calls.ATENDIMENTOS ENCERRADOS": "CLOSED SERVICES",

    //MAP
    "map.título": "Location",
    "map.erro": "error!",
    "map.mensagem de erro": "It was not possible to load map.",
    "map.Localização não disponível": "Location not available",

    //MODAL
    "modal.CONFIRMAR": "CONFIRM",
    "modal.Salvar alterações": "Save changes",
    "Modal.Há alterações não salvas nos parâmetros dos passos ":
      "There are unsaved changes in step parameters ",
    "Modal.Como deseja proceder?": "How do you wish to proceed?",
    "Modal.Avaliar alterações realizadas": "Confirm changes made",
    "Modal.Descartar alterações": "Discard changes",

    //USUÁRIOS
    "user.Nome": "Name",
    "user.E-mail": "E-mail",
    "user.Permissão": "Permission",
    "user.Loja": "Store",
    "user.Status": "Status",
    "user.Excluir": "Delete",
    "user.Editar": "Edit",
    "user.Procurar usuário": "Search user",
    "user.ADICIONAR USUÁRIO": "ADD USER",
    "user.AINDA NÃO HÁ NENHUM USUÁRIO CADASTRADO :(": "THERE ARE NO USERS REGISTERED YET :(",
    "user.+ Usuário": "+ User",
    "user.Informe o nome do usuário": "Enter user name",
    "user.Preencha o nome do usuário": "Fill in the user name",
    "user.Informe o e-mail do usuário": "Enter the user's e-mail",
    "user.Preencha com um e-mail válido": "Enter a valid e-mail address",
    "user.Administrador": "Administrator",
    "user.Usuário": "User",
    "user.Preencha o telefone do usuário": "Fill the user's phone number",
    "user.Selecione a permissão do usuário": "Select user permission",
    "user.Ativo": "Active",
    "user.Inativo": "Inactive",
    "user.Usuário deletado da plataforma com sucesso!": "User removed from platform successfully!",
    "user.Ocorreu um erro. Tente novamente mais tarde": "An error has occurred. Try again later",
    "user.EXCLUIR USUÁRIO": "DELETE USER",
    "user.delete.paragrafo 1": "Do you wish to delete user from the IOK platform?",
    "user.delete.paragrafo 2": "By doing so, they will lose access to all platform tools, unless they are added to it again, following the registration instructions.",
    "user.EDITAR USUÁRIO": "EDIT USER",
    "user.Usuário editado com sucesso!": "User edited successfully!",
    "user.Não foi possível editar este usuário. Por favor, tente mais tarde": "It was not possible to edit this user. Please, try later",
    "user.INFORMAÇÕES DO USUÁRIO": "USER INFORMATION",
    "user.Telefone": "Phone",
    "user.Não foi possível cadastrar este usuário. Por favor, tente mais tarde": "It was not possible to register this user. Please, try later",
    "user.Usuário adicionado com sucesso!": "User added successfully!",
    "user.CRIAR TIPO DE USUÁRIO": "CREATE USER TYPE",
    "user.Criar novo tipo de usuário": "Create new user type",
    "user.Adicionar novo usuário": "Add new user",
    "user.Permissões": "Permissions",
    "user.Preencha o nome do novo tipo de usuário": "Fill in the name of the new user type",
    "user.Selecione pelo menos uma permissão": "Select at least one permission",
    "user.Não foi possível carregar as permissões": "It was not possible to load the permissions",
    "user.Tipo de usuário criado com sucesso!": "User type created successfully!",
    "user.Não foi possível criar este novo tipo de usuário. Por favor, tente mais tarde": "It was not possible to create this new user type. Please, try later",
    "user.Não foi possível carregar os tipos de usuário da companhia": "It was not possible to load the company's user types",
    "user.Tipo de usuário": "User type",
    "user.E-mail já cadastrado na plataforma. Informe um novo e-mail": "E-mail already registered on the platform. Enter a new e-mail",
    "user.UNIR CONTAS": "MERGE ACCOUNTS",
    "user.ID da companhia": "Company ID",
    "user.Digite o ID da companhia que deseja unir a sua conta": "Enter the ID of the company you want to merge",
    "user.Preencha o ID da companhia": "Fill in the company ID",
    "user.O administrador da conta com a qual deseja se unir poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.": "The administrator of the account you wish to merge will be able to find the ID by clicking on your username, located in the top menu, in the right corner of the screen.",
    "user.O administrador da outra conta receberá um e-mail para aceitar sua solicitação. Assim que for aceita, a união das duas contas será realizada!": "The administrator of the other account will receive an e-mail to accept your request. Once accepted, the two accounts will be merged!",
    "user.Não foi possível solicitar a união de contas. Por favor, confirme o ID ou tente mais tarde": "Unable to request account merge. Please confirm ID or try later",
    "user.SOLICITAÇÃO APROVADA COM SUCESSO": "REQUEST SUCCESSFULLY APPROVED",
    "user.AGUARDE ENQUANTO REALIZAMOS A UNIÃO DAS CONTAS. ESTE PROCESSO PODE LEVAR ALGUNS MINUTOS...": "PLEASE WAIT WHILE WE MERGE THE ACCOUNTS. THIS PROCESS MAY TAKE A FEW MINUTES...",
    "user.UNIÃO DE CONTAS FINALIZADA COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "MERGE OF ACCOUNTS SUCCESSFULLY COMPLETED! CLICK THE BUTTON BELOW TO ACCESS THE PLATFORM.",
    "user.NÃO CONSEGUIMOS REALIZAR A UNIÃO DAS CONTAS. POR FAVOR, TENTE NOVAMENTE. SE O PROBLEMA PERSISTIR, ENTRE EM CONTATO COM NOSSO SUPORTE.": "WE ARE UNABLE TO MERGE THE ACCOUNTS. PLEASE TRY AGAIN. IF THE PROBLEM PERSISTS, PLEASE CONTACT OUR SUPPORT.",
    "user.possui todos os acessos": "has all access",
    "user.possui o acesso das abas cookbook, meus menus e equipamentos": "has access to the cookbook, my menus and equipment tabs",
    "user.possui apenas o acesso da aba cookbook": "only has access to the cookbook tab",
    "user.possui apenas o acesso da aba equipamentos": "only has access to the equipment tab",
    "user.possui apenas o acesso da aba meus menus": "only has access to the my menus tab",
    "user.Grupo de equipamentos": "Equipment group",
    "user.Informe o telefone do usuário": "Enter the user's phone number",
    "user.Nenhum grupo criado": "No groups created",
    "user.Digite o ID da companhia que terá acesso à sua conta": "Enter the ID of the company that will have access to your account",
    "user.Ao informar um ID para unir contas com sua companhia, esteja ciente de que o novo usuário também se tornará Administrador da conta de sua companhia. É importante informar que a partir do momento em que um usuário aceitar unir contas com sua companhia, a companhia pertencente a ele deixará de existir.":
      "When providing an ID to merge accounts with your company, be aware that the new user will also become an Administrator of your company's account. It is important to inform that from the moment a user agrees to join accounts with your company, the company belonging to them will cease to exist.",
    "user.Exemplo: Sua conta: 001 | Outra conta: 111. Depois de unir contas com você, a outra conta passará a ser 001.": "Example: Your account: 001 | Another account: 111. After merging accounts with you, the other account will become 001.",
    "user.O administrador da outra conta poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.": "The administrator of the other account will be able to locate the ID by clicking on your username, located in the top menu, in the right corner of the screen.",

    //TUTORIAIS 
    "tutorial.Fechar": "Close",
    "tutorial.Fim": "End",
    "tutorial.Próximo": "Next",
    "tutorial.Abrir": "Open",
    "tutorial.Pular tutorial": "Skip tutorial",
    "tutorial.Editar detalhes da receita": "Edit recipe details",
    "tutorial.Agora você pode adicionar mais detalhes às suas receitas e deixá-las ainda mais completas. Clique em Editar detalhes e preencha as informações necessárias.":
      "Now you can add more details to your recipes and make them even more complete. Click Edit details and fill in the necessary information.",
    "tutorial.Salvar edição da receita": "Save recipe edit",
    "tutorial.Depois de informar mais detalhes da receita, clique em Salvar.": "After you have given more recipe details, click Save.",
    "tutorial.Editar registro de um equipamento": "Edit equipment registration",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Categoria e Loja.":
      "To complete your equipment's registration, open the side menu, click Edit and fill in the Category and Store information.",
    "tutorial.Editar usuário": "Edit user",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente a Loja a qual ele faz parte.":
      "To complete user registration, open the side menu, click Edit and fill in the information regarding the store they are part of.",
    "tutorial.Salvar edições de um equipamento": "Save an equipment's edits",
    "tutorial.Depois de completar o registro de seus equipamentos, clique em Salvar.": "After completing your equipment's registration, click Save.",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em uma loja, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.":
      "By clicking on the line of an equipment registered in a store, you can view more statistics and information about it, in addition to being able to request a service or trigger updates.",
    "tutorial.Para visualizar mais informações sobre a loja, como localização e receitas mais preparadas, clique sobre a linha da unidade que deseja analisar.":
      "To view more information about the store, such as location and most prepared recipes, click on the row of the unit you want to analyze.",
    "tutorial.Criar receita": "Create recipe",
    "tutorial.No cookbook você pode criar suas próprias receitas! Clique em Criar Receita e preencha todas as informações necessárias.":
      "In the cookbook you can create your own recipes! Click Create Recipe and fill in all the necessary information.",
    "tutorial.Editar ou excluir um usuário": "Edit or delete an user",
    "tutorial.Para excluir ou editar as informações de um usuário já cadastrado, clique no menu lateral e selecione a opção desejada.":
      "To delete or edit an registered user's information, click on the side menu and select the desired option.",
    "tutorial.Gerenciamento de usuários": "User management",
    "tutorial.Aqui você pode gerenciar seus usuários com mais facilidade. Ao clicar em + Usuário, você poderá adicionar um novo usuário ou criar um novo tipo de usuário.": "Here you can manage your users more easily. By clicking + User, you can add a new user or create a new user type.",
    "tutorial.Suas receitas": "Your recipes",
    "tutorial.Pelo cookbook você também encontra suas próprias receitas, além de poder criá-las! Clique em Receitas Salvas para visualizar as receitas que foram salvas do cookbook da Prática e também as criadas por você.":
      "Through the cookbook you can also find your own recipes, in addition to being abble to create them! Click Saved Recipes to view the saved recipes from Prática's cookbook and the ones created by you.",
    "tutorial.Filtros do Cookbook": "Cookbook filters",
    "tutorial.Para facilitar a navegação pelas receitas, você pode aplicar filtros a elas. Basta clicar no filtro desejado e escolher uma de suas opções.":
      "In order to facilitate the navigation through the recipes, you can apply filters to them. You just have to click on the desired filter and choose one of its options.",
    "tutorial.Cookbook": "Cookbook",
    "tutorial.No cookbook, em Receitas Disponíveis, você encontrará as receitas criadas pelos Chefs da Prática e poderá utilizá-las sempre que quiser.":
      "In the cookbook, in Available Recipes, you will find the recipes created by Prática's Chefs and will be able to use them when you wish.",
    "tutorial.Atualizar equipamentos": "Update equipment",
    "tutorial.Para enviar uma atualização a seus equipamentos, clique em Atualizar Equipamentos, selecione os modelos que deseja atualizar e a forma como deseja realizar essa atualização - via wi-fi ou pen drive. Depois disso, clique em Confirmar.":
      "To send an update to your equipment, click Update Equipment, select the models you wish to update and how you wish to do it - via wi-fi or USB flash drive. After that, click Confirm.",
    "tutorial.Adicionar equipamento": "Add equipment",
    "tutorial.Para adicionar um equipamento à sua conta, clique em Adicionar Equipamento e preencha as informações do modal. Depois disso, clique em Salvar.":
      "To add an equipment to your account, click Add Equipment and fill in the modal information. After that, click Save.",
    "tutorial.Editar equipamento": "Edit equipment",
    "tutorial.Para desvincular um equipamento ou editar suas informações, clique no menu lateral e selecione a opção desejada.":
      "To unlink an equipment or edit its information, click on the side menu and select the desired option.",
    "tutorial.Seus equipamentos": "Your equipment",
    "tutorial.Aqui você visualizará todos os equipamentos cadastrados em sua plataforma.": "Here you will view all the equipment registered in your platform.",
    "tutorial.Restaurar arquivos": "Restore files",
    "tutorial.Para restaurar um arquivo excluído ou deletá-lo permanentemente, clique no menu lateral e selecione a opção desejada.":
      "To restore a deleted file or delete it permanently, click on the side menu and select the desired option.",
    "tutorial.Lixeira": "Trash",
    "tutorial.Os menus excluídos serão mantidos na Lixeira por 30 dias. Depois desse prazo serão excluídos permanentemente.": "The deleted menus will be kept in Trash for 30 days. After this period they will be permanently deleted.",
    "tutorial.Salvar edições de usuário": "Save user edits",
    "tutorial.Depois de completar o cadastro dos usuários, clique em Salvar.": "After completing the user registration, click Save.",
    "tutorial.Criar menu": "Create menu",
    "tutorial.Para criar e personalizar um menu, clique em Criar Menu e preencha as informações necessárias no modal. Você pode editar um menu a qualquer momento.":
      "To create and customize a menu, click Create Menu and fill in the necessary information in the modal. You can edit a menu at any moment.",
    "tutorial.Importar menu": "Import menu",
    "tutorial.Para importar um menu, clique em Importar Menu e selecione o modelo e versão do equipamento.": "To import a menu, click Import Menu and select the equipment model and version.",
    "tutorial.Criar grupo": "Create group",
    "tutorial.Para organizar suas receitas em grupos, clique em Criar Grupo e preencha as informações necessárias no modal.": "To manage your group recipes, click Create Group and fill in the necessary information in the modal.",
    "tutorial.Tipos de usuário": "User types",
    "tutorial.Agora você também pode criar tipos de usuários com permissões específicas para atender melhor a sua organização.": "You can now also create user types with specific permissions to better serve your organization.",
    "tutorial.Gerenciamento de contas": "Account management",
    "tutorial.Se caso outra pessoa da sua organização também migrou a conta principal da empresa, aqui você pode unir as duas contas.": "If someone else in your organization also migrated their main company account, here you can merge the two accounts.",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Grupo.": "To complete the registration of your equipment, open the side menu, click Edit and fill in the Group information.",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente ao Grupo de equipamentos a qual ele faz parte.": "To complete user registration, open the side menu, click Edit and fill in the information regarding the Equipment Group to which it belongs.",
    "tutorial.Grupos de equipamentos": "Equipment groups",
    "tutorial.Na aba Grupos você pode visualizar os grupos cadastrados e os equipamentos distribuídos entre eles. Além disso, é possível cadastrar novos grupos e editar os já cadastrados.": "In the Groups tab you can view the registered groups and the equipment distributed among them. Furthermore, it is possible to register new groups and edit those already registered.",
    "tutorial.Detalhes de grupos": "Group details",
    "tutorial.Para visualizar mais informações sobre o grupo, como localização e receitas mais preparadas, clique sobre a linha que deseja analisar.": "To view more information about the group, such as location and the most prepared recipes, click on the row you wish to analyze.",
    "tutorial.Equipamentos em grupos": "Equipment in groups",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em um grupo, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "By clicking on the line of a device registered in a group, you can view more statistics and information about it, in addition to being able to request service or trigger updates.",
    "tutorial.Grupos": "Groups",
    "tutorial.Atualização e cache": "Update and cache",
    "tutorial.Para atualizar a plataforma e corrigir eventuais problemas de cache, clique aqui.": "To update the platform and correct any cache issues, click here.",
    "tutorial.Dados dos equipamentos": "Equipment data",
    "tutorial.Ao clicar sobre a linha de um equipamento, você pode visualizar mais estatísticas e informações sobre ele, como as receitas mais preparadas, limpezas realizadas e outros dados relevantes.": "By clicking on the row of a piece of equipment, you can view more statistics and information about it, such as the most prepared recipes, completed cleanings, and other relevant data.",
    "tutorial.Para alterar o idioma de sua plataforma, clique em Idioma e selecione a linguagem desejada.": "To change the language of your platform, click on Language and select the desired language.",
    "tutorial.Alterar idioma": "Change the language",
    "tutorial.Para editar ou configurar sua conta, clique em seu nome e selecione a opção desejada.": "To edit or configure your account, click on your name and select the desired option.",
    "tutorial.Editar conta": "Edit account",
    "tutorial.Total de Usuários Cadastrados em sua conta.": "Total Users Registered in your account.",
    "tutorial.Home": "Home",
    "tutorial.Localização de seus equipamentos, sendo possível expandir o mapa para melhor visualização;": "Location of your equipment, making it possible to expand the map for better viewing;",
    "tutorial.Atualizações referentes a versão dos equipamentos vinculados a sua conta;": "Updates regarding the version of equipment linked to your account;",
    "tutorial.Total de Receitas Cadastradas;": "Total Registered Recipe;",
    "tutorial.Menus Disponíveis;": "Available Menus;",
    "tutorial.Esta é a sua home! Aqui você encontra um resumo geral sobre sua conta, como quantidade de Equipamentos Vinculados;": "This is your home! Here you will find a general summary of your account, such as the number of Linked Equipment;",
    "tutorial.Clique nas abas do menu para explorar a plataforma e conhecer tudo o que ela pode oferecer.": "Click on the menu tabs to explore the platform and discover everything it can offer.",
    "tutorial.Exportar menu": "Export menu",
    "tutorial.Depois de personalizar seu menu, exporte-o para seus equipamentos pela internet ou por pen drive.": "After customizing your menu, export it to your devices via the internet or via USB.",
    "tutorial.Na aba Localização você pode visualizar os endereços já cadastrados, além de criar localizações precisas para vincular seus equipamentos a elas.": "In the Location tab you can view the addresses already registered, in addition to creating precise locations to link your equipment to them.",
    "tutorial.Localização": "Location",

    //RECOVER ACCOUNT
    "recoverAccount.Para prosseguir, selecione a categoria na qual você se encaixa:": "To proceed, select the category you fit into:",
    "recoverAccount.Deseja acessar a plataforma como DISTRIBUIDOR? Crie sua conta clicando em EMPRESA e entre em contato conosco para liberarmos os demais acessos.":
      "Do you wish to access the platform as a DISTRIBUTOR? Create your account by clicking COMPANY and contact us to release the other accesses.",
    "recoverAccount.A qual organização você pertence": "Which organization do you belong to?",

    //RECOVER PASSWORD PAGE
    "recoverPassword.E-mail para recuperação de senha":
      "E-mail for password recovery",
    "recoverPassword.Informe o e-mail cadastrado na plataforma":
      "Inform the e-mail registered on the platform",
    "recoverPassword.E-mail inválido. Por favor, verifique":
      "Invalid e-mail. Please, verify",
    "recoverPassword.RECUPERAR SENHA": "RECOVER PASSWORD",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL COM O LINK DE RECUPERAÇÃO DE SENHA":
      "SHORTLY YOU WILL RECEIVE AN E-MAIL WITH THE PASSWORD RECOVERY LINK",
    "recoverPassword.Informe seu e-mail": "Inform your e-mail",
    "recoverPassword.Crie uma nova senha": "Create a new password",
    "recoverPassword.O campo senha deve conter no mínimo 6 caracteres":
      "The password field must have at least 6 characters",
    "recoverPassword.Confirme sua senha": "Confirm password",
    "recoverPassword.As senhas devem ser iguais": "The passwords must match",
    "recoverPassword.ENTRAR": "ENTER",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL PARA CONFIRMAR SUA IDENTIDADE": "SHORTLY YOU WILL RECEIVE AN E-MAIL TO CONFIRM YOUR IDENTITY",
    "recoverPassword.CONTINUAR": "CONTINUE",
    "recoverPassword.CONFIRMAR": "CONFIRM",

    // EXPORT
    "export.Limpezas": "Cleanings",
    "export.Eventos": "Events",
    "export.Lista de receitas preparadas": "List of prepared recipes",
    "export.Lista de limpezas realizadas": "List of completed cleanings",
    "export.Lista de eventos ocorridos": "List of occurred events",
    "export.Exportar dados": "Export data",
    "export.Informações do equipamento": "Equipment information",
    "export.Dados exportados com sucesso!": "Data exported successfully!",
    "export.Não foi possível exportar os dados. Por favor, tente novamente mais tarde.": "Unable to export data. Please try again later.",
    "export.Detalhes do menu": "Menu details",
    "export.Detalhes de receitas": "Recipe details",
    "export.Detalhes e Passos": "Details and Steps",
    "export.Exportando dados. Em breve o arquivo estará disponível!": "Exporting data. The file will be available soon!",
    "export.Resumo dos passos": "Summary of steps",
    "export.salvar imagem": "save image"
  },
};

export default en;
