import styled, { css } from "styled-components";

interface RotateBannerProps {
    isLoginPage?: boolean
};

export const LoadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

export const DivSlider = styled.div<RotateBannerProps>`
    width: 100%;
    height: 100%;
    position: relative;

    ${(props) => !props.isLoginPage &&
        css`
            display: flex;
            justify-content: center;
            align-items: center;
        `
    }
`;

export const ImageSlider = styled.img<RotateBannerProps>`
    position: absolute;
    object-fit: cover;
    opacity: 0;
    transition: opacity 800ms ease-in-out;

    ${(props) => props.isLoginPage &&
        css`
            width: 100%;
            height: 100%;
        `
    }

    &.selected {
        opacity:1;
    }
`;

export const ProgressBar = styled.div<{ progressBarColor: string }>`
    width: 35%;
    height: 50px;
    border: 2px solid ${(props) => props.progressBarColor};
    border-radius: 6px;
    background: transparent;
    position: relative;
    overflow: hidden;
`;

export const Progress = styled.div<{ progressBarColor: string }>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.progressBarColor};
  animation: progressAnimation 4s infinite;
`;

export const Message = styled.p`
  margin-top: 10px;
  font-size: 1.2rem;
  color: #333;
`;

export const progressAnimation = `
  @keyframes progressAnimation {
    0% { width: 0%; }
    100% { width: 100%; }
  }
`;