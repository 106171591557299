import styled from 'styled-components';
import * as DesignSystem from '../../../assets/styles/StyleTypes';
import invalidIcon from '../../../assets/image/invalid.png';

type ButtonProps = {
  width: string;
  mobileWidth: string;
};

export const Button = styled.button<ButtonProps>`
  width: ${(props) => props.width};
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  box-shadow: ${(props) => props.theme.shadows.modalShadow};
  text-align: center;
  border-radius: ${DesignSystem.BorderRadius.SM};
  font-size: 12px;
  font-weight: medium;
  font-family: Roboto, sans-serif;
  text-transform: capitalize;

  &#no-capitalize {
    text-transform: none;
  }
  
  &.buttonModal{
    height: 38px;
    min-width: 82px;
    //padding: 0px 20px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }
  
  &.small {
    height: 23px;
    min-width: 52px;
    padding: 0px 5px;
    letter-spacing: 0.18px;
  }
  
  &.medium {
    height: 28px;
    min-width: 62px;
    padding: 0px 3px;
    letter-spacing: 0.18px;
  }
  
  &.largeNotUppercase {
    height: 38px;
    min-width: 82px;
    padding-left: 5px;
    letter-spacing: 0.2px;
    text-decoration: underline;
    color: #333333;
  }

  &.header {
    height: 38px;
    min-width: 82px;
    padding: 0px 10px;
    font-size: 13px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    @media screen and (max-width: 960px) {
      font-size: 12px;
      height: 28px;
      flex: 1 0 auto;
      width: auto;
    }
  }
  
  &.large {
    height: 38px;
    min-width: 82px;
    padding: 0px 10px;
    font-size: 13px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    @media screen and (max-width: 960px) {
      font-size: 12px;
      height: 28px;
      &#joyride-create-menu {
        height: 30px;
      }
      width: ${(props) => props.mobileWidth}
    }
  }

  &.extra-large {
    height: 38px;
    min-width: 230px;
    padding: 0px 20px;
    font-size: 13px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    @media screen and (max-width: 960px) {
      font-size: 12px;
      min-width: 100%;
      width: 30vw;
    }
  }

  &.red-solid {
    background: #C21618;
    border: 1px solid ${DesignSystem.Colors.HIGH_PURE};
    color: ${DesignSystem.Colors.HIGH_PURE};
    outline: none;
  }

  &.white-solid {
    background: ${DesignSystem.Colors.HIGH_PURE};
    border: 1px solid ${DesignSystem.Colors.HIGH_PURE};
    color: ${DesignSystem.Colors.PRIMARY_PURE};
    outline: none;
  }

  &.white-solid-with-black-text {
    background: ${DesignSystem.Colors.HIGH_PURE};
    border: 1px solid ${DesignSystem.Colors.HIGH_PURE};
    color: ${DesignSystem.Colors.LOW_PURE};
    outline: none;
    font-weight: bold;
    background-image: url(${invalidIcon});
    background-repeat: no-repeat;
    background-position: 97% center;
    text-align: left;
  }

  &.white-outline {
    background: transparent;
    border: 1px solid ${DesignSystem.Colors.HIGH_PURE};
    color: ${DesignSystem.Colors.HIGH_PURE};
    box-shadow: none;
    outline: none;
  }

  &.white-dark {
    background: transparent;
    border: 1px solid ${(props) => props.theme.colors.boxText};
    color: ${(props) => props.theme.colors.boxText};
    box-shadow: none;
    outline: none;
  }

  &.confirmation-gradient {
    background: ${props => props.theme.colors.backgroundButton};
    color: ${DesignSystem.Colors.HIGH_PURE};
    outline: none;

    @media screen and (max-width: 960px) {
      background: ${DesignSystem.Colors.PRIMARY_LIGHT};
    }
  }

  &.confirmation-gradient-modal {
    background: ${props => props.theme.colors.backgroundButtonModal};
    color: ${DesignSystem.Colors.HIGH_PURE};
    outline: none;
  }

  &.cancellation-gradient {
    background: ${DesignSystem.Colors.CANCELATION_GRADIENT};
    color: ${DesignSystem.Colors.HIGH_PURE};
    outline: none;
  }
  &.cancellationCloseButton{
    background: transparent;
    border: 1px solid ${DesignSystem.Colors.TOAST_WARNING};
    color: ${(props) => props.theme.colors.cancellationButton};
    box-shadow: none;
    outline: none;
    
    @media screen and (min-width: 770px) {
      display: none;
    }
  }

  &.cancellation {
    background: transparent;
    border: 1px solid ${DesignSystem.Colors.TOAST_WARNING};
    color: ${(props) => props.theme.colors.cancellationButton};
    box-shadow: none;
    outline: none;

  }

  &.confirmation-solid {
    background: ${(props) => props.theme.colors.solidButton};
    color: ${DesignSystem.Colors.HIGH_PURE};
    border: 1px solid ${(props) => props.theme.colors.solidButton};
    outline: none;
  }

  &.outline-active {
    background: transparent;
    border: 1px solid ${(props) => props.theme.colors.solidButton};
    color: ${(props) =>
    props.theme.title === 'light'
      ? props.theme.colors.outlineActiveButton
      : DesignSystem.Colors.PRIMARY_LIGHT};
    box-shadow: none;
    outline: none;
  }

  &.outline-inactive {
    background: transparent;
    border: 1px solid ${(props) => props.theme.colors.outlineInactiveButton};
    color: ${(props) =>
    props.theme.title === 'dark'
      ? props.theme.colors.outlineInactiveButton
      : DesignSystem.Colors.LOW_LIGHT};
    box-shadow: none;
    outline: none;
  }

  &.outline-dark {
    background: transparent;
    border: 1px solid transparent;
    color: #004992;
    outline: none;
  }

  &.dark {
    background: ${DesignSystem.Colors.LOW_PURE};
    border: 1px solid ${DesignSystem.Colors.LOW_PURE};
    color: ${DesignSystem.Colors.HIGH_PURE};
    outline: none;
  }
`;
