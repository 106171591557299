import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ScrollState {
  showScroll: boolean;
}

const initialState: ScrollState = {
  showScroll: false,
};

const scrollSlice = createSlice({
  name: 'scroll',
  initialState,
  reducers: {
    setShowScroll(state, action: PayloadAction<boolean>) {
      state.showScroll = action.payload;
    },
  },
});

export const { setShowScroll } = scrollSlice.actions;
export default scrollSlice.reducer;
