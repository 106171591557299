import { createGlobalStyle, css } from 'styled-components';
import { Colors } from './StyleTypes';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Global component for the all application.
 */
interface GlobalStyleProps {
    showScroll?: boolean;
}

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
    * {
        scrollbar-width: thin;
        scrollbar-color: ${Colors.HIGH_PURE} #C9C9C9;
    }

    body {    
        margin: 0%;
        padding: 0%;
        max-height: 100vh;
        font-family: 'Roboto', sans-serif;
        color: ${(props) => props.theme.colors.textPrimary};
        font-size: 14px;  
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 100vh;
        height: 100dvh;
        width: 100vw;
        width: 100dvw;
        overflow: hidden;      
        background-color: ${(props) => props.theme.colors.background};
        @media screen and (max-width: 960px) {
            iframe[title='Botão para abrir a janela de mensagens'] {
                display: none;
            }
        }
        #zendesk {
            display: none;
        }
        #ze-snippet {
      /* Exemplo: Alterar a cor de fundo para azul */
      background-color: #dbb434;
      width: 100px;

      #gHHEeh {
        width: 10px;
      }
    }

    #root {
        @media screen and (max-width: 960px) {
            height: 100vh;
            height: 100dvh;
            width: 100vw;
            width: 100dvw;
        }
    }

    .gHHEeh {
      width: 10px;
    }

    /* Exemplo: Alterar a cor do texto para branco */
    #ze-snippet .ze-widget-container {
      color: #c41212;
    }
        
    }

    // test ios
    input, textarea, select {
        font-size: 14px;
    }

    .fGdDiY {
            width: 10px !important
        }
        
    /* Works on Chrome, Edge, and Safari */
    ::-webkit-scrollbar {
        width: 5px;
        border-radius: 5px;
    }
    
    ::-webkit-scrollbar-track {
        background: ${Colors.LOW_LIGHT};
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: ${Colors.HIGH_PURE};
        border-radius: 5px;
        border: 1px solid ${Colors.HIGH_PURE};
    }
      
    /* Hide scrollbar for IE, Edge and Firefox */
    ${(props) => !props.showScroll &&
        css`
            * {
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */
            }
        `
    }
    

    .Toastify__toast-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: auto;
        text-align: center;
        line-height: 1.5;

        @media (max-width: 480px) {
            width: 100%; 
        }
      }
    .Toastify__toast-icon {
        display: none !important;
    }
    .Toastify__toast-body {
        margin: 10px !important;
    }
    .Toastify__toast--success {
        background: #048243 !important;
        color: white !important;
    }
    .Toastify__toast--warning {
        background: yellow;
    }
    .Toastify__toast--error {
        background: #c21618 !important;
        color: white !important;
    }
    .Toastify__toast--info {
        background: #5DAEFF !important;
        color: white !important;
    }


    /**********************************/
/**********************************/
/********* ZENDESK WIDGET *********/
/**********************************/
/**********************************/

body>div:not(#root)>div>div {
	overflow: unset !important;
}

/* Chat Content */
body>div:not(#root)>div>div>iframe {
	// padding: 10px;
	box-shadow: 5px 0px 10px #00000080;
	border-radius: 10px;
}

/* Button Open Chat Content */
body>div:not(#root)>div>iframe {
	border-radius: 10px;
}

@media screen and (max-width: 1440px) {

	/* Chat Content */
	body>div:not(#root)>div>div>iframe {
		// padding: 10px;
		box-shadow: 5px 0px 10px #00000080;
		border-radius: 10px;
	}
}

@media screen and (max-device-width: 480px) {
	body>div:not(#root)>div>div:not(.MuiPaper-root):not(.no-hide) {
		bottom: 50px !important;
		display: none !important;
	}

	/* Button Open Chat Content */
	body>div:not(#root)>div>iframe {
		bottom: 50px !important;
		display: none !important;
	}

	body.show-chat>div:not(#root)>div>div {
		bottom: 55px !important;
		display: block !important;
	}

	body.show-chat>div:not(#root)>div>div>iframe {
		box-shadow: 0 !important;
		border-radius: 0px !important;
		z-index: 999 !important;
	}

	/* Button Open Chat Content */
	body.show-chat>div:not(#root)>div>iframe {
		bottom: 55px !important;
		display: block !important;
		z-index: 999 !important;
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
	body>div:not(#root)>div>div:not(.MuiPaper-root):not(.no-hide) {
		display: none !important;
	}

	/* Button Open Chat Content */
	body>div:not(#root)>div>iframe {
		display: none !important;
	}

	body.show-chat>div:not(#root)>div>div {
		bottom: 55px !important;
		display: block !important;
	}

	body.show-chat>div:not(#root)>div>div>iframe {
		box-shadow: 0 !important;
		border-radius: 0px !important;
		z-index: 999 !important;
	}

	/* Button Open Chat Content */
	body.show-chat>div:not(#root)>div>iframe {
		bottom: 55px !important;
		display: block !important;
		z-index: 999 !important;
	}
}
`;

export default GlobalStyle;
