import { LOCALES } from '../constants';
const zh = {
  [LOCALES.ZH]: {
    //LOGIN
    "login.Login": "登录",
    "login.Informe seu e-mail": "输入您的电子邮件",
    "login.E-mail inválido. Por favor, verifique": "无效的电子邮件。请检查并重试",
    "login.Senha": "密码",
    "login.Sua senha deve possuir no mínimo 6 caracteres": "您的密码必须至少有6个字符",
    "login.Permanecer conectado": "保持连接",
    "login.Esqueci minha senha": "忘记密码",
    "login.ENTRAR": "进入",
    "login.CRIAR CONTA": "创建账户",
    "login.Não foi possÍvel fazer o login. Verifique seus dados e tente novamente": "无法登录。检查您的数据并重试",
    "login.Solicitar Acesso": "请求访问",
    "login.Utilize esta opção caso possua apenas fornos da versão antiga, que não geram PIN.": "如果您只有旧版本的烤箱，不生成PIN，请使用此选项。",
    "login.A PLATAFORMA IOK MUDOU!": "IOK平台已更改！",
    "login.Não exibir este aviso novamente": "不再显示此通知",
    "login.Se você já possui um cadastro na antiga plataforma,": "如果您已在旧平台上注册，",
    "login.clique aqui": "点击这里",
    "login. para migrar seus dados e aproveitar toda a experiência IOK.": "迁移您的数据，享受全面的IOK体验。",
    "login.Sua conta não foi ativada. Por gentileza, verifique seu e-mail para realizar a ativação": "您的账户尚未激活。请验证您的电子邮件以激活它",
    "login.Para solicitar acesso a uma organização, o nome informado por você deve ser exatamente o mesmo cadastrado pelo administrador da conta. Em caso de dúvida, procure o responsável pela rede.": "要请求对组织的访问权限，您提供的名称必须与由账户管理员注册的名称完全相同。如有疑问，请搜索负责的机构。",
    "login.Se você já possui um cadastro na antiga plataforma, ": "如果您已经在旧平台注册, ",
    "login. para": " 为了 ",
    "login.migrar": "迁移 ",
    "login.seus dados e aproveitar toda a experiência IOK.": "您的数据并享受整个 IOK 体验。",
    "login.PLATAFORMA EM MANUTENÇÃO": "平台维护中",
    "login.ESTAMOS PREPARANDO A NOVA PLATAFORMA IOK E EM BREVE ELA ESTARÁ PRONTA!": "我们正在准备新的 IOK 平台，很快就会准备就绪！",
    "login.Migrar Conta": "迁移账户",
    "login.Se você já possui um cadastro na antiga plataforma, clique aqui em Migrar Conta para migrar seus dados e aproveitar toda a experiência IOK": "如果您已经在旧平台上注册，请单击此处迁移帐户以迁移您的数据并享受整个 IOK 体验",
    "login.Política de privacidade": "隐私政策",
    "login.ENTENDI": "好的",
    "login.Para melhorar a sua experiência na plataforma e oferecer serviços personalizados, utilizamos cookies.": "我们使用 cookie 来改善您在平台上的体验并提供个性化服务。",
    "login.Ao utilizar a Plataforma IOK, você automaticamente concorda com seu uso.": "使用 IOK 平台即表示您自动同意其使用。",

    //REGISTER
    "DISTRIBUIDOR": "经销商：我是练习设备的经销商。",
    "REDE": "网络：我拥有一个网络。",
    "DONO": "公司：我拥有自己的公司/我是网络的一部分。",
    "register.Informe seu e-mail cadastrado na plataforma IOK anterior": "输入您在旧IOK平台上注册的电子邮件",
    "register.E-mail inválido. Por favor, verifique": "无效的电子邮件。请检查。",
    "register.BUSCAR DADOS": "搜索数据",
    "register.Nome da organização": "组织名称",
    "register.Informe o nome de sua organização": "输入您的组织名称",
    "register.O campo nome da organização é obrigatório": "组织名称字段是必需的",
    "register.Este nome já existe. Por favor, escolha outro": "此名称已存在。请选择另一个",
    "register.RECUPERAR CONTA": "检索账户",
    "register.E-mail não encontrado": "电子邮件未找到",
    "register.Digite seu e-mail da plataforma IOK antiga": "输入您在旧IOK平台上的电子邮件",
    "register.Não foi possível recuperar sua conta. Verifique seus dados e tente novamente": "无法检索您的账户。验证您的数据并重试",
    "register.Não foi possível recuperar seus dados. Verifique e tente novamente": "无法检索您的数据。验证并重试",
    "register.PROSSEGUIR": "继续",
    "register.VOCÊ ESTÁ SOLICITANDO ACESSO PARA A ORGANIZAÇÃO": "您正在请求访问组织：",
    "register.COM O ADMINISTRADOR": "有管理员：",
    "register.E-mail já cadastrado na plataforma. Informe um novo e-mail ou acesse a plataforma com o e-mail já cadastrado": "在平台上已注册的电子邮件。输入新的电子邮件或使用已注册的电子邮件访问平台。",
    "register.Empresa já cadastrada na plataforma. Por favor, informe o nome de uma nova empresa": "公司已在平台上注册。请输入一个新的公司名称",
    "register.VOCÊ NÃO RECONHECEU O USUÁRIO {userName} COMO PARTE DA SUA ORGANIZAÇÃO E POR ISSO A CONTA DELE FOI DELETADA.": "您没有识别用户 {userName} 作为您组织的一部分，因此他们的账户已被删除。",
    "register.NÂO FOI POSSÍVEL DELETAR O USÁRIO {userName}. POR GENTILEZA, TENTE NOVAMENTE MAIS TARDE.": "无法删除用户 {userName}。请稍后重试。",
    "register.CONFIRMAR": "确认",
    "register.Informe o ID da organização": "填写您组织的ID",
    "register.O campo com ID é obrigatório": "ID字段是必需的",
    "register.Ainda não tenho uma organização. Criar": "我还没有组织。创建",
    "register.ID informado não corresponde a nenhuma companhia. Verifique e tente novamente": "提供的ID与任何公司都不匹配。请验证并重试",
    "register.O ADMINISTRADOR DA ORGANIZAÇÃO RECEBERÁ UM E-MAIL PARA ATIVAR SUA CONTA. POR GENTILIZA, AGUARDE": "组织管理员将收到一封激活您用户账户的电子邮件。请等待",
    "register.Este nome de franquia já possui uma conta ativa na plataforma": "此特许经营名称在平台上已有一个活跃的账户",
    "register.Não foi possível fazer o cadastro. Verifique seus dados e tente novamente": "无法注册。验证您的数据并重试。",
    "register.VOCÊ RECEBERÁ UM E-MAIL PARA CONCLUIR SEU CADASTRO E ATIVAR SUA CONTA DE ADMINISTRADOR": "您将收到一封电子邮件完成注册并激活您的管理员账户",
    "register.Nome": "名称",
    "register.Informe seu nome": "输入您的姓名",
    "register.O campo nome é obrigatório": "名称字段是必需的",
    "register.E-mail": "电子邮件",
    "register.Informe seu e-mail": "输入您的电子邮件",
    "register.E-mail inválido. Por favor, verifique novamente": "无效的电子邮件。请检查",
    "register.Telefone": "电话号码",
    "register.Informe seu telefone": "输入您的电话号码",
    "register.Crie sua senha": "创建密码",
    "register.Informe sua senha": "输入您的密码",
    "register.O campo senha deve conter no mínimo 6 caracteres": "密码字段必须至少有6个字符",
    "register.Confirme sua senha": "确认密码",
    "register.As senhas devem ser iguais": "密码必须匹配",
    "register.CRIAR CONTA": "创建账户",
    "register.CONTA ATIVADA COM SUCESSO": "账户已成功激活",
    "register.ACESSAR": "访问",
    "register.NÃO FOI POSSÍVEL ATIVAR SUA CONTA": "无法激活您的账户",
    "register.Digite seu telefone": "提供您的电话号码",
    "register.AGUARDE ENQUANTO TRANSFERIMOS SEUS DADOS. ESTE PROCESSO DEVE LEVAR ALGUNS MINUTOS. CASO VOCÊ POSSUA UMA GRANDE QUANTIDADE DE DADOS, A TRANSFERÊNCIA PODE LEVAR ATÉ UMA HORA.": "我们正在传输您的数据，请稍候。此过程应该需要几分钟。如果您有大量数据，传输可能需要长达一小时的时间。",
    "register.DADOS TRANSFERIDOS COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "数据成功传输！点击下面的按钮访问平台。",
    "register.EMAIL INVÁLIDO. POR GENTILEZA, VERIFIQUE SE SUA CONTA JÁ FOI ATIVADA.": "无效的电子邮件。请检查您的账户是否已激活。",
    "register.A nova plataforma IOK agora conta com novas formas de acesso - que são referentes ao tipo de organização em que você atua. O responsável pela sua organização deve migrar a conta principal da empresa, se tornando o administrador, enquanto o restante dos funcionários devem se vincular a ela pelo ID fornecido.": "新的IOK平台现在有了新的访问方式 - 与您所在的组织类型有关。负责您组织的人必须迁移公司的主账户，成为管理员，而其余员工必须使用提供的ID链接到它。",
    "register.O administrador da minha organização já realizou a migração da conta e temos um ID. Desejo migrar meus dados de usuário.": "我的组织管理员已经迁移了账户，我们有一个ID。我想迁移我的用户数据。",
    "register.Desejo migrar a conta da minha organização e me tornar o administrador.": "我想迁移我的组织账户并成为管理员。",
    "register.Conta da organização já migrada": "组织账户已经迁移",
    "register.Migrar conta da organização": "迁移组织账户",
    "register.VOCÊ POSSUI MUITOS MENUS, NÃO CONSEGUIMOS REALIZAR A IMPORTAÇÃO AUTOMÁTICA. SUA CONTA SERÁ ATIVADA, PORÉM OS MENUS PRECISARÃO SER MIGRADOS MANUALMENTE.": "您有很多菜单，我们无法执行自动导入。您的帐户将被激活，但需要手动迁移菜单。",
    "register.PARA MIGRAR SEUS MENUS, APÓS O LOGIN, CLIQUE NO SEU NOME NO CANTO SUPERIOR DIREITO, CLIQUE EM EDITAR CONTA E DEPOIS EM IMPORTAR MENUS.": "要迁移您的菜单，请在登录后单击右上角您的姓名，单击“编辑帐户”，然后单击“导入菜单”。",
    "register.Atenção:": "注意：",
    "register.apenas uma pessoa da organização pode criar a conta e, depois de ativá-la, convidar os demais usuários da empresa para acessá-la.": "只有组织中的一人可以创建账户，并在激活后邀请公司的其他用户访问它。",
    "register.TOKEN DESENVOLVEDOR CANCELADO": "开发者令牌已取消",

    //HOME
    "home.Menus disponíveis na plataforma": "平台上的可用菜单",
    "home.Criados na plataforma": "在平台上创建",
    "home.Recebidos dos fornos": "从烤箱接收",
    "home.Equipamentos vinculados": "关联设备",
    "home.Total de receitas cadastradas": "注册的食谱总数",
    "home.Receitas": "食谱",
    "home.Receitas em menus": "菜单中的食谱",
    "home.Em menus": "在菜单中",
    "home.Receitas salvas no Cookbook": "保存于食谱中的食谱",
    "home.No Cookbook": "在食谱中",
    "home.Atualizações": "更新",
    "home.Copa": "Copa",
    "home.Rocket": "Rocket",
    "home.Single Mag": "Single Mag",
    "home.Versão instalada": "已安装版本",
    "home.Versão disponível": "可用版本",
    "home.Usuários cadastrados": "注册用户",
    "home.Aceitação pendente": "待接受",
    "home.Atualizado": "已更新",
    "home.OLÁ": "你好",
    "home.É muito bom ter você aqui!": "很高兴你能来到这里！",
    "home.Com a Plataforma IOK é possível administrar seus fornos de onde estiver, criar e gerenciar receitas e menus, fazer backups, analisar os dados de seus equipamentos e muito mais!": "借助 IOK 平台，您可以随时随地管理烤箱、创建和管理食谱和菜单、进行备份、分析设备数据等等！",
    "home.Conheça a plataforma": "发现平台",
    "home.Taxa de erro por modelo": "每个模型的错误率",
    "home.Erro": "错误",
    "home.Modelos em evidência": "突出型号",
    "home.Modelos": "型号",
    "home.Total de equipamentos vinculados": "关联设备总数",
    "home.Total de equipamentos online": "在线设备总数",
    "home.Equipamentos online": "在线设备",
    "home.Países com Prática": "拥有Prática的国家",
    "home.Países": "国家",
    "home.Aguarde enquanto carregamos os dados": "请稍候，我们正在加载数据",
    "home.Quase lá! Aguarde um pouco mais": "快好了！请再稍等一下",
    "home.QUEREMOS SABER SUA OPINIÃO SOBRE": "我们想知道您的意见",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a Plataforma {model} foi criado!": "我们非常重视您的观点，因此，在 {model} 平台上创建了这份满意度调查表！",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a linha {model} foi criado!": "我们非常重视您的观点，因此创建了这份关于 {model} 系列的满意度调查表！",
    "home.Suas respostas nos ajudarão a aprimorar nossos serviços, garantindo que continuemos a oferecer a você a melhor experiência possível.": "您的回复将帮助我们改进服务，确保我们继续为您提供最佳体验。",
    "home.Reserve uns minutinhos de seu dia e participe!": "每天抽出几分钟来参与！",
    "home.Responder a pesquisa": "回答调查",
    "home.Vendas por Equipamento": "按设备销售",
    "home.Total de equipamentos vendidos": "设备总销量",
    "home.Equipamentos vendidos": "已售设备",

    //MAIN
    "main.HOME": "主页",
    "main.COOKBOOK": "食谱书",
    "main.MEUS MENUS": "我的菜单",
    "main.EQUIPAMENTOS": "设备",
    "main.USUÁRIOS": "用户",
    "main.ATENDIMENTOS": "服务",
    "main.Idioma": "语言",
    "main.Editar conta": "编辑账户",
    "main.Configurações": "设置",
    "main.Sair": "退出",
    "main.Configurações salvas com sucesso!": "设置成功保存!",
    "main.SALVAR": "保存",
    "main.CONFIGURAÇÕES": "设置",
    "main.Tema dark": "深色主题",
    "main.Notificações": "通知",
    "main.Informações para as receitas": "食谱信息",
    "main.Peso:": "重量",
    "main.Gramas": "克",
    "main.Oz": "盎司",
    "main.Temperatura:": "温度：",
    "main.Celsius (°C)": "摄氏度（°C）",
    "main.Fahrenheit (°F)": "华氏度（°F）",
    "main.Conta editada com sucesso!": "账户编辑成功!",
    "main.EDITAR CONTA": "编辑账户",
    "main.Nome": "名称",
    "main.Preencha com o seu nome": "填写您的姓名",
    "main.Telefone": "电话",
    "main.Preencha com o seu telefone": "填写您的电话号码",
    "main.E-mail": "电子邮件",
    "main.Preencha com o seu e-mail": "填写您的电子邮件",
    "main.Alterar Senha": "更改密码",
    "main.ALTERAR SENHA": "更改密码",
    "main.Senha atual": "当前密码",
    "main.Nova senha": "新密码",
    "main.Confirmar nova senha": "确认新密码",
    "main.Informe sua senha atual": "输入您的当前密码",
    "main.O campo senha deve conter no mínimo 6 caracteres": "密码字段必须至少有6个字符",
    "main.Informe sua nova senha": "输入您的新密码",
    "main.Confirme sua nova senha": "确认您的新密码",
    "main.Sua nova senha deve conter no mínimo 6 caracteres": "您的新密码必须至少包含6个字符",
    "main.INFORME SUA SENHA": "输入您的密码",
    "main.Informe sua senha para realizar a alteração de e-mail da plataforma:": "输入您的密码以更改平台电子邮件：",
    "main.CONFIRMAR": "确认",
    "main.Existem campos que não foram preenchidos corretamente": "有字段填写不正确",
    "main.Informe sua senha para prosseguir com a alteração de e-mail": "输入您的密码以继续更改电子邮件",
    "main.Senha alterada com sucesso": "密码成功更改",
    "main.Informe sua senha atual corretamente": "正确输入您的当前密码",
    "main.As senhas devem ser iguais": "密码必须匹配",
    "main.Nome do novo tipo de usuário": "新用户类型名称",
    "main.Dê um nome para o novo tipo de usuário": "为新用户类型命名",
    "main.Token Desenvolvedor": "开发者令牌",
    "main.O Token Desenvolvedor é um código único e intransferível que permite a realização de alterações": "开发者令牌是一种独特且不可转让的代码，允许通过外部 API 直接在平台代码中进行开发级别的更改。它们使平台能够定制以更好地满足您公司的需求。",
    "main.É recomendado que estas alterações sejam realizadas por um profissional da área de tecnologia": "建议由技术领域的专业人员进行这些更改。",
    "main.Para acessar o token, informe sua senha": "要访问令牌，请提供您的密码。",
    "main.Copie e cole o token na autenticação de todas as requisições.": "将令牌复制并粘贴到所有请求的身份验证中。",
    "main.Seu Token": " 您的令牌",
    "main.Token copiado com sucesso!": "令牌已成功复制！",
    "main.Você deseja cancelar o preenchimento das informações?": "您要取消填写信息吗？",
    "main.Rever tutoriais": "重新查看教程",
    "main.Tutoriais resetados com sucesso": "教程已成功重置",
    "main.review.tutorials": "您是否要重新查看IOK平台上的教程?",
    "main.Procurar": "搜索",

    //MENUS
    "menus.Procurar menu": "搜索菜单或用户",
    "menus.Procurar menu excluído": "搜索已删除菜单",
    "menus.Menus": "菜单",
    "menus.Lixeira": "垃圾桶",
    "menus.CRIAR MENU": "创建菜单",
    "menus.IMPORTAR MENU": "导入菜单",
    "menus.Os arquivos serão mantidos na lixeira por 30 dias a contar de sua data de exclusão. Após este período, eles serão excluídos definitivamente.": "文件将在删除日期起的30天内保留在垃圾桶中。此期满后，它们将被彻底删除。",
    "menus.VOLTAR": "返回",
    "menus.Compatível com: ": "兼容性: ",
    "menus.Última atualização: ": "最后更新: ",
    "menus.Última atualização": "最后更新",
    "menus.EXPORTAR MENU": "导出菜单",
    "menus.EDITAR MENU": "编辑菜单",
    "menus.Equipamentos associados": "关联设备",
    "menus.Grupos": "群组",
    "menus.Procurar grupo": "搜索群组",
    "menus.CRIAR GRUPO": "创建群组",
    "menus.receitas": "食谱",
    "menus.Nenhum grupo cadastrado": "未注册任何群组",
    "menus.Nenhum menu cadastrado": "未注册任何菜单",
    "menus.Nome": "名称",
    "menus.Modelo": "型号",
    "menus.Atividade": "活动",
    "menus.Associação": "关联",
    "menus.Exportar": "导出",
    "menus.Editar": "编辑",
    "menus.Excluir": "删除",
    "menus.Equips": "装备",
    "menus.Receitas": "食谱",
    "menus.Compatibilidade": "兼容性",
    "menus.Versão": "版本",
    "menus.Origem": "来源",
    "menus.Excluído por: ": "删除者: ",
    "menus.Restaurar": "恢复",
    "menus.Existem campos que não foram preenchidos": "有未填写的字段",
    "menus.Menu criado com sucesso!": "成功创建菜单!",
    "menus.Existem campos com informações inválidas": "存在包含无效信息的字段",
    "menus.SALVAR": "保存",
    "menus.CANCELAR": "取消",
    "menus.Nome do menu:": "菜单名称: ",
    "menus.Informe o nome do menu": "填写菜单名称",
    "menus.Preencha o nome do menu sem caracteres especiais": "使用无特殊字符填写菜单名称",
    "menus.Selecionar": "选择",
    "menus.Idioma": "语言",
    "menus.Temperatura de pré-aquec 1:": "预热温度1:",
    "menus.Informe a temperatura de pré-aquec 1": "填写预热温度1",
    "menus.Temperatura de pré-aquec 2:": "预热温度2:",
    "menus.Informe a temperatura de pré-aquec 2": "填写预热温度2",
    "menus.Tempo para estabilização:": "稳定时间:",
    "menus.Informe o tempo para estabilização": "填写稳定时间",
    "menus.Desativar pré-aquec 2": "停用预热2",
    "menus.Configurações de data e hora": "日期和时间设置",
    "menus.Data:": "日期:",
    "menus.DD/MM/AAAA": "DD/MM/YYYY",
    "menus.Hora:": "时间:",
    "menus.24h": "24小时制",
    "menus.AM/PM": "AM/PM",
    "menus.EUR: DD/MM/AAAA": "EUR: DD/MM/YYYY",
    "menus.USA: MM/DD/AAAA": "美国: MM/DD/YYYY",
    "menus.ISO: AAAA/MM/DD": "ISO: YYYY/MM/DD",
    "menus.Seleção de acessos para habilitar": "选择启用的访问方式",
    "menus.Modo manual": "手动模式",
    "menus.Habilitado": "启用",
    "menus.Desabilitado": "禁用",
    "menus.Favoritos": "收藏",
    "menus.Aquecer/Dourar": "加热/褐化",
    "menus.Repetir receita": "重复食谱",
    "menus.Configurações gerais do menu": "菜单的常规设置",
    "menus.Temperatura": "温度",
    "menus.Temperatura:": "温度:",
    "menus.Celsius (°C)": "摄氏度 (°C)",
    "menus.Fahrenheit (°F)": "华氏度 (°F)",
    "menus.Peso": "重量",
    "menus.Gramas": "克",
    "menus.Oz": "盎司",
    "menus.Tema:": "主题:",
    "menus.Dark": "深色",
    "menus.Light": "浅色",
    "menus.Introdução:": "介绍:",
    "menus.Ativada": "已激活",
    "menus.Desativada": "已停用",
    "menus.Menu deletado com sucesso!": "成功删除菜单!",
    "menus.Não foi possivel deletar o menu": "无法删除菜单",
    "menus.Sim": "是",
    "menus.Não": "否",
    "menus.Excluir definitivamente": "永久删除",
    "menus.SALVAR EM PEN DRIVE": "保存到闪存驱动器",
    "menus.EXPORTAR": "导出",
    "menus.Selecione os modelos e versões dos equipamentos que receberão o menu.": "选择接收菜单的设备型号和版本",
    "menus.Forno": "烤箱",
    "menus.Fundo verde": "绿色背景",
    "menus.Fundo preto": "黑色背景",
    "menus.Linux": "Linux",
    "menus.Atenção: antes de transferir o menu de seu pen drive para o equipamento, é necessário descompactar o arquivo, exceto para menus de equipamentos Linux.": "注意：在将菜单从闪存驱动器传输到设备之前，需要解压文件，除了 Linux 设备的菜单。",
    "menus.Selecione os equipamentos para os quais deseja exportar o menu.": "选择要导出菜单的设备。",
    "menus.Nome do equipamento": "设备名称",
    "menus.Selecionar todos": "全选",
    "menus.Selecionar todas": "全选",
    "menus.Categoria: ": "类别: ",
    "menus.Você deseja excluir o menu?": "您是否希望删除菜单？",
    "menus.O menu será deletado da plataforma, mas permanecerá no equipamento. Uma cópia de segurança será criada automaticamente e ficará disponível por 30 dias em Meus Menus - Lixeira.": "菜单将从平台上删除，但将保留在设备上。系统将自动创建一个安全备份，并在“我的菜单 - 垃圾桶”中保留30天。",
    "menus.Você deseja excluir o arquivo definitivamente?": "您是否希望永久删除该文件？",
    "menus.Esta ação não poderá ser desfeita.": "此操作无法撤销。",
    "menus.Menu editado com sucesso!": "菜单编辑成功!",
    "menus.IMPORTAR": "导入",
    "menus.VERIFICAÇÃO DE VERSÃO": "版本验证",
    "menus.Para conferir a versão da placa de seu equipamento, siga os seguintes passos:": "要检查设备的主板版本，请按以下步骤操作：",
    "menus.No forno, acesse Configurações;": "在烤箱中，访问设置;",
    "menus.Insira a senha 456789;": "输入密码456789;",
    "menus.Selecione a opção Sistema;": "选择系统选项;",
    "menus.Selecione Informações do Fabricante;": "选择制造商信息;",
    "menus.O modelo de sua placa estará na linha Firmware IHM;": "您的主板型号将在固件IHM行中显示;",
    "menus.1ª geração A": "第一代 A",
    "menus.Firmware IHM: 12.3.4": "固件IHM: 12.3.4",
    "menus.1ª geração B": "第一代 B",
    "menus.2ª geração/Linux:": "第二代/Linux",
    "menus.Firmware IHM: L1.2.3": "固件IHM: L1.2.3",
    "menus.Selecione o modelo/versão do equipamento do qual deseja importar o menu. Saiba como conferir a versão de seu equipamento": "选择您希望导入菜单的设备型号/版本。了解如何检查您设备的版本 ",
    "menus.Selecione o modelo/versão do equipamento": "选择设备型号/版本",
    "menus.Como verificar a versão da placa": "如何检查主板版本",
    "menus.1° Geração": "第一代",
    "menus.Menu restaurado com sucesso!": "成功恢复菜单!",
    "menus.Não foi possivel restaurar o menu": "无法恢复菜单",
    "menus.RESTAURAR ARQUIVO": "恢复文件",
    "menus.Você deseja restaurar o arquivo?": "您是否希望恢复文件？",
    "menus.O arquivo será restaurado como uma nova versão na plataforma, na guia Todos. Para salvá-lo no equipamento, exporte-o.": "该文件将在平台上的“全部”选项卡下恢复为新版本。要将其保存在您的设备上，请将其导出。",
    "menus.EXCLUIR MENU": "删除菜单",
    "menus.Nenhum equipamento associado": "没有关联设备",
    "menus.Nomeie o novo menu": "命名新菜单",
    "menus.Nomeie o novo grupo": "命名新群组",
    "menus.Nome do grupo:": "群组名称: ",
    "menus.Preencha o nome do grupo sem caracteres especiais": "使用无特殊字符填写群组名称",
    "menus.Pré-aquecimento:": "预热:",
    "menus.Pré-aquecimento": "预热",
    "menus.Grupo criado com sucesso!": "成功创建群组!",
    "menus.SELECIONE UMA IMAGEM": "选择图像",
    "menus.SELECIONAR": "选择",
    "menus.Selecionar do computador": "从计算机中选择",
    "menus.Selecionar do banco de imagens": "从图像库中选择",
    "menus.Formato .JPG ou .PNG": ".JPG 或 .PNG 格式",
    "menus.Procurar imagem": "搜索图像",
    "menus.Pertence ao menu": "属于菜单",
    "menus.Alterar imagem": "更改图像",
    "menus.Remover imagem": "移除图像",
    "menus.EDITAR GRUPO": "编辑群组",
    "menus.CRIAR RECEITA": "创建食谱",
    "menus.SALVAR DO COOKBOOK": "从食谱保存",
    "menus.Procurar receita do grupo": "搜索群组的食谱",
    "menus.Ingrediente principal": "主要成分",
    "menus.Mover receitas": "移动食谱",
    "menus.Excluir receitas": "删除食谱",
    "menus.Editar grupo": "编辑群组",
    "menus.Excluir grupo": "删除群组",
    "menus.aqui": "这里",
    "menus.Se o código possuir apenas números e o fundo da tela for verde, pertence à 1ª A, se possuir apenas números e o fundo da tela for preta, pertence à 1ª B e, se começar com as letras L, F ou S, sua placa pertence à 2ª geração/Linux.": "如果代码只有数字且背景是绿色，它属于第一代A；如果代码只有数字且背景是黑色，它属于第一代B；如果以字母L、F或S开头，它的主板属于第二代/Linux。",
    "menus.1ª A - Fundo verde": "第一代A - 绿色背景",
    "menus.1ª B - Fundo preto": "第一代B - 黑色背景",
    "menus.2ª - Linux": "第二代 - Linux",
    "menus.Tivemos um problema. Tente novamente mais tarde.": "我们遇到了问题。请稍后再试。",
    "menus.Grupo editado com sucesso!": "群组编辑成功!",
    "menus.AINDA NÃO HÁ GRUPOS AQUI :(": "这里仍然没有群组 :(",
    "menus.NENHUM MENU NA LIXEIRA": "垃圾桶中没有菜单",
    "menus.AINDA NÃO HÁ MENUS AQUI :(": "这里仍然没有菜单 :(",
    "menus.Adicionar imagem": "添加图像",
    "menus.Nome da receita": "食谱名称",
    "menus.Origem da receita": "食谱来源",
    "menus.Detalhes da receita": "食谱详情",
    "menus.Programação da receita": "食谱编程",
    "menus.Nomeie sua receita": "命名您的食谱",
    "menus.Preencha o nome da receita sem caracteres especiais": "使用无特殊字符填写食谱名称",
    "menus.Tipo de prato": "餐点类型",
    "menus.SALVAR RECEITAS DO COOKBOOK": "从食谱保存食谱",
    "menus.Selecione as receitas que deseja salvar no grupo de seu menu:": "选择要保存在菜单群组中的食谱：",
    "menus.Procurar receita": "搜索食谱",
    "menus.Grupo deletado com sucesso!": "成功删除群组!",
    "menus.Não foi possivel deletar o grupo": "无法删除群组",
    "menus.EXCLUIR GRUPO": "删除群组",
    "menus.Você deseja excluir o grupo definitivamente?": "您是否希望永久删除该群组？",
    "menus.AINDA NÃO HÁ RECEITAS AQUI :(": "这里仍然没有食谱 :(",
    "menus.Temp. de entrada": "起始温度",
    "menus.Temp. de pré-aquec": "预热温度",
    "menus.Ingredientes para montagem": "组装材料",
    "menus.Instruções operacionais": "操作说明",
    "menus.Passo": "步骤",
    "menus.Temp": "温度 ",
    "menus.Temp.": "温度. ",
    "menus.Tempo": "时间",
    "menus.Velocidade do ar": "风速",
    "menus.Micro-ondas": "微波炉",
    "menus.Resistência inferior": "低阻",
    "menus.Informações": "信息",
    "menus.Digite as informações do passo": "输入步骤信息",
    "menus.desativar": "停用",
    "menus.Ingredientes": "成分",
    "menus.Temperatura de entrada": "起始温度",
    "menus.Equipamentos compatíveis": "兼容设备",
    "menus.SALVAR NO COOKBOOK": "保存到食谱",
    "menus.ver localização": "查看位置",
    "menus.SELECIONE RECEITAS PARA EXCLUIR": "选择要删除的食谱",
    "menus.Você deseja excluir as receitas selecionadas definitivamente": "您是否希望永久删除所选的食谱？",
    "menus.Receitas deletadas com sucesso!": "成功删除食谱!",
    "menus.Informe aqui os ingredientes para montagem": "在这里填写组装的成分",
    "menus.Informe aqui as instruções operacionais": "在这里填写操作说明",
    "menus.Adicionar passo": "添加步骤",
    "menus.Receita criada com sucesso!": "成功创建食谱!",
    "menus.Ideal para:": "适用于：",
    "menus.editar detalhes": "编辑详情",
    "menus.excluir": "删除",
    "menus.editar": "编辑",
    "menus.Receita editada com sucesso!": "成功编辑食谱!",
    "menus.Passo deletado com sucesso!": "成功删除步骤!",
    "menus.Não foi possivel deletar o passo": "无法删除步骤",
    "menus.EXCLUIR PASSO": "删除步骤",
    "menus.Você deseja excluir este passo": "您是否希望删除这个步骤？",
    "menus.Esta ação não poderá ser desfeita. Caso prefira, é possível apenas desativá-lo, mantendo assim sua configuração.": "此操作无法撤销。如果您愿意，可以选择仅停用它，保留其设置。",
    "menus.Plataforma": "平台",
    "menus.Receita salva com sucesso!": "成功保存食谱!",
    "menus.Exportar receitas": "导出食谱",
    "menus.expandir": "展开",
    "menus.Vapor combinado": "蒸汽组合",
    "menus.Ar quente": "热空气",
    "menus.Sonda": "探针",
    "menus.Tempo (min)": "时间（分钟）",
    "menus.Umidade (%)": "湿度（%）",
    "menus.Injeção de vapor": "注入蒸汽",
    "menus.Dumper aberto": "打开排水孔",
    "menus.Informe o peso": "填写重量",
    "menus.Informe a temperatura de entrada": "填写起始温度",
    "menus.Informe os ingredientes para montagem": "输入组装材料",
    "menus.Informe as instruções operacionais": "输入操作说明",
    "menus.Injeção de vapor (seg)": "注入蒸汽（秒）",
    "menus.Aquecer mais": "更多加热",
    "menus.Dourar mais": "更多褐色",
    "menus.Aquecer e dourar mais": "更多加热和褐色",
    "menus.Favoritar receita": "喜爱的食谱",
    "menus.Informe o tempo para aquecer mais de 0 a 59 segundos": "输入加热更多的时间，范围从0到59秒",
    "menus.Informe o tempo para dourar mais de 0 a 59 segundos": "输入褐色更多的时间，范围从0到59秒",
    "menus.Informe o tempo para aquecer e dourar mais de 0 a 59 segundos": "输入加热和褐色更多的时间，范围从0到59秒",
    "menus.Apenas favoritos": "仅收藏",
    "menus.Forno combinado": "组合烤箱",
    "menus.Cocção múltipla": "多烹饪",
    "menus.Receitas Technicook": "Technicook 食谱",
    "menus.Editar grupos": "编辑群组",
    "menus.Informe a data": "输入日期",
    "menus.Informe a hora": "输入时间",
    "menus.até 300": "最多300",
    "menus.Vapor": "蒸汽",
    "menus.Turbina": "涡轮",
    "menus.Criar grupo": "创建群组",
    "menus.MOVER": "移动",
    "menus.Configurações de acessos": "访问设置：",
    "menus.SELECIONE RECEITAS PARA MOVER": "选择要移动的食谱",
    "menus.AVANÇAR": "前进",
    "menus.Selecione ou crie um grupo no mesmo menu para mover as receitas selecionadas ou as mova para outro menu. Note que apenas os menus/grupos compatíveis com as predefinições das receitas serão exibidos.": "在相同的菜单中选择或创建一个群组，以移动所选的食谱，或将它们移到另一个菜单。请注意，只有与食谱预设兼容的菜单/群组才会显示。",
    "menus.Mover receitas para o mesmo menu": "将食谱移至相同的菜单",
    "menus.Mover receitas para outro menu": "将食谱移至另一个菜单",
    "menus.Selecionar grupo": "选择群组",
    "menus.Nenhuma receita foi selecionada": "未选择任何食谱",
    "menus.Passo editado com sucesso!": "成功编辑步骤!",
    "menus.Menu atualizado": "菜单已更新",
    "menus.até 260": "最多260",
    "menus.Umidade na câmara": "室内湿度",
    "menus.Passos": "步骤",
    "menus.ativar": "启用",
    "menus.Informe a temperatura de pré-aquec": "输入预热温度",
    "menus.Movas as receitas selecionadas para outro menu. Note que apenas os menus compatíveis com as predefinições das receitas serão exibidos.": "将所选的食谱移至另一个菜单。请注意，只有与食谱预设兼容的菜单才会显示。",
    "menus.Sonda (°C)": "探针（ºC）",
    "menus.Turbo Grill": "涡轮烤架",
    "menus.Pré-aquecimento padrão, conforme passo 1": "标准预热，按照步骤1",
    "menus.salvar": "保存",
    "menus.até": "最多",
    "menus.Verificação de receita": "食谱检查",
    "menus.Auto import": "自动导入",
    "menus.Atualização forçada": "强制更新",
    "menus.Lista de receitas exportada com sucesso!": "成功导出食谱列表!",
    "menus.Receita movida com sucesso!": "成功移动食谱!",
    "menus.Receitas movidas com sucesso!": "食谱成功移动!",
    "menus.+ Receita": "食谱",
    "menus.Escolha um idioma compatível com o equipamento": "选择与设备兼容的语言",
    "menus.MENU IMPORTADO COM SUCESSO!": "菜单导入成功!",
    "menus.Dourar": "棕色的",
    "menus.Não atribuir a menu": "不分配给菜单",
    "menus.Todos": "所有",
    "menus.Meus menus": "我的菜单",
    "menus.Para salvar uma receita, preencha todos os campos": "保存食谱，请填写所有字段",
    "menus.Selecione os grupos de equipamentos para os quais deseja exportar o menu.": "选择要为其导出菜单的设备组。",
    "menus.Você deseja excluir este passo?": "你要删除这一步吗?",
    "menus.Formato inválido. São permitidos apenas os formatos .jpeg e .png": "无效格式。只允许 .jpeg 和 .png 格式。",
    "menus.Reordenar grupos": "重新排序分组",
    "menus.Reordenar receitas": "重新排序食谱",
    "menus.ITENS REORDENADOS COM SUCESSO! PARA APLICAR AS ALTERAÇÕES NO FORNO, EXPORTE O MENU.": "项目成功重新排序！要应用更改到烤箱，请导出菜单。",
    "menus.ARRASTE OS ITENS PARA O LOCAL DESEJADO": "将项目拖动到所需位置。",
    "menus.Dumper": "自卸车",
    "menus.Aberto": "打开",
    "menus.Fechado": "关闭",
    "menus.Intermitente": "间歇性",
    "menus.Se o seu menu incluir itens sensíveis à uniformidade, recomendamos definir o Tempo de Estabilização entre 3:00 e 5:00 minutos. Caso contrário, você pode deixá-lo em 0:00 minutos.": "如果您的菜单包含对均匀性敏感的项目，我们建议将稳定时间设置在 3:00 到 5:00 分钟之间。否则，您可以将其保留在 0:00 分钟。",
    "menus.Selecione o menu que deseja exportar:": "选择您要导出的菜单：",
    "menus.Temperatura de pré-aquecimento:": "预热温度:",
    "menus.Temperatura de lastro": "镇流器温度",
    "menus.Temperatura de teto": "天花板温度",
    "menus.Volume de injeção de vapor (ml)": "蒸汽喷射量（ml)",
    "menus.Parada de turbina": "涡轮停止",
    "menus.Alta": "高",
    "menus.Média": "中",
    "menus.Baixa": "低",
    "menus.a": "这",
    "menus.Valor da injeção": "注入值",
    "menus.Volume: até 999ml": "容量：高达 999 毫升",
    "menus.Tempo: até 15 segundos": "时间：长达 15 秒",
    "menus.Tempo de injeção de vapor (seg)": "蒸汽喷射时间（秒）",
    "menus.Atenção!": "注意力！",
    "menus.Verifique as receitas do menu e ajuste os valores da Injeção de Vapor após alterar sua unidade de medida.": "更改测量单位后，检查菜单食谱并调整蒸汽喷射值。",
    "menus.Certifique-se de que a unidade de temperatura (°C ou °F) definida no equipamento seja a mesma que foi selecionada na criação do menu:": "确保设备上定义的温度单位（°C 或 °F）与创建菜单时选择的温度单位相同：",
    "menus.Injeção desativada": "注射禁用",
    "menus.Selecione a unidade de temperatura que está definida no equipamento:": "选择设备上定义的温度单位：",
    "menus.Clique para atualizar": "点击更新",
    "menus.A função Atualização Forçada envia automaticamente o menu para os equipamentos selecionados na próxima vez em que forem ligados.": "强制更新功能会在下次开机时自动将菜单发送到所选设备。",
    "menus.As opções selecionadas estarão disponíveis nos equipamentos que receberem este menu, podendo ser utilizadas pelo operador. É possível alterar estas configurações posteriormente no próprio forno ou pela plataforma.": "所选选项将在接收此菜单的设备上可用，并且可供操作员使用。稍后可以在烤箱本身或通过平台更改这些设置。",
    "menus.+ Menu": "+ 菜单",
    "menus.Não foi possivel deletar as receitas": "无法删除食谱",
    "menus.PRÉ": "预",
    "menus.Speed Ovens": "Speed Ovens",
    "menus.Fornos Combinados": "组合烤箱",
    "menus.Fornos de Panificação": "烘焙烤箱",
    "menus.Após a criação, o tipo do menu não pode ser alterado. Ele só pode ser definido no momento da criação.": "创建后，菜单类型不能更改。它只能在创建时设置。",
    "menus.Pré-aquecimento do grupo": "组预热",

    //COOKBOOK
    "cookbook.RECEITAS DISPONÍVEIS": "可用食谱",
    "cookbook.RECEITAS SALVAS": "保存的食谱",
    "cookbook.ENVIAR PARA MENU": "发送到菜单",
    "cookbook.SALVAR": "保存",
    "cookbook.salvar": "保存",
    "cookbook.Filtrar por": "筛选方式",
    "cookbook.Procurar receita do cookbook": "从食谱书中搜索食谱",
    "cookbook.Equipamento": "设备",
    "cookbook.Ingrediente principal": "主要成分",
    "cookbook.Tipo de prato": "餐点类型",
    "cookbook.Região": "地区",
    "cookbook.Pré-aquecimento": "预热",
    "cookbook.Procurar receita": "搜索食谱",
    "cookbook.Menu": "菜单",
    "cookbook.ENVIAR": "发送",
    "cookbook.Procurar nome do menu": "搜索菜单名称",
    "cookbook.Escolha um grupo deste menu": "从此菜单选择一个群组",
    "cookbook.Selecionar grupo": "选择群组",
    "cookbook.Tivemos um problema. Tente novamente mais tarde": "我们遇到了问题。请稍后再试",
    "cookbook.Receita adicionada com sucesso!": "成功添加食谱!",
    "cookbook.Não foi possível adicionar a receita. Tente novamente mais tarde": "无法添加食谱。请稍后再试",
    "cookbook.Selecione um menu e um grupo para enviar esta receita": "选择要发送此食谱的菜单和群组",
    "cookbook.Selecione o modelo do equipamento primeiro": "首先选择设备型号",
    "cookbook.Grupo": "群组",
    "cookbook.Não foi possível salvar os passos da receita": "无法保存食谱的步骤",
    "cookbook.Bovina": "牛肉",
    "cookbook.Frango": "鸡肉",
    "cookbook.Ovos": "鸡蛋",
    "cookbook.Pães": "面包",
    "cookbook.Peixes": "鱼",
    "cookbook.Queijo": "奶酪",
    "cookbook.Suína": "猪肉",
    "cookbook.Vegetais": "蔬菜",
    "cookbook.Outros": "其他",
    "cookbook.Entradas/aperitivos": "开胃菜",
    "cookbook.Principais": "主菜",
    "cookbook.Snacks": "小吃",
    "cookbook.Pizzas": "披萨",
    "cookbook.Massas": "意大利面",
    "cookbook.Acompanhamentos": "配菜",
    "cookbook.Sobremesas": "甜点",
    "cookbook.Manhã": "早餐",
    "cookbook.Padaria": "面点",
    "cookbook.Todos": "全部",
    "cookbook.Não atribuir origem": "不指定来源",
    "cookbook.Brasil": "巴西",
    "cookbook.Alemanha": "德国",
    "cookbook.França": "法国",
    "cookbook.Rússia": "俄罗斯",
    "cookbook.China": "中国",
    "cookbook.Japão": "日本",
    "cookbook.Itália": "意大利",
    "cookbook.Egito": "埃及",
    "cookbook.Grécia": "希腊",
    "cookbook.Argentina": "阿根廷",
    "cookbook.África": "非洲",
    "cookbook.Estados Unidos": "美国",
    "cookbook.Coreia": "韩国",
    "cookbook.Turquia": "土耳其",
    "cookbook.Ideal para": "适合",
    "cookbook.Polônia": "波兰",
    "cookbook.Sanduíches": "三明治",

    //EQUIPAMENTOS
    "equipment.equipamentos": "设备",
    "equipment.procurar equipamento": "搜索设备或用户",
    "equipment.adicionar equipamento": "添加设备",
    "equipment.atualizar equipamento": "更新设备",
    "equipment.+ equipamentos": "设备",
    "equipment.buscar equipamento": "搜索设备",
    "equipment.Desvincular": "取消关联",
    "equipment.Editar": "编辑",
    "equipment.sem conteúdo": "这里仍然没有设备 :(",
    "equipment.editar equipamento": "编辑设备",
    "equipment.equipamento editado com sucesso!": "设备编辑成功!",
    "equipment.não foi possível editar o equipamento. Tente novamente mais tarde": "无法编辑设备。请稍后再试",
    "equipment.Menu exportado com sucesso!": "成功导出菜单!",
    "equipment.Não foi possível exportar o menu. Tente novamente mais tarde": "无法导出菜单。请稍后再试",
    "equipment.Selecione pelo menos um equipamento para enviar o menu": "至少选择一个设备发送菜单",
    "equipment.Informações do menu": "菜单信息",
    "equipment.Procurar receita ou grupo": "搜索食谱或群组",
    "equipment.Grupo": "群组",
    "equipment.Receita": "食谱",
    "equipment.Quantidade": "数量",
    "equipment.Receitas mais preparadas no equipamento durante o período": "期间设备上最常准备的食谱",
    "equipment.Informações de limpeza": "清洁信息",
    "equipment.Data da limpeza": "清洁日期",
    "equipment.Hora da limpeza": "清洁时间",
    "equipment.nome": "名称",
    "equipment.modelo": "型号",
    "equipment.categoria": "类别",
    "equipment.loja": "商店",
    "equipment.desvincular equipamento": "取消关联设备",
    "equipment.unlink.paragrafo 1": "您是否希望取消与IOK平台的设备关联？",
    "equipment.unlink.paragrafo 2": "这样一来，将无法通过平台管理您的设置和菜单，除非您按照连接说明重新关联设备。",
    "equipamento desvinculado com sucesso!": "设备取消关联成功!",
    "equipment.ocorreu um erro. Tente novamente mais tarde": "发生错误。请稍后再试",
    "equipment.sim": "是",
    "equipment.não": "否",
    "equipment.Encerrar acesso remoto": "结束远程访问",
    "equipment.Horas sem limpeza": "无清洁小时",
    "equipment.Histórico de eventos": "事件历史记录",
    "equipment.Evento": "事件",
    "equipment.Data": "日期",
    "equipment.ERRO SENSOR RI": "下阻传感器错误",
    "equipment.ERRO COM INV. DE FREQ.": "频率变频器错误",
    "equipment.ERRO MAGNETRON": "磁控管错误",
    "equipment.ERRO SENSOR CAMARA": "相机传感器错误",
    "equipment.ERRO CAN": "CAN错误",
    "equipment.Erro INVERSOR DE FREQUENCIA": "频率反向器错误",
    "equipment.ERRO DO MODELO DO EQUIPAMENTO": "设备型号错误",
    "equipment.ERRO PAINEL": "面板错误",
    "equipment.ERRO INV.FREQ.STATUS": "频率反向器错误状态",
    "equipment.ERRO SENSOR BOARD": "板传感器错误",
    "equipment.ERRO ENERGY": "能量错误",
    "equipment.ERRO I2C": "I2C错误",
    "equipment.EQUIPAMENTO LIGADO": "连接设备",
    "equipment.EQUIPAMENTO DESLIGADO": "设备已关闭",
    "equipment.Plataforma": "平台",
    "equipment.Forno": "烤箱",
    "equipment.Origem": "来源",
    "equipment.Locais": "位置",
    "equipment.não foi possível carregar as entidades": "无法加载实体",
    "equipment.conceder acesso remoto a": "授予远程访问权限给",
    "equipment.atualizar equipamentos": "更新设备",
    "equipment.suporte": "支持",
    "equipment.PIN": "PIN",
    "equipment.nome do equipamento": "设备名称",
    "equipment.vinculado à loja": "关联到商店",
    "equipment.cadastrar loja": "注册商店",
    "equipment.informe o PIN do forno": "输入烤箱PIN",
    "equipment.preencha o PIN do forno": "填写烤箱PIN",
    "equipment.adicione um nome para facilitar seu reconhecimento": "添加一个易于识别的名称",
    "equipment.preencha o nome do equipamento": "填写设备名称",
    "equipment.selecionar": "选择",
    "equipment.nome fantasia": "幻想名称",
    "equipment.informe o nome fantasia": "输入幻想名称",
    "equipment.preencha o nome fantasia": "填写幻想名称",
    "equipment.CNPJ": "CNPJ",
    "equipment.XX.XXX.XXX/0001.XX": "XX.XXX.XXX/0001.XX",
    "equipment.preencha o CNPJ": "填写CNPJ",
    "equipment.CEP": "邮政编码",
    "equipment.00000-000": "00000-000",
    "equipment.preencha o CEP": "填写邮政编码",
    "equipment.cidade": "城市",
    "equipment.informe a cidade": "输入城市",
    "equipment.preencha a cidade": "填写城市",
    "equipment.UF": "州",
    "equipment.bairro": "街区",
    "equipment.informe o bairro": "输入街区",
    "equipment.endereço": "地址",
    "equipment.informe o seu endereço": "输入您的地址",
    "equipment.preencha o seu endereço": "填写您的地址",
    "equipment.Nº": "编号",
    "equipment.equipamento cadastrado com sucesso!": "设备注册成功!",
    "equipment.ocorreu um erro": "发生错误",
    "equipment.preencha o modelo": "填写型号",
    "equipment.selecione uma categoria": "选择类别",
    "equipment.selecione uma loja": "选择商店",
    "equipment.preencha o bairro": "填写街区",
    "equipment.não foi possível cadastrar o equipamento. Tente novamente mais tarde": "无法注册设备。请稍后再试",
    "equipment.loja cadastrada com sucesso": "商店注册成功",
    "equipment.não foi possível cadastrar a loja. Tente novamente mais tarde": "无法注册商店。请稍后再试",
    "equipment.equipment": "设备",
    "equipment.stores": "商店",
    "equipment.search equipment": "搜索设备",
    "equipment.add equipment": "添加设备",
    "equipment.update equipment": "更新设备",
    "equipment.plus equipment": "设备",
    "equipment.Procurar equipamento": "搜索设备",
    "equipment.EQUIPAMENTOS": "设备",
    "equipment.Atualizar equipamento": "更新设备",
    "equipment.Atualizar equipamentos": "更新设备",
    "equipment.confirmar": "确认",
    "equipment.Selecione os modelos que deseja atualizar:": "选择要更新的型号",
    "equipment.Como deseja realizar a atualização?": "您希望如何进行更新？",
    "equipment.Enviar atualização pela plataforma (wi-fi)": "通过平台发送更新（Wi-Fi）",
    "equipment.Salvar arquivo de atualização em pen drive": "将更新文件保存在USB闪存驱动器中",
    "equipment.Linha Express": "快速通道",
    "equipment.Escolha como deseja realizar a atualização": "选择您希望如何进行更新",
    "equipment.Selecione ao menos um modelo de equipamento para atualizar": "至少选择一个设备型号进行更新",
    "equipment.Status:": "状态: ",
    "equipment.Número de série:": "序列号",
    "equipment.Menu:": "菜单",
    "equipment.Loja:": "商店",
    "equipment.End:": "结束: ",
    "equipment.Cidade:": "城市: ",
    "equipment.CEP:": "邮政编码: ",
    "equipment.Atualização de software enviada com sucesso!": "软件更新已成功发送!",
    "equipment.Não foi possível enviar a atualização de sodtware. Tente novamente mais tarde": "无法发送软件更新。请稍后再试",
    "equipment.Download realizado com sucesso": "下载成功完成",
    "equipment.Não foi possível realizar o download. Tente novamente mais tarde": "无法完成下载。请稍后再试",
    "equipment.name": "名称",
    "equipment.model": "型号",
    "equipment.category": "类别",
    "equipment.store": "商店",
    "FIT Express/FIT ST": "FIT Express/FIT ST",
    "Copa/Single Mag/Rocket": "Copa/Single Mag/Rocket",
    "Forza STi": "Forza STi",
    "TSi": "TSi",
    "FIT Express": "FIT Express",
    "FIT ST": "FIT ST",
    "equipment.Preencha o nome da versão": "填写版本名称",
    "equipment.Informe aqui todas as alterações desta nova versão": "在这里输入此新版本中的所有更改",
    "equipment.Nova versão de software atualizada com sucesso!": "新软件版本已成功更新!",
    "equipment.Informe pelo menos uma mudança nesta versão": "在此版本中至少输入一个更改",
    "equipment.Falha ao ler o arquivo": "无法读取文件",
    "equipment.selecionar tipo": "选择类型",
    "equipment.selecione o tipo da versão": "选择版本类型",
    "equipment.atualização": "更新",
    "equipment.Selecione abaixo o que deseja fazer:": "在下面选择您希望执行的操作：",
    "equipment.Atualizar meus equipamentos": "更新我的设备",
    "equipment.Enviar atualização para usuários selecionados": "将更新发送给选定的用户",
    "equipment.ENVIAR": "发送",
    "equipment.ENVIAR ATUALIZAÇÃO": "发送更新",
    "equipment.Selecione os e-mails para os quais deseja enviar a atualização:": "选择要发送更新的电子邮件：",
    "equipment.A atualização disponibilizada no dia": "当天提供的更新",
    "equipment.referente ao equipamento": "关于设备",
    "equipment.contempla o seguinte:": "考虑以下内容：",
    "equipment.Selecionar arquivo para envio": "选择要发送的文件",
    "equipment.Por favor, selecione um arquivo .tar": "请选择一个 .tar 文件",
    "equipment.Selecione o modelo do equipamento:": "选择设备型号：",
    "equipment.Selecione uma opção": "选择一个选项",
    "equipment.Nomeie a versão": "命名版本",
    "equipment.Clique sobre o arquivo que deseja baixar:": "单击您要下载的文件:",
    "equipment.Baixar atualização": "下载更新",
    "equipment.Versões": "版本",
    "equipment.e superiores": "及更高",
    "FIT Express, FIT ST": "FIT Express, FIT ST",
    "Copa Express, Rocket Express, Copa Single Mag": "Copa Express, Rocket Express, Copa Single Mag",
    "equipment.e": "这是",
    "equipment.Últimas versões de software:": "最新软件版本: ",
    "equipment.Você não possui nenhum equipamento desatualizado": "你没有任何过时的设备",
    "equipment.grupos": "团体",
    "equipment.não foi possível carregar os grupos de equipamentos da companhia": "无法加载公司设备组",
    "equipment.vinculado ao grupo": "已链接至群组",
    "equipment.selecione um grupo": "选择一个组",
    "equipment.cadastrar grupo": "注册群组",
    "equipment.Número de Série": "序列号",
    "equipment.Versão": "版本",
    "equipment.não foi possível cadastrar o equipamento. Selecione ou cadastre um grupo": "无法注册设备。请选择或注册一个组",
    "equipment.CRIE GRUPOS DE EQUIPAMENTOS!": "创建设备组！",
    "equipment.Para que os usuários vinculados à conta possam visualizar os equipamentos você precisa:": "为了让与账户关联的用户能够查看设备，您需要：",
    "equipment.1) Criar grupo(s);": "1) 创建组（或多个组）；",
    "equipment.2) Atribuir equipamento(s) ao grupo;": "2) 将设备分配给组；",
    "equipment.3) Atribuir usuário(s) ao grupo.": "3) 将用户分配给组。",
    "equipment.Status software": "软件状态",
    "equipment.Status menu": "菜单状态",
    "equipment.Pendente": "待处理",
    "equipment.Aceito": "已接受",
    "equipment.Recusado": "已拒绝",
    "equipment.Atualizar": "更新",
    "equipment.Deseja enviar a atualização para o equipamento selecionado?": "是否要将更新发送到所选设备？",
    "equipment.Companhia": "公司",
    "equipment.Exportar menu": "导出菜单",
    "equipment.companhias": "公司",
    "equipment.Nome da companhia": "公司名称",
    "equipment.Procurar equipamento, usuário ou companhia": "搜索设备、用户或公司",
    "equipment.Versão da power": "动力版",
    "equipment.AINDA NÃO HÁ DADOS DE RECEITAS AQUI :(": "这里还没有食谱数据:(",
    "equipment.AINDA NÃO HÁ DADOS DE LIMPEZAS AQUI :(": "这里还没有清洁数据:(",
    "equipment.AINDA NÃO HÁ DADOS DE EVENTOS AQUI :(": "这里还没有事件数据:(",
    "ID": "ID",
    "equipment.Hora": "小时",
    "equipment.EVENTOS MAIS OCORRIDOS NO EQUIPAMENTO DURANTE O PERÍODO": "期间设备上发生最多的事件",
    "equipment.hour": "小时",
    "equipment.minutes": "分钟",
    "equipment.date format": "日/月/年",
    "equipment.preparada": "准备",
    "equipment.vezes": "次",
    "equipment.vez": "次",
    "equipment.Ver": "查看",
    "equipment.Os equipamentos enviarão os dados à plataforma ao serem ligados. A cada dois meses, os dados recebidos serão automaticamente excluídos.": "设备开机后会向平台发送数据。每两个月，收到的数据将被自动删除。",
    "equipment.Ligado": "开启",
    "equipment.Desligado": "关闭",
    "equipment.CAN ERROR": "CAN 错误",
    "equipment.CAMERA SENSOR ERROR": "相机传感器错误",
    "equipment.STEAM/RI SENSOR ERROR": "RI 传感器错误",
    "equipment.PROBE 1 ERROR": "探测警报 1",
    "equipment.PROBE 2 ERROR": "探测警报 2",
    "equipment.PROBE 3 ERROR": "探测警报 3",
    "equipment.PROBE 4 ERROR": "探测警报 4",
    "equipment.PROBE 5 ERROR": "探测警报 5",
    "equipment.PROBE 6 ERROR": "探测警报 6",
    "equipment.GENERAL PROBE ERROR": "一般探针错误",
    "equipment.WATER LACK ERROR": "缺水警报",
    "equipment.HIGH PANEL TEMPERATURE ERROR": "仪表盘上的高温警报",
    "equipment.FREQUENCY INVERTER GENERAL ERROR": "变频器错误",
    "equipment.FREQUENCY INVERTER COMMUNICATION ERROR": "变频器通讯警报",
    "equipment.FREQUENCY INVERTER STATUS ERROR": "变频器状态警报",
    "equipment.CLEANING NOT COMPLETED ERROR": "清洁不彻底警报",
    "equipment.MAGNETRON ERROR": "磁控管警报",
    "equipment.EQUIPMENT FAMILY/MODEL ERROR": "设备型号错误",
    "equipment.BOARD TEMPERATURE SENSOR ERROR": "板温度传感器警报",
    "equipment.ENERGY MODULE ERROR": "能源模块错误",
    "equipment.POWER BOARD I2C BUS ERROR": "电源板错误",
    "equipment.GAS SYSTEM ERROR": "气体错误",
    "equipment.VERY HIGH PANEL TEMPERATURE ERROR": "超高面板温度错误",
    "equipment.POWER ON": "开",
    "equipment.POWER OFF": "关闭",
    "equipment.DOOR OPENED": "门打开",
    "equipment.DOOR CLOSED": "门已关闭",
    "equipment.CLEANING CANCELED ERROR": "用户取消清理",
    "equipment.PIN não encontrado. Por favor, verifique e tente novamente": "未找到 PIN。请检查并重试",
    "equipment.Online": "在线",
    "equipment.Offline": "离线",
    "equipment.Exibir apenas eventos": "仅显示事件",
    "equipment.Endereço": "地址",
    "equipment.EQUIPMENT CONNECTED TO THE PLATFORM": "与平台连接的设备",
    "equipment.EQUIPMENT DISCONNECTED FROM THE PLATFORM": "设备与平台断开连接",
    "equipment.MENU RECEIVED FROM THE PLATFORM": "从平台收到的菜单",
    "equipment.MENU REJECTED BY THE USER": "用户拒绝菜单",
    "equipment.CONNECTED TO THE INTERNET": "连接到互联网",
    "equipment.DISCONNECTED FROM THE INTERNET": "与互联网断开连接",
    "equipment.Infelizmente não há versões que realizem o envio de dados compatíveis com este equipamento.": "很遗憾，没有版本可以执行与此设备兼容的数据传输。",
    "equipment.O equipamento": "设备",
    "equipment.deve ser atualizado para a versão mais recente de software para que passe a enviar os dados para a plataforma": "必须更新到最新的软件版本，才能将数据发送到平台",
    "equipment.Versão do seu equipamento": "您的设备版本",
    "equipment.Versão disponível": "可用版本",
    "equipment.Selecione abaixo como deseja enviar a atualização para seu equipamento": "请在下方选择如何将更新发送到您的设备",
    "equipment.Salvar em pen drive": "保存到U盘",
    "equipment.Enviar por wi-fi": "通过Wi-Fi发送",
    "equipment.Cliente": "客户",
    "equipment.NÚMERO DE SÉRIE NÃO ENCONTRADO": "未找到序列号",
    "equipment.BUSCAR": "搜索",
    "equipment.ACESSADOS RECENTEMENTE": "最近访问过",
    "equipment.SEM BUSCAS RECENTES": "最近没有搜索",
    "equipment.Procurar equipamento por NS": "通过NS搜索设备",
    "equipment.AIR FILTER CLEANING COMPLETED": "空气过滤器清洁完成",
    "equipment.AIR FILTER CLEANING REJECTED": "空气过滤器清洁被拒绝",
    "equipment.CATALYTIC FILTER CLEANING COMPLETED": "催化过滤器清洁完成",
    "equipment.CATALYTIC FILTER CLEANING REJECTED": "催化过滤器清洁被拒绝",
    "equipment.ID do equipamento": "设备ID",
    "equipment.Todo equipamento deve ser vinculado a um grupo. Selecione um grupo da lista ou cadastre um novo clicando em + Cadastrar grupo.": "所有设备必须链接到一个组。从列表中选择一个组，或通过单击 + 注册组来注册一个新组。",
    "equipment.Nomeie seu forno": "为你的烤箱命名",
    "equipment.Para visualizar o PIN, acesse nas configurações do equipamento a Plataforma Virtual IOK.": "要查看 PIN，请访问设备设置中的 IOK 虚拟平台。",
    "equipment.localização": "地点",
    "equipment.AINDA NÃO HÁ NENHUMA LOCALIZAÇÃO CADASTRADA :(": "尚未注册位置:(",
    "equipment.Procurar empresa ou local": "搜索公司或地点",
    "equipment.Adicionar localização": "添加位置",
    "equipment.espaço": "空间",
    "equipment.local": "当地的",
    "equipment.EXCLUIR LOCALIZAÇÃO": "删除位置",
    "equipment.Você deseja excluir esta localização?": "您想删除该位置吗？",
    "equipment.Ao fazer isso, os equipamentos atrelados à ela não exibirão nenhuma localização, sendo necessário informar um novo local para eles.": "执行此操作时，与其链接的设备将不会显示任何位置，因此需要为其提供新位置。",
    "equipment.Localização deletada com sucesso!": "位置删除成功！",
    "equipment.Erro ao deletar a localização. Tente novamente mais tarde": "删除位置时出错。稍后再试",
    "equipment.Não foi possivel deletar a localização": "无法删除位置",
    "equipment.Localização adicionada com sucesso!": "位置添加成功！",
    "equipment.Não foi possível adicionar a localização. Tente novamente mais tarde": "无法添加位置。稍后再试",
    "equipment.Nome do espaço": "空间名称",
    "equipment.Informe o nome do espaço": "输入空间名称",
    "equipment.Preencha o nome do espaço": "填写空间名称",
    "equipment.país": "国家",
    "equipment.Endereço (rua, avenida, rodovia)": "地址（街道、大道、高速公路）",
    "equipment.Nº/Km": "条数/公里",
    "equipment.informe o país": "输入国家",
    "equipment.preencha o país": "填写国家",
    "equipment.Localização editada com sucesso!": "位置编辑成功！",
    "equipment.Não foi possível editar a localização. Tente novamente mais tarde": "无法编辑位置。稍后再试",
    "equipment.Editar localização": "编辑位置",
    "equipment.Pico de preparações": "准备高峰",
    "equipment.informações da semana": "本周信息",
    "equipment.horários de pico": "高峰时间",
    "equipment.horário de pico": "高峰时间",
    "equipment.preparações do dia": "当天的准备",
    "equipment.Horário": "时间",
    "equipment.Preparações": "准备",
    "equipment.dia selecionado": "选定的日子",
    "equipment.Vinculação IOK": "IOK绑定",
    "equipment.Última preparação": "最后一次准备",
    "equipment.A limpeza adequada e diária do forno auxilia em seu funcionamento correto e impacta na garantia.": "正确的日常清洁烤箱有助于其正常运行并影响保修。",
    "equipment.Total de limpezas realizadas:": "执行的总清洁次数：",
    "equipment.Limpezas não concluídas:": "清洁工作未完成：",
    "equipment.Desde a vinculação do forno a IOK": "从连接烤箱到 IOK",
    "equipment.Equipamento ligado há:": "设备已运行：",
    "equipment.Total de receitas preparadas:": "已准备食谱总数：",
    "equipment.Receitas preparadas": "已准备的食谱",
    "equipment.Aberturas de porta": "门洞",
    "equipment.Desde a última vez que o forno foi ligado": "自上次打开烤箱以来",
    "equipment.Preparação": "准备",
    "equipment.Nenhuma localização criada": "未创建地点",
    "equipment.Acesso remoto": "远程访问",
    "equipment.Informe o ID da empresa que poderá acessar remotamente seu forno. Use ; para separar IDs.": "输入可以远程访问您烤箱的公司的 ID。使用 ;来分隔ID。",
    "equipment.O acesso remoto permite que as empresas selecionadas enviem menus e atualizações para seu equipamento.": "远程访问允许选定的公司向您的设备发送菜单和更新。",
    "equipment.Concedo acesso remoto à Prática oficial.": "我授予对官方练习的远程访问权限。",
    "equipment.Deseja selecionar outros equipamentos vinculados à sua conta para conceder acesso remoto a eles?": "您想要选择链接到您的帐户的其他设备来授予他们远程访问权限吗？",
    "equipment.Selecione abaixo os equipamentos para os quais deseja conceder acesso remoto à": "在下面选择您想要授予远程访问权限的设备",
    "equipment.Acesso remoto concedido com sucesso!": "远程访问授予成功！",
    "equipment.Selecione pelo menos um equipamento": "选择至少一台设备",
    "equipment.Não foi possível editar os equipamentos. Tente novamente mais tarde": "无法编辑装备。稍后再试",
    "equipment.Solicitar atendimento": "请求服务",
    "equipment.Relate um problema": "报告问题",
    "equipment.Descreva aqui o problema de seu equipamento": "在此描述您的设备问题",
    "equipment.Não foi possível enviar sua solicitação de atendimento. Tente mais tarde": "我们无法发送您的服务请求。稍后再试",
    "equipment.Solicitação técnica enviada com sucesso!": "技术请求发送成功！",
    "equipment.Enviar": "发送",
    "equipment.Solicitação de atendimento": "服务请求",
    "equipment.Informações do magnetron": "磁控管信息",
    "equipment.Vida útil aproximada": "预计寿命",
    "equipment.MAGNETRON USAGE HOURS": "磁控管使用时间",
    "equipment.Filtros": "筛选条件",
    "equipment.Filtrar": "筛选",
    "equipment.Limpar filtros": "清除筛选条件",
    "equipment.Procurar por modelo, versão ou país": "按型号、版本、地区或国家搜索",
    "equipment.Procurar equipamento, NS, modelo, versão ou país": "搜索设备、序列号、型号、版本或国家",
    "equipment.Atendimento": "服务",
    "equipment.Eventos mais ocorridos no equipamento durante o período": "期间设备上发生最多的事件",
    "equipment.EQUIPAMENTO SEM NÚMERO DE SÉRIE": "没有序列号的设备",
    "equipment.Os seguintes IDs não pertencem a uma companhia do tipo 'Distribuidor' e foram removidos": "以下ID不属于“分销商”类型的公司，已被删除",
    "equipment.BAIXAR VERSÕES": "下载版本",
    "equipment.Compatível com todas as versões da IHM.": "兼容所有 HMI 版本。",
    "equipment.Compatível apenas com a versão 2.0.0C da Power.": "仅与 Power 版本 2.0.0C 兼容。",
    "equipment.Procurar evento": "搜索活动",
    "equipment.Forno conectado": "烤箱已连接",
    "equipment.Limpeza de filtro de ar": "空气滤清器清洗",
    "equipment.Limpeza de filtro catalítico": "催化剂滤网清洗",
    "equipment.Forno ligado": "烤箱已打开",
    "equipment.Inversor de frequência": "频率变频器",
    "equipment.Temperatura de painel": "面板温度",
    "equipment.Sensor da câmara": "室内传感器",
    "equipment.Magnetron": "磁控管",
    "equipment.Power": "Power",
    "equipment.Informações de menu": "菜单信息",
    "equipment.Limpeza": "清洁",
    "equipment.O equipamento já está utilizando a versão mais recente do software.": "该设备已经使用了最新版本的软件。",

    //LOJAS
    "store.Procurar loja": "搜索商店",
    "store.Excluir": "删除",
    "store.Editar": "编辑",
    "store.nome": "名称",
    "store.equipamentos": "设备",
    "store.cidade": "城市",
    "store.estado": "州",
    "store.salvar": "保存",
    "store.cadastrar loja": "注册商店",
    "store.Nome fantasia": "幻想名称",
    "store.Preencha o nome da loja": "填写商店名称",
    "store.CNPJ": "CNPJ",
    "store.Preencha o CNPJ da loja": "填写商店的CNPJ",
    "store.CEP": "邮政编码",
    "store.Preencha o CEP da loja": "填写商店的邮政编码",
    "store.Informe o nome fantasia": "填写幻想名称",
    "store.Cidade": "城市",
    "store.Informe a cidade": "输入城市",
    "store.Preencha a cidade": "填写城市",
    "store.UF": "州",
    "store.Preencha o UF da loja": "填写商店所在的州",
    "store.Bairro": "街区",
    "store.Informe o bairro": "输入街区",
    "store.Preencha o bairro da loja": "填写商店的街区",
    "store.Endereço": "地址",
    "store.Informe o endereço": "输入地址",
    "store.Preencha o endereço da loja": "填写商店的地址",
    "store.Nº": "编号",
    "store.Loja cadastrada com sucesso": "商店注册成功",
    "store.Erro ao adicionar loja. Tente novamente": "添加商店时发生错误。请重试",
    "store.Loja deletada com sucesso": "商店删除成功",
    "store.Erro ao deletar loja. Tente novamente mais tarde": "删除商店时发生错误。请稍后再试",
    "store.AINDA NÃO HÁ NENHUMA LOJA CADASTRADA :(": "这里还没有注册的商店 :(",
    "store.EXCLUIR LOJA": "删除商店",
    "store.Você deseja excluir a loja da plataforma IOK?": "您是否要从IOK平台中删除商店？",
    "store.Ao fazer isso, os equipamentos atrelados à ela não terão vínculo com nenhuma loja, a menos que os vincule novamente.": "这样一来，与它关联的设备将不再关联任何商店，除非您再次进行关联。",
    "store.loja editada com sucesso": "商店编辑成功",
    "store.não foi possível editar a loja. Tente novamente mais tarde": "无法编辑商店。请稍后再试",
    "store.editar loja": "编辑商店",
    "store.Procurar grupo de equipamentos": "搜索设备组",
    "store.cadastrar grupo": "注册组",
    "store.AINDA NÃO HÁ NENHUM GRUPO CADASTRADO :(": "尚未注册任何团体:(",
    "store.cadastrar grupo de equipamentos": "注册设备组",
    "store.grupo de equipamentos cadastrado com sucesso!": "设备组注册成功!",
    "store.não foi possível cadastrar o grupo. Tente novamente mais tarde": "无法注册群组。稍后再试",
    "store.Nome do grupo": "团体名称",
    "store.informe o nome do grupo": "输入群组名称",
    "store.preencha o nome do grupo": "填写群组名称",
    "store.Grupo de equipamentos deletado com sucesso!": "设备组删除成功!",
    "store.Erro ao deletar o grupo. Tente novamente mais tarde": "删除群组时出错。稍后再试",
    "store.EXCLUIR GRUPO DE EQUIPAMENTOS": "删除设备组",
    "store.Você deseja excluir este grupo de equipamentos da plataforma IOK?": "您想从IOK平台删除该设备组吗？",
    "store.Ao fazer isso, os equipamentos atrelados à ele não terão vínculo com nenhum grupo, a menos que os vincule novamente.": "当您执行此操作时，与其链接的设备将不会链接到任何组，除非您再次链接它们。",
    "store.grupo de equipamentos editado com sucesso!": "设备组编辑成功!",
    "store.não foi possível editar o grupo. Tente novamente mais tarde": "无法编辑组。稍后再试",
    "store.editar grupo de equipamentos": "编辑设备组",
    "store.CEP não encontrado no Brasil": "在巴西找不到邮政编码",

    //NAVBAR
    "navbar.home": "主页",
    "navbar.cookbook": "食谱书",
    "navbar.my menus": "我的菜单",
    "navbar.equipment": "设备",
    "navbar.users": "用户",
    "navbar.calls": "服务",
    "navbar.settings": "设置",
    "navbar.help": "帮助",
    "navbar.logout": "退出",
    "navbar.Editar conta": "编辑账户",
    "navbar.API Externa": " 外部 API",

    //FOOTERBAR
    "footerBar.menu": "菜单",
    "footerBar.cookbook": "食谱书",
    "footerBar.home": "主页",
    "footerBar.menus": "菜单",
    "footerBar.notifications": "通知",

    //ATENDIMENTOS
    "calls.ATENDIMENTOS EM ABERTO": "进行中的服务",
    "calls.ATENDIMENTOS ENCERRADOS": "已关闭的服务",

    //MAPA
    "map.título": "位置",
    "map.erro": "错误",
    "map.mensagem de erro": "无法加载地图",
    "map.Localização não disponível": "位置不可用",

    //MODAL
    "modal.CONFIRMAR": "确认",
    "modal.Salvar alterações": "保存编辑",
    "Modal.Há alterações não salvas nos parâmetros dos passos": "步骤参数有未保存的更改",
    "Modal.Como deseja proceder?": "您希望如何进行？",
    "Modal.Avaliar alterações realizadas": "评估所做的更改",
    "Modal.Descartar alterações": "放弃更改",

    //USUÁRIOS
    "user.Nome": "名称",
    "user.E-mail": "电子邮件",
    "user.Permissão": "权限",
    "user.Loja": "存储",
    "user.Status": "状态",
    "user.Excluir": "删除",
    "user.Editar": "编辑",
    "user.Procurar usuário": "搜索用户",
    "user.ADICIONAR USUÁRIO": "添加用户",
    "user.AINDA NÃO HÁ NENHUM USUÁRIO CADASTRADO :(": "还没有注册的用户 :(",
    "user.+ Usuário": "+ 用户",
    "user.Informe o nome do usuário": "输入用户名",
    "user.Preencha o nome do usuário": "填写用户名",
    "user.Informe o e-mail do usuário": "输入用户的电子邮件",
    "user.Preencha com um e-mail válido": "输入有效的电子邮件地址",
    "user.Administrador": "管理员",
    "user.Usuário": "用户",
    "user.Preencha o telefone do usuário": "填写用户的电话号码",
    "user.Selecione a permissão do usuário": "选择用户权限",
    "user.Ativo": "激活",
    "user.Inativo": "非激活",
    "user.Usuário deletado da plataforma com sucesso!": "成功从平台移除用户!",
    "user.Ocorreu um erro. Tente novamente mais tarde": "发生错误。请稍后重试",
    "user.EXCLUIR USUÁRIO": "删除用户",
    "user.delete.paragrafo 1": "您是否希望从IOK平台中删除用户？",
    "user.delete.paragrafo 2": "这样做将使他们失去访问所有平台工具的权限，除非按照注册说明再次添加他们。",
    "user.EDITAR USUÁRIO": "编辑用户",
    "user.Usuário editado com sucesso!": "用户编辑成功!",
    "user.Não foi possível editar este usuário. Por favor, tente mais tarde": "无法编辑此用户。请稍后重试",
    "user.INFORMAÇÕES DO USUÁRIO": "用户信息",
    "user.Telefone": "电话",
    "user.Não foi possível cadastrar este usuário. Por favor, tente mais tarde": "无法注册此用户。请稍后重试",
    "user.Usuário adicionado com sucesso!": "成功添加用户!",
    "user.CRIAR TIPO DE USUÁRIO": "创建用户类型",
    "user.Criar novo tipo de usuário": "创建新用户类型",
    "user.Adicionar novo usuário": "添加新用户",
    "user.Permissões": "权限",
    "user.Preencha o nome do novo tipo de usuário": "填写新用户类型的名称",
    "user.Selecione pelo menos uma permissão": "至少选择一个权限",
    "user.Não foi possível carregar as permissões": "无法加载权限",
    "user.Tipo de usuário criado com sucesso!": "用户类型成功创建!",
    "user.Não foi possível criar este novo tipo de usuário. Por favor, tente mais tarde": "无法创建这个新用户类型。请稍后重试",
    "user.Não foi possível carregar os tipos de usuário da companhia": "无法加载公司的用户类型",
    "user.Tipo de usuário": "用户类型",
    "user.E-mail já cadastrado na plataforma. Informe um novo e-mail": "电子邮件已在平台上注册。请输入新的电子邮件",
    "user.UNIR CONTAS": "加入账户",
    "user.ID da companhia": "公司ID",
    "user.Digite o ID da companhia que deseja unir a sua conta": "输入您想要合并帐户的公司的 ID",
    "user.Preencha o ID da companhia": "填写公司ID",
    "user.O administrador da conta com a qual deseja se unir poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.":
      "您想要加入的帐户的管理员将能够通过单击位于屏幕右上角顶部菜单中的用户名来找到 ID。",
    "user.O administrador da outra conta receberá um e-mail para aceitar sua solicitação. Assim que for aceita, a união das duas contas será realizada!":
      "其他帐户的管理员将收到一封电子邮件以接受您的请求。一旦接受，两个帐户将被合并!",
    "user.Não foi possível solicitar a união de contas. Por favor, confirme o ID ou tente mais tarde": "无法请求帐户合并。请确认ID或稍后再试",
    "user.SOLICITAÇÃO APROVADA COM SUCESSO": "请求成功批准",
    "user.AGUARDE ENQUANTO REALIZAMOS A UNIÃO DAS CONTAS. ESTE PROCESSO PODE LEVAR ALGUNS MINUTOS...": "我们正在连接帐户，请稍候。此过程可能需要几分钟...",
    "user.UNIÃO DE CONTAS FINALIZADA COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "账户联盟成功完成！单击下面的按钮访问平台。",
    "user.NÃO CONSEGUIMOS REALIZAR A UNIÃO DAS CONTAS. POR FAVOR, TENTE NOVAMENTE. SE O PROBLEMA PERSISTIR, ENTRE EM CONTATO COM NOSSO SUPORTE.":
      "我们无法合并帐户。请再试一次。如果问题仍然存在，请联系我们的支持人员。",
    "user.possui todos os acessos": "拥有所有访问权限",
    "user.possui o acesso das abas cookbook, meus menus e equipamentos": "可以访问食谱、我的菜单和设备选项卡",
    "user.possui apenas o acesso da aba cookbook": "只能访问食谱选项卡",
    "user.possui apenas o acesso da aba equipamentos": "只能访问设备选项卡",
    "user.possui apenas o acesso da aba meus menus": "只能访问我的菜单选项卡",
    "user.Grupo de equipamentos": "设备组",
    "user.Informe o telefone do usuário": "输入用户的电话号码",
    "user.Nenhum grupo criado": "没有创建组",
    "user.Digite o ID da companhia que terá acesso à sua conta": "输入有权访问您帐户的公司 ID",
    "user.Ao informar um ID para unir contas com sua companhia, esteja ciente de que o novo usuário também se tornará Administrador da conta de sua companhia. É importante informar que a partir do momento em que um usuário aceitar unir contas com sua companhia, a companhia pertencente a ele deixará de existir.":
      "当提供 ID 将帐户与您的公司合并时，请注意，新用户也将成为您公司帐户的管理员。重要的是要告知，从用户同意加入您公司的帐户那一刻起，属于他们的公司将不复存在。",
    "user.Exemplo: Sua conta: 001 | Outra conta: 111. Depois de unir contas com você, a outra conta passará a ser 001.": "示例：您的帐户：001 |另一个账户：111。与您合并账户后，另一个账户将变为001。",
    "user.O administrador da outra conta poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.": "其他帐户的管理员将能够通过单击位于屏幕右上角顶部菜单中的用户名来找到 ID。",

    //TUTORIAIS
    "tutorial.Fechar": "结束",
    "tutorial.Fim": "下一个",
    "tutorial.Próximo": "打开",
    "tutorial.Abrir": "跳过教程",
    "tutorial.Pular tutorial": "编辑食谱详情",
    "tutorial.Editar detalhes da receita": "现在您可以为您的食谱添加更多细节，使它们更加完整。单击“编辑详情”并填写必要的信息。",
    "tutorial.Agora você pode adicionar mais detalhes às suas receitas e deixá-las ainda mais completas. Clique em Editar detalhes e preencha as informações necessárias.": "保存食谱编辑",
    "tutorial.Salvar edição da receita": "在提供更多食谱细节之后，单击“保存”。",
    "tutorial.Depois de informar mais detalhes da receita, clique em Salvar.": "编辑设备注册",
    "tutorial.Editar registro de um equipamento": "要完成设备的注册，请打开侧边菜单，单击“编辑”并填写类别和商店信息。",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Categoria e Loja.": "编辑用户",
    "tutorial.Editar usuário": "要完成用户注册，请打开侧边菜单，单击“编辑”并填写有关其所属商店的信息。",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente a Loja a qual ele faz parte.": "保存设备的编辑",
    "tutorial.Salvar edições de um equipamento": "在完成设备的注册后，点击“保存”。",
    "tutorial.Depois de completar o registro de seus equipamentos, clique em Salvar.": "商店中的设备",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em uma loja, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "商店详情",
    "tutorial.Para visualizar mais informações sobre a loja, como localização e receitas mais preparadas, clique sobre a linha da unidade que deseja analisar.": "要查看有关商店的更多信息，例如位置和准备最多的食谱，请单击要分析的单元行。",
    "tutorial.Criar receita": "在食谱书中，您可以创建自己的食谱！点击“创建食谱”并填写所有必要的信息。",
    "tutorial.No cookbook você pode criar suas próprias receitas! Clique em Criar Receita e preencha todas as informações necessárias.": "编辑或删除用户",
    "tutorial.Editar ou excluir um usuário": "要删除或编辑已注册用户的信息，请单击侧边菜单并选择所需的选项。",
    "tutorial.Para excluir ou editar as informações de um usuário já cadastrado, clique no menu lateral e selecione a opção desejada.": "用户管理",
    "tutorial.Gerenciamento de usuários": "在这里，您可以轻松管理用户。要添加用户，请单击“添加用户”并填写所有必要的信息。",
    "tutorial.Aqui você pode gerenciar seus usuários com mais facilidade. Ao clicar em + Usuário, você poderá adicionar um novo usuário ou criar um novo tipo de usuário.": "在这里您可以更轻松地管理您的用户。通过单击+用户，您可以添加新用户或创建新用户类型。",
    "tutorial.Suas receitas": "在这本食谱书中，你不仅可以找到各种食谱，还可以创建自己的食谱！点击“保存的食谱”即可查看Prática食谱书中保存的食谱以及你自己创建的食谱。",
    "tutorial.Pelo cookbook você também encontra suas próprias receitas, além de poder criá-las! Clique em Receitas Salvas para visualizar as receitas que foram salvas do cookbook da Prática e também as criadas por você.": "食谱筛选",
    "tutorial.Filtros do Cookbook": "为了方便浏览食谱，你可以对它们应用筛选器。你只需点击所需的筛选器，然后选择其中的一个选项。",
    "tutorial.Para facilitar a navegação pelas receitas, você pode aplicar filtros a elas. Basta clicar no filtro desejado e escolher uma de suas opções.": "食谱书",
    "tutorial.Cookbook": "在食谱书的“可用食谱”中，你将找到Prática厨师创建的食谱，并可以在需要时使用它们。",
    "tutorial.No cookbook, em Receitas Disponíveis, você encontrará as receitas criadas pelos Chefs da Prática e poderá utilizá-las sempre que quiser.": "更新设备",
    "tutorial.Atualizar equipamentos": "要向设备发送更新，请点击“更新设备”，选择要更新的型号和希望的更新方式 - 通过Wi-Fi或USB闪存驱动器。之后，点击确认。",
    "tutorial.Para enviar uma atualização a seus equipamentos, clique em Atualizar Equipamentos, selecione os modelos que deseja atualizar e a forma como deseja realizar essa atualização - via wi-fi ou pen drive. Depois disso, clique em Confirmar.": "添加设备",
    "tutorial.Adicionar equipamento": "要添加设备到你的账户，请点击“添加设备”并填写模态信息。之后，点击保存。",
    "tutorial.Para adicionar um equipamento à sua conta, clique em Adicionar Equipamento e preencha as informações do modal. Depois disso, clique em Salvar.": "编辑设备",
    "tutorial.Editar equipamento": "要取消链接设备或编辑其信息，请点击侧边菜单并选择所需选项。",
    "tutorial.Para desvincular um equipamento ou editar suas informações, clique no menu lateral e selecione a opção desejada.": "你的设备",
    "tutorial.Seus equipamentos": "在这里，你可以查看在你的平台上注册的所有设备。",
    "tutorial.Aqui você visualizará todos os equipamentos cadastrados em sua plataforma.": "恢复文件",
    "tutorial.Restaurar arquivos": "要恢复已删除的文件或永久删除它，请点击侧边菜单并选择所需选项。",
    "tutorial.Para restaurar um arquivo excluído ou deletá-lo permanentemente, clique no menu lateral e selecione a opção desejada.": "回收站",
    "tutorial.Lixeira": "被删除的菜单将在回收站中保存30天。此期满后，它们将被永久删除。",
    "tutorial.Os menus excluídos serão mantidos na Lixeira por 30 dias. Depois desse prazo serão excluídos permanentemente.": "保存用户编辑",
    "tutorial.Salvar edições de usuário": "完成用户注册后，请点击保存。",
    "tutorial.Depois de completar o cadastro dos usuários, clique em Salvar.": "创建菜单",
    "tutorial.Criar menu": "要创建和自定义菜单，请点击“创建菜单”并在模态框中填写必要的信息。您可以随时编辑菜单。",
    "tutorial.Para criar e personalizar um menu, clique em Criar Menu e preencha as informações necessárias no modal. Você pode editar um menu a qualquer momento.": "导入菜单",
    "tutorial.Importar menu": "要导入菜单，请点击“导入菜单”并选择设备型号和版本。",
    "tutorial.Para importar um menu, clique em Importar Menu e selecione o modelo e versão do equipamento.": "创建分组",
    "tutorial.Criar grupo": "要管理您的分组食谱，请点击“创建分组”并在模态框中填写必要的信息。",
    "tutorial.Para organizar suas receitas em grupos, clique em Criar Grupo e preencha as informações necessárias no modal.": "用户类型",
    "tutorial.Tipos de usuário": "用户类型",
    "tutorial.Agora você também pode criar tipos de usuários com permissões específicas para atender melhor a sua organização.": "您现在还可以创建具有特定权限的用户类型，以更好地为您的组织服务。",
    "tutorial.Gerenciamento de contas": "帐户管理",
    "tutorial.Se caso outra pessoa da sua organização também migrou a conta principal da empresa, aqui você pode unir as duas contas.": "如果您组织中的其他人也迁移了他们的主要公司帐户，您可以在此处合并这两个帐户。",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Grupo.": "要完成设备的注册，请打开侧面菜单，单击编辑并填写组信息。",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente ao Grupo de equipamentos a qual ele faz parte.": "要完成用户注册，请打开侧面菜单，单击编辑并填写其所属设备组的信息。",
    "tutorial.Grupos de equipamentos": "设备组",
    "tutorial.Na aba Grupos você pode visualizar os grupos cadastrados e os equipamentos distribuídos entre eles. Além disso, é possível cadastrar novos grupos e editar os já cadastrados.": "在组选项卡中，您可以查看已注册的组以及它们之间分布的设备。此外，还可以注册新组并编辑已注册的组。",
    "tutorial.Detalhes de grupos": "团体详情",
    "tutorial.Para visualizar mais informações sobre o grupo, como localização e receitas mais preparadas, clique sobre a linha que deseja analisar.": "要查看有关组的更多信息，例如位置和最常准备的食谱，请单击要分析的行。",
    "tutorial.Equipamentos em grupos": "设备成组",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em um grupo, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "通过单击组中注册的设备的行，除了能够请求服务或触发更新之外，您还可以查看有关该设备的更多统计信息和信息。",
    "tutorial.Grupos": "团体",
    "tutorial.Atualização e cache": "更新和缓存",
    "tutorial.Para atualizar a plataforma e corrigir eventuais problemas de cache, clique aqui.": "要更新平台并纠正任何缓存问题，请单击此处。",
    "tutorial.Dados dos equipamentos": "设备数据",
    "tutorial.Ao clicar sobre a linha de um equipamento, você pode visualizar mais estatísticas e informações sobre ele, como as receitas mais preparadas, limpezas realizadas e outros dados relevantes.": "单击设备的行，您可以查看有关该设备的更多统计信息和详细信息，例如最常准备的食谱、完成的清洁和其他相关数据。",
    "tutorial.Para alterar o idioma de sua plataforma, clique em Idioma e selecione a linguagem desejada.": "要更改平台的语言，请单击语言并选择所需的语言。",
    "tutorial.Alterar idioma": "更改语言",
    "tutorial.Para editar ou configurar sua conta, clique em seu nome e selecione a opção desejada.": "要编辑或配置您的帐户，请单击您的姓名并选择所需的选项。",
    "tutorial.Editar conta": "编辑帐户",
    "tutorial.Total de Usuários Cadastrados em sua conta.": "您帐户中注册的用户总数。",
    "tutorial.Home": "主页",
    "tutorial.Localização de seus equipamentos, sendo possível expandir o mapa para melhor visualização;": "您的设备的位置，可以扩大地图以便更好地查看；",
    "tutorial.Atualizações referentes a versão dos equipamentos vinculados a sua conta;": "有关与您的帐户关联的设备版本的更新；",
    "tutorial.Total de Receitas Cadastradas;": "注册总收入；",
    "tutorial.Menus Disponíveis;": "可用菜单；",
    "tutorial.Esta é a sua home! Aqui você encontra um resumo geral sobre sua conta, como quantidade de Equipamentos Vinculados;": "这是您的主页！在这里您可以找到您帐户的一般摘要，例如链接设备的数量；",
    "tutorial.Clique nas abas do menu para explorar a plataforma e conhecer tudo o que ela pode oferecer.": "单击菜单选项卡探索该平台并发现它可以提供的一切。",
    "tutorial.Exportar menu": "导出菜单",
    "tutorial.Depois de personalizar seu menu, exporte-o para seus equipamentos pela internet ou por pen drive.": "自定义菜单后，通过互联网或笔式驱动器将其导出到您的设备。",
    "tutorial.Na aba Localização você pode visualizar os endereços já cadastrados, além de criar localizações precisas para vincular seus equipamentos a elas.": "在“位置”选项卡中，您可以查看已注册的地址，此外还可以创建精确的位置以将您的设备链接到这些地址。",
    "tutorial.Localização": "地点",

    //RECOVER ACCOUNT
    "recoverAccount.Para prosseguir, selecione a categoria na qual você se encaixa:": "为了继续，请选择您适合的类别：",
    "recoverAccount.Deseja acessar a plataforma como DISTRIBUIDOR? Crie sua conta clicando em EMPRESA e entre em contato conosco para liberarmos os demais acessos.": "您是否希望作为分销商访问平台？点击公司创建您的账户，并联系我们以释放其他访问权限。",
    "recoverAccount.A qual organização você pertence": "您属于哪个组织？",

    //RECOVER PASSWORD
    "recoverPassword.E-mail para recuperação de senha": "用于密码恢复的电子邮件",
    "recoverPassword.Informe o e-mail cadastrado na plataforma": "提供在平台上注册的电子邮件",
    "recoverPassword.E-mail inválido. Por favor, verifique": "无效的电子邮件。请验证",
    "recoverPassword.RECUPERAR SENHA": "恢复密码",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL COM O LINK DE RECUPERAÇÃO DE SENHA": "您将很快收到一封包含密码恢复链接的电子邮件",
    "recoverPassword.Login": "登录",
    "recoverPassword.Informe seu e-mail": "输入您的电子邮件",
    "recoverPassword.Crie uma nova senha": "创建一个新密码",
    "recoverPassword.O campo senha deve conter no mínimo 6 caracteres": "密码字段必须至少有6个字符",
    "recoverPassword.Confirme sua senha": "确认密码",
    "recoverPassword.As senhas devem ser iguais": "密码必须匹配",
    "recoverPassword.ENTRAR": "进入",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL PARA CONFIRMAR SUA IDENTIDADE": "您将很快收到一封确认身份的电子邮件",
    "recoverPassword.CONTINUAR": "继续",
    "recoverPassword.CONFIRMAR": "确认",

    // EXPORT
    "export.Limpezas": "清洁",
    "export.Eventos": "事件",
    "export.Lista de receitas preparadas": "已准备食谱列表",
    "export.Lista de limpezas realizadas": "完成的清洁列表",
    "export.Lista de eventos ocorridos": "发生事件列表",
    "export.Exportar dados": "导出数据",
    "export.Informações do equipamento": "设备信息",
    "export.Dados exportados com sucesso!": "数据导出成功",
    "export.Não foi possível exportar os dados. Por favor, tente novamente mais tarde.": "无法导出数据。 请稍后再试。",
    "export.Detalhes do menu": "菜单详情",
    "export.Detalhes de receitas": "食谱详情",
    "export.Detalhes e Passos": "细节和步骤",
    "export.Exportando dados. Em breve o arquivo estará disponível!": "正在导出数据。文件即将可用！",
    "export.Resumo dos passos": "步骤摘要",
    "export.salvar imagem": "保存图片"
  },
};

export default zh;