import styled from 'styled-components';

export const Nav = styled.nav`
  position: relative;
  height: 100%;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  border-radius: 0px 35px 35px 0px;
  transition: all 0.5s ease-in-out;
  padding: 23px 0;
  gap: 60px;

  &:hover {
    box-shadow: inset -40px 0px 30px -20px rgba(255, 255, 255, 0.2);
    & .button-hidden {
      opacity: 1;
    }
  }
  &.back-off {
    width: 100px;
    display: flex;
    justify-content: flex-start;
  }
  p {
    color: white;
    font-size: 11px;
    position: absolute;
    bottom: 2px;
    width: 100%;
    left: 0;
    text-align: center;
    cursor: pointer;
  }

  .cache {
    color: white;
    font-size: 13px;
    position: absolute;
    width: 100%;
    bottom: 30px;
    left: 0;
    cursor: pointer;
    text-align: center;
  }

  #link {
   // margin-top: 25px;
  }

  @media screen and (max-width: 960px) {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    border-radius: 0px;
    transition: all 0.5s ease-in-out;
    gap: 45px;
    &:hover {
      box-shadow: none;
    }
    &.back-off {
    }
    #link {
      margin-top: 0;
    }
  }
`;

export const ButtonHidden = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  background: tranparent;
  border: none;
  padding: 10px 5px;
  top: 0;
  right: 5px;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.5s ease-in-out;
  outline: none;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

export const LeftArrow = styled.div`
  &.right {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid white;
  }

  &.left {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid white;
  }
`;

export const Image = styled.img`
  height: 80px;
  width: 132px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  
  &.back-off {
    opacity: 0;
  }
  @media screen and (max-width: 960px) {
    padding-left: 20px;
  }
`;
