import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import {Div} from './styles'

type BackdropProps = {
  moveUp?: boolean;
};

const Backdrop: FC<BackdropProps> = (props: BackdropProps) => {
  return ReactDOM.createPortal(
    <Div moveUp={props.moveUp} />,
    document.getElementById('backdrop')!
  );
}

export default Backdrop
