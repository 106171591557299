import { LOCALES } from '../constants';
const de = {
  [LOCALES.DE]: {
    //LOGIN
    "login.Login": "Anmelden",
    "login.Informe seu e-mail": "Geben Sie Ihre E-Mail-Adresse ein",
    "login.E-mail inválido. Por favor, verifique": "Ungültige E-Mail-Adresse. Bitte überprüfen Sie diese.",
    "login.Senha": "Passwort",
    "login.Sua senha deve possuir no mínimo 6 caracteres": "Ihr Passwort muss mindestens 6 Zeichen lang sein",
    "login.Permanecer conectado": "Verbunden bleiben",
    "login.Esqueci minha senha": "Passwort vergessen",
    "login.ENTRAR": "EINGEBEN",
    "login.CRIAR CONTA": "KONTO ERSTELLEN",
    "login.Não foi possÍvel fazer o login. Verifique seus dados e tente novamente": "Eine Anmeldung war nicht möglich. Überprüfen Sie Ihre Daten und versuchen Sie es erneut.",
    "login.Solicitar Acesso": "Zugriff anfordern",
    "login.Utilize esta opção caso possua apenas fornos da versão antiga, que não geram PIN.": "Verwenden Sie diese Option, wenn Sie nur Öfen der alten Version haben, die keine PIN generieren.",
    "login.A PLATAFORMA IOK MUDOU!": "DIE IOK-PLATTFORM HAT SICH GEÄNDERT!",
    "login.Não exibir este aviso novamente": "Diese Benachrichtigung nicht erneut anzeigen",
    "login.Se você já possui um cadastro na antiga plataforma,": "Wenn Sie bereits auf der früheren Plattform registriert sind,",
    "login.clique aqui": "klicken Sie hier,",
    "login. para migrar seus dados e aproveitar toda a experiência IOK.": "um Ihre Daten zu migrieren und das volle IOK-Erlebnis zu genießen.",
    "login.Sua conta não foi ativada. Por gentileza, verifique seu e-mail para realizar a ativação": "Ihr Konto wurde nicht aktiviert. Überprüfen Sie bitte Ihre E-Mail, um es zu aktivieren",
    "login.Para solicitar acesso a uma organização, o nome informado por você deve ser exatamente o mesmo cadastrado pelo administrador da conta. Em caso de dúvida, procure o responsável pela rede.": "Um Zugang zu einer Organisation zu beantragen, muss der von Ihnen angegebene Name genau mit dem Namen übereinstimmen, der vom Administrator des Kontos registriert wurde. Bei Unsicherheiten suchen Sie nach der verantwortlichen Kette.",
    "login.Se você já possui um cadastro na antiga plataforma, ": "Wenn Sie bereits eine Registrierung auf der alten Plattform haben, ",
    "login. para": " für ",
    "login.migrar": "wandern ",
    "login.seus dados e aproveitar toda a experiência IOK.": "Ihre Daten und genießen Sie das gesamte IOK-Erlebnis.",
    "login.PLATAFORMA EM MANUTENÇÃO": "PLATTFORM IN WARTUNG",
    "login.ESTAMOS PREPARANDO A NOVA PLATAFORMA IOK E EM BREVE ELA ESTARÁ PRONTA!": "Wir bereiten die neue IOK-Plattform vor und sie wird bald fertig sein!",
    "login.Migrar Conta": "Konto migrieren",
    "login.Se você já possui um cadastro na antiga plataforma, clique aqui em Migrar Conta para migrar seus dados e aproveitar toda a experiência IOK": "Wenn Sie bereits über eine Registrierung auf der alten Plattform verfügen, klicken Sie hier auf Konto migrieren, um Ihre Daten zu migrieren und das gesamte IOK-Erlebnis zu genießen",
    "login.Política de privacidade": "Datenschutzrichtlinie",
    "login.ENTENDI": "OK",
    "login.Para melhorar a sua experiência na plataforma e oferecer serviços personalizados, utilizamos cookies.": "Wir verwenden Cookies, um Ihr Erlebnis auf der Plattform zu verbessern und personalisierte Dienste anzubieten.",
    "login.Ao utilizar a Plataforma IOK, você automaticamente concorda com seu uso.": "Durch die Nutzung der IOK-Plattform stimmen Sie der Nutzung automatisch zu.",

    //REGISTER
    "DISTRIBUIDOR": "HÄNDLER: Ich vertreibe Übungsgeräte.",
    "REDE": "NETZWERK: Ich besitze ein Netzwerk.",
    "DONO": "UNTERNEHMEN: Ich besitze mein eigenes Unternehmen/ich bin Teil eines Netzwerks.",
    "register.Informe seu e-mail cadastrado na plataforma IOK anterior": "Geben Sie Ihre E-Mail-Adresse ein, die auf der vorherigen IOK-Plattform registriert ist",
    "register.E-mail inválido. Por favor, verifique": "Ungültige E-Mail-Adresse. Bitte überprüfen Sie diese.",
    "register.BUSCAR DADOS": "DATEN SUCHEN",
    "register.Nome da organização": "Organisationsname",
    "register.Informe o nome de sua organização": "Geben Sie den Namen Ihrer Organisation ein",
    "register.O campo nome da organização é obrigatório": "Das Feld für den Organisationsnamen ist erforderlich",
    "register.Este nome já existe. Por favor, escolha outro": "Dieser Name existiert bereits. Bitte wählen Sie einen anderen aus.",
    "register.RECUPERAR CONTA": "KONTO ABRUFEN",
    "register.E-mail não encontrado": "E-Mail nicht gefunden",
    "register.Digite seu e-mail da plataforma IOK antiga": "Geben Sie Ihre E-Mail-Adresse von der früheren IOK-Plattform ein",
    "register.Não foi possível recuperar sua conta. Verifique seus dados e tente novamente": "Es war nicht möglich, Ihr Konto abzurufen. Überprüfen Sie Ihre Daten und versuchen Sie es erneut.",
    "register.Não foi possível recuperar seus dados. Verifique e tente novamente": "Es war nicht möglich, Ihre Daten abzurufen. Überprüfen Sie und versuchen Sie es erneut.",
    "register.PROSSEGUIR": "WEITER",
    "register.VOCÊ ESTÁ SOLICITANDO ACESSO PARA A ORGANIZAÇÃO": "SIE BEANTRAGEN ZUGANG ZUR ORGANISATION:",
    "register.COM O ADMINISTRADOR": "MIT ADMINISTRATOR:",
    "register.E-mail já cadastrado na plataforma. Informe um novo e-mail ou acesse a plataforma com o e-mail já cadastrado": "E-Mail ist bereits auf der Plattform registriert. Geben Sie eine neue E-Mail-Adresse ein oder greifen Sie mit der bereits registrierten zu.",
    "register.Empresa já cadastrada na plataforma. Por favor, informe o nome de uma nova empresa": "Unternehmen ist bereits auf der Plattform registriert. Bitte geben Sie einen neuen Firmennamen ein.",
    "register.VOCÊ NÃO RECONHECEU O USUÁRIO {userName} COMO PARTE DA SUA ORGANIZAÇÃO E POR ISSO A CONTA DELE FOI DELETADA.": "SIE HABEN DEN BENUTZER {Benutzername} NICHT ALS TEIL IHRER ORGANISATION ERKANNT UND DAHER WURDE SEIN KONTO GELÖSCHT.",
    "register.NÂO FOI POSSÍVEL DELETAR O USÁRIO {userName}. POR GENTILEZA, TENTE NOVAMENTE MAIS TARDE.": "ES WAR NICHT MÖGLICH, DEN BENUTZER {Benutzername} ZU LÖSCHEN. VERSUCHEN SIE ES SPÄTER ERNEUT.",
    "register.CONFIRMAR": "BESTÄTIGEN",
    "register.Informe o ID da organização": "Geben Sie die ID Ihrer Organisation ein",
    "register.O campo com ID é obrigatório": "Das ID-Feld ist erforderlich",
    "register.Ainda não tenho uma organização. Criar": "Ich habe noch keine Organisation. Erstellen Sie eine",
    "register.ID informado não corresponde a nenhuma companhia. Verifique e tente novamente": "Die angegebene ID entspricht keinem Unternehmen. Überprüfen Sie und versuchen Sie es erneut.",
    "register.O ADMINISTRADOR DA ORGANIZAÇÃO RECEBERÁ UM E-MAIL PARA ATIVAR SUA CONTA. POR GENTILIZA, AGUARDE": "DER ORGANISATIONSAADMINISTRATOR ERHÄLT EINE E-MAIL ZUR AKTIVIERUNG IHRES BENUTZERKONTOS. BITTE WARTEN SIE",
    "register.Este nome de franquia já possui uma conta ativa na plataforma": "Dieser Franchisename hat bereits ein aktives Konto auf der Plattform.",
    "register.Não foi possível fazer o cadastro. Verifique seus dados e tente novamente": "Die Registrierung war nicht möglich. Überprüfen Sie Ihre Daten und versuchen Sie es erneut.",
    "register.VOCÊ RECEBERÁ UM E-MAIL PARA CONCLUIR SEU CADASTRO E ATIVAR SUA CONTA DE ADMINISTRADOR": "SIE ERHALTEN EINE E-MAIL, UM IHRE REGISTRIERUNG ABZUSCHLIESSEN UND IHR ADMINISTRATORKONTO ZU AKTIVIEREN.",
    "register.Nome": "Name",
    "register.Informe seu nome": "Geben Sie Ihren Namen ein",
    "register.O campo nome é obrigatório": "Das Namensfeld ist erforderlich",
    "register.E-mail": "E-Mail",
    "register.Informe seu e-mail": "Geben Sie Ihre E-Mail-Adresse ein",
    "register.E-mail inválido. Por favor, verifique novamente": "Ungültige E-Mail-Adresse. Bitte überprüfen Sie diese.",
    "register.Telefone": "Telefonnummer",
    "register.Informe seu telefone": "Geben Sie Ihre Telefonnummer ein",
    "register.Crie sua senha": "Ein Passwort erstellen",
    "register.Informe sua senha": "Geben Sie Ihr Passwort ein",
    "register.O campo senha deve conter no mínimo 6 caracteres": "Das Passwortfeld muss mindestens 6 Zeichen lang sein",
    "register.Confirme sua senha": "Passwort bestätigen",
    "register.As senhas devem ser iguais": "Das Passwort muss übereinstimmen",
    "register.CRIAR CONTA": "KONTO ERSTELLEN",
    "register.CONTA ATIVADA COM SUCESSO": "KONTO ERFOLGREICH AKTIVIERT",
    "register.ACESSAR": "ZUGANG",
    "register.NÃO FOI POSSÍVEL ATIVAR SUA CONTA": "ES WAR NICHT MÖGLICH, IHR KONTO ZU AKTIVIEREN",
    "register.Digite seu telefone": "Geben Sie Ihre Telefonnummer ein",
    "register.AGUARDE ENQUANTO TRANSFERIMOS SEUS DADOS. ESTE PROCESSO DEVE LEVAR ALGUNS MINUTOS. CASO VOCÊ POSSUA UMA GRANDE QUANTIDADE DE DADOS, A TRANSFERÊNCIA PODE LEVAR ATÉ UMA HORA.": "BITTE WARTEN SIE, WÄHREND WIR IHRE DATEN ÜBERTRAGEN. Dieser Vorgang sollte einige Minuten dauern. WENN SIE ÜBER EINE GROSSE DATENMENGE VERFÜGEN, KANN DIE ÜBERTRAGUNG BIS ZU EINER STUNDE DAUERN.",
    "register.DADOS TRANSFERIDOS COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "DATEN ERFOLGREICH ÜBERTRAGEN! KLICKEN SIE AUF DEN UNTEN STEHENDEN KNOPF, UM AUF DIE PLATTFORM ZU ZUGREIFEN.",
    "register.EMAIL INVÁLIDO. POR GENTILEZA, VERIFIQUE SE SUA CONTA JÁ FOI ATIVADA.": "UNGÜLTIGE E-MAIL. BITTE ÜBERPRÜFEN SIE, OB IHR KONTO BEREITS AKTIVIERT WURDE.",
    "register.A nova plataforma IOK agora conta com novas formas de acesso - que são referentes ao tipo de organização em que você atua. O responsável pela sua organização deve migrar a conta principal da empresa, se tornando o administrador, enquanto o restante dos funcionários devem se vincular a ela pelo ID fornecido.": "Die neue IOK-Plattform hat jetzt neue Zugangsformen, die mit der Art der Organisation, in der Sie arbeiten, zusammenhängen. Die für Ihre Organisation Verantwortlichen müssen das Hauptkonto des Unternehmens migrieren und dabei zum Administrator werden, während der Rest der Mitarbeiter sich damit verknüpfen muss, indem er die bereitgestellte ID verwendet.",
    "register.O administrador da minha organização já realizou a migração da conta e temos um ID. Desejo migrar meus dados de usuário.": "Der Administrator meiner Organisation hat das Konto bereits migriert, und wir haben eine ID. Ich möchte meine Benutzerdaten migrieren.",
    "register.Desejo migrar a conta da minha organização e me tornar o administrador.": "Ich möchte das Konto meiner Organisation migrieren und Administrator werden.",
    "register.Conta da organização já migrada": "Organisationskonto bereits migriert",
    "register.Migrar conta da organização": "Organisationskonto migrieren",
    "register.VOCÊ POSSUI MUITOS MENUS, NÃO CONSEGUIMOS REALIZAR A IMPORTAÇÃO AUTOMÁTICA. SUA CONTA SERÁ ATIVADA, PORÉM OS MENUS PRECISARÃO SER MIGRADOS MANUALMENTE.": "SIE HABEN VIELE MENÜS, WIR KÖNNEN KEINEN AUTOMATISCHEN IMPORT DURCHFÜHREN. IHR KONTO WIRD AKTIVIERT, MENÜS MÜSSEN ABER MANUELL MIGRIERT WERDEN.",
    "register.PARA MIGRAR SEUS MENUS, APÓS O LOGIN, CLIQUE NO SEU NOME NO CANTO SUPERIOR DIREITO, CLIQUE EM EDITAR CONTA E DEPOIS EM IMPORTAR MENUS.": "UM IHRE MENÜS ZU MIGRIEREN, KLICKEN SIE NACH DER ANMELDUNG AUF IHREN NAMEN IN DER OBEREN RECHTEN ECKE, KLICKEN SIE AUF KONTO BEARBEITEN UND DANN AUF MENÜS IMPORTIEREN.",
    "register.Atenção:": "Achtung:",
    "register.apenas uma pessoa da organização pode criar a conta e, depois de ativá-la, convidar os demais usuários da empresa para acessá-la.": "nur eine Person aus der Organisation kann das Konto erstellen und nach der Aktivierung die anderen Benutzer des Unternehmens einladen, darauf zuzugreifen.",
    "register.TOKEN DESENVOLVEDOR CANCELADO": "ENTWICKLER-TOKEN STORNIERT",

    //HOME
    "home.Menus disponíveis na plataforma": "Menüs auf der Plattform verfügbar",
    "home.Criados na plataforma": "Auf der Plattform erstellt",
    "home.Recebidos dos fornos": "Von den Öfen empfangen",
    "home.Equipamentos vinculados": "Verknüpfte Geräte",
    "home.Total de receitas cadastradas": "Gesamtzahl der registrierten Rezepte",
    "home.Receitas": "Rezepte",
    "home.Receitas em menus": "Rezepte in Menüs",
    "home.Em menus": "In Menüs",
    "home.Receitas salvas no Cookbook": "Rezepte im Kochbuch gespeichert",
    "home.No Cookbook": "Im Kochbuch",
    "home.Atualizações": "Aktualisierungen",
    "home.Copa": "Copa",
    "home.Rocket": "Rocket",
    "home.Single Mag": "Single Mag",
    "home.Versão instalada": "Installierte Version",
    "home.Versão disponível": "Verfügbare Version",
    "home.Usuários cadastrados": "Registrierte Benutzer",
    "home.Aceitação pendente": "Ausstehende Zustimmung",
    "home.Atualizado": "Aktualisiert",
    "home.OLÁ": "HALLO",
    "home.É muito bom ter você aqui!": "Schön, dass Sie hier sind!",
    "home.Com a Plataforma IOK é possível administrar seus fornos de onde estiver, criar e gerenciar receitas e menus, fazer backups, analisar os dados de seus equipamentos e muito mais!": "Mit der IOK-Plattform können Sie Ihre Öfen von überall aus verwalten, Rezepte und Menüs erstellen und verwalten, Backups erstellen, Daten Ihrer Geräte analysieren und vieles mehr!",
    "home.Conheça a plataforma": "Entdecken Sie die Plattform",
    "home.Taxa de erro por modelo": "Fehlerrate pro Modell",
    "home.Erro": "Fehler",
    "home.Modelos em evidência": "Hervorgehobene modelle",
    "home.Modelos": "Modelle",
    "home.Total de equipamentos vinculados": "Gesamtzahl der verbundenen Geräte",
    "home.Total de equipamentos online": "Gesamtzahl der online Geräte",
    "home.Equipamentos online": "Online Geräte",
    "home.Países com Prática": "Länder mit Prática",
    "home.Países": "Länder",
    "home.Aguarde enquanto carregamos os dados": "Bitte warten Sie, während wir die Daten laden",
    "home.Quase lá! Aguarde um pouco mais": "Fast geschafft! Bitte warten Sie noch einen Moment",
    "home.QUEREMOS SABER SUA OPINIÃO SOBRE": "WIR MÖCHTEN IHRE MEINUNG DARÜBER WISSEN",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a Plataforma {model} foi criado!": "Wir legen großen Wert auf Ihre Meinung und deshalb wurde dieses Zufriedenheitsumfrageformular auf der {model}-Plattform erstellt!",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a linha {model} foi criado!": "Wir legen großen Wert auf Ihre Meinung und deshalb wurde dieses Formular zur Zufriedenheitsumfrage über die Produktlinie {model} erstellt!",
    "home.Suas respostas nos ajudarão a aprimorar nossos serviços, garantindo que continuemos a oferecer a você a melhor experiência possível.": "Ihre Antworten werden uns helfen, unsere Dienstleistungen zu verbessern und sicherzustellen, dass wir Ihnen weiterhin das bestmögliche Erlebnis bieten.",
    "home.Reserve uns minutinhos de seu dia e participe!": "Nehmen Sie sich ein paar Minuten Zeit und machen Sie mit!",
    "home.Responder a pesquisa": "Beantworten Sie die Umfrage",
    "home.Vendas por Equipamento": "Verkäufe nach Ausrüstung",
    "home.Total de equipamentos vendidos": "Gesamt verkaufte Ausrüstung",
    "home.Equipamentos vendidos": "Verkaufte Ausrüstung",

    //MAIN
    "main.HOME": "HOME",
    "main.COOKBOOK": "KOCHBUCH",
    "main.MEUS MENUS": "MEINE MENÜS",
    "main.EQUIPAMENTOS": "AUSRÜSTUNG",
    "main.USUÁRIOS": "BENUTZER",
    "main.ATENDIMENTOS": "DIENSTLEISTUNGEN",
    "main.Idioma": "Sprache",
    "main.Editar conta": "Konto bearbeiten",
    "main.Configurações": "Einstellungen",
    "main.Sair": "Abmelden",
    "main.Configurações salvas com sucesso!": "Einstellungen erfolgreich gespeichert!",
    "main.SALVAR": "SPEICHERN",
    "main.CONFIGURAÇÕES": "EINSTELLUNGEN",
    "main.Tema dark": "Dunkles Thema",
    "main.Notificações": "Benachrichtigungen",
    "main.Informações para as receitas": "Rezeptinformationen",
    "main.Peso:": "Gewicht",
    "main.Gramas": "Gramm",
    "main.Oz": "Unze",
    "main.Temperatura:": "Temperaturen:",
    "main.Celsius (°C)": "Celsius (°C)",
    "main.Fahrenheit (°F)": "Fahrenheit (°F)",
    "main.Conta editada com sucesso!": "Konto erfolgreich bearbeitet!",
    "main.EDITAR CONTA": "KONTO BEARBEITEN",
    "main.Nome": "Name",
    "main.Preencha com o seu nome": "Geben Sie Ihren Namen ein",
    "main.Telefone": "Telefon",
    "main.Preencha com o seu telefone": "Geben Sie Ihre Telefonnummer ein",
    "main.E-mail": "E-Mail",
    "main.Preencha com o seu e-mail": "Geben Sie Ihre E-Mail-Adresse ein",
    "main.Alterar Senha": "Passwort ändern",
    "main.ALTERAR SENHA": "PASSWORT ÄNDERN",
    "main.Senha atual": "Aktuelles Passwort",
    "main.Nova senha": "Neues Passwort",
    "main.Confirmar nova senha": "Neues Passwort bestätigen",
    "main.Informe sua senha atual": "Geben Sie Ihr aktuelles Passwort ein",
    "main.O campo senha deve conter no mínimo 6 caracteres": "Das Passwortfeld muss mindestens 6 Zeichen lang sein",
    "main.Informe sua nova senha": "Geben Sie Ihr neues Passwort ein",
    "main.Confirme sua nova senha": "Bestätigen Sie Ihr neues Passwort",
    "main.Sua nova senha deve conter no mínimo 6 caracteres": "Ihr neues Passwort muss mindestens 6 Zeichen lang sein",
    "main.INFORME SUA SENHA": "GEBEN SIE IHR PASSWORT EIN",
    "main.Informe sua senha para realizar a alteração de e-mail da plataforma:": "Geben Sie Ihr Passwort ein, um Ihre E-Mail-Adresse auf der Plattform zu ändern:",
    "main.CONFIRMAR": "BESTÄTIGEN",
    "main.Existem campos que não foram preenchidos corretamente": "Es gibt Felder, die nicht korrekt ausgefüllt wurden",
    "main.Informe sua senha para prosseguir com a alteração de e-mail": "Geben Sie Ihr Passwort ein, um mit der Änderung Ihrer E-Mail fortzufahren",
    "main.Senha alterada com sucesso": "Passwort erfolgreich geändert",
    "main.Informe sua senha atual corretamente": "Geben Sie Ihr aktuelles Passwort korrekt ein",
    "main.As senhas devem ser iguais": "Die Passwörter müssen übereinstimmen",
    "main.Nome do novo tipo de usuário": "Name des neuen Benutzertyps",
    "main.Dê um nome para o novo tipo de usuário": "Geben Sie dem neuen Benutzertyp einen Namen",
    "main.Token Desenvolvedor": "Entwickler-Token",
    "main.O Token Desenvolvedor é um código único e intransferível que permite a realização de alterações": "Der Entwickler-Token ist ein einzigartiger und nicht übertragbarer Code, der Änderungen auf Entwicklungsebene direkt im Code der Plattform durch externe APIs ermöglicht. Diese ermöglichen die Anpassung der Plattform, um die Bedürfnisse Ihres Unternehmens besser zu erfüllen.",
    "main.É recomendado que estas alterações sejam realizadas por um profissional da área de tecnologia": "Es wird empfohlen, dass diese Änderungen von einem Fachmann aus dem Technologiebereich durchgeführt werden.",
    "main.Para acessar o token, informe sua senha": "Um auf den Token zuzugreifen, geben Sie Ihr Passwort ein.",
    "main.Copie e cole o token na autenticação de todas as requisições.": "Kopieren und fügen Sie den Token in die Authentifizierung aller Anfragen ein.",
    "main.Seu Token": "Ihr Token",
    "main.Token copiado com sucesso!": "Token erfolgreich kopiert!",
    "main.Você deseja cancelar o preenchimento das informações?": "Möchten Sie das Ausfüllen der Informationen abbrechen?",
    "main.Rever tutoriais": "Tutorials erneut ansehen",
    "main.Tutoriais resetados com sucesso": "Tutorials erfolgreich zurückgesetzt",
    "main.review.tutorials": "Möchten Sie die Tutorials der IOK-Plattform erneut ansehen?",
    "main.Procurar": "Suchen",


    //MENUS
    "menus.Procurar menu": "Suchmenü oder Benutzer",
    "menus.Procurar menu excluído": "Gelöschtes Menü suchen",
    "menus.Menus": "Menüs",
    "menus.Lixeira": "Papierkorb",
    "menus.CRIAR MENU": "MENÜ ERSTELLEN",
    "menus.IMPORTAR MENU": "MENÜ IMPORTIEREN",
    "menus.Os arquivos serão mantidos na lixeira por 30 dias a contar de sua data de exclusão. Após este período, eles serão excluídos definitivamente.": "Die Dateien werden 30 Tage lang ab dem Löschdatum im Papierkorb aufbewahrt. Nach dieser Frist werden sie endgültig gelöscht.",
    "menus.VOLTAR": "RÜCKKEHR",
    "menus.Compatível com: ": "Kompatibel mit: ",
    "menus.Última atualização: ": "Letzte Aktualisierung: ",
    "menus.Última atualização": "Letzte Aktualisierung",
    "menus.EXPORTAR MENU": "MENÜ EXPORTIEREN",
    "menus.EDITAR MENU": "MENÜ BEARBEITEN",
    "menus.Equipamentos associados": "Zugehörige Ausrüstung",
    "menus.Grupos": "Gruppen",
    "menus.Procurar grupo": "Gruppe suchen",
    "menus.CRIAR GRUPO": "GRUPPE ERSTELLEN",
    "menus.receitas": "Rezepte",
    "menus.Nenhum grupo cadastrado": "Keine Gruppe registriert",
    "menus.Nenhum menu cadastrado": "Kein Menü registriert",
    "menus.Nome": "Name",
    "menus.Modelo": "Modell",
    "menus.Atividade": "Aktivität",
    "menus.Associação": "Assoziation",
    "menus.Exportar": "Exportieren",
    "menus.Editar": "Bearbeiten",
    "menus.Excluir": "Löschen",
    "menus.Equips": "Ausrüstung",
    "menus.Receitas": "Rezepte",
    "menus.Compatibilidade": "Kompatibilität",
    "menus.Versão": "Version",
    "menus.Origem": "Ursprung",
    "menus.Excluído por: ": "Gelöscht von: ",
    "menus.Restaurar": "Wiederherstellen",
    "menus.Existem campos que não foram preenchidos": "Es gibt Felder, die nicht ausgefüllt wurden.",
    "menus.Menu criado com sucesso!": "Menü erfolgreich erstellt!",
    "menus.Existem campos com informações inválidas": "Es gibt Felder mit ungültigen Informationen",
    "menus.SALVAR": "SPEICHERN",
    "menus.CANCELAR": "ABBRECHEN",
    "menus.Nome do menu:": "Name des Menüs: ",
    "menus.Informe o nome do menu": "Menünamen angeben",
    "menus.Preencha o nome do menu sem caracteres especiais": "Füllen Sie den Menünamen ohne Sonderzeichen aus",
    "menus.Selecionar": "Auswählen",
    "menus.Idioma": "Sprache",
    "menus.Temperatura de pré-aquec 1:": "Vorheiztemperatur 1:",
    "menus.Informe a temperatura de pré-aquec 1": "Vorheiztemperatur 1 angeben",
    "menus.Temperatura de pré-aquec 2:": "Vorheiztemperatur 2:",
    "menus.Informe a temperatura de pré-aquec 2": "Vorheiztemperatur 2 angeben",
    "menus.Tempo para estabilização:": "Stabilisierungszeit:",
    "menus.Informe o tempo para estabilização": "Stabilisierungszeit angeben",
    "menus.Desativar pré-aquec 2": "Vorheizung 2 deaktivieren",
    "menus.Configurações de data e hora": "Datum- und Uhrzeiteinstellungen",
    "menus.Data:": "Datum:",
    "menus.DD/MM/AAAA": "TT/MM/JJJJ",
    "menus.Hora:": "Uhrzeiten:",
    "menus.24h": "24h",
    "menus.AM/PM": "AM/PM",
    "menus.EUR": "EUR: TT/MM/JJJJ",
    "menus.USA": "USA: MM/TT/JJJJ",
    "menus.ISO": "ISO: JJJJ/MM/TT",
    "menus.Seleção de acessos para habilitar": "Auswahl des aktivierten Zugriffs",
    "menus.Modo manual": "Manueller Modus",
    "menus.Habilitado": "Aktiviert",
    "menus.Desabilitado": "Deaktiviert",
    "menus.Favoritos": "Favoriten",
    "menus.Aquecer/Dourar": "Erhitzen / Bräunen",
    "menus.Repetir receita": "Rezept wiederholen",
    "menus.Configurações gerais do menu": "Allgemeine Menüeinstellungen",
    "menus.Temperatura": "Temperatur",
    "menus.Temperatura:": "Temperatur:",
    "menus.Celsius (°C)": "Celsius (°C)",
    "menus.Fahrenheit (°F)": "Fahrenheit (°F)",
    "menus.Peso": "Gewicht",
    "menus.Gramas": "Gramm",
    "menus.Oz": "Unze",
    "menus.Tema:": "Thema:",
    "menus.Dark": "Dunkel",
    "menus.Light": "Hell",
    "menus.Introdução:": "Einführung:",
    "menus.Ativada": "Aktiviert",
    "menus.Desativada": "Deaktiviert",
    "menus.Menu deletado com sucesso!": "Menü erfolgreich gelöscht!",
    "menus.Não foi possivel deletar o menu": "Menü kann nicht gelöscht werden",
    "menus.Sim": "Ja",
    "menus.Não": "Nein",
    "menus.Excluir definitivamente": "Dauerhaft löschen",
    "menus.SALVAR EM PEN DRIVE": "Auf USB-Stick speichern",
    "menus.EXPORTAR": "EXPORTIEREN",
    "menus.Selecione os modelos e versões dos equipamentos que receberão o menu.": "Wählen Sie die Ausrüstungsmodelle und Versionen aus, um das Menü zu empfangen",
    "menus.Forno": "Ofen",
    "menus.Fundo verde": "Grüner Hintergrund",
    "menus.Fundo preto": "Schwarzer Hintergrund",
    "menus.Linux": "Linux",
    "menus.Atenção: antes de transferir o menu de seu pen drive para o equipamento, é necessário descompactar o arquivo, exceto para menus de equipamentos Linux.": "Achtung: Bevor Sie das Menü von Ihrem USB-Stick auf das Gerät übertragen, ist es notwendig, die Datei zu entpacken, außer bei Menüs für Linux-Geräte.",
    "menus.Selecione os equipamentos para os quais deseja exportar o menu.": "Wählen Sie das Gerät aus, für das Sie das Menü exportieren möchten.",
    "menus.Nome do equipamento": "Name des Geräts",
    "menus.Selecionar todos": "Alles auswählen",
    "menus.Selecionar todas": "Alle auswählen",
    "menus.Categoria: ": "Kategorie: ",
    "menus.Você deseja excluir o menu?": "Möchten Sie das Menü löschen?",
    "menus.O menu será deletado da plataforma, mas permanecerá no equipamento. Uma cópia de segurança será criada automaticamente e ficará disponível por 30 dias em Meus Menus - Lixeira.": "Das Menü wird von der Plattform gelöscht, bleibt jedoch auf dem Gerät erhalten. Es wird automatisch eine Sicherungskopie erstellt und steht 30 Tage lang in Meine Menüs - Papierkorb zur Verfügung.",
    "menus.Você deseja excluir o arquivo definitivamente?": "Möchten Sie die Datei dauerhaft löschen?",
    "menus.Esta ação não poderá ser desfeita.": "Diese Aktion kann nicht rückgängig gemacht werden.",
    "menus.Menu editado com sucesso!": "Menü erfolgreich bearbeitet!",
    "menus.IMPORTAR": "IMPORTIEREN",
    "menus.VERIFICAÇÃO DE VERSÃO": "VERSIONSÜBERPRÜFUNG",
    "menus.Para conferir a versão da placa de seu equipamento, siga os seguintes passos:": "Um die Board-Version des Geräts zu überprüfen, befolgen Sie die folgenden Schritte:",
    "menus.No forno, acesse Configurações;": "Rufen Sie in dem Ofen die Einstellungen auf;",
    "menus.Insira a senha 456789;": "Geben Sie das Passwort 456789 ein;",
    "menus.Selecione a opção Sistema;": "Wählen Sie die Option System aus;",
    "menus.Selecione Informações do Fabricante;": "Wählen Sie Herstellerinformationen aus;",
    "menus.O modelo de sua placa estará na linha Firmware IHM;": "Ihr Board-Modell befindet sich in der Zeile Firmware IHM;",
    "menus.1ª geração A": "1. Generation A",
    "menus.Firmware IHM: 12.3.4": "Firmware IHM: 12.3.4",
    "menus.1ª geração B": "1. Generation B",
    "menus.2ª geração/Linux:": "2. Generation/Linux",
    "menus.Firmware IHM: L1.2.3": "Firmware IHM: L1.2.3",
    "menus.Selecione o modelo/versão do equipamento do qual deseja importar o menu. Saiba como conferir a versão de seu equipamento": "Wählen Sie das Modell/Version des Geräts aus, von dem Sie das Menü importieren möchten. Erfahren Sie, wie Sie die Version Ihres Geräts überprüfen können ",
    "menus.Selecione o modelo/versão do equipamento": "Wählen Sie das Gerätemodell/die Version aus",
    "menus.Como verificar a versão da placa": "Wie man die Board-Version überprüft",
    "menus.1° Geração": "1. Generation",
    "menus.Menu restaurado com sucesso!": "Menü erfolgreich wiederhergestellt!",
    "menus.Não foi possivel restaurar o menu": "Menü kann nicht wiederhergestellt werden",
    "menus.RESTAURAR ARQUIVO": "DATEI WIEDERHERSTELLEN",
    "menus.Você deseja restaurar o arquivo?": "Möchten Sie die Datei wiederherstellen?",
    "menus.O arquivo será restaurado como uma nova versão na plataforma, na guia Todos. Para salvá-lo no equipamento, exporte-o.": "Die Datei wird als neue Version auf der Plattform unter der Registerkarte „Alle“ wiederhergestellt. Um es auf Ihrem Gerät zu speichern, exportieren Sie es.",
    "menus.EXCLUIR MENU": "MENÜ LÖSCHEN",
    "menus.Nenhum equipamento associado": "Keine zugehörige Ausrüstung",
    "menus.Nomeie o novo menu": "Name des neuen Menüs",
    "menus.Nomeie o novo grupo": "Name der neuen Gruppe",
    "menus.Nome do grupo:": "Name der Gruppe: ",
    "menus.Preencha o nome do grupo sem caracteres especiais": "Füllen Sie den Gruppennamen ohne Sonderzeichen aus",
    "menus.Pré-aquecimento": "Vorheizung",
    "menus.Pré-aquecimento:": "Vorheizung:",
    "menus.Grupo criado com sucesso!": "Gruppe erfolgreich erstellt!",
    "menus.SELECIONE UMA IMAGEM": "BILD AUSWÄHLEN",
    "menus.SELECIONAR": "AUSWÄHLEN",
    "menus.Selecionar do computador": "Von Computer auswählen",
    "menus.Selecionar do banco de imagens": "Aus Bildbank auswählen",
    "menus.Formato .JPG ou .PNG": "Format .JPG oder .PNG",
    "menus.Procurar imagem": "Bild suchen",
    "menus.Pertence ao menu": "Gehört zum Menü",
    "menus.Alterar imagem": "Bild ändern",
    "menus.Remover imagem": "Bild entfernen",
    "menus.EDITAR GRUPO": "GRUPPE BEARBEITEN",
    "menus.CRIAR RECEITA": "REZEPT ERSTELLEN",
    "menus.SALVAR DO COOKBOOK": "AUS KOCHBUCH SPEICHERN",
    "menus.Procurar receita do grupo": "Gruppenrezept suchen",
    "menus.Ingrediente principal": "Hauptzutat",
    "menus.Mover receitas": "Rezepte verschieben",
    "menus.Excluir receitas": "Rezepte löschen",
    "menus.Editar grupo": "Gruppe bearbeiten",
    "menus.Excluir grupo": "Gruppe löschen",
    "menus.aqui": "hier",
    "menus.Se o código possuir apenas números e o fundo da tela for verde, pertence à 1ª A, se possuir apenas números e o fundo da tela for preta, pertence à 1ª B e, se começar com as letras L, F ou S, sua placa pertence à 2ª geração/Linux.": "Wenn der Code nur Zahlen enthält und der Hintergrund grün ist, gehört er zur 1. A. Wenn er nur Zahlen enthält und der Hintergrund schwarz ist, gehört er zur 1. B. Wenn er mit den Buchstaben L, F oder S beginnt, gehört die Platine zur 2. Generation/Linux.",
    "menus.1ª A - Fundo verde": "1. A - Grüner Hintergrund",
    "menus.1ª B - Fundo preto": "1. B - Schwarzer Hintergrund",
    "menus.2ª - Linux": "2. - Linux",
    "menus.Tivemos um problema. Tente novamente mais tarde.": "Es gab ein Problem. Versuchen Sie es später erneut.",
    "menus.Grupo editado com sucesso!": "Gruppe erfolgreich bearbeitet!",
    "menus.AINDA NÃO HÁ GRUPOS AQUI :(": "HIER SIND IMMER NOCH KEINE GRUPPEN :(",
    "menus.NENHUM MENU NA LIXEIRA": "KEIN MENÜ IM PAPIERKORB",
    "menus.AINDA NÃO HÁ MENUS AQUI :(": "HIER SIND IMMER NOCH KEINE MENÜS :(",
    "menus.Adicionar imagem": "Bild hinzufügen",
    "menus.Nome da receita": "Name des Rezepts",
    "menus.Origem da receita": "Ursprung des Rezepts",
    "menus.Detalhes da receita": "Details des Rezepts",
    "menus.Programação da receita": "Programmierung des Rezepts",
    "menus.Nomeie sua receita": "Benennen Sie Ihr Rezept",
    "menus.Preencha o nome da receita sem caracteres especiais": "Füllen Sie den Rezeptnamen ohne Sonderzeichen aus",
    "menus.Tipo de prato": "Art der Mahlzeit",
    "menus.SALVAR RECEITAS DO COOKBOOK": "REZEPTE AUS KOCHBUCH SPEICHERN",
    "menus.Selecione as receitas que deseja salvar no grupo de seu menu:": "Wählen Sie die Rezepte aus, die Sie in Ihrer Menügruppe speichern möchten:",
    "menus.Procurar receita": "Rezept suchen",
    "menus.Grupo deletado com sucesso!": "Gruppe erfolgreich gelöscht!",
    "menus.Não foi possivel deletar o grupo": "Gruppe kann nicht gelöscht werden",
    "menus.EXCLUIR GRUPO": "GRUPPE LÖSCHEN",
    "menus.Você deseja excluir o grupo definitivamente?": "Möchten Sie die Gruppe endgültig löschen?",
    "menus.AINDA NÃO HÁ RECEITAS AQUI :(": "HIER SIND IMMER NOCH KEINE REZEPTE :(",
    "menus.Temp. de entrada": "Starttemperatur",
    "menus.Temp. de pré-aquec": "Vorheiztemperatur",
    "menus.Ingredientes para montagem": "Zutaten für die Montage",
    "menus.Instruções operacionais": "Betriebsanleitung",
    "menus.Passo": "Schritt",
    "menus.Temp": "Temperatur ",
    "menus.Temp.": "Temp. ",
    "menus.Tempo": "Zeit",
    "menus.Velocidade do ar": "Luftgeschwindigkeit",
    "menus.Micro-ondas": "Mikrowelle",
    "menus.Resistência inferior": "Niedriger Widerstand",
    "menus.Informações": "Information",
    "menus.Digite as informações do passo": "Geben Sie die Schrittinformationen ein",
    "menus.desativar": "Deaktivieren",
    "menus.Ingredientes": "Zutaten",
    "menus.Temperatura de entrada": "Starttemperatur",
    "menus.Equipamentos compatíveis": "Kompatible Ausrüstung",
    "menus.SALVAR NO COOKBOOK": "Im Kochbuch speichern",
    "menus.ver localização": "Standort anzeigen",
    "menus.SELECIONE RECEITAS PARA EXCLUIR": "REZEPTE ZUM LÖSCHEN AUSWÄHLEN",
    "menus.Você deseja excluir as receitas selecionadas definitivamente": "Möchten Sie die ausgewählten Rezepte endgültig löschen?",
    "menus.Receitas deletadas com sucesso!": "Rezepte erfolgreich gelöscht!",
    "menus.Informe aqui os ingredientes para montagem": "Geben Sie hier die Zutaten für die Montage ein",
    "menus.Informe aqui as instruções operacionais": "Geben Sie hier die Betriebsanweisungen ein",
    "menus.Adicionar passo": "Schritt hinzufügen",
    "menus.Receita criada com sucesso!": "Rezept erfolgreich erstellt!",
    "menus.Ideal para:": "Ideal für: ",
    "menus.editar detalhes": "details bearbeiten",
    "menus.excluir": "löschen",
    "menus.editar": "bearbeiten",
    "menus.Receita editada com sucesso!": "Rezept erfolgreich bearbeitet!",
    "menus.Passo deletado com sucesso!": "Schritt erfolgreich gelöscht!",
    "menus.Não foi possivel deletar o passo": "Schritt kann nicht gelöscht werden",
    "menus.EXCLUIR PASSO": "SCHRITT LÖSCHEN",
    "menus.Você deseja excluir este passo": "Möchten Sie diesen Schritt löschen?",
    "menus.Esta ação não poderá ser desfeita. Caso prefira, é possível apenas desativá-lo, mantendo assim sua configuração.": "Diese Aktion kann nicht rückgängig gemacht werden. Wenn Sie möchten, können Sie ihn nur deaktivieren und seine Einstellungen beibehalten.",
    "menus.Plataforma": "Plattform",
    "menus.Receita salva com sucesso!": "Rezept erfolgreich gespeichert!",
    "menus.Exportar receitas": "Rezepte exportieren",
    "menus.expandir": "Erweitern",
    "menus.Vapor combinado": "Kombidampf",
    "menus.Ar quente": "Heißluft",
    "menus.Sonda": "Probe",
    "menus.Tempo (min)": "Zeit (Min)",
    "menus.Umidade (%)": "Luftfeuchtigkeit (%)",
    "menus.Injeção de vapor": "Dampfeinspritzung",
    "menus.Dumper aberto": "Dumper öffnen",
    "menus.Informe o peso": "Geben Sie das Gewicht an",
    "menus.Informe a temperatura de entrada": "Geben Sie die Starttemperatur an",
    "menus.Informe os ingredientes para montagem": "Geben Sie die Zutaten für die Montage ein",
    "menus.Informe as instruções operacionais": "Geben Sie die Betriebsanweisungen ein",
    "menus.Injeção de vapor (seg)": "Dampfeinspritzung (Sek)",
    "menus.Aquecer mais": "Mehr erhitzen",
    "menus.Dourar mais": "Mehr bräunen",
    "menus.Aquecer e dourar mais": "Mehr erhitzen und bräunen",
    "menus.Favoritar receita": "Lieblingsrezept",
    "menus.Informe o tempo para aquecer mais de 0 a 59 segundos": "Geben Sie die Zeit zum weiteren Erhitzen an, von 0 bis 59 Sekunden",
    "menus.Informe o tempo para dourar mais de 0 a 59 segundos": "Geben Sie die Zeit zum weiteren Bräunen an, von 0 bis 59 Sekunden",
    "menus.Informe o tempo para aquecer e dourar mais de 0 a 59 segundos": "Geben Sie die Zeit zum weiteren Erhitzen und Bräunen an, von 0 bis 59 Sekunden",
    "menus.Apenas favoritos": "Nur Favoriten",
    "menus.Forno combinado": "Kombi-Ofen",
    "menus.Cocção múltipla": "Mehrere Zubereitungen",
    "menus.Receitas Technicook": "Technicook-Rezepte",
    "menus.Editar grupos": "Gruppen bearbeiten",
    "menus.Informe a data": "Datum eingeben",
    "menus.Informe a hora": "Uhrzeit eingeben",
    "menus.até 300": "bis zu 300",
    "menus.Vapor": "Dampf",
    "menus.Turbina": "Turbine",
    "menus.Criar grupo": "Gruppe erstellen",
    "menus.MOVER": "BEWEGEN",
    "menus.Configurações de acessos": "Zugriffseinstellungen:",
    "menus.SELECIONE RECEITAS PARA MOVER": "REZEPTE ZUM VERSCHIEBEN AUSWÄHLEN",
    "menus.AVANÇAR": "VORAN",
    "menus.Selecione ou crie um grupo no mesmo menu para mover as receitas selecionadas ou as mova para outro menu. Note que apenas os menus/grupos compatíveis com as predefinições das receitas serão exibidos.": "Wählen oder erstellen Sie eine Gruppe im gleichen Menü aus, um ausgewählte Rezepte zu verschieben, oder verschieben Sie sie in ein anderes Menü. Beachten Sie, dass nur die Menüs/Gruppen angezeigt werden, die mit den Voreinstellungen des Rezepts kompatibel sind.",
    "menus.Mover receitas para o mesmo menu": "Rezepte im selben Menü verschieben",
    "menus.Mover receitas para outro menu": "Rezepte in ein anderes Menü verschieben",
    "menus.Selecionar grupo": "Gruppe auswählen",
    "menus.Nenhuma receita foi selecionada": "Kein Rezept wurde ausgewählt",
    "menus.Passo editado com sucesso!": "Schritt erfolgreich bearbeitet!",
    "menus.Menu atualizado": "Menü aktualisiert",
    "menus.até 260": "bis zu 260",
    "menus.Umidade na câmara": "Kammertemperatur",
    "menus.Passos": "Schritte",
    "menus.ativar": "aktivieren",
    "menus.Informe a temperatura de pré-aquec": "Geben Sie die Vorheiztemperatur ein",
    "menus.Movas as receitas selecionadas para outro menu. Note que apenas os menus compatíveis com as predefinições das receitas serão exibidos.": "Verschieben Sie ausgewählte Rezepte in ein anderes Menü. Beachten Sie, dass nur die Menüs angezeigt werden, die mit den Voreinstellungen des Rezepts kompatibel sind.",
    "menus.Sonda (°C)": "Probe (ºC)",
    "menus.Turbo Grill": "Turbo-Grill",
    "menus.Pré-aquecimento padrão, conforme passo 1": "Standard-Vorheizung gemäß Schritt 1",
    "menus.salvar": "speichern",
    "menus.até": "bis zu",
    "menus.Verificação de receita": "Rezeptüberprüfung",
    "menus.Auto import": "Automatischer Import",
    "menus.Atualização forçada": "Erzwungenes Update",
    "menus.Lista de receitas exportada com sucesso!": "Rezeptliste erfolgreich exportiert!",
    "menus.Receita movida com sucesso!": "Rezept erfolgreich verschoben!",
    "menus.Receitas movidas com sucesso!": "Rezepte erfolgreich verschoben!",
    "menus.+ Receita": "Rezept",
    "menus.Escolha um idioma compatível com o equipamento": "Wählen Sie eine mit der Ausrüstung kompatible Sprache aus",
    "menus.MENU IMPORTADO COM SUCESSO!": "Menü erfolgreich importiert!",
    "menus.Dourar": "Braun",
    "menus.Não atribuir a menu": "Nicht dem Menü zuweisen",
    "menus.Todos": "Alle",
    "menus.Meus menus": "Meine Menüs",
    "menus.Para salvar uma receita, preencha todos os campos": "Um ein Rezept zu speichern, füllen Sie bitte alle Felder aus",
    "menus.Selecione os grupos de equipamentos para os quais deseja exportar o menu.": "Wählen Sie die Gerätegruppen aus, für die Sie das Menü exportieren möchten.",
    "menus.Você deseja excluir este passo?": "Möchten Sie diesen Schritt löschen?",
    "menus.Formato inválido. São permitidos apenas os formatos .jpeg e .png": "Ungültiges Format. Nur die Formate .jpeg und .png sind erlaubt.",
    "menus.Reordenar grupos": "Gruppen neu anordnen",
    "menus.Reordenar receitas": "Rezepte neu ordnen",
    "menus.ITENS REORDENADOS COM SUCESSO! PARA APLICAR AS ALTERAÇÕES NO FORNO, EXPORTE O MENU.": "ELEMENTE ERFOLGREICH NEU GEORDNET! UM DIE ÄNDERUNGEN IM OFEN ANZUWENDEN, EXPORTIEREN SIE DAS MENÜ.",
    "menus.ARRASTE OS ITENS PARA O LOCAL DESEJADO": "Ziehen Sie die Elemente an die gewünschte Position.",
    "menus.Dumper": "Kipper",
    "menus.Aberto": "Offen",
    "menus.Fechado": "Geschlossen",
    "menus.Intermitente": "Wechselnd",
    "menus.Se o seu menu incluir itens sensíveis à uniformidade, recomendamos definir o Tempo de Estabilização entre 3:00 e 5:00 minutos. Caso contrário, você pode deixá-lo em 0:00 minutos.": "Wenn Ihr Menü Gerichte enthält, die empfindlich auf Gleichmäßigkeit reagieren, empfehlen wir, die Einschwingzeit zwischen 3:00 und 5:00 Minuten einzustellen. Ansonsten können Sie es auch bei 0:00 Minuten belassen.",
    "menus.Selecione o menu que deseja exportar:": "Wählen Sie das Menü aus, das Sie exportieren möchten:",
    "menus.Temperatura de pré-aquecimento:": "Vorheiztemperatur:",
    "menus.Temperatura de lastro": "Ballasttemperatur",
    "menus.Temperatura de teto": "Deckentemperatur",
    "menus.Volume de injeção de vapor (ml)": "Dampfinjektionsvolumen (ml)",
    "menus.Parada de turbina": "Turbinenstopp",
    "menus.Alta": "Hoch",
    "menus.Média": "Mittel",
    "menus.Baixa": "Niedrig",
    "menus.a": "der",
    "menus.Valor da injeção": "Einspritzwert ",
    "menus.Volume: até 999ml": "Volumen: bis zu 999 ml",
    "menus.Tempo: até 15 segundos": "Zeit: bis zu 15 Sekunden",
    "menus.Tempo de injeção de vapor (seg)": "Dampfinjektionszeit (Sek.)",
    "menus.Atenção!": "Aufmerksamkeit!",
    "menus.Verifique as receitas do menu e ajuste os valores da Injeção de Vapor após alterar sua unidade de medida.": "Überprüfen Sie die Menürezepte und passen Sie die Dampfinjektionswerte an, nachdem Sie Ihre Maßeinheit geändert haben.",
    "menus.Certifique-se de que a unidade de temperatura (°C ou °F) definida no equipamento seja a mesma que foi selecionada na criação do menu:": "Stellen Sie sicher, dass die am Gerät definierte Temperatureinheit (°C oder °F) mit der bei der Erstellung des Menüs ausgewählten übereinstimmt:",
    "menus.Injeção desativada": "Injektion deaktiviert",
    "menus.Selecione a unidade de temperatura que está definida no equipamento:": "Wählen Sie die Temperatureinheit aus, die auf dem Gerät definiert ist:",
    "menus.Clique para atualizar": "Klicken Sie zum Aktualisieren",
    "menus.A função Atualização Forçada envia automaticamente o menu para os equipamentos selecionados na próxima vez em que forem ligados.": "Die Forced Update-Funktion sendet das Menü beim nächsten Einschalten automatisch an das ausgewählte Gerät.",
    "menus.As opções selecionadas estarão disponíveis nos equipamentos que receberem este menu, podendo ser utilizadas pelo operador. É possível alterar estas configurações posteriormente no próprio forno ou pela plataforma.": "Die ausgewählten Optionen sind auf Geräten verfügbar, die dieses Menü empfangen, und können vom Bediener verwendet werden. Es ist möglich, diese Einstellungen später im Ofen selbst oder über die Plattform zu ändern.",
    "menus.+ Menu": "+ Menü",
    "menus.Não foi possivel deletar as receitas": "Rezepte können nicht gelöscht werden",
    "menus.PRÉ": "VOR",
    "menus.Speed Ovens": "Speed Ovens",
    "menus.Fornos Combinados": "Kombidämpfer",
    "menus.Fornos de Panificação": "Backöfen",
    "menus.Após a criação, o tipo do menu não pode ser alterado. Ele só pode ser definido no momento da criação.": "Nach der Erstellung kann der Menütitel nicht geändert werden. Er kann nur zum Zeitpunkt der Erstellung festgelegt werden.",
    "menus.Pré-aquecimento do grupo": "Gruppenvorheizung",


    //COOKBOOK
    "cookbook.RECEITAS DISPONÍVEIS": "VERFÜGBARE REZEPTE",
    "cookbook.RECEITAS SALVAS": "GESPEICHERTE REZEPTE",
    "cookbook.ENVIAR PARA MENU": "AN MENU SENDEN",
    "cookbook.SALVAR": "SPEICHERN",
    "cookbook.salvar": "speichern",
    "cookbook.Filtrar por": "Filtern nach",
    "cookbook.Procurar receita do cookbook": "Rezept aus dem Kochbuch suchen",
    "cookbook.Equipamento": "Ausrüstung",
    "cookbook.Ingrediente principal": "Hauptzutat",
    "cookbook.Tipo de prato": "Art der Mahlzeit",
    "cookbook.Região": "Region",
    "cookbook.Pré-aquecimento": "Vorheizen",
    "cookbook.Procurar receita": "Rezept suchen",
    "cookbook.Menu": "Menü",
    "cookbook.ENVIAR": "SENDEN",
    "cookbook.Procurar nome do menu": "Nach dem Namen des Menüs suchen",
    "cookbook.Escolha um grupo deste menu": "Wählen Sie eine Gruppe aus diesem Menü aus",
    "cookbook.Selecionar grupo": "Gruppe auswählen",
    "cookbook.Tivemos um problema. Tente novamente mais tarde": "Wir hatten ein Problem. Versuchen Sie es später erneut",
    "cookbook.Receita adicionada com sucesso!": "Rezept erfolgreich hinzugefügt!",
    "cookbook.Não foi possível adicionar a receita. Tente novamente mais tarde": "Es war nicht möglich, das Rezept hinzuzufügen. Versuchen Sie es später erneut",
    "cookbook.Selecione um menu e um grupo para enviar esta receita": "Wählen Sie ein Menü und eine Gruppe aus, um dieses Rezept zu senden",
    "cookbook.Selecione o modelo do equipamento primeiro": "Wählen Sie zuerst das Modell der Ausrüstung aus",
    "cookbook.Grupo": "Gruppe",
    "cookbook.Não foi possível salvar os passos da receita": "Es war nicht möglich, die Schritte des Rezepts zu speichern",
    "cookbook.Bovina": "Rindfleisch",
    "cookbook.Frango": "Hühnchen",
    "cookbook.Ovos": "Eier",
    "cookbook.Pães": "Brot",
    "cookbook.Peixes": "Fisch",
    "cookbook.Queijo": "Käse",
    "cookbook.Suína": "Schweinefleisch",
    "cookbook.Vegetais": "Gemüse",
    "cookbook.Outros": "Andere",
    "cookbook.Entradas/aperitivos": "Vorspeisen",
    "cookbook.Principais": "Hauptgerichte",
    "cookbook.Snacks": "Snacks",
    "cookbook.Pizzas": "Pizzas",
    "cookbook.Massas": "Pastas",
    "cookbook.Acompanhamentos": "Beilagen",
    "cookbook.Sobremesas": "Desserts",
    "cookbook.Manhã": "Frühstück",
    "cookbook.Padaria": "Bäckerei",
    "cookbook.Todos": "Alle",
    "cookbook.Não atribuir origem": "Keine Herkunft zuweisen",
    "cookbook.Brasil": "Brasilien",
    "cookbook.Alemanha": "Deutschland",
    "cookbook.França": "Frankreich",
    "cookbook.Rússia": "Russland",
    "cookbook.China": "China",
    "cookbook.Japão": "Japan",
    "cookbook.Itália": "Italien",
    "cookbook.Egito": "Ägypten",
    "cookbook.Grécia": "Griechenland",
    "cookbook.Argentina": "Argentinien",
    "cookbook.África": "Afrika",
    "cookbook.Estados Unidos": "Vereinigte Staaten",
    "cookbook.Coreia": "Korea",
    "cookbook.Turquia": "Türkei",
    "cookbook.Ideal para": "Ideal für",
    "cookbook.Polônia": "Polen",
    "cookbook.Sanduíches": "Sandwiches",

    //EQUIPAMENTOS
    "equipment.equipamentos": "Ausrüstung",
    "equipment.procurar equipamento": "Nach Gerät oder Benutzer suchen",
    "equipment.adicionar equipamento": "Ausrüstung hinzufügen",
    "equipment.atualizar equipamento": "Ausrüstung aktualisieren",
    "equipment.+ equipamentos": "Ausrüstung",
    "equipment.buscar equipamento": "Ausrüstung suchen",
    "equipment.Desvincular": "Verknüpfung aufheben",
    "equipment.Editar": "Bearbeiten",
    "equipment.sem conteúdo": "hier sind noch keine Geräte :(",
    "equipment.editar equipamento": "Ausrüstung bearbeitet erfolgreich",
    "equipment.equipamento editado com sucesso!": "Ausrüstung erfolgreich bearbeitet!",
    "equipment.não foi possível editar o equipamento. Tente novamente mais tarde": "Es war nicht möglich, die Ausrüstung zu bearbeiten. Versuchen Sie es später erneut",
    "equipment.Menu exportado com sucesso!": "Menü erfolgreich exportiert!",
    "equipment.Não foi possível exportar o menu. Tente novamente mais tarde": "Es war nicht möglich, das Menü zu exportieren. Versuchen Sie es später erneut",
    "equipment.Selecione pelo menos um equipamento para enviar o menu": "Wählen Sie mindestens eine Ausrüstung aus, um das Menü zu senden",
    "equipment.Informações do menu": "Menüinformationen",
    "equipment.Procurar receita ou grupo": "Rezept oder Gruppe suchen",
    "equipment.Grupo": "Gruppe",
    "equipment.Receita": "Rezept",
    "equipment.Quantidade": "Menge",
    "equipment.Receitas mais preparadas no equipamento durante o período": "Am häufigsten zubereitete Rezepte auf dem Gerät während des Zeitraums",
    "equipment.Informações de limpeza": "Reinigungsinformationen",
    "equipment.Data da limpeza": "Reinigungsdatum",
    "equipment.Hora da limpeza": "Reinigungsstunde",
    "equipment.nome": "Name",
    "equipment.modelo": "Modell",
    "equipment.categoria": "Kategorie",
    "equipment.loja": "Geschäft",
    "equipment.desvincular equipamento": "Ausrüstung entkoppeln",
    "equipment.unlink.paragrafo 1": "Möchten Sie die Ausrüstung von der IOK-Plattform entkoppeln?",
    "equipment.unlink.paragrafo 2": "Dadurch ist es nicht möglich, Ihre Einstellungen und Menüs über die Plattform zu verwalten, es sei denn, Sie verknüpfen die Ausrüstung erneut, indem Sie den Anweisungen zur Verbindung folgen.",
    "equipamento desvinculado com sucesso!": "Ausrüstung erfolgreich entkoppelt!",
    "equipment.ocorreu um erro. Tente novamente mais tarde": "Ein Fehler ist aufgetreten. Versuchen Sie es später erneut",
    "equipment.sim": "Ja",
    "equipment.não": "Nein",
    "equipment.Encerrar acesso remoto": "Ende des Remote-Zugriffs",
    "equipment.Horas sem limpeza": "Stunden ohne Reinigung",
    "equipment.Histórico de eventos": "Ereignishistorie",
    "equipment.Evento": "Ereignis",
    "equipment.Data": "Datum",
    "equipment.ERRO SENSOR RI": "Fehler mit dem Sensor für geringen Widerstand",
    "equipment.ERRO COM INV. DE FREQ.": "Fehler mit dem Frequenzumrichter",
    "equipment.ERRO MAGNETRON": "Magnetronfehler",
    "equipment.ERRO SENSOR CAMARA": "Fehler mit dem Kamerasensor",
    "equipment.ERRO CAN": "CAN-Fehler",
    "equipment.Erro INVERSOR DE FREQUENCIA": "Frequenzumrichterfehler",
    "equipment.ERRO DO MODELO DO EQUIPAMENTO": "Fehler des Ausrüstungsmodells",
    "equipment.ERRO PAINEL": "Panelfehler",
    "equipment.ERRO INV.FREQ.STATUS": "Frequenzumrichterfehlerstatus",
    "equipment.ERRO SENSOR BOARD": "Fehler des Platinensensors",
    "equipment.ERRO ENERGY": "Energiefehler",
    "equipment.ERRO I2C": "I2C-Fehler",
    "equipment.EQUIPAMENTO LIGADO": "Ausrüstung an",
    "equipment.EQUIPAMENTO DESLIGADO": "Ausrüstung aus",
    "equipment.Plataforma": "Plattform",
    "equipment.Forno": "Ofen",
    "equipment.Origem": "Ursprung",
    "equipment.Locais": "Standorte",
    "equipment.não foi possível carregar as entidades": "Es war nicht möglich, die Entitäten zu laden",
    "equipment.conceder acesso remoto a": "Remote-Zugriff gewähren für",
    "equipment.atualizar equipamentos": "Ausrüstung aktualisieren",
    "equipment.suporte": "Support",
    "equipment.PIN": "PIN",
    "equipment.nome do equipamento": "Ausrüstungsname",
    "equipment.vinculado à loja": "Mit Geschäft verknüpft",
    "equipment.cadastrar loja": "Geschäft registrieren",
    "equipment.informe o PIN do forno": "Geben Sie den Ofen-PIN ein",
    "equipment.preencha o PIN do forno": "Füllen Sie den Ofen-PIN aus",
    "equipment.adicione um nome para facilitar seu reconhecimento": "Fügen Sie einen Namen hinzu, um die Erkennung zu erleichtern",
    "equipment.preencha o nome do equipamento": "Füllen Sie den Namen der Ausrüstung aus",
    "equipment.selecionar": "Wählen Sie",
    "equipment.nome fantasia": "Fantasiename",
    "equipment.informe o nome fantasia": "Geben Sie den Fantasienamen ein",
    "equipment.preencha o nome fantasia": "Füllen Sie den Fantasienamen aus",
    "equipment.CNPJ": "CNPJ",
    "equipment.XX.XXX.XXX/0001.XX": "XX.XXX.XXX/0001.XX",
    "equipment.preencha o CNPJ": "Füllen Sie den CNPJ aus",
    "equipment.CEP": "Postleitzahl",
    "equipment.00000-000": "00000-000",
    "equipment.preencha o CEP": "Füllen Sie die Postleitzahl aus",
    "equipment.cidade": "Stadt",
    "equipment.informe a cidade": "Geben Sie die Stadt ein",
    "equipment.preencha a cidade": "Füllen Sie die Stadt aus",
    "equipment.UF": "Bundesland",
    "equipment.bairro": "Stadtteil",
    "equipment.informe o bairro": "Geben Sie den Stadtteil ein",
    "equipment.endereço": "Adresse",
    "equipment.informe o seu endereço": "Geben Sie Ihre Adresse ein",
    "equipment.preencha o seu endereço": "Füllen Sie Ihre Adresse aus",
    "equipment.Nº": "Nr.",
    "equipment.equipamento cadastrado com sucesso!": "Ausrüstung erfolgreich registriert!",
    "equipment.ocorreu um erro": "Ein Fehler ist aufgetreten",
    "equipment.preencha o modelo": "Füllen Sie das Modell aus",
    "equipment.selecione uma categoria": "Wählen Sie eine Kategorie aus",
    "equipment.selecione uma loja": "Wählen Sie ein Geschäft aus",
    "equipment.preencha o bairro": "Füllen Sie den Stadtteil aus",
    "equipment.não foi possível cadastrar o equipamento. Tente novamente mais tarde": "Es war nicht möglich, die Ausrüstung zu registrieren. Versuchen Sie es später erneut",
    "equipment.loja cadastrada com sucesso": "Geschäft erfolgreich registriert",
    "equipment.não foi possível cadastrar a loja. Tente novamente mais tarde": "Es war nicht möglich, das Geschäft zu registrieren. Versuchen Sie es später erneut",
    "equipment.equipment": "Ausrüstung",
    "equipment.stores": "Geschäfte",
    "equipment.search equipment": "Ausrüstung suchen",
    "equipment.add equipment": "Ausrüstung hinzufügen",
    "equipment.update equipment": "Ausrüstung aktualisieren",
    "equipment.plus equipment": "Ausrüstung",
    "equipment.Procurar equipamento": "Ausrüstung suchen",
    "equipment.EQUIPAMENTOS": "AUSRÜSTUNG",
    "equipment.Atualizar equipamento": "Ausrüstung aktualisieren",
    "equipment.Atualizar equipamentos": "Ausrüstung aktualisieren",
    "equipment.confirmar": "bestätigen",
    "equipment.Selecione os modelos que deseja atualizar:": "Wählen Sie die Modelle aus, die Sie aktualisieren möchten",
    "equipment.Como deseja realizar a atualização?": "Wie möchten Sie mit dem Update fortfahren?",
    "equipment.Enviar atualização pela plataforma (wi-fi)": "Update über Plattform senden (Wi-Fi)",
    "equipment.Salvar arquivo de atualização em pen drive": "Update-Datei auf USB-Flash-Laufwerk speichern",
    "equipment.Linha Express": "Express-Linie",
    "equipment.Escolha como deseja realizar a atualização": "Wählen Sie aus, wie Sie mit dem Update fortfahren möchten",
    "equipment.Selecione ao menos um modelo de equipamento para atualizar": "Wählen Sie mindestens ein Ausrüstungsmodell aus, um es zu aktualisieren",
    "equipment.Status:": "Status: ",
    "equipment.Número de série:": "Seriennummer",
    "equipment.Menu:": "Menü",
    "equipment.Loja:": "Geschäft",
    "equipment.End:": "Ende: ",
    "equipment.Cidade:": "Stadt: ",
    "equipment.CEP:": "Postleitzahl: ",
    "equipment.Atualização de software enviada com sucesso!": "Software-Update erfolgreich gesendet!",
    "equipment.Não foi possível enviar a atualização de sodtware. Tente novamente mais tarde": "Es war nicht möglich, das Software-Update zu senden. Versuchen Sie es später erneut",
    "equipment.Download realizado com sucesso": "Download erfolgreich abgeschlossen",
    "equipment.Não foi possível realizar o download. Tente novamente mais tarde": "Es war nicht möglich, den Download abzuschließen. Versuchen Sie es später erneut",
    "equipment.name": "Name",
    "equipment.model": "Modell",
    "equipment.category": "Kategorie",
    "equipment.store": "Geschäft",
    "FIT Express/FIT ST": "FIT Express/FIT ST",
    "Copa/Single Mag/Rocket": "Copa/Single Mag/Rocket",
    "Forza STi": "Forza STi",
    "TSi": "TSi",
    "FIT Express": "FIT Express",
    "FIT ST": "FIT ST",
    "equipment.Preencha o nome da versão": "Füllen Sie den Versionsnamen aus",
    "equipment.Informe aqui todas as alterações desta nova versão": "Geben Sie hier alle Änderungen in dieser neuen Version ein",
    "equipment.Nova versão de software atualizada com sucesso!": "Neue Softwareversion erfolgreich aktualisiert!",
    "equipment.Informe pelo menos uma mudança nesta versão": "Geben Sie mindestens eine Änderung in dieser Version ein",
    "equipment.Falha ao ler o arquivo": "Fehler beim Lesen der Datei",
    "equipment.selecionar tipo": "Typ auswählen",
    "equipment.selecione o tipo da versão": "Versionsart auswählen",
    "equipment.atualização": "UPDATE",
    "equipment.Selecione abaixo o que deseja fazer:": "Wählen Sie unten aus, was Sie tun möchten:",
    "equipment.Atualizar meus equipamentos": "Meine Ausrüstung aktualisieren",
    "equipment.Enviar atualização para usuários selecionados": "Aktualisierung an ausgewählte Benutzer senden",
    "equipment.ENVIAR": "SENDEN",
    "equipment.ENVIAR ATUALIZAÇÃO": "AKTUALISIERUNG SENDEN",
    "equipment.Selecione os e-mails para os quais deseja enviar a atualização:": "Wählen Sie die E-Mails aus, an die Sie die Aktualisierung senden möchten:",
    "equipment.A atualização disponibilizada no dia": "Die verfügbare Aktualisierung am Tag",
    "equipment.referente ao equipamento": "bezüglich der Ausrüstung",
    "equipment.contempla o seguinte:": "berücksichtigt Folgendes:",
    "equipment.Selecionar arquivo para envio": "Datei zum Senden auswählen",
    "equipment.Por favor, selecione um arquivo .tar": "Bitte wählen Sie eine .tar-Datei aus",
    "equipment.Selecione o modelo do equipamento:": "Ausrüstungsmodell auswählen:",
    "equipment.Selecione uma opção": "Option auswählen",
    "equipment.Nomeie a versão": "Nenne die Version",
    "equipment.Clique sobre o arquivo que deseja baixar:": "Klicken Sie auf die Datei, die Sie herunterladen möchten:",
    "equipment.Baixar atualização": "Aktualisierung herunterladen",
    "equipment.Versões": "Versionen",
    "equipment.e superiores": "und höher",
    "FIT Express, FIT ST": "FIT Express, FIT ST",
    "Copa Express, Rocket Express, Copa Single Mag": "Copa Express, Rocket Express, Copa Single Mag",
    "equipment.e": "es ist",
    "equipment.Últimas versões de software:": "Neueste Softwareversionen: ",
    "equipment.Você não possui nenhum equipamento desatualizado": "Sie haben keine veraltete Ausrüstung",
    "equipment.grupos": "Gruppen",
    "equipment.não foi possível carregar os grupos de equipamentos da companhia": "Unternehmensausrüstungsgruppen können nicht geladen werden",
    "equipment.vinculado ao grupo": "Mit der Gruppe verknüpft",
    "equipment.selecione um grupo": "Wählen Sie eine Gruppe aus",
    "equipment.cadastrar grupo": "Gruppe registrieren",
    "equipment.Número de Série": "S/N",
    "equipment.Versão": "Version",
    "equipment.não foi possível cadastrar o equipamento. Selecione ou cadastre um grupo": "Gerät konnte nicht registriert werden. Wählen Sie eine Gruppe aus oder registrieren Sie eine",
    "equipment.CRIE GRUPOS DE EQUIPAMENTOS!": "CRIE GERÄTEGRUPPEN!",
    "equipment.Para que os usuários vinculados à conta possam visualizar os equipamentos você precisa:": "Damit Benutzer, die mit dem Konto verknüpft sind, die Geräte sehen können, müssen Sie:",
    "equipment.1) Criar grupo(s);": "1) Gruppe(n) erstellen;",
    "equipment.2) Atribuir equipamento(s) ao grupo;": "2) Gerät(e) der Gruppe zuweisen;",
    "equipment.3) Atribuir usuário(s) ao grupo.": "3) Benutzer der Gruppe zuweisen.",
    "equipment.Status software": "Softwarestatus",
    "equipment.Status menu": " Menüstatus",
    "equipment.Pendente": "Ausstehend",
    "equipment.Aceito": "Akzeptiert",
    "equipment.Recusado": "Abgelehnt",
    "equipment.Atualizar": "Aktualisieren",
    "equipment.Deseja enviar a atualização para o equipamento selecionado?": "Möchten Sie das Update an das ausgewählte Gerät senden?",
    "equipment.Companhia": "Unternehmen",
    "equipment.Exportar menu": "Menü Exportieren",
    "equipment.companhias": "Firmen",
    "equipment.Nome da companhia": "Name der Firma",
    "equipment.Procurar equipamento, usuário ou companhia": "Suchen Sie nach Geräten, Benutzern oder Unternehmen",
    "equipment.Versão da power": "Power-Version",
    "equipment.AINDA NÃO HÁ DADOS DE RECEITAS AQUI :(": "HIER GIBT ES NOCH KEINE REZEPTDATEN :(",
    "equipment.AINDA NÃO HÁ DADOS DE LIMPEZAS AQUI :(": "HIER GIBT ES NOCH KEINE REINIGUNGSDATEN :(",
    "equipment.AINDA NÃO HÁ DADOS DE EVENTOS AQUI :(": "HIER GIBT ES NOCH KEINE VERANSTALTUNGSDATEN :(",
    "ID": "ID",
    "equipment.Hora": "Stunde",
    "equipment.EVENTOS MAIS OCORRIDOS NO EQUIPAMENTO DURANTE O PERÍODO": "DIE MEISTEN EREIGNISSE AN DER AUSRÜSTUNG WÄHREND DES ZEITRAUMS AUFTRETEN",
    "equipment.hour": "std",
    "equipment.minutes": "min",
    "equipment.date format": "TT/MM/JJJJ",
    "equipment.preparada": "zubereitet",
    "equipment.vezes": "male",
    "equipment.vez": "mal",
    "equipment.Ver": "Anzeigen",
    "equipment.Os equipamentos enviarão os dados à plataforma ao serem ligados. A cada dois meses, os dados recebidos serão automaticamente excluídos.": "Beim Einschalten sendet das Gerät Daten an die Plattform. Alle zwei Monate werden die erhaltenen Daten automatisch gelöscht.",
    "equipment.Ligado": "Eingeschaltet",
    "equipment.Desligado": "Ausgeschaltet",
    "equipment.CAN ERROR": "CAN-Fehler",
    "equipment.CAMERA SENSOR ERROR": "Kamerasensorfehler",
    "equipment.STEAM/RI SENSOR ERROR": "RI-Sensorfehler",
    "equipment.PROBE 1 ERROR": "Sondenwarnung 1",
    "equipment.PROBE 2 ERROR": "Sondenwarnung 2",
    "equipment.PROBE 3 ERROR": "Sondenwarnung 3",
    "equipment.PROBE 4 ERROR": "Sondenwarnung 4",
    "equipment.PROBE 5 ERROR": "Sondenwarnung 5",
    "equipment.PROBE 6 ERROR": "Sondenwarnung 6",
    "equipment.GENERAL PROBE ERROR": "Allgemeiner Sondenfehler",
    "equipment.WATER LACK ERROR": "Warnung vor Wassermangel",
    "equipment.HIGH PANEL TEMPERATURE ERROR": "Übertemperaturwarnung auf dem Armaturenbrett",
    "equipment.FREQUENCY INVERTER GENERAL ERROR": "Frequenzumrichterfehler",
    "equipment.FREQUENCY INVERTER COMMUNICATION ERROR": "Kommunikationsalarm am Frequenzumrichter",
    "equipment.FREQUENCY INVERTER STATUS ERROR": "Warnung zum Status des Frequenzumrichters",
    "equipment.CLEANING NOT COMPLETED ERROR": "Warnung wegen unvollständiger Reinigung",
    "equipment.MAGNETRON ERROR": "Magnetron-Alarm",
    "equipment.EQUIPMENT FAMILY/MODEL ERROR": "Gerätemodellfehler",
    "equipment.BOARD TEMPERATURE SENSOR ERROR": "Alarm des Platinentemperatursensors",
    "equipment.ENERGY MODULE ERROR": "Energiemodulfehler",
    "equipment.POWER BOARD I2C BUS ERROR": "Leistungsplatinenfehler",
    "equipment.GAS SYSTEM ERROR": "Gasfehler",
    "equipment.VERY HIGH PANEL TEMPERATURE ERROR": "Fehler bei extrem hoher Paneltemperatur",
    "equipment.POWER ON": "Ein",
    "equipment.POWER OFF": "Aus",
    "equipment.DOOR OPENED": "Tür offen",
    "equipment.DOOR CLOSED": "Tür geschlossen",
    "equipment.CLEANING CANCELED ERROR": "Desinfektion durch Benutzer abgebrochen",
    "equipment.PIN não encontrado. Por favor, verifique e tente novamente": "PIN nicht gefunden. Bitte überprüfen Sie es und versuchen Sie es erneut",
    "equipment.Online": "Online",
    "equipment.Offline": "Offline",
    "equipment.Exibir apenas eventos": "Nur Ereignisse anzeigen",
    "equipment.Endereço": "Adresse",
    "equipment.EQUIPMENT CONNECTED TO THE PLATFORM": "Mit der Plattform verbundene Ausrüstung",
    "equipment.EQUIPMENT DISCONNECTED FROM THE PLATFORM": "Ausrüstung von der Plattform getrennt",
    "equipment.MENU RECEIVED FROM THE PLATFORM": "Menü von der Plattform erhalten",
    "equipment.MENU REJECTED BY THE USER": "Vom Benutzer abgelehntes Menü",
    "equipment.CONNECTED TO THE INTERNET": "Mit dem Internet verbunden",
    "equipment.DISCONNECTED FROM THE INTERNET": "Vom Internet getrennt",
    "equipment.Infelizmente não há versões que realizem o envio de dados compatíveis com este equipamento.": "Leider gibt es keine Versionen, die Datenübertragungen durchführen, die mit diesem Gerät kompatibel sind.",
    "equipment.O equipamento": "Das Gerät",
    "equipment.deve ser atualizado para a versão mais recente de software para que passe a enviar os dados para a plataforma": "muss auf die neueste Softwareversion aktualisiert werden, damit es Daten an die Plattform senden kann",
    "equipment.Versão do seu equipamento": "Version Ihres Geräts",
    "equipment.Versão disponível": "Verfügbare Version",
    "equipment.Selecione abaixo como deseja enviar a atualização para seu equipamento": "Wählen Sie unten aus, wie Sie das Update an Ihr Gerät senden möchten",
    "equipment.Salvar em pen drive": "Auf USB-Stick speichern",
    "equipment.Enviar por wi-fi": "Über WLAN senden",
    "equipment.Cliente": "Klient",
    "equipment.NÚMERO DE SÉRIE NÃO ENCONTRADO": "SERIENNUMMER NICHT GEFUNDEN",
    "equipment.BUSCAR": "SUCHEN",
    "equipment.ACESSADOS RECENTEMENTE": "KÜRZLICH ZUGRIFFEN",
    "equipment.SEM BUSCAS RECENTES": "KEINE LETZTEN SUCHEN",
    "equipment.Procurar equipamento por NS": "Suche nach NS-Geräten",
    "equipment.AIR FILTER CLEANING COMPLETED": "Luftfilterreinigung abgeschlossen",
    "equipment.AIR FILTER CLEANING REJECTED": "Luftfilterreinigung abgelehnt",
    "equipment.CATALYTIC FILTER CLEANING COMPLETED": "Katalytische Filterreinigung abgeschlossen",
    "equipment.CATALYTIC FILTER CLEANING REJECTED": "Katalytische Filterreinigung abgelehnt",
    "equipment.ID do equipamento": "Geräte-ID",
    "equipment.Todo equipamento deve ser vinculado a um grupo. Selecione um grupo da lista ou cadastre um novo clicando em + Cadastrar grupo.": "Alle Geräte müssen einer Gruppe zugeordnet sein. Wählen Sie eine Gruppe aus der Liste aus oder registrieren Sie eine neue, indem Sie auf + Gruppe registrieren klicken.",
    "equipment.Nomeie seu forno": "Benennen Sie Ihren Ofen",
    "equipment.Para visualizar o PIN, acesse nas configurações do equipamento a Plataforma Virtual IOK.": "Um die PIN anzuzeigen, greifen Sie in den Geräteeinstellungen auf die IOK Virtual Platform zu.",
    "equipment.localização": "Standort",
    "equipment.AINDA NÃO HÁ NENHUMA LOCALIZAÇÃO CADASTRADA :(": "ES IST NOCH KEIN STANDORT REGISTRIERT :(",
    "equipment.Procurar empresa ou local": "Suchen Sie nach Firma oder Standort",
    "equipment.Adicionar localização": "Ort hinzufügen",
    "equipment.espaço": "Raum",
    "equipment.local": "Lokal",
    "equipment.EXCLUIR LOCALIZAÇÃO": "STANDORT LÖSCHEN",
    "equipment.Você deseja excluir esta localização?": "Möchten Sie diesen Standort löschen?",
    "equipment.Ao fazer isso, os equipamentos atrelados à ela não exibirão nenhuma localização, sendo necessário informar um novo local para eles.": "Wenn Sie dies tun, zeigen die damit verbundenen Geräte keinen Standort an, so dass für sie ein neuer Standort angegeben werden muss.",
    "equipment.Localização deletada com sucesso!": "Standort erfolgreich gelöscht!",
    "equipment.Erro ao deletar a localização. Tente novamente mais tarde": "Fehler beim Löschen des Standorts. Versuchen Sie es später erneut",
    "equipment.Não foi possivel deletar a localização": "Standort kann nicht gelöscht werden",
    "equipment.Localização adicionada com sucesso!": "Standort erfolgreich hinzugefügt!",
    "equipment.Não foi possível adicionar a localização. Tente novamente mais tarde": "Standort kann nicht hinzugefügt werden. Versuchen Sie es später erneut",
    "equipment.Nome do espaço": "Raumname",
    "equipment.Informe o nome do espaço": "Geben Sie den Namen des Raums ein",
    "equipment.Preencha o nome do espaço": "Geben Sie den Namen des Raums ein",
    "equipment.país": "Land",
    "equipment.Endereço (rua, avenida, rodovia)": "Adresse (Straße, Allee, Autobahn)",
    "equipment.Nº/Km": "Anzahl/Km",
    "equipment.informe o país": "Geben Sie das Land ein",
    "equipment.preencha o país": "Geben Sie das Land ein",
    "equipment.Localização editada com sucesso!": "Standort erfolgreich bearbeitet!",
    "equipment.Não foi possível editar a localização. Tente novamente mais tarde": "Standort kann nicht bearbeitet werden. Versuchen Sie es später erneut",
    "equipment.Editar localização": "Standort bearbeiten",
    "equipment.Pico de preparações": "Höchstwert der Zubereitungen",
    "equipment.informações da semana": "Informationen der Woche",
    "equipment.horários de pico": "Spitzenzeiten",
    "equipment.horário de pico": "Spitzenzeit",
    "equipment.preparações do dia": "Zubereitungen des Tages",
    "equipment.Horário": "Zeit",
    "equipment.Preparações": "Zubereitungen",
    "equipment.dia selecionado": "ausgewählten Tag",
    "equipment.Vinculação IOK": "IOK-Bindung",
    "equipment.Última preparação": "Letzte Zubereitung",
    "equipment.A limpeza adequada e diária do forno auxilia em seu funcionamento correto e impacta na garantia.": "Eine ordnungsgemäße und tägliche Reinigung des Ofens trägt zur ordnungsgemäßen Funktion bei und wirkt sich auf die Garantie aus.",
    "equipment.Total de limpezas realizadas:": "Gesamtreinigung durchgeführt:",
    "equipment.Limpezas não concluídas:": "Reinigungen nicht abgeschlossen:",
    "equipment.Desde a vinculação do forno a IOK": "Vom Anschluss des Ofens an IOK",
    "equipment.Equipamento ligado há:": "Gerät eingeschaltet seit:",
    "equipment.Total de receitas preparadas::": "Gesamtzahl der zubereiteten Rezepte:",
    "equipment.Receitas preparadas": "Zubereitete Rezepte",
    "equipment.Aberturas de porta": "Türöffnungen",
    "equipment.Desde a última vez que o forno foi ligado": "Seit dem letzten Einschalten des Ofens",
    "equipment.Preparação": "Zubereitung",
    "equipment.Nenhuma localização criada": "Keine Standorte erstellt",
    "equipment.Acesso remoto": "Fernzugriff",
    "equipment.Informe o ID da empresa que poderá acessar remotamente seu forno. Use ; para separar IDs.": "Geben Sie die ID des Unternehmens ein, das aus der Ferne auf Ihren Ofen zugreifen kann. Verwenden ; um IDs zu trennen.",
    "equipment.O acesso remoto permite que as empresas selecionadas enviem menus e atualizações para seu equipamento.": "Durch den Fernzugriff können ausgewählte Unternehmen Menüs und Aktualisierungen an Ihre Geräte senden.",
    "equipment.Concedo acesso remoto à Prática oficial.": "Ich gewähre Fernzugriff auf die offizielle Prática.",
    "equipment.Deseja selecionar outros equipamentos vinculados à sua conta para conceder acesso remoto a eles?": "Möchten Sie andere mit Ihrem Konto verknüpfte Geräte auswählen, um ihnen Fernzugriff zu gewähren?",
    "equipment.Selecione abaixo os equipamentos para os quais deseja conceder acesso remoto à": "Wählen Sie unten das Gerät aus, für das Sie Fernzugriff gewähren möchten",
    "equipment.Acesso remoto concedido com sucesso!": "Fernzugriff erfolgreich gewährt!",
    "equipment.Selecione pelo menos um equipamento": "Wählen Sie mindestens ein Gerät aus",
    "equipment.Não foi possível editar os equipamentos. Tente novamente mais tarde": "Ausrüstung kann nicht bearbeitet werden. Versuchen Sie es später noch einmal",
    "equipment.Solicitar atendimento": "Service anfordern",
    "equipment.Relate um problema": "Melden Sie ein Problem",
    "equipment.Descreva aqui o problema de seu equipamento": "Beschreiben Sie hier Ihr Geräteproblem",
    "equipment.Não foi possível enviar sua solicitação de atendimento. Tente mais tarde": "Wir konnten Ihre Serviceanfrage nicht senden. Versuchen Sie es später",
    "equipment.Solicitação técnica enviada com sucesso!": "Technische Anfrage erfolgreich gesendet!",
    "equipment.Enviar": "Bestätigen",
    "equipment.Solicitação de atendimento": "Serviceanfrage",
    "equipment.Informações do magnetron": "Magnetron-Informationen",
    "equipment.Vida útil aproximada": "Geschätzte Lebensdauer",
    "equipment.MAGNETRON USAGE HOURS": "Magnetron-Betriebsstunden",
    "equipment.Filtros": "Filter",
    "equipment.Filtrar": "Filtern",
    "equipment.Limpar filtros": "Filter zurücksetzen",
    "equipment.Procurar por modelo, versão ou país": "Nach Modell, Version oder Land suchen",
    "equipment.Procurar equipamento, NS, modelo, versão ou país": "Gerät, S/N, Modell, Version oder Land suchen",
    "equipment.Atendimento": "Service",
    "equipment.Eventos mais ocorridos no equipamento durante o período": "Ereignisse, die während des Zeitraums am häufigsten auf dem Gerät aufgetreten sind",
    "equipment.EQUIPAMENTO SEM NÚMERO DE SÉRIE": "GERÄT OHNE SERIENNUMMER",
    "equipment.Os seguintes IDs não pertencem a uma companhia do tipo 'Distribuidor' e foram removidos": "Die folgenden IDs gehören nicht zu einem Unternehmen vom Typ 'Händler' und wurden entfernt",
    "equipment.BAIXAR VERSÕES": "VERSIONEN HERUNTERLADEN",
    "equipment.Compatível com todas as versões da IHM.": "Kompatibel mit allen HMI-Versionen.",
    "equipment.Compatível apenas com a versão 2.0.0C da Power.": "Nur kompatibel mit Power-Version 2.0.0C.",
    "equipment.Procurar evento": "Ereignis suchen",
    "equipment.Forno conectado": "Ofen verbunden",
    "equipment.Limpeza de filtro de ar": "Reinigung des Luftfilters",
    "equipment.Limpeza de filtro catalítico": "Reinigung des Katalysatorfilters",
    "equipment.Forno ligado": "Ofen eingeschaltet",
    "equipment.Inversor de frequência": "Frequenzumrichter",
    "equipment.Temperatura de painel": "Schalttafeltemperatur",
    "equipment.Sensor da câmara": "Kamersensor",
    "equipment.Magnetron": "Magnetron",
    "equipment.Power": "Power",
    "equipment.Informações de menu": "Menüinformationen",
    "equipment.Limpeza": "Reinigung",
    "equipment.O equipamento já está utilizando a versão mais recente do software.": "Die Ausrüstung verwendet bereits die neueste Version der Software.",

    //LOJAS
    "store.Procurar loja": "Geschäft suchen",
    "store.Excluir": "Löschen",
    "store.Editar": "Bearbeiten",
    "store.nome": "Name",
    "store.equipamentos": "Ausrüstung",
    "store.cidade": "Stadt",
    "store.estado": "Bundesland",
    "store.salvar": "Speichern",
    "store.cadastrar loja": "Geschäft registrieren",
    "store.Nome fantasia": "Fantasiename",
    "store.Preencha o nome da loja": "Geben Sie den Geschäftsnamen ein",
    "store.CNPJ": "CNPJ",
    "store.Preencha o CNPJ da loja": "Geben Sie den CNPJ des Geschäfts ein",
    "store.CEP": "Postleitzahl",
    "store.Preencha o CEP da loja": "Geben Sie die Postleitzahl des Geschäfts ein",
    "store.Informe o nome fantasia": "Fantasiename ausfüllen",
    "store.Cidade": "Stadt",
    "store.Informe a cidade": "Geben Sie die Stadt ein",
    "store.Preencha a cidade": "Füllen Sie die Stadt aus",
    "store.UF": "Bundesland",
    "store.Preencha o UF da loja": "Füllen Sie das Bundesland des Geschäfts aus",
    "store.Bairro": "Stadtteil",
    "store.Informe o bairro": "Geben Sie den Stadtteil ein",
    "store.Preencha o bairro da loja": "Füllen Sie den Stadtteil des Geschäfts aus",
    "store.Endereço": "Adresse",
    "store.Informe o endereço": "Geben Sie die Adresse ein",
    "store.Preencha o endereço da loja": "Füllen Sie die Adresse des Geschäfts aus",
    "store.Nº": "Nr.",
    "store.Loja cadastrada com sucesso": "Geschäft erfolgreich registriert",
    "store.Erro ao adicionar loja. Tente novamente": "Fehler beim Hinzufügen des Geschäfts. Versuchen Sie es erneut",
    "store.Loja deletada com sucesso": "Geschäft erfolgreich gelöscht",
    "store.Erro ao deletar loja. Tente novamente mais tarde": "Fehler beim Löschen des Geschäfts. Versuchen Sie es später erneut",
    "store.AINDA NÃO HÁ NENHUMA LOJA CADASTRADA :(": "ES SIND NOCH KEINE GESCHÄFTE REGISTRIERT :(",
    "store.EXCLUIR LOJA": "GESCHÄFT LÖSCHEN",
    "store.Você deseja excluir a loja da plataforma IOK?": "Möchten Sie das Geschäft von der IOK-Plattform löschen?",
    "store.Ao fazer isso, os equipamentos atrelados à ela não terão vínculo com nenhuma loja, a menos que os vincule novamente.": "Dadurch wird die damit verknüpfte Ausrüstung nicht mit einem Geschäft verknüpft, es sei denn, Sie verknüpfen sie erneut.",
    "store.loja editada com sucesso": "Geschäft erfolgreich bearbeitet",
    "store.não foi possível editar a loja. Tente novamente mais tarde": "Es war nicht möglich, das Geschäft zu bearbeiten. Versuchen Sie es später erneut",
    "store.editar loja": "Geschäft bearbeiten",
    "store.Procurar grupo de equipamentos": "Gerätegruppe durchsuchen",
    "store.cadastrar grupo": "Gruppe registrieren",
    "store.AINDA NÃO HÁ NENHUM GRUPO CADASTRADO :(": "ES IST NOCH KEINE GRUPPE REGISTRIERT :(",
    "store.cadastrar grupo de equipamentos": "Gerätegruppe registrieren",
    "store.grupo de equipamentos cadastrado com sucesso!": "Gerätegruppe erfolgreich registriert!",
    "store.não foi possível cadastrar o grupo. Tente novamente mais tarde": "Die Gruppe kann nicht registriert werden. Versuchen Sie es später erneut",
    "store.Nome do grupo": "Name der Gruppe",
    "store.informe o nome do grupo": "Geben Sie den Gruppennamen ein",
    "store.preencha o nome do grupo": "Geben Sie den Gruppennamen ein",
    "store.Grupo de equipamentos deletado com sucesso!": "Gerätegruppe erfolgreich gelöscht!",
    "store.Erro ao deletar o grupo. Tente novamente mais tarde": "Fehler beim Löschen der Gruppe. Versuchen Sie es später erneut",
    "store.EXCLUIR GRUPO DE EQUIPAMENTOS": "GERÄTEGRUPPE LÖSCHEN",
    "store.Você deseja excluir este grupo de equipamentos da plataforma IOK?": "Möchten Sie diese Gerätegruppe von der IOK-Plattform löschen?",
    "store.Ao fazer isso, os equipamentos atrelados à ele não terão vínculo com nenhum grupo, a menos que os vincule novamente.": "Wenn Sie dies tun, werden die damit verknüpften Geräte keiner Gruppe zugeordnet, es sei denn, Sie verknüpfen sie erneut.",
    "store.grupo de equipamentos editado com sucesso!": "Gerätegruppe erfolgreich bearbeitet!",
    "store.não foi possível editar o grupo. Tente novamente mais tarde": "Die Gruppe kann nicht bearbeitet werden. Versuchen Sie es später erneut",
    "store.editar grupo de equipamentos": "Gerätegruppe bearbeiten",
    "store.CEP não encontrado no Brasil": "Postleitzahl in Brasilien nicht gefunden",

    //NAVBAR
    "navbar.home": "Startseite",
    "navbar.cookbook": "Kochbuch",
    "navbar.my menus": "Meine Menüs",
    "navbar.equipment": "Ausrüstung",
    "navbar.users": "Benutzer",
    "navbar.calls": "Dienstleistungen",
    "navbar.settings": "Einstellungen",
    "navbar.help": "Hilfe",
    "navbar.logout": "Abmelden",
    "navbar.Editar conta": "Konto bearbeiten",
    "navbar.API Externa": "Externe API",

    //FOOTERBAR
    "footerBar.menu": "Menü",
    "footerBar.cookbook": "Kochbuch",
    "footerBar.home": "Startseite",
    "footerBar.menus": "Menüs",
    "footerBar.notifications": "Benachrichtigungen",

    //ATENDIMENTOS
    "calls.ATENDIMENTOS EM ABERTO": "LAUFENDE DIENSTLEISTUNGEN",
    "calls.ATENDIMENTOS ENCERRADOS": "GESCHLOSSENE DIENSTLEISTUNGEN",

    //MAPA
    "map.título": "Standort",
    "map.erro": "Fehler",
    "map.mensagem de erro": "Karte konnte nicht geladen werden",
    "map.Localização não disponível": "Standort nicht verfügbar",

    //MODAL
    "modal.CONFIRMAR": "BESTÄTIGEN",
    "modal.Salvar alterações": "Änderungen speichern",
    "Modal.Há alterações não salvas nos parâmetros dos passos": "Es gibt ungespeicherte Änderungen an den Schrittparametern",
    "Modal.Como deseja proceder?": "Wie möchten Sie fortfahren?",
    "Modal.Avaliar alterações realizadas": "Gemachte Änderungen bewerten",
    "Modal.Descartar alterações": "Änderungen verwerfen",

    //USUÁRIOS
    "user.Nome": "Name",
    "user.E-mail": "E-Mail",
    "user.Permissão": "Berechtigung",
    "user.Loja": "Speichern",
    "user.Status": "Status",
    "user.Excluir": "Löschen",
    "user.Editar": "Bearbeiten",
    "user.Procurar usuário": "Benutzer suchen",
    "user.ADICIONAR USUÁRIO": "BENUTZER HINZUFÜGEN",
    "user.AINDA NÃO HÁ NENHUM USUÁRIO CADASTRADO :(": "ES SIND NOCH KEINE BENUTZER REGISTRIERT :(",
    "user.+ Usuário": "+ Benutzer",
    "user.Informe o nome do usuário": "Benutzernamen eingeben",
    "user.Preencha o nome do usuário": "Benutzernamen ausfüllen",
    "user.Informe o e-mail do usuário": "Die E-Mail-Adresse des Benutzers eingeben",
    "user.Preencha com um e-mail válido": "Geben Sie eine gültige E-Mail-Adresse ein",
    "user.Administrador": "Administrator",
    "user.Usuário": "Benutzer",
    "user.Preencha o telefone do usuário": "Benutzertelefonnummer ausfüllen",
    "user.Selecione a permissão do usuário": "Benutzerberechtigung auswählen",
    "user.Ativo": "Aktiv",
    "user.Inativo": "Inaktiv",
    "user.Usuário deletado da plataforma com sucesso!": "Benutzer erfolgreich von der Plattform entfernt!",
    "user.Ocorreu um erro. Tente novamente mais tarde": "Ein Fehler ist aufgetreten. Versuchen Sie es später erneut",
    "user.EXCLUIR USUÁRIO": "BENUTZER LÖSCHEN",
    "user.delete.paragrafo 1": "Möchten Sie den Benutzer von der IOK-Plattform löschen?",
    "user.delete.paragrafo 2": "Dadurch verlieren sie den Zugriff auf alle Plattform-Tools, es sei denn, sie werden erneut hinzugefügt, indem sie den Anweisungen zur Registrierung folgen.",
    "user.EDITAR USUÁRIO": "BENUTZER BEARBEITEN",
    "user.Usuário editado com sucesso!": "Benutzer erfolgreich bearbeitet!",
    "user.Não foi possível editar este usuário. Por favor, tente mais tarde": "Es war nicht möglich, diesen Benutzer zu bearbeiten. Bitte versuchen Sie es später erneut.",
    "user.INFORMAÇÕES DO USUÁRIO": "BENUTZERINFORMATIONEN",
    "user.Telefone": "Telefon",
    "user.Não foi possível cadastrar este usuário. Por favor, tente mais tarde": "Es war nicht möglich, diesen Benutzer zu registrieren. Bitte versuchen Sie es später erneut.",
    "user.Usuário adicionado com sucesso!": "Benutzer erfolgreich hinzugefügt!",
    "user.CRIAR TIPO DE USUÁRIO": "BENUTZERTYP ERSTELLEN",
    "user.Criar novo tipo de usuário": "Neuen Benutzertyp erstellen",
    "user.Adicionar novo usuário": "Neuen Benutzer hinzufügen",
    "user.Permissões": "Berechtigungen",
    "user.Preencha o nome do novo tipo de usuário": "Füllen Sie den Namen des neuen Benutzertyps aus",
    "user.Selecione pelo menos uma permissão": "Wählen Sie mindestens eine Berechtigung aus",
    "user.Não foi possível carregar as permissões": "Es war nicht möglich, die Berechtigungen zu laden",
    "user.Tipo de usuário criado com sucesso!": "Benutzertyp erfolgreich erstellt!",
    "user.Não foi possível criar este novo tipo de usuário. Por favor, tente mais tarde": "Es war nicht möglich, diesen neuen Benutzertyp zu erstellen. Bitte versuchen Sie es später erneut.",
    "user.Não foi possível carregar os tipos de usuário da companhia": "Es war nicht möglich, die Benutzertypen des Unternehmens zu laden",
    "user.Tipo de usuário": "Benutzertyp",
    "user.E-mail já cadastrado na plataforma. Informe um novo e-mail": "E-Mail ist bereits auf der Plattform registriert. Geben Sie eine neue E-Mail-Adresse ein",
    "user.UNIR CONTAS": "KONTEN BEITRETEN",
    "user.ID da companhia": "Firmen-ID",
    "user.Digite o ID da companhia que deseja unir a sua conta": "Geben Sie die ID des Unternehmens ein, mit dem Sie Ihr Konto zusammenführen möchten",
    "user.Preencha o ID da companhia": "Geben Sie die Firmen-ID ein",
    "user.O administrador da conta com a qual deseja se unir poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.":
      "Der Administrator des Kontos, dem Sie beitreten möchten, kann die ID finden, indem er im oberen Menü in der rechten Ecke des Bildschirms auf Ihren Benutzernamen klickt.",
    "user.O administrador da outra conta receberá um e-mail para aceitar sua solicitação. Assim que for aceita, a união das duas contas será realizada!":
      "Der Administrator des anderen Kontos erhält eine E-Mail, um Ihre Anfrage anzunehmen. Nach der Annahme werden die beiden Konten zusammengeführt!",
    "user.Não foi possível solicitar a união de contas. Por favor, confirme o ID ou tente mais tarde": "Kontozusammenführung kann nicht angefordert werden. Bitte bestätigen Sie die ID oder versuchen Sie es später",
    "user.SOLICITAÇÃO APROVADA COM SUCESSO": "ANFRAGE ERFOLGREICH GENEHMIGT",
    "user.AGUARDE ENQUANTO REALIZAMOS A UNIÃO DAS CONTAS. ESTE PROCESSO PODE LEVAR ALGUNS MINUTOS...": "BITTE WARTEN SIE, WÄHREND WIR DIE KONTEN VERBINDEN. DIESER VORGANG KANN EINIGE MINUTEN DAUERN...",
    "user.UNIÃO DE CONTAS FINALIZADA COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "KONTENVEREINIGUNG ERFOLGREICH ABGESCHLOSSEN! Klicken Sie auf die Schaltfläche unten, um auf die Plattform zuzugreifen.",
    "user.NÃO CONSEGUIMOS REALIZAR A UNIÃO DAS CONTAS. POR FAVOR, TENTE NOVAMENTE. SE O PROBLEMA PERSISTIR, ENTRE EM CONTATO COM NOSSO SUPORTE.":
      "WIR KÖNNEN DIE KONTEN NICHT ZUSAMMENFÜHREN. BITTE VERSUCHE ES ERNEUT. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an unseren Support.",
    "user.Grupo de equipamentos": "Gerätegruppe",
    "user.Informe o telefone do usuário": "Geben Sie die Telefonnummer des Benutzers ein",
    "user.Nenhum grupo criado": "Keine Gruppen erstellt",
    "user.possui todos os acessos": "hat sämtlichen Zugriff",
    "user.possui o acesso das abas cookbook, meus menus e equipamentos": "hat Zugriff auf das Kochbuch, meine Menüs und Geräteregisterkarten",
    "user.possui apenas o acesso da aba cookbook": "hat nur Zugriff auf die Registerkarte Kochbuch",
    "user.possui apenas o acesso da aba equipamentos": "hat nur Zugriff auf die Registerkarte Ausrüstung",
    "user.possui apenas o acesso da aba meus menus": "hat nur Zugriff auf die Registerkarte Meine Menüs",
    "user.Digite o ID da companhia que terá acesso à sua conta": "Geben Sie die ID des Unternehmens ein, das Zugriff auf Ihr Konto haben soll",
    "user.Ao informar um ID para unir contas com sua companhia, esteja ciente de que o novo usuário também se tornará Administrador da conta de sua companhia. É importante informar que a partir do momento em que um usuário aceitar unir contas com sua companhia, a companhia pertencente a ele deixará de existir.":
      "Beachten Sie bei der Angabe einer ID zum Zusammenführen von Konten mit Ihrem Unternehmen, dass der neue Benutzer auch zum Administrator des Kontos Ihres Unternehmens wird. Es ist wichtig zu informieren, dass das zu ihm gehörende Unternehmen nicht mehr existiert, sobald ein Benutzer zustimmt, Konten bei Ihrem Unternehmen anzumelden.",
    "user.Exemplo: Sua conta: 001 | Outra conta: 111. Depois de unir contas com você, a outra conta passará a ser 001.": "Beispiel: Ihr Konto: 001 | Ein anderes Konto: 111. Nachdem Sie die Konten mit Ihnen zusammengeführt haben, wird das andere Konto zu 001.",
    "user.O administrador da outra conta poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.": "Der Administrator des anderen Kontos kann die ID finden, indem er im oberen Menü in der rechten Ecke des Bildschirms auf Ihren Benutzernamen klickt.",

    //TUTORIAIS
    "tutorial.Fechar": "Schließen",
    "tutorial.Fim": "Ende",
    "tutorial.Próximo": "Weiter",
    "tutorial.Abrir": "Öffnen",
    "tutorial.Pular tutorial": "Tutorial überspringen ",
    "tutorial.Editar detalhes da receita": "Rezeptdetails bearbeiten",
    "tutorial.Agora você pode adicionar mais detalhes às suas receitas e deixá-las ainda mais completas. Clique em Editar detalhes e preencha as informações necessárias.": "Jetzt können Sie weitere Details zu Ihren Rezepten hinzufügen und sie noch vollständiger gestalten. Klicken Sie auf Details bearbeiten und füllen Sie die erforderlichen Informationen aus.",
    "tutorial.Salvar edição da receita": "Rezeptbearbeitung speichern",
    "tutorial.Depois de informar mais detalhes da receita, clique em Salvar.": "Nachdem Sie weitere Rezeptdetails eingegeben haben, klicken Sie auf Speichern",
    "tutorial.Editar registro de um equipamento": "Ausrüstungsregistrierung bearbeiten",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Categoria e Loja.": "Um die Registrierung Ihrer Ausrüstung abzuschließen, öffnen Sie das Seitenmenü, klicken Sie auf Bearbeiten und füllen Sie die Kategorie- und Geschäftsinformationen aus.",
    "tutorial.Editar usuário": "Benutzer bearbeiten",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente a Loja a qual ele faz parte.": "Um die Benutzerregistrierung abzuschließen, öffnen Sie das Seitenmenü, klicken Sie auf Bearbeiten und füllen Sie die Informationen zum Geschäft aus, dem sie angehören.",
    "tutorial.Salvar edições de um equipamento": "Änderungen an einer Ausrüstung speichern",
    "tutorial.Depois de completar o registro de seus equipamentos, clique em Salvar.": "Nachdem Sie die Registrierung Ihrer Ausrüstung abgeschlossen haben, klicken Sie auf Speichern.",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em uma loja, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "Durch Klicken auf die Zeile einer in einem Geschäft registrierten Ausrüstung können Sie weitere Statistiken und Informationen dazu anzeigen und außerdem einen Service anfordern oder Updates auslösen.",
    "tutorial.Para visualizar mais informações sobre a loja, como localização e receitas mais preparadas, clique sobre a linha da unidade que deseja analisar.": "Um weitere Informationen über das Geschäft zu sehen, wie Standort und am häufigsten zubereitete Rezepte, klicken Sie auf die Zeile der Einheit, die Sie analysieren möchten.",
    "tutorial.Criar receita": "Rezept erstellen",
    "tutorial.No cookbook você pode criar suas próprias receitas! Clique em Criar Receita e preencha todas as informações necessárias.": "Im Kochbuch können Sie Ihre eigenen Rezepte erstellen! Klicken Sie auf Rezept erstellen und füllen Sie alle erforderlichen Informationen aus.",
    "tutorial.Editar ou excluir um usuário": "Benutzer bearbeiten oder löschen",
    "tutorial.Para excluir ou editar as informações de um usuário já cadastrado, clique no menu lateral e selecione a opção desejada.": "Um die Informationen eines registrierten Benutzers zu löschen oder zu bearbeiten, klicken Sie auf das Seitenmenü und wählen Sie die gewünschte Option aus.",
    "tutorial.Gerenciamento de usuários": "Benutzerverwaltung",
    "tutorial.Aqui você pode gerenciar seus usuários com mais facilidade. Ao clicar em + Usuário, você poderá adicionar um novo usuário ou criar um novo tipo de usuário.": "Hier können Sie Ihre Benutzer einfacher verwalten. Durch Klicken auf + Benutzer können Sie einen neuen Benutzer hinzufügen oder einen neuen Benutzertyp erstellen.",
    "tutorial.Suas receitas": "Ihre Rezepte",
    "tutorial.Pelo cookbook você também encontra suas próprias receitas, além de poder criá-las! Clique em Receitas Salvas para visualizar as receitas que foram salvas do cookbook da Prática e também as criadas por você.": "Durch das Kochbuch können Sie auch Ihre eigenen Rezepte finden und diese erstellen! Klicken Sie auf Gespeicherte Rezepte, um die gespeicherten Rezepte aus dem Kochbuch von Prática und die von Ihnen erstellten Rezepte anzuzeigen.",
    "tutorial.Filtros do Cookbook": "Kochbuchfilter",
    "tutorial.Para facilitar a navegação pelas receitas, você pode aplicar filtros a elas. Basta clicar no filtro desejado e escolher uma de suas opções.": "Um die Navigation durch die Rezepte zu erleichtern, können Sie Filter darauf anwenden. Sie müssen nur auf den gewünschten Filter klicken und eine seiner Optionen auswählen.",
    "tutorial.Cookbook": "Kochbuch",
    "tutorial.No cookbook, em Receitas Disponíveis, você encontrará as receitas criadas pelos Chefs da Prática e poderá utilizá-las sempre que quiser.": "Im Kochbuch unter Verfügbare Rezepte finden Sie die von den Köchen von Prática erstellten Rezepte und können sie bei Bedarf verwenden.",
    "tutorial.Atualizar equipamentos": "Ausrüstung aktualisieren",
    "tutorial.Para enviar uma atualização a seus equipamentos, clique em Atualizar Equipamentos, selecione os modelos que deseja atualizar e a forma como deseja realizar essa atualização - via wi-fi ou pen drive. Depois disso, clique em Confirmar.": "Um ein Update an Ihre Ausrüstung zu senden, klicken Sie auf Ausrüstung aktualisieren, wählen Sie die Modelle aus, die Sie aktualisieren möchten, und wie Sie dies tun möchten - über Wi-Fi oder USB-Flash-Laufwerk. Klicken Sie anschließend auf Bestätigen.",
    "tutorial.Adicionar equipamento": "Ausrüstung hinzufügen",
    "tutorial.Para adicionar um equipamento à sua conta, clique em Adicionar Equipamento e preencha as informações do modal. Depois disso, clique em Salvar.": "Um eine Ausrüstung zu Ihrem Konto hinzuzufügen, klicken Sie auf Ausrüstung hinzufügen und füllen Sie die Modalinformationen aus. Klicken Sie anschließend auf Speichern.",
    "tutorial.Editar equipamento": "Ausrüstung bearbeiten",
    "tutorial.Para desvincular um equipamento ou editar suas informações, clique no menu lateral e selecione a opção desejada.": "Um eine Ausrüstung zu entkoppeln oder ihre Informationen zu bearbeiten, klicken Sie auf das Seitenmenü und wählen Sie die gewünschte Option aus.",
    "tutorial.Seus equipamentos": "Ihre Ausrüstung",
    "tutorial.Aqui você visualizará todos os equipamentos cadastrados em sua plataforma.": "Hier sehen Sie alle in Ihrer Plattform registrierten Geräte.",
    "tutorial.Restaurar arquivos": "Dateien wiederherstellen",
    "tutorial.Para restaurar um arquivo excluído ou deletá-lo permanentemente, clique no menu lateral e selecione a opção desejada.": "Um eine gelöschte Datei wiederherzustellen oder sie dauerhaft zu löschen, klicken Sie auf das Seitenmenü und wählen Sie die gewünschte Option aus.",
    "tutorial.Lixeira": "Papierkorb",
    "tutorial.Os menus excluídos serão mantidos na Lixeira por 30 dias. Depois desse prazo serão excluídos permanentemente.": "Die gelöschten Menüs werden 30 Tage lang im Papierkorb aufbewahrt. Nach dieser Frist werden sie dauerhaft gelöscht.",
    "tutorial.Salvar edições de usuário": "Benutzeränderungen speichern",
    "tutorial.Depois de completar o cadastro dos usuários, clique em Salvar.": "Nach Abschluss der Benutzerregistrierung klicken Sie auf Speichern.",
    "tutorial.Criar menu": "Menü erstellen",
    "tutorial.Para criar e personalizar um menu, clique em Criar Menu e preencha as informações necessárias no modal. Você pode editar um menu a qualquer momento.": "Um ein Menü zu erstellen und anzupassen, klicken Sie auf Menü erstellen und füllen Sie die erforderlichen Informationen im Modal aus. Sie können ein Menü jederzeit bearbeiten.",
    "tutorial.Importar menu": "Menü importieren",
    "tutorial.Para importar um menu, clique em Importar Menu e selecione o modelo e versão do equipamento.": "Um ein Menü zu importieren, klicken Sie auf Menü importieren und wählen Sie das Gerätemodell und die Version aus.",
    "tutorial.Criar grupo": "Gruppe erstellen",
    "tutorial.Para organizar suas receitas em grupos, clique em Criar Grupo e preencha as informações necessárias no modal.": "Um Ihre Gruppenrezepte zu verwalten, klicken Sie auf Gruppe erstellen und füllen Sie die erforderlichen Informationen im Modal aus.",
    "tutorial.Tipos de usuário": "Benutzertypen",
    "tutorial.Agora você também pode criar tipos de usuários com permissões específicas para atender melhor a sua organização.": "Sie können jetzt auch Benutzertypen mit spezifischen Berechtigungen erstellen, um Ihrer Organisation einen besseren Service zu bieten.",
    "tutorial.Gerenciamento de contas": "Kontoverwaltung",
    "tutorial.Se caso outra pessoa da sua organização também migrou a conta principal da empresa, aqui você pode unir as duas contas.": "Wenn jemand anderes in Ihrer Organisation ebenfalls sein Hauptunternehmenskonto migriert hat, können Sie hier die beiden Konten zusammenführen.",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Grupo.": "Um die Registrierung Ihrer Ausrüstung abzuschließen, öffnen Sie das Seitenmenü, klicken Sie auf Bearbeiten und geben Sie die Gruppeninformationen ein.",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente ao Grupo de equipamentos a qual ele faz parte.": "Um die Benutzerregistrierung abzuschließen, öffnen Sie das Seitenmenü, klicken Sie auf Bearbeiten und geben Sie die Informationen zur Gerätegruppe ein, zu der sie gehört.",
    "tutorial.Grupos de equipamentos": "Gerätegruppen",
    "tutorial.Na aba Grupos você pode visualizar os grupos cadastrados e os equipamentos distribuídos entre eles. Além disso, é possível cadastrar novos grupos e editar os já cadastrados.": "Auf der Registerkarte „Gruppen“ können Sie die registrierten Gruppen und die darauf verteilte Ausrüstung anzeigen. Darüber hinaus besteht die Möglichkeit, neue Gruppen zu registrieren und bereits registrierte zu bearbeiten.",
    "tutorial.Detalhes de grupos": "Gruppendetails",
    "tutorial.Para visualizar mais informações sobre o grupo, como localização e receitas mais preparadas, clique sobre a linha que deseja analisar.": "Um weitere Informationen über die Gruppe anzuzeigen, wie den Standort und die am häufigsten zubereiteten Rezepte, klicken Sie auf die Zeile, die Sie analysieren möchten.",
    "tutorial.Equipamentos em grupos": "Ausrüstung in Gruppen",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em um grupo, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "Wenn Sie auf die Zeile eines in einer Gruppe registrierten Geräts klicken, können Sie weitere Statistiken und Informationen darüber anzeigen und außerdem einen Service anfordern oder Updates auslösen.",
    "tutorial.Grupos": "Gruppen",
    "tutorial.Atualização e cache": "Aktualisieren und zwischenspeichern",
    "tutorial.Para atualizar a plataforma e corrigir eventuais problemas de cache, clique aqui.": "Klicken Sie hier, um die Plattform zu aktualisieren und etwaige Cache-Probleme zu beheben.",
    "tutorial.Dados dos equipamentos": "Gerätedaten",
    "tutorial.Ao clicar sobre a linha de um equipamento, você pode visualizar mais estatísticas e informações sobre ele, como as receitas mais preparadas, limpezas realizadas e outros dados relevantes.": "Wenn Sie auf die Zeile eines Geräts klicken, können Sie weitere Statistiken und Informationen dazu anzeigen, z. B. die am häufigsten zubereiteten Rezepte, durchgeführte Reinigungen und andere relevante Daten.",
    "tutorial.Para alterar o idioma de sua plataforma, clique em Idioma e selecione a linguagem desejada.": "Um die Sprache Ihrer Plattform zu ändern, klicken Sie auf Sprache und wählen Sie die gewünschte Sprache aus.",
    "tutorial.Alterar idioma": "Ändere die Sprache",
    "tutorial.Para editar ou configurar sua conta, clique em seu nome e selecione a opção desejada.": "Um Ihr Konto zu bearbeiten oder zu konfigurieren, klicken Sie auf Ihren Namen und wählen Sie die gewünschte Option aus.",
    "tutorial.Editar conta": "Konto bearbeiten",
    "tutorial.Total de Usuários Cadastrados em sua conta.": "Gesamtzahl der in Ihrem Konto registrierten Benutzer.",
    "tutorial.Home": "Heim",
    "tutorial.Localização de seus equipamentos, sendo possível expandir o mapa para melhor visualização;": "Standort Ihrer Ausrüstung, sodass die Karte zur besseren Anzeige erweitert werden kann;",
    "tutorial.Atualizações referentes a versão dos equipamentos vinculados a sua conta;": "Aktualisierungen bezüglich der mit Ihrem Konto verknüpften Geräteversion;",
    "tutorial.Total de Receitas Cadastradas;": "Gesamter registrierter Umsatz;",
    "tutorial.Menus Disponíveis;": "Verfügbare Menüs;",
    "tutorial.Esta é a sua home! Aqui você encontra um resumo geral sobre sua conta, como quantidade de Equipamentos Vinculados;": "Das ist dein Zuhause! Hier finden Sie eine allgemeine Zusammenfassung Ihres Kontos, z. B. die Anzahl der verknüpften Geräte.",
    "tutorial.Clique nas abas do menu para explorar a plataforma e conhecer tudo o que ela pode oferecer.": "Klicken Sie auf die Menüregisterkarten, um die Plattform zu erkunden und alles zu entdecken, was sie zu bieten hat.",
    "tutorial.Exportar menu": "Menü „Exportieren“",
    "tutorial.Depois de personalizar seu menu, exporte-o para seus equipamentos pela internet ou por pen drive.": "Nachdem Sie Ihr Menü angepasst haben, exportieren Sie es über das Internet oder einen USB-Stick auf Ihre Geräte.",
    "tutorial.Na aba Localização você pode visualizar os endereços já cadastrados, além de criar localizações precisas para vincular seus equipamentos a elas.": "Auf der Registerkarte „Standort“ können Sie die bereits registrierten Adressen anzeigen und genaue Standorte erstellen, um Ihre Geräte damit zu verknüpfen.",
    "tutorial.Localização": "Standort",

    //RECOVER ACCOUNT
    "recoverAccount.Para prosseguir, selecione a categoria na qual você se encaixa:": "Um fortzufahren, wählen Sie die Kategorie aus, der Sie angehören:",
    "recoverAccount.Deseja acessar a plataforma como DISTRIBUIDOR? Crie sua conta clicando em EMPRESA e entre em contato conosco para liberarmos os demais acessos.": "Möchten Sie auf die Plattform als VERTRETER zugreifen? Erstellen Sie Ihr Konto, indem Sie auf das Unternehmen klicken, und kontaktieren Sie uns, um die anderen Zugänge freizugeben.",
    "recoverAccount.A qual organização você pertence": "Zu welcher Organisation gehören Sie?",

    //RECOVER PASSWORD
    "recoverPassword.E-mail para recuperação de senha": "E-Mail für die Wiederherstellung des Passworts",
    "recoverPassword.Informe o e-mail cadastrado na plataforma": "Geben Sie die auf der Plattform registrierte E-Mail-Adresse an",
    "recoverPassword.E-mail inválido. Por favor, verifique": "Ungültige E-Mail-Adresse. Bitte überprüfen Sie diese.",
    "recoverPassword.RECUPERAR SENHA": "PASSWORT WIEDERHERSTELLEN",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL COM O LINK DE RECUPERAÇÃO DE SENHA": "IN KÜRZE ERHALTEN SIE EINE E-MAIL MIT DEM LINK ZUR PASSWORTWIEDERHERSTELLUNG",
    "recoverPassword.Login": "Anmelden",
    "recoverPassword.Informe seu e-mail": "Geben Sie Ihre E-Mail-Adresse an",
    "recoverPassword.Crie uma nova senha": "Ein neues Passwort erstellen",
    "recoverPassword.O campo senha deve conter no mínimo 6 caracteres": "Das Passwortfeld muss mindestens 6 Zeichen lang sein",
    "recoverPassword.Confirme sua senha": "Passwort bestätigen",
    "recoverPassword.As senhas devem ser iguais": "Die Passwörter müssen übereinstimmen",
    "recoverPassword.ENTRAR": "EINGEBEN",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL PARA CONFIRMAR SUA IDENTIDADE": "IN KÜRZE ERHALTEN SIE EINE E-MAIL, UM IHRE IDENTITÄT ZU BESTÄTIGEN",
    "recoverPassword.CONTINUAR": "WEITER",
    "recoverPassword.CONFIRMAR": "BESTÄTIGEN",

    // EXPORT
    "export.Limpezas": "Reinigungen",
    "export.Eventos": "Ereignisse",
    "export.Lista de receitas preparadas": "Liste der zubereiteten Rezepte",
    "export.Lista de limpezas realizadas": "Liste der durchgeführten Reinigungen",
    "export.Lista de eventos ocorridos": "Liste der aufgetretenen Ereignisse",
    "export.Exportar dados": "Daten exportieren",
    "export.Informações do equipamento": "Geräteinformationen",
    "export.Dados exportados com sucesso!": "Daten erfolgreich exportiert!",
    "export.Não foi possível exportar os dados. Por favor, tente novamente mais tarde.": "Daten konnten nicht exportiert werden. Bitte versuchen Sie es später noch einmal.",
    "export.Detalhes do menu": "Menüdetails",
    "export.Detalhes de receitas": "Rezeptdetails",
    "export.Detalhes e Passos": "Details und Schritte",
    "export.Exportando dados. Em breve o arquivo estará disponível!": "Daten werden exportiert. Die Datei wird in Kürze verfügbar sein!",
    "export.Resumo dos passos": "Zusammenfassung der Schritte",
    "export.salvar imagem": "bild speichern"
  },
};

export default de;