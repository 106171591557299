import styled, { css } from 'styled-components';
import * as DesignSystem from '../../../assets/styles/StyleTypes';
import invalidIcon from '../../../assets/image/invalid.png';
import Edit from '../../../assets/image/Edit.svg';

export const Div = styled.div`
  display: flex;
  flex-direction: column;
 // margin-bottom: 18px;
  width: 100%;
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const Label = styled.label`
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: medium;
  margin: 0 0 10px 0;
  color: ${(props) => props.theme.colors.textPrimary};
  letter-spacing: 0px;
  &.white-theme {
    color: ${DesignSystem.Colors.HIGH_PURE};
  }
`;

export const Small = styled.small`
  font-size: 10px;
  font-weight: 400;
  margin: 5px 0 0 0;
  color: ${(props) => props.theme.colors.textErrorInput};
  font-family: Roboto, sans-serif;
  &.white-theme {
    /* color: ${(props) => props.theme.colors.textErrorInput}; */
    color: ${DesignSystem.Colors.TOAST_WARNING};
    font-weight: 700;
  }
`;

export const InputPassword = styled.div`
  height: 40px;
  border-radius: ${DesignSystem.BorderRadius.SM};
  padding: 0 10px;
  color: ${(props) => props.theme.colors.textPrimary};
  /* border: 1px solid ${(props) => props.theme.colors.textPrimary}; */
  background-color: ${(props) => props.theme.colors.backgroundInput};
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  & svg {
      color: ${(props) => props.theme.colors.placeholderInputPassword};
  }

  &.white-theme {
    /* color: ${DesignSystem.Colors.LOW_PURE}; */
    color: ${(props) => props.theme.colors.textPrimary};
    border: 1px solid ${(props) => props.theme.colors.backgroundInput};
    background-color: ${DesignSystem.Colors.HIGH_LIGHT};
    /* background-color: ${DesignSystem.Colors.HIGH_PURE}; */

    & > svg {
      color: ${(props) => props.theme.colors.placeholderInputPassword};
    }

    > input {
      background: none;
      color: ${DesignSystem.Colors.LOW_PURE};
      &::placeholder {
        color: #c9c9c9;
      }
    }
  }

  & > div {
    height: 40px;
    width: 40px;
    background: transparent;
  }

  & > input {
    width: 100%;
    border: none;
    background: ${(props) => props.theme.colors.backgroundInput};
    color: ${(props) => props.theme.colors.textPrimary};
    height: 40px;
    line-height: 41px;
    outline: none;
    &::placeholder {
      color: ${(props) => props.theme.colors.placeholderInputPassword}
    }
  }
`;

export const Input = styled.input`
  height: 40px;
  border-radius: ${DesignSystem.BorderRadius.SM};
  padding: 0 10px;
  //padding: ${DesignSystem.Padding.XS};
  color: ${(props) => props.theme.colors.textPrimary};
  border: none;
  background-color: ${(props) => props.theme.colors.backgroundInput};
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  outline: none;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled {
    color: #A6A6A6;
  }

  &::placeholder {
    color: #A6A6A6;
  }

  &.invalid {
    border: 1px solid ${DesignSystem.Colors.TOAST_WARNING};
    background-color: ${(props) => props.theme.colors.backgroundInputError};
    color: ${(props) => props.theme.colors.textErrorInput};
    background-image: url(${invalidIcon});
    background-repeat: no-repeat;
    background-size: 20px auto;
    background-position: 97% center;
  }

  &.white-theme {
    color: ${DesignSystem.Colors.LOW_PURE};
    border: 1px solid ${DesignSystem.Colors.HIGH_PURE};
    background-color: ${DesignSystem.Colors.HIGH_PURE};
    &::placeholder {
      color: #bebebe;
    }
  }

  &.white-theme.invalid {
    border: 1px solid ${DesignSystem.Colors.TOAST_WARNING};
    background-color: ${DesignSystem.Colors.ERROR_WARNING};
    color: ${DesignSystem.Colors.LOW_PURE};
    background-image: url(${invalidIcon});
    background-repeat: no-repeat;
    background-size: 20px auto;
    background-position: 97% center;
  }
`;

interface InputProps {
  paddingInfo?: boolean
}

export const InputArea = styled.textarea<InputProps>`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    max-width: 100% !important;
    min-height: 60px;
    border: 1px solid #cecece;
    border-radius: 5px;
    padding:10px;
    color: ${(props) => props.theme.colors.textPrimary};
    border: none;
    background-color: ${(props) => props.theme.colors.backgroundInput};
    outline: none;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    resize: none;
    &::placeholder {
        color: #A6A6A6;
        font-size: 12px;
    }

    ${props => props.paddingInfo &&
    css`
      padding: 20px;
    `
  }
`;
