import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  runTutorials: false
}

export const tutorialsSlice = createSlice({
  name: 'tutorials',
  initialState,
  reducers: {
    setRunTutorials(state, action) {
      state.runTutorials = action.payload;
    }
  }
})

export const { setRunTutorials } = tutorialsSlice.actions;
export default tutorialsSlice.reducer;