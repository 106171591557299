import React, {
  useEffect,
  useRef,
  useState,
} from "react";

import { LoadingWrapper, DivSlider, ImageSlider, ProgressBar, Progress, Message } from "./styles";

interface LoadingComponentProps {
  images: string[];
  messages?: string[];
  showProgress?: boolean;
  progressBarColor?: string;
  interval?: number;
  isLoginPage?: boolean
}


const RotateBanner: React.FC<LoadingComponentProps> = ({
  images,
  messages = [],
  showProgress = false,
  progressBarColor = '',
  interval = 3000,
  isLoginPage = false
}) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [activeMessageIndex, setActiveMessageIndex] = useState(0);
  const autoPlayRef = useRef<Function>();

  const nextSlide = () => {
    setActiveImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const nextMessage = () => {
    if (messages) {
      setActiveMessageIndex((prevIndex) => (prevIndex === messages.length - 1 ? 0 : prevIndex + 1));
    }
  }

  useEffect(() => {
    autoPlayRef.current = () => {
      nextSlide();
      nextMessage();
    };
  }, [images.length, messages.length]);

  useEffect(() => {
    const play = () => {
      if (autoPlayRef.current) {
        autoPlayRef.current();
      }
    };
    const intervalId = setInterval(play, interval);

    return () => clearInterval(intervalId);
  }, [interval]);

  return (
    <LoadingWrapper>
      <DivSlider id="slider" isLoginPage={isLoginPage}>
        {images.map((image, index) => {
          return (
            <ImageSlider
              key={image}
              className={activeImageIndex === index ? "selected" : "slide"}
              src={image}
              alt={`Imagem ${index + 1}`}
              isLoginPage={isLoginPage}
            />
          );
        })}
      </DivSlider>
      {showProgress && messages && (
        <>
          <ProgressBar progressBarColor={progressBarColor ?? ''}>
            <Progress progressBarColor={progressBarColor ?? ''} />
          </ProgressBar>
          {messages && (
            <Message>{messages[activeMessageIndex]}</Message>
          )}
        </>
      )}
    </LoadingWrapper>
  );
};

export default RotateBanner;
