import React from 'react';
import * as Styled from './styles';
import { useIntl } from 'react-intl';
import PrimaryButton from '../../FormElements/PrimaryButton';
import Cookie from '../../../assets/image/Cookie.png';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../../db/db';
import { privacyPolicyByLangugue } from '../../../utils/privacyPolicy';

type ModalProps = {
    id: number;
    onConfirm: () => void;
  };

const PrivacyBanner: React.FC<ModalProps> = (props) => {
    const intl = useIntl();
    const userConfigsDb = useLiveQuery(() => db.userConfigs.toArray());
  
    const handleShowBanner = () => {
        const submit = {
            userConfigs: {
              id: userConfigsDb && userConfigsDb[0].id,
              showBanner: false
            }
          }
          db.userConfigs.update(props.id, submit.userConfigs);
          props.onConfirm();
    };

    return (
        <Styled.PrivacyBanner>
            <Styled.Image><img src={Cookie} alt='Cookie' /></Styled.Image>
            <Styled.Text>                
                {intl.formatMessage({ id: 'login.Para melhorar a sua experiência na plataforma e oferecer serviços personalizados, utilizamos cookies.'})}<br></br>
                {intl.formatMessage({ id: 'login.Ao utilizar a Plataforma IOK, você automaticamente concorda com seu uso.'})}                        
                <Styled.Link href={privacyPolicyByLangugue(userConfigsDb && userConfigsDb[0]!.language ? userConfigsDb[0]!.language : 'PT')} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: 'login.Política de privacidade'})}</Styled.Link>.
            </Styled.Text>
            <PrimaryButton type={'button'} model={'white-outline'} size={'large'} width={'168px'} onClick={handleShowBanner}>{intl.formatMessage({ id: 'login.ENTENDI'})}</PrimaryButton>
        </Styled.PrivacyBanner>
    );
};

export default PrivacyBanner;
