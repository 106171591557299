export const LOCALES = {
  EN: 'en-us',
  DE: 'de-de',
  FR: 'fr-ca',
  PT: 'pt-br',
  ES: 'es-es',
  RU: 'ru-ru',
  PL: 'pl-pl',
  EL: 'el-gr',
  ZH: 'zh-zh'
};
