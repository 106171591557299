import styled from 'styled-components';

export const PrivacyBanner = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #001D3A; 
    color: #ffffff;
    text-align: center;
    z-index: 1000;
    display: flex;
    align-items: center;
    opacity: 0;
    animation: showBanner forwards 3s;

    button {
        margin-right: 100px;
    }

    @keyframes showBanner {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 90%;
        }
    }

    @media (max-width: 500px) {
        display: block;

        button {
            margin-right: 0;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
`;

export const Text = styled.div`
    margin: 50px;
    padding-right: 10px;
    width: 100%;
    font-family: Roboto;
    font-weight: 500;
    font-size: 20px;
    text-align: start;

    @media (max-width: 500px) {
        margin: 0px;
        text-align: center;
    }
`;

export const Link = styled.a`
    color: #ffffff; 
    text-decoration: underline;
    margin-left: 5px;
`;

export const Image = styled.div`
    margin-left: 50px;

    @media (max-width: 500px) {
        margin-top: 20px;
        margin-left: 0px;
        margin-bottom: 20px;
    }
`;