import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StepConvectionOvenModel } from '../../models/stepConvectionOven';

interface StepsConvectionOvenState {
  steps: StepConvectionOvenModel[];
}

const initialState: StepsConvectionOvenState = {
  steps: [],
};

const stepsConvectionOvenSlice = createSlice({
  name: 'stepsConvectionOven',
  initialState,
  reducers: {
    setStepsConvectionOven(state, action: PayloadAction<StepConvectionOvenModel[]>) {
      state.steps = action.payload;
    },
    addStepConvectionOven(state, action: PayloadAction<StepConvectionOvenModel>) {
      state.steps.push(action.payload);
    },
    removeStepConvectionOven(state, action: PayloadAction<number>) {
      state.steps = state.steps.filter(step => step.id !== action.payload);
    },
    updateStepConvectionOven(state, action: PayloadAction<{ index: number; changes: Partial<StepConvectionOvenModel> }>) {
      const { index, changes } = action.payload;
      const step = state.steps[index];
      if (step) {
         state.steps[index] = { ...step, ...changes };
      }
    },
    clearStepsConvectionOven(state) {
      state.steps = [];
    }
  }
});

export const { setStepsConvectionOven, addStepConvectionOven, removeStepConvectionOven, updateStepConvectionOven, clearStepsConvectionOven } = stepsConvectionOvenSlice.actions;

export default stepsConvectionOvenSlice.reducer;