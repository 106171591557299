import styled from "styled-components";
import { Colors } from '../../../assets/styles/StyleTypes'

type DivSelectProps = {
  isLogin: boolean;
}

export const DivSelectLangStyle = styled.div<DivSelectProps>`
  position: relative;
  width: 70%;
  max-width: 300px;

  & .selected {
    width: 100%;
    color: ${props => props.isLogin ? Colors.PRIMARY_HIGHLIGHT : props.theme.colors.textHeader};
    padding: 5px;
    font-weight: 700;
    font-size: 16px;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: right;
    align-items: center;
    margin: 0;

    img {
      margin: 0px 5px;
      height: 25px;
    }
    .down-arrow {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid ${props => props.isLogin ? Colors.PRIMARY_HIGHLIGHT : props.theme.colors.textHeader};
      color: ${props => props.isLogin ? Colors.PRIMARY_HIGHLIGHT : props.theme.colors.textHeader};
    }

    .up-arrow {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid ${props => props.isLogin ? Colors.PRIMARY_HIGHLIGHT : props.theme.colors.textHeader};
      color: ${props => props.isLogin ? Colors.PRIMARY_HIGHLIGHT : props.theme.colors.textHeader};
    }
  }

  & .dropdown {    
    background-color: ${props => props.isLogin ? Colors.HIGH_MEDIUM : props.theme.colors.dropdownBackground};   
    margin: 0;
    padding: 0;
    padding-right: 15px;
    position: absolute;
    right: 0;
    width: 100%;
    /* width: 106px;   */
    border-radius: 5px;
    box-shadow: ${props => props.theme.shadows.modalShadow};

    li {    
      color: ${props => props.isLogin ? Colors.LOW_PURE : props.theme.colors.textPrimary};
      list-style-type: none;
      width: 100%;
      text-align: right;
      font-size: 12px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-right: 15px;
      cursor: pointer;
      font-family: Roboto, sans-serif;
      font-weight: normal;

      :hover {
        background-color: ${props => props.isLogin ? Colors.HIGH_DARK : props.theme.colors.dropdownHover};
      }
    }
  }

  @media (max-width: 960px) {
    width: fit-content;

    span {
      color: ${Colors.HIGH_PURE};
    }

    & .selected {
      width: auto;
      .up-arrow {
        border-bottom: 5px solid ${Colors.HIGH_PURE};
      }
      .down-arrow {
        border-top: 5px solid ${Colors.HIGH_PURE};
      }
    }
  }
`;
